import React, { useEffect, useState } from 'react'
import './ViewEducator.scss';
import searchIcon from '../../../../Assets/common/search_icon.svg';
import searchCloseIcon from '../../../../Assets/common/search_close_icon.png';
import { IconButton, Modal, Switch, TablePagination } from '@mui/material';
import { getEducatorList, deleteEducator, updateEducator, updateEducatorState, resetEducatorPassword } from '../../../../Services/EducatorService';
import viewCollegeIcon from './../../../../Assets/memberImg/view.svg';
import editCollegeIcon from './../../../../Assets/memberImg/edit.svg';
import deleteCollegeIcon from './../../../../Assets/memberImg/delete.png';
import Swal from 'sweetalert2';
import actionBtnIcon from './../../../../Assets/common/actionBtn.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import resetPasswordICon from './../../../../Assets/common/resetPassword.svg';
import biometricIcon from './../../../../Assets/memberImg/fingerprint.svg';
import NavigateBack from '../../../CommonScreens/NavigateBack/NavigateBack';
import noDataFoundImg from '../../../../Assets/memberImg/noEducatorFound.png';
import { useNavigate } from 'react-router-dom';
import { SelectCollegeModal } from '../../../../Components/SelectCollegeModal/SelectCollegeModal';
import downArrow from "../../../../Assets/commonImg/select-dropdown-arrow.svg";
import SearchField from '../../../../Components/searchField/searchField';
import { alpha, styled } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';
import MembersContainer from '../../../../Components/MembersContainer/MembersContainer';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchByEntityName from '../../../../Components/SearchByEntityName/SearchByEntityName';
import { offset } from 'highcharts';

export default function ViewEducator({ sideNavState, setSideNavState }) {
    const PinkSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: green[600],
            '&:hover': {
                backgroundColor: alpha(green[800], theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: green[800],
        },
        '& .MuiSwitch-switchBase': {
            color: red[600],
            '&:hover': {
                backgroundColor: alpha(red[800], theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
            backgroundColor: red[800],
        },
    }));

    useEffect(() => {
        getEducatorListData(0, 10);
    }, [])

    const navigate = useNavigate();
    const [educatorData, setEducatorData] = useState([]);
    const [searchText, setSearchText] = useState('')
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [count, setTotalEducator] = React.useState(0);
    const [isLoading, setLoading] = useState(false);
    const [openCollege, setCollegeOpen] = React.useState(false);
    const [collegeDetails, setCollegeDetails] = React.useState({});
    const [filter, setFilter] = React.useState({});
    const [isClearInputField, setClearInputField] = React.useState(false);
    const [searchByValue, setSearchByValue] = React.useState("fullName");
    const [offset, setOffset] = React.useState(0);
    const [limit, setLimit] = React.useState(0);

    const handleOpen = () => setCollegeOpen(true);
    const handleClose = () => setCollegeOpen(false);

    const getEducatorListData = async (offset, limit, searchKey = null, searchText = null) => {
        try {
            setLoading(true);
            const educatorData = await getEducatorList(offset, limit, searchKey, searchText);
            console.log(`🚀 ~ getEducatorListData ~ educatorData:`, educatorData)
            setLoading(false);
            if (educatorData && educatorData?.data) {
                const educatorListData = educatorData.data.educators || [];
                const count = educatorData.data.totalEducators || 0;
                setOffset(educatorData.data.offset);
                setLimit(educatorData.data.limit);
                setEducatorData(educatorListData);
                setTotalEducator(count);
            } else {
                setEducatorData([]);
                setTotalEducator(0);
            }
        } catch (error) {
            setLoading(false);
            console.log("getEducator List", error);
        }
    }

    const handleView = (id, type) => {
        navigate('/home/addEducator/' + id, { state: { type }, replace: true });
    };

    const handleEdit = (id, type) => {
        navigate('/home/addEducator/' + id, { state: { type }, replace: true });
    };

    const handleDelete = (id) => {
        console.log("🚀 ~ handleDelete ~ id:", id)
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteEducator(id).then((resp) => {
                    console.log(`Delete clicked for ID ${id}`);
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your file has been deleted.",
                        icon: "success"
                    });
                    getEducatorListData(0, 10);
                }).catch((error) => {
                    console.log('educator delete error', error);
                })

            }
        });
    };

    const handleResetPassword = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to reset the password",
            // icon: "?",
            iconHtml: '?',
            showCancelButton: true,
            confirmButtonText: "Reset",
            cancelButtonText: "Cancel",
            customClass: {
                cancelButton: 'cancel-button',
                confirmButton: 'confirm-button',
                icon: 'custom-icon'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                resetEducatorPassword({educatorId: id, permanent: false}).then((res) => {
                    if(res.status == 202) {
                        Swal.fire({
                            icon: "success",
                            text: "Successful!",
                            showConfirmButton: true,
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: "Something went wrong!",
                            showConfirmButton: false,
                        });
                    }
                }).catch(error => {
                    Swal.fire({
                        icon: "error",
                        text: "Something went wrong!",
                        showConfirmButton: false,
                    });
                })
            }
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const { searchKey, searchText } = filter || {};
        getEducatorListData(newPage * rowsPerPage, rowsPerPage, searchKey, searchText);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        const { searchKey, searchText } = filter || {};
        getEducatorListData(0, parseInt(event.target.value, 10), searchKey, searchText);
    };

    const handleCollegeDetails = (collegeDetails) => {
        if (typeof collegeDetails === "object") {
            setCollegeDetails(collegeDetails);
            setRowsPerPage(10);
            setPage(0);
            setFilter({
                searchKey: "collegeId",
                searchText: collegeDetails?.collegeId,
            });
            getEducatorListData(0, 10, "collegeId", collegeDetails?.collegeId);
            setClearInputField((prevState) => !prevState);
        }
    };

    const handleSearchEntityChange = (data) => {
        let offset = 0;
        let limit = 10;
        setPage(0);
        getEducatorListData(offset, limit, data.searchKey, data.searchText)
    }

    const activateEducator = (row, state) => {
        const payload = {
            accountStatus: state ? "enable" : "disable",
            collegeId: row.collegeId,
            educatorId: row._id,
            userName: row.username,
        }
        // row.isActive = !row.isActive;

        updateEducatorState(payload)
        .then((res) => {
            console.log("🚀 ~ createCollege ~ res:", res);
            Swal.fire({
                title: "Successful",
                text: res.data.description,
                icon: res.data.message,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Okay"
            }).finally(() => {
                getEducatorListData(offset, limit)
            })
        })
        .catch((error) => {
            if (error?.response?.data) {
                Swal.fire({
                    title: "Error",
                    text: error.response.data,
                    icon: "error",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Okay"
                })
            } else {
                Swal.fire({
                    title: "Error",
                    text: "Something went wrong please try again",
                    icon: "error",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Okay"
                })
            }
        });
    }

    const filters = (
        <div className="pt-3">
            <div className="row">
                <div className="col-md-3 col-12">
                    <div className="position-relative">
                        <input className="custom-input" type="text" placeholder="Select College" onClick={handleOpen} readOnly value={collegeDetails?.collegeName} />
                        <div className="down-arrow">
                            {" "}
                            <img src={downArrow} width={"12px"} height={"13px"} />
                        </div>
                    </div>
                </div>
                <SearchByEntityName sendSearchEntityData={handleSearchEntityChange}></SearchByEntityName>
            </div>
        </div>
    )

    return (
        <div className={'view-college-container ' + (sideNavState ? 'fullWidthOpenSideNav' : 'halfWidthOpenSideNav')}>
            <NavigateBack />
            <MembersContainer>
                <div className='px-3'>
                    {filters}
                </div>

                {educatorData && educatorData.length > 0 ? (
                    <div className='table-container p-3'>
                        <TableContainer component={Paper} sx={{ maxHeight: " 70vh" }}>
                            <Table stickyHeader sx={{ minWidth: 800, borderCollapse: "separate", borderSpacing: "0 10px" }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="tableHeaders" align="center">USN</TableCell>
                                        <TableCell className="tableHeaders nowrap" align="center">Educator name</TableCell>
                                        <TableCell className="tableHeaders" align="center">Email</TableCell>
                                        <TableCell className="tableHeaders" align="center">Contact</TableCell>
                                        <TableCell className="tableHeaders" align="center">Username</TableCell>
                                        <TableCell className="tableHeaders nowrap" align="center">College Name</TableCell>
                                        <TableCell className="tableHeaders" align="center">Status</TableCell>
                                        <TableCell className="tableHeaders" align="center">Action</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {educatorData.map((row, index) => {

                                        return (<TableRow key={row._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover role="checkbox" tabIndex={-1}>

                                            <TableCell align="center">{page * rowsPerPage + index + 1}</TableCell>
                                            <TableCell align="center"><div className='d-flex justify-content-center flex-column align-items-center nowrap'>
                                                <div>{row.fullName}</div>
                                                {(row.isActive || row.isActive === undefined) ? <div className='educatorStatus'>Active</div> : <div className='educatorStatus deactivate'>Deactive</div>}
                                            </div></TableCell>
                                            <TableCell align="center">{row.primaryEmail}</TableCell>
                                            <TableCell align="center">{row.mobile}</TableCell>
                                            <TableCell align="center">{row.username}</TableCell>
                                            <TableCell align="center" className='nowrap'>{row.collegeName}</TableCell>
                                            <TableCell align="center">
                                                <PinkSwitch 
                                                    defaultChecked
                                                    value={row.isActive} 
                                                    checked={row.isActive}
                                                    onChange={() => activateEducator(row, !row.isActive)}
                                                />
                                            </TableCell>
                                            <TableCell align="center" sx={{ width: 178 }}>
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic" className="custom-dropdown">
                                                        <img src={actionBtnIcon} alt='' width={'28px'} className='actionBtnIcon' />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => handleView(row._id, { type: 'view' })}>
                                                            <button className='actionBtn' >
                                                                <img src={viewCollegeIcon} alt='' width={'25px'} /> View
                                                            </button>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => handleEdit(row._id, { type: 'edit' })}>
                                                            <button className='actionBtn' >
                                                                <img src={editCollegeIcon} alt='' width={'19px'} /> Edit Profile
                                                            </button>
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Item onClick={() => handleDelete(row._id)}>
                                                            <button className='actionBtn'>
                                                                <img src={deleteCollegeIcon} alt='' width={'22px'} /> Delete
                                                            </button>
                                                        </Dropdown.Item> */}
                                                        <Dropdown.Item onClick={() => handleResetPassword(row._id)}>
                                                            <button className='actionBtn' >
                                                                <img src={resetPasswordICon} alt='' width={'22px'} /> Reset Password
                                                            </button>
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Item onClick={() => handleDelete(row._id)}>
                                                            <button className='actionBtn' >
                                                                <img src={biometricIcon} alt='' width={'22px'} /> Biometric
                                                            </button>
                                                        </Dropdown.Item> */}
                                                        <Dropdown.Item href="#/action-3">
                                                            <div className='col-12'>
                                                                <div className='row'>
                                                                    <button className='col-6 activeBtn' onClick={() => activateEducator(row, true)}>Active</button>
                                                                    <button className='col-6 deActiveBtn' onClick={() => activateEducator(row, false)}>Deactive</button>
                                                                </div>
                                                            </div>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                    <div>
                                                    </div>
                                                </Dropdown>
                                            </TableCell>
                                        </TableRow>)
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={count}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Items per page"
                            disabled={isLoading}
                        />
                    </div>
                ) : (
                    <div className='noDataFoundSection mt-5'>
                        <div>
                            <img src={noDataFoundImg} alt='' />
                        </div>
                        <div className='noDataFound mt-5'>No Educator(s) Found</div>
                    </div>

                )}
            </MembersContainer>
            <Modal open={openCollege} onClose={handleClose} backdrop="static">
                <div>
                    <SelectCollegeModal {...{ handleClose, setCollegeName: handleCollegeDetails }} />
                </div>
            </Modal>
        </div>
    )
}