import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Radio from '@mui/material/Radio';
import React, { useState, useEffect } from 'react';
import SearchIcon from '../../Assets/commonImg/searchIcon.svg';
import Paper from '@mui/material/Paper';
import RadioGroup from '@mui/material/RadioGroup';
import { FormControlLabel } from '@mui/material';
import { getcollegeList } from '../../Services/memberService';
import "./SelectCollegeModal"

export const SelectCollegeModal = (args) => {
    const {
        onFormValueChange,
        handleClose,
        setCollegeName,
        isStyleUsed = true
    } = args;
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // bgcolor: 'background.paper',
        border: 'none',
        // boxShadow: 24,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '8px',
        padding: '10px',
    };

    useEffect(() => {
        getCollegeList(offset, limit);
    }, []);

    const [collegeData, setCollegeData] = useState([]);
    const [count, setTotalCollege] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchText, setSearchText] = useState('');
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);

    const getCollegeList = async (offset, limit, searchText = null) => {
        try {
            const collegeData = await getcollegeList(offset, limit, searchText);
            const collegeListData = collegeData.data.collegeList;
            const count = collegeData.data.totalRecords;
            setCollegeData(collegeListData);
            setTotalCollege(count);
        } catch (error) {
            console.log('error in college list', error)
        }
    }

    const handleSearchText = async () => {
        getCollegeList(offset, limit, searchText)
    }

    const handleSearchInput = (e) => {
        setSearchText(e.target.value)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getCollegeList(newPage * rowsPerPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, limit));
        setPage(0);
        getCollegeList(0, (parseInt(event.target.value, limit)))
    };

    const onValueChange = (uuid, displayName) => {
        if (onFormValueChange) {
            onFormValueChange('collegeId', uuid);
        }
        if (setCollegeName) {
            setCollegeName({ 'collegeId': uuid, collegeName: displayName });
        }
        handleClose();
    }

    return (<>
        <Box sx={isStyleUsed ? style : ''}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className='collegeList px-2'>College List</div>
                <button onClick={() => handleClose()} style={{
                    background: 'none',
                    border: 'none',
                    fontSize: '1.5rem',
                    lineHeight: '1',
                    cursor: 'pointer',
                    color: '#000',
                }}>&times;</button>
            </div>
            <div className="px-3">
                <div className="position-relative mb-3">
                    <input type="text" placeholder="Search by college name"
                        onChange={handleSearchInput}
                        style={{
                            height: '36px',
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: '5px',
                            border: 'none',
                            padding: '0 25px',
                        }} />
                    <button onClick={handleSearchText} style={{
                        height: '36px',
                        position: 'absolute',
                        right: '0',
                        top: '0',
                        background: '#2580EC 0% 0% no-repeat padding-box',
                        boxShadow: '0px 3px 6px #00000029',
                        border: '1px solid #FFFFFF',
                        borderRadius: '5px',
                        color: '#FFFFFF'
                    }}><img src={SearchIcon} width={'18px'} height={'18px'} />Search</button>
                </div>
                <div>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{
                                        backgroundColor: '#76A5DE',
                                        border: '1px solid #707070',
                                        font: 'normal normal medium 15px/20px Roboto',
                                        color: '#FFFFFF !important'
                                    }}>College Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* <RadioGroup name="shipSpeed"  >
                                  
                                </RadioGroup> */}

                                {collegeData.map((row, id) => (
                                    <TableRow
                                        key={row.displayName + id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        onClick={() => { onValueChange(row._id, row.displayName); }}
                                    >
                                        <TableCell component="th" scope="row"
                                            sx={{ border: 'none', px: '17px', py: '9px', cursor: 'pointer' }} >
                                            {row.displayName}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                        {/* <TablePagination
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{ flex: '1 1 auto' }}  
                        /> */}
                </div>
                </div>
            </div>
        </Box>
    </>);
}