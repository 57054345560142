import { createService, readService, deleteService, updateService } from './HttpService'
import { url } from '../Config/UrlRoute'
import { getDecryptData, decodeJWTToken } from './SessionStoreService'

const getEducatorList = (offset, limit, searchKey = null, searchText = null, collegeId = null) => {
    const userData = JSON.parse(getDecryptData('uadminProfile'));
    if (userData) {
        const endPoint = url.university.educator.getEducator;
        const params = {
            universityId: userData.universityId,
            offset: offset,
            limit: limit,
            searchKey,
            searchText
        };
        if(searchKey) params.searchKey = searchKey;
        if(searchText) params.searchText = searchText;
        if(collegeId) params.collegeId = collegeId;
        return readService(endPoint, params);
    }
}
const createEducator = (payload) => {
    const endPoint = url.university.educator.createEducator;
    return createService(endPoint, payload);
}

const updateEducator = (id, payload) => {
    const endPoint = url.university.educator.createEducator;
    const params = { educatorId: id };
    return updateService(endPoint, undefined,
         { ...payload, ...params });
}
const deleteEducator = (id) => {
    const endPoint = url.university.educator.createEducator + `/${id}`;
    return deleteService(endPoint);
}
const getEducatorById = (id) => {
    const endPoint = url.university.educator.getEducator;
    const params = { educatorId: id }
    return readService(endPoint, params);
}

const getRegisteredEducatorList = (offset, limit, searchKey = null, searchText = null) => {
    const cognitoUserSession = getDecryptData('cognitoUserSession');
    if (cognitoUserSession) {
        const token = JSON.parse(cognitoUserSession);
        const user = decodeJWTToken(token?.idToken?.jwtToken);
        const endPoint = url.university.educator.getRegisteredEducator;
        const params = {
            offset: offset,
            limit: limit,
            searchKey,
            searchText
        };
        if (!searchKey || !searchText) {
            delete params.searchKey
            delete params.searchText
        };
        return readService(endPoint, params);
    }
}

const updateEducatorState = (payload) => {
    return updateService(url.college.educator.updateEducator, {}, payload)
}

const resetEducatorPassword = (params) => {
    const userData = JSON.parse(getDecryptData('uadminProfile'));
    params = {...params, adminId: userData._id}
    return createService(url.university.educator.resetPassword, {},  params)
}

const deleteRegisteredEducator = (educatorId) => {
    const params = {educatorId: educatorId}
    return deleteService(url.university.educator.getRegisteredEducator, params)
}

export {
    getEducatorList,
    createEducator,
    deleteEducator,
    getEducatorById,
    updateEducator,
    getRegisteredEducatorList,
    updateEducatorState,
    resetEducatorPassword,
    deleteRegisteredEducator
}