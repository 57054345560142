import React from "react";
import { currentAuthenticatedUser, changePassword } from "../../../Services/AuthService";

import "./ChangePassword.scss";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

export default function ChangePassword() {
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors, isValid },
	} = useForm();

	const onSubmit = (event) => {
		if (isValid) {
			const oldPassword = getValues("oldPassword");
			const newPassword = getValues("newPassword");
			currentAuthenticatedUser()
				.then((user) => {
					changePassword(user, oldPassword, newPassword)
						.then((resData) => {
							if (resData) {
								Swal.fire({
									icon: "success",
									title: "Your password has been changed",
									showConfirmButton: false,
								});
							}
						})
						.catch((err) => {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								text: err.message,
							});
						});
				})
				.catch((error) => {
					Swal.fire({
						icon: 'error',
						title: 'Error',
						text: error.message,
					});
				});
		}
	};

	return (
		<div className="changePassword-container">
			<div className="changePasswordHeading">Change Password</div>
			<form className="p-3" onSubmit={handleSubmit(onSubmit)}>
				<div className="row passwordField">
					<div className="col-4 infoHeading">Current Password</div>
					<div className="col-8">
						<div className="row">
							<div className="col-7">
								<div className="mt-2">
									<input
										className="custom-input"
										{...register("oldPassword", {
											required: "Password is required",
										})}
									></input>
									{errors.oldPassword && <p>{errors.oldPassword.message}</p>}
								</div>
							</div>
							<div className="col-5"></div>
						</div>
					</div>
				</div>
				<div className="row passwordField">
					<div className="col-4 infoHeading">New Password</div>
					<div className="col-8">
						<div className="row">
							<div className="col-7">
								<div className="mt-2">
									<input
										className="custom-input"
										{...register("newPassword", {
											required: "Password is required",
											pattern: {
												value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
												message: "Password Pattern (8 characters minimum, Atleast 1 Uppercase,1 Lower case , 1 Number and 1 Special character(ex:@))",
											},
										})}
									></input>
									{errors.newPassword && <p>{errors.newPassword.message}</p>}
								</div>
							</div>
							<div className="col-5"></div>
						</div>
					</div>
				</div>
				<div className="d-flex justify-content-end align-items-center mt-4">
					<button type="submit" className="changePassword" disabled={!isValid}>
						Change Password
					</button>
				</div>
			</form>
		</div>
	);
}
