import React from 'react'
import './StudentPreview.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import BackIcon from '../../../Assets/commonImg/back.svg';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Swal from 'sweetalert2';
import { createBulkStudents } from '../../../Services/StudentService';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


export default function StudentPreview({ sideNavState, setSideNavState }) {
    const { state } = useLocation();

    console.log('bulkStudentList', state)
    const navigate = useNavigate()
    const goBack = () => {
        navigate('/home/CreatingStudentUsingCSV', { replace: true });
    }

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const bulkStudentList = state?.data?.studentList;
    const isSubmit = state?.data?.isSubmit || false;
    const collegeName = state?.data?.collegeName;
    const courseName = state?.data?.courseName;
    const formData = state?.formData;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const createStudentsBulk = () => {
        if(formData && bulkStudentList?.length > 0) {
            let type = 'create';
            createBulkStudents(formData, bulkStudentList, type)
                    .then((studentResponse) => {
                        if(studentResponse.status === 200) {
                            Swal.fire({
                                title: "Successful",
                                text: "Students Created Successful!",
                                icon: "success",
                                confirmButtonColor: "#3085d6",
                                confirmButtonText: "Okay"
                            }).finally(() => {
                                navigate('/home/members', { replace: true });
                            })
                        }
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: error.message,
                        })
                    });
        }
    }

    return (
        <div className='student-preview-container'
            style={{
                width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
                marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
                transition: "0.5s",
                position: "absolute",
                top: "4rem",
                padding: "9px",
            }}>
            <div className='backBtn' onClick={goBack}><img src={BackIcon} width={'12px'} height={'13px'} marginTop={'5px'} /><div className='backText'>Back</div></div>
            <div className='student-preview-card'>
                <div className='student-preview-card-title mb-2 d-flex justify-content-between'>
                    <div>
                    Students Preview
                    </div>
                <div>
                    <div >
                        <span className='label-text'>College Name: </span> <span className='label-value'>{collegeName}</span>
                    </div>
                    <div >
                        <span className='label-text'>Course Name: </span> <span className='label-value'>{courseName}</span>
                    </div>
                </div>
                </div>
                <div>
                    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow:'none' }}>
                        <TableContainer>
                            <Table sx={{boxShadow:'none', borderBottom:'1px solid #707070'}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">SL No.</TableCell>
                                        <TableCell align="center">Student Name</TableCell>
                                        <TableCell align="center">User Name</TableCell>
                                        <TableCell align="center">Contact No.</TableCell>
                                        <TableCell align="center">Preview Message</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{
                                    background: '#FFFFFF',
                                    border: '1px solid #707070',
                                    borderRadius: '20px !important'
                                }}>
                                    {bulkStudentList && bulkStudentList
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    <TableCell align="center">{index + 1}</TableCell>
                                                    <TableCell align="center">{row.fullName}</TableCell>
                                                    <TableCell align="center">{row.username}</TableCell>
                                                    <TableCell align="center">{row.mobile}</TableCell>
                                                    <TableCell align="center"
                                                        style={{ color: row.previewMessage == 'OK' ? 'green' : 'red' }}>{row.remarks}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={bulkStudentList?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
            <button className='submit-btn my-5' disabled={!isSubmit} onClick={createStudentsBulk}>Submit</button>
        </div>


    )
}