import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import './CollegeList.scss';
import BackIcon from '../../../../Assets/commonImg/back.svg';
import searchIcon from '../../../../Assets/commonImg/searchIcon.svg';
import phoneIcon from '../../../../Assets/commonImg/phone.svg';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const complaintData = [
    { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', district: 'Bangalore', city: 'Bangalore', contact: 7865457373 },
    { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', district: 'Bangalore', city: 'Bangalore', contact: 7865457373 },
    { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', district: 'Bangalore', city: 'Bangalore', contact: 7865457373 },
    { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', district: 'Bangalore', city: 'Bangalore', contact: 7865457373 },
    { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', district: 'Bangalore', city: 'Bangalore', contact: 7865457373 },
    { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', district: 'Bangalore', city: 'Bangalore', contact: 7865457373 },
    { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', district: 'Bangalore', city: 'Bangalore', contact: 7865457373 },
    { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', district: 'Bangalore', city: 'Bangalore', contact: 7865457373 },
];


export default function CollegeList({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/home/collegePerformance', { replace: true });
    }
    const onContinueClick = () => {
        navigate('/home/calendarView/addNewEvent', { replace: true });
    }

    const [isChecked, setIsChecked] = useState(false);
    function handleCheckboxChange() {
        setIsChecked(!isChecked);
    }

    const [selectedCollege, setSelectedDiv] = useState(false);
    const onSelectedCollege = () => {
        setSelectedDiv(!selectedCollege);
    };

   
    return (
        <div className='college-list-container'
            style={{
                width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
                marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
                transition: "0.5s",
                position: "absolute",
                top: "4rem",
                padding: "1% 1.5%",

            }}>
            <div className='backBtn' onClick={goBack}><img src={BackIcon} width={'12px'} height={'13px'} marginTop={'5px'} /><div className='backText'>Back</div></div>
            <div className='college-list-card mt-2 p-3'>
                <div className='college-list-title'>College List</div>
                <div className='row d-flex justify-content-start align-items-center flex-wrap filter-section px-2'>
                    <div className='col-lg-2'></div>
                    <div className='col-lg-4 col-12 px-0 mx-0 search-filter position-relative'>
                        <input className="custom-search-input px-3 mx-0" type='text' placeholder='Search By College Name' />
                        <button className="search-btn"><img src={searchIcon} width={'9px'} height={'9px'} style={{ marginRight: '10px' }} />Search</button>
                    </div>
                    <div className="col-lg-4 d-none"></div>
                </div>
                <div className='mt-2'>
                    <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '40px' }}>
                        <TableContainer>
                            <Table sx={{
                                background: '#FFFFFF',
                                boxShadow: '0px 3px 6px #00000029',
                                borderRadius: '0px 0px 5px 5px'
                            }}>
                                <TableHead>
                                    <TableRow className='table-row'>
                                        <TableCell align="center"> <label>
                                            <input
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                                onClick={onSelectedCollege}
                                            />
                                            <span className="checkmark"></span>
                                        </label></TableCell>
                                        <TableCell align="center">College Name</TableCell>
                                        <TableCell align="center">State</TableCell>
                                        <TableCell align="center">District</TableCell>
                                        <TableCell align="center">City</TableCell>
                                        <TableCell align="center">Contact</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {complaintData
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    <TableCell align="center"><label>
                                                        <input
                                                            type="checkbox"
                                                            checked={isChecked}
                                                            onChange={handleCheckboxChange}
                                                            onClick={onSelectedCollege}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label></TableCell>
                                                    <TableCell align="center">{row.collegeName}</TableCell>
                                                    <TableCell align="center">{row.state}</TableCell>
                                                    <TableCell align="center">{row.district}</TableCell>
                                                    <TableCell align="center">{row.city}</TableCell>
                                                    <TableCell align="center"><img src={phoneIcon} width={'15px'} height={'15px'} style={{ marginRight: '10px' }} />{row.contact}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            </div>
            {selectedCollege &&
                <div className="d-flex justify-content-start gap-3 my-3">
                    <div className="cancel-btn">Cancel</div>
                    <div className="continue-btn" onClick={onContinueClick}>Continue</div>
                </div>
            }
        </div>
    )
}
