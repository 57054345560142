import { readService } from './HttpService'
import { url } from '../Config/UrlRoute'

const getAddress = async (payload) => {
    const endPoint = url.university.address;
    console.log("🚀 ~ getAddress ~ endPoint:", endPoint)
    return await readService(endPoint, payload);
}

export {
    getAddress
}