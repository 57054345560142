import React, { useState, useEffect } from "react";
import { parse, format } from 'date-fns';
import { getAddress } from "../../../../Services/AddressService";
import { createCollegeAdmin, getByCollegeAdminId, updateCollegeAdmin } from "../../../../Services/CollegeService";
import { SelectCollegeModal } from "../../../../Components/SelectCollegeModal/SelectCollegeModal";
import "./AddCollegeAdmin.scss";
import NavigateBack from "../../../CommonScreens/NavigateBack/NavigateBack";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Modal from '@mui/material/Modal';
import downArrow from '../../../../Assets/commonImg/select-dropdown-arrow.svg';
import Swal from "sweetalert2";
import passIcon from "../../../../Assets/commonImg/view.svg";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    // bgcolor: 'background.paper',
    border: 'none',
    // boxShadow: 24,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '8px',
    padding: '10px',
};

export default function AddCollegeAdmin({ sideNavState, setSideNavState }) {
    const location = useLocation();
    const type = location.state?.type;
    const { id } = useParams();
    const navigate = useNavigate();
    const initialState = {
        firstName: "",
        middleName: "",
        lastName: "",
        fullName: "",
        birthdate: "",
        gender: "",
        mobile: "",
        primaryEmail: "",
        secondaryEmails: "",
        username: "",
        password: "",
        collegeId: "",
        country: "",
        stateProvince: "",
        city: "",
        postalCode: "",
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const [adharNo, setAdharNo] = useState("");
    const [universitySerialNo, setUniversitySerialNo] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [statesList, setStates] = useState([]);
    const [citiesList, setCities] = useState([]);
    const [isAllFieldDisabled, setAllFieldDisabled] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const { firstName, middleName, lastName, fullName, birthdate, gender, mobile, primaryEmail, secondaryEmails, username, password, collegeId, country, stateProvince, city, postalCode } = formData;
    const [collegeName, setCollege] = useState('');

    useEffect(() => {
        getCountry();
        if (type && id) {
            if (type.type === "edit") {
                setAllFieldDisabled(false)
            } else {
                setAllFieldDisabled(true)
            }
            getCollegeAdminById(id);
        }
    }, [])

    const getCollegeAdminById = () => {
        getByCollegeAdminId(id).finally(() => { }).then(async (resData) => {
            if (typeof resData === 'object' && resData.hasOwnProperty('data')) {
                const collegeAdminDetails = resData?.data;
                setCollege(collegeAdminDetails.collegeName);

                if (collegeAdminDetails?.country) {
                    await handleCountryInput(collegeAdminDetails.country);
                }
                if (collegeAdminDetails?.stateProvince || 'maharashtra') {
                    await handleStateInput(collegeAdminDetails.stateProvince || 'maharashtra', collegeAdminDetails.country);
                }
                setFormData((previousData) => {
                    const parsedDate = new Date(collegeAdminDetails?.birthdate);
                    const patchData = {
                        firstName: collegeAdminDetails.firstName,
                        middleName: collegeAdminDetails.middleName,
                        lastName: collegeAdminDetails.lastName,
                        fullName: collegeAdminDetails.fullName,
                        birthdate: !isNaN(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : '',
                        gender: collegeAdminDetails.gender,
                        mobile: collegeAdminDetails.mobile,
                        primaryEmail: collegeAdminDetails.primaryEmail,
                        secondaryEmails: collegeAdminDetails.secondaryEmails,
                        username: collegeAdminDetails.username,
                        password: collegeAdminDetails.password,
                        collegeId: collegeAdminDetails.collegeId,
                        country: collegeAdminDetails.country,
                        stateProvince: collegeAdminDetails.stateProvince || 'maharashtra',
                        city: collegeAdminDetails.city,
                        postalCode: collegeAdminDetails.postalCode
                    };

                    return { ...previousData, ...patchData }
                })



            }
        }).catch((error) => { });
    };

    const getCountry = () => {
        getAddress({ addressCategory: 'country' }).finally(() => {
        }).then((res) => {
            if (res?.data && Array.isArray(res?.data)) {
                setCountryList(res?.data)
            } else {
                setCountryList([])
            }
        }).catch((error) => {
            setCountryList([]);
            console.log("🚀 ~ getAddress ~ error:", error)
        })
    }
    const handleFileInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleCountryInput = (event) => {
        return new Promise((resolve, reject) => {
            getAddress({ addressCategory: 'state', countryName: event }).finally(() => {
            }).then((res) => {
                if (res?.data && Array.isArray(res?.data)) {
                    setStates(res?.data)
                    console.log("🚀 ~ getAddres?.datas ~ res?.data:", res?.data)
                } else {
                    setStates([])
                }
                resolve(true);
            }).catch((error) => {
                setStates([]);
                console.log("🚀 ~ getAddress ~ error:", error)
                resolve(false);
            })
        })
    }

    const handleStateInput = (state, country) => {
        return new Promise((resolve, reject) => {
            getAddress({ addressCategory: 'city', countryName: country, stateName: state }).finally(() => {
            }).then((res) => {
                if (res?.data && Array.isArray(res?.data)) {
                    setCities(res?.data)
                } else {
                    setCities([])
                }
                resolve(true);
            }).catch((error) => {
                setCities([]);
                console.log("🚀 ~ getAddress ~ error:", error)
                resolve(false);
            })
        })
    }


    const setCollegeName = (event) => {
        setCollege(event.collegeName)
    }

    const onFormValueChange = (key, value) => {
        const obj = { [key]: value };
        if (key === 'country') {
            const formValueData = { ...formData, country: value, stateProvince: '', city: '' };
            setFormData(formValueData);
            setStates([]);
            setCities([]);
            handleCountryInput(value)
            return;
        }
        if (key === 'stateProvince') {
            setFormData({ ...formData, stateProvince: value, city: '' });
            setCities([]);
            handleStateInput(value, country)
            return
        }

        setFormData((prevState) => {
            const updatedFormValues = { ...prevState, ...obj };
            updatedFormValues.fullName = `${updatedFormValues?.firstName} ${updatedFormValues?.middleName} ${updatedFormValues?.lastName}`.trim();
            return updatedFormValues;
        });
    };

    const onSaveCollegeAdmin = (e) => {
        e.preventDefault();
        const formValues = Object.assign({},formData);
        formValues.birthdate = new Date(formValues.birthdate).getTime();
        formValues.mobile = '+91' + formValues.mobile;
        console.log('show line',)

        const operationType = type?.type || "create";
        const mobileNumberPattern = /^\+91\d{10}$/;
        if (!formValues.firstName || formValues.firstName.trim() === "") {
            Swal.fire({
                title: "Empty",
                text: "First Name is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        } else if (!formValues.birthdate) {
            Swal.fire({
                title: "Empty",
                text: "Birth date is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        }
        else if (!formValues.gender || formValues.gender.trim() === "") {
            Swal.fire({
                title: "Empty",
                text: "Gender is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        }
        else if (!mobileNumberPattern.test(formValues.mobile)) {
            Swal.fire({
                title: "Empty",
                text: "Mobile Number is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        }
        else if (!formValues.primaryEmail || formValues.primaryEmail.trim() === "") {
            Swal.fire({
                title: "Empty",
                text: "primary email is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        }
        else if(!formValues.username || formValues.username.trim() === ""){
            Swal.fire({
                title: "Empty",
                text: "username is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        } 
        else if (operationType !== "edit" && (!formValues.password || formValues.password.trim() === "")) {
            Swal.fire({
                title: "Empty",
                text: "password is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        }
        else if (!formValues.collegeId || formValues.collegeId.trim() === "") {
            Swal.fire({
                title: "Empty",
                text: "College is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        }
        else {
            if (!type) {
                createCollegeAdmin({ ...formValues }).finally(() => { }).then((res) => {
                    Swal.fire({
                        title: "Successful",
                        text: "New College Admin Added Successfully!",
                        icon: "success",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Okay"
                    }).finally(() => {
                        navigate('/home/members', { replace: true });
                    });
                }).catch((error) => {
                    console.log("🚀 ~ createCollege ~ error:", error);
                });
            } else if (operationType === "edit") {
                delete formValues.password;
                updateCollegeAdmin(formValues, id).finally(() => { }).then((res) => {
                    Swal.fire({
                        title: "Successful",
                        text: "College Admin Updated Successfully!",
                        icon: "success",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Okay"
                    }).finally(() => {
                        navigate('/home/members', { replace: true });
                    });
                }).catch((error) => {
                    console.log("🚀 ~ createCollege ~ error:", error);
                });
            } else {
                console.log(`🚀 ~ onSaveCollegeAdmin ~ type:`, type);
            }
        }
    };

    const onResetCollegeAdmin = () => {
        setFormData(initialState);
    };

    const [openCollege, setCollegeOpen] = React.useState(false);
    const handleOpen = () => setCollegeOpen(true);
    const handleClose = () => setCollegeOpen(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [showPassword, setShowPassword] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    let passwordField = <></>;
    if (!type?.type) {
        passwordField = <>
            <div className="col-md-4 col-12 position-relative">
            <p className="input-label">Password<b style={{ color: 'red' }}>*</b></p>
            <input
                className="custom-input"
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={password}
                onChange={(event) => {
                    const key = event.target.name;
                    const value = event.target.value;
                    onFormValueChange(key, value);
                }}
                disabled={isAllFieldDisabled}
            />
            <img
                src={passIcon}
                alt="Toggle Password Visibility"
                onClick={togglePasswordVisibility}
                className="password-icon"
			/>
        </div>
        </>
    }

    const formatMobileNumber = (number) => {
        return number.startsWith('+91') ? number.slice(3) : number;
    };
    return (
        <div className={"college-admin-container " + (sideNavState ? "fullWidthOpenSideNav" : "halfWidthOpenSideNav")}>
        <NavigateBack />
        <div className="college-admin-form-card">
          <div className="college-admin-form-title">
            <p className="locality">College Admin Profile</p>
          </div>
          <div className="row mx-0">
            <div className="col-12">
              <div className="row">
                <div className="col-md-4 col-12">
                                <p className="input-label">First Name<b style={{ color: 'red' }}>*</b></p>
                  <input
                    className="custom-input"
                    type="text"
                    name="firstName"
                    value={firstName}
                    onChange={(event) => {
                      const key = event.target.name;
                      const value = event.target.value;

                      onFormValueChange(key, value);
                    }}
                    disabled={isAllFieldDisabled}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <p className="input-label">Middle Name</p>
                  <input
                    className="custom-input"
                    type="text"
                    name="middleName"
                    value={middleName}
                    onChange={(event) => {
                      const key = event.target.name;
                      const value = event.target.value;
                      onFormValueChange(key, value);
                    }}
                    disabled={isAllFieldDisabled}

                  />
                </div>
                <div className="col-md-4 col-12">
                  <p className="input-label">Last Name</p>
                  <input
                    className="custom-input"
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={(event) => {
                      const key = event.target.name;
                      const value = event.target.value;
                      onFormValueChange(key, value);
                    }}
                    disabled={isAllFieldDisabled}

                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-md-8 col-12">
                  <p className="input-label">Full Name</p>
                                <input className="custom-input" disabled={true} type="text" name="fullName" value={fullName} />
                </div>
                <div className="col-md-4 col-12">
                                <p className="input-label">Date Of Birth<b style={{ color: 'red' }}>*</b></p>
                  <input
                    className="custom-input"
                                    type='date'
                    name="birthdate"
                    value={birthdate}
                    onChange={(event) => {
                      const key = event.target.name;
                      const value = event.target.value;
                      onFormValueChange(key, value);
                    }}
                    disabled={isAllFieldDisabled}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-md-4 col-12">
                                <p className="input-label">Gender<b style={{ color: 'red' }}>*</b></p>
                                <select className="custom-input" name="gender" value={gender} onChange={(event) => {
                      const key = event.target.name;
                      const value = event.target.value;
                      onFormValueChange(key, value);
                    }}
                    disabled={isAllFieldDisabled}
                  >
                                    <option value="" default disabled>Select Gender</option>
                                    <option value='Male'>Male</option>
                                    <option value='Female'>Female</option>
                  </select>
                </div>
                {type?.type !== "view" ? (
                  <div className="col-md-4 col-12">
                    <p className="input-label">
                      Mobile No.<b style={{ color: "red" }}>*</b>
                    </p>
                    <div className="row">
                      <div className="col-2">
                        <input
                          className="custom-input mobile-number"
                          value="+91"
                          readOnly
                        ></input>
                      </div>
                      <div className="col-10">
                        <input
                          className="custom-input"
                          type="text"
                          name="mobile"
                          value={mobile}
                          onChange={(event) => {
                            const key = event.target.name;
                            const value = event.target.value;
                            onFormValueChange(key, value);
                          }}
                          disabled={isAllFieldDisabled}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-4 col-12">
                    <p className="input-label">
                      Mobile No.<b style={{ color: "red" }}>*</b>
                    </p>
                    <div className="row">
                      <div className="col-2">
                        <input
                          className="custom-input mobile-number"
                          value="+91"
                          readOnly
                        ></input>
                      </div>
                      <div className="col-10">
                        <input
                          className="custom-input"
                          type="text"
                          name="mobile"
                          value={formatMobileNumber(mobile)}
                          onChange={(event) => {
                            const key = event.target.name;
                            const value = event.target.value;
                            onFormValueChange(key, value);
                          }}
                          disabled={isAllFieldDisabled}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-md-4 col-12">
                                <p className="input-label">Primary Email Id<b style={{ color: 'red' }}>*</b></p>
                  <input
                    className="custom-input"
                    type="email"
                    name="primaryEmail"
                    value={primaryEmail}
                    onChange={(event) => {
                      const key = event.target.name;
                      const value = event.target.value;
                      onFormValueChange(key, value);
                    }}
                    disabled={isAllFieldDisabled}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-md-4 col-12">
                  <p className="input-label">Secondary Email Id</p>
                  <input
                    className="custom-input"
                    type="email"
                    name="secondaryEmails"
                    value={secondaryEmails}
                    onChange={(event) => {
                      const key = event.target.name;
                      const value = event.target.value;
                      onFormValueChange(key, value);
                    }}
                    disabled={isAllFieldDisabled}
                  />
                </div>
                <div className="col-md-4 col-12">
                                <p className="input-label">Username<b style={{ color: 'red' }}>*</b></p>
                  <input
                    className="custom-input"
                    type="text"
                    name="username"
                    value={username}
                    onChange={(event) => {
                      const key = event.target.name;
                      const value = event.target.value;
                      onFormValueChange(key, value);
                    }}
                    autoComplete="off"
                    disabled={isAllFieldDisabled}
                  />
                </div>
                {passwordField}

              </div>
            </div>
          </div>
          <div>
            <div className="college-admin-form-title mt-1">
              <p className="locality">Locality</p>
            </div>
            <div className="row mx-0">
              <div className="col-md-3 col-12">
                <p className="input-label">Country</p>
                            <select className="custom-input" value={country} name="country" onChange={(event) => {
                    const key = event.target.name;
                    const value = event.target.value;
                    onFormValueChange(key, value);
                            }} required disabled={isAllFieldDisabled}>
                  <option value="">Select country</option>
                  {countryList.map((country) => (
                    <option key={country?._id} value={country?.name}>
                      {country?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3 col-12">
                <p className="input-label">State</p>
                            <select className="custom-input" value={stateProvince}
                                name="stateProvince" onChange={(event) => {
                    const key = event.target.name;
                    const value = event.target.value;
                    onFormValueChange(key, value);
                                }} disabled={!country || isAllFieldDisabled || !statesList.length} required>
                  <option value="">Select State</option>
                  {statesList.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3 col-12">
                <p className="input-label">City</p>
                            <select className="custom-input" value={city} name="city" onChange={(event) => {
                    const key = event.target.name;
                    const value = event.target.value;
                    onFormValueChange(key, value);
                            }} disabled={!stateProvince || isAllFieldDisabled || !citiesList.length} required>
                  <option value="">Select City</option>
                  {citiesList.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3 col-12">
                <p className="input-label">Postal Code</p>
                            <input className="custom-input" type='number' name="postalCode" value={postalCode} onChange={(event) => {
                    const key = event.target.name;
                    const value = event.target.value;
                    onFormValueChange(key, value);
                            }} required disabled={isAllFieldDisabled}></input>
              </div>
            </div>
          </div>
          <div>
            <div className="college-admin-form-title mt-1">
              <p className="locality">Academic Profile</p>
            </div>
            <div className="row mx-0">
              <div className="col-md-3 col-12">
                            <p className="input-label">Select College<b style={{ color: 'red' }}>*</b></p>
                            <div className='position-relative'>
                                <input className="custom-input" type='text' onClick={handleOpen} readOnly value={collegeName} disabled={isAllFieldDisabled} />
                                <div className='down-arrow'> <img src={downArrow} width={'12px'} height={'13px'} /></div>

                </div>
              </div>
              <div className="col-md-3 col-12">
                <p className="input-label">Upload Photo (150*150)</p>
                            <label htmlFor="filePicker" className="d-flex align-items-center justify-content-center custom-input" style={{ padding: "5px 10px", color: "#707070" }}>
                  Brows or Upload
                </label>
                            <div className="mt-0">{selectedFile && <p>{selectedFile.name}</p>}</div>
                            <input id="filePicker" style={{ visibility: "hidden" }} type={"file"} onChange={handleFileInputChange} />
                <div></div>
              </div>
              <div className="col-md-3 col-12"></div>
              <div className="col-md-3 col-12"></div>
            </div>
          </div>
          <div className="text-danger">
            <i>Note:* Marked Fields are mandatory!</i>
          </div>
        </div>
            {type?.type !== 'view' ?
                <div className="form-submit-card" >
            <button className="save-btn" onClick={onSaveCollegeAdmin}>
                        {type?.type === 'edit' ? 'Update' : 'Save'}
            </button>
            <button className="cancel-btn" onClick={onResetCollegeAdmin}>
              Reset
            </button>
          </div>
                : <></>
            }
            <Modal
                open={openCollege}
                onClose={handleClose}
                backdrop="static"
            >
          <div>
                    <SelectCollegeModal {...{ onFormValueChange, handleClose, collegeId, setCollegeName }} />
          </div>
        </Modal>
      </div>
    );
}
