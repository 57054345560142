import { AES, enc } from 'crypto-js';
import { environment } from '../Config/environment';


function encryptText(plaintext) {
    if (plaintext) {
        return AES.encrypt(plaintext, environment.AESEncryptSecretKey).toString();
    }
}

function decryptText(text) {
    if (text) {
        const decryptedBytes = AES.decrypt(text, environment.AESEncryptSecretKey);
        const decrypted = decryptedBytes.toString(enc.Utf8);
        return decrypted;
    }
}

export {
    encryptText,
    decryptText
}