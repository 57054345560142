import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ViewStudentPerformance.scss';
import BackIcon from '../../../../Assets/commonImg/back.svg';
import SearchIcon from '../../../../Assets/commonImg/searchIcon.svg';
import viewIcon from '../../../../Assets/commonImg/view.svg';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StudentPassOutChart from './../CollegePerformance/StudentPassOutChart';
import { Chart } from "react-google-charts";

export const onlineClassData = [
    ["Task", "Hours per Day"],
    ["Online Class scheduled", 50],
    ["Online Class Attended", 25],
    ["Online Class Missed", 25],
];

export const onlineClassOptions = {
    pieHole: 0.4,
    is3D: false,
    display: true,
    position: 'right',
    width: "100%",
    slices: { 0: { color: '#147AD6' }, 1: { color: '#FBEB8B' }, 2: { color: '#EA8284' } }
};
export const onsiteClassData = [
    ["Task", "Hours per Day"],
    ["Onsite Class scheduled", 50],
    ["Onsite Class Attended", 25],
    ["Onsite Class Missed", 25],
];

export const onsiteClassOptions = {
    pieHole: 0.4,
    is3D: false,
    display: true,
    position: 'right',
    width: "100%",
    slices: { 0: { color: '#147AD6' }, 1: { color: '#FBEB8B' }, 2: { color: '#EA8284' } }
};
export const assessmentData = [
    ["Task", "Hours per Day"],
    ["Online Class scheduled", 50],
    ["Online Class Attended", 25],
    ["Online Class Missed", 25],
];

export const assessmentOptions = {
    pieHole: 0.4,
    is3D: false,
    display: true,
    position: 'right',
    width: "100%",
    slices: { 0: { color: '#147AD6' }, 1: { color: '#FBEB8B' }, 2: { color: '#EA8284' } }
};
export const assignmentData = [
    ["Task", "Hours per Day"],
    ["Assessment Completed", 50], 
    ["Assessment Pending", 25],
    ["Assessment in Progress", 25],
];

export const assignmentOptions = {
    pieHole: 0.4,
    is3D: false,
    display: true,
    position: 'right',
    width: "100%",
    slices: { 0: { color: '#147AD6' }, 1: { color: '#FBEB8B' }, 2: { color: '#EA8284' } }
};

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        }
    ]
};


const taskProgressData = [
    { subjectName: 'Anatomy', assignment: '20%', assessment: '25%', totalVideosViewed: '25%', onlineLectureAttende: '25%', onsiteLectureAttended: '25%' },
    { subjectName: 'Anatomy', assignment: '20%', assessment: '25%', totalVideosViewed: '25%', onlineLectureAttende: '25%', onsiteLectureAttended: '25%' },
    { subjectName: 'Anatomy', assignment: '20%', assessment: '25%', totalVideosViewed: '25%', onlineLectureAttende: '25%', onsiteLectureAttended: '25%' },
    { subjectName: 'Anatomy', assignment: '20%', assessment: '25%', totalVideosViewed: '25%', onlineLectureAttende: '25%', onsiteLectureAttended: '25%' },
    { subjectName: 'Anatomy', assignment: '20%', assessment: '25%', totalVideosViewed: '25%', onlineLectureAttende: '25%', onsiteLectureAttended: '25%' },
    { subjectName: 'Anatomy', assignment: '20%', assessment: '25%', totalVideosViewed: '25%', onlineLectureAttende: '25%', onsiteLectureAttended: '25%' },
    { subjectName: 'Anatomy', assignment: '20%', assessment: '25%', totalVideosViewed: '25%', onlineLectureAttende: '25%', onsiteLectureAttended: '25%' },
    { subjectName: 'Anatomy', assignment: '20%', assessment: '25%', totalVideosViewed: '25%', onlineLectureAttende: '25%', onsiteLectureAttended: '25%' },
];


export default function ViewStudentPerformance({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/home/studentPerformance', { replace: true });
    }

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const TaskProgressView = () => {
        navigate('TaskProgressView', { replace: true });
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className='view-student-performance-container'
            style={{
                width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
                marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
                transition: "0.5s",
                position: "absolute",
                top: "4rem",
                padding: "1%",

            }}>
            <div className='backBtn' onClick={goBack}><img src={BackIcon} width={'12px'} height={'13px'} marginTop={'5px'} /><div className='backText'>Back</div></div>
            <div className='view-student-performance-card p-3'>
                <div className='view-student-performance-tracker-title'>
                    Student Performance
                </div>
                <div className='cards-slider my-4'>
                    <Slider {...settings}>
                        <div className='cards mx-3'>
                            <div className='cards-content'>
                                <div className='card-header p-3'>
                                    <div className='header-title'>Online Class</div>
                                </div>
                                <div >
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="150px"
                                        data={onlineClassData}
                                        options={onlineClassOptions}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='cards mx-3'>
                            <div className='cards-content'>
                                <div className='card-header p-3'>
                                    <div className='header-title'>Onsite Class</div>
                                </div>
                                <div >
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="150px"
                                        data={onsiteClassData}
                                        options={onsiteClassOptions}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='cards mx-3'>
                            <div className='cards-content'>
                                <div className='card-header p-3'>
                                    <div className='header-title'>Assessment</div>
                                </div>
                                <div >
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="150px"
                                        data={assessmentData}
                                        options={assessmentOptions}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='cards mx-3'>
                            <div className='cards-content'>
                                <div className='card-header p-3'>
                                    <div className='header-title'>Assignment</div>
                                </div>
                                <div >
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="150px"
                                        data={assignmentData}
                                        options={assignmentOptions}
                                    />
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
                <div className='mt-3'>
                <div className='current-student'>
                    <div className='row'>
                        <div className='col-6'><div className='py-3 px-4'>Course Name : BGS Course</div></div>
                        <div className='col-6'><div className='py-3 px-4'>Dr. Sarang P.</div></div>
                    </div>
                </div>
                    <Paper sx={{ width: '100%', overflow: 'hidden', }}>
                        <TableContainer>
                            <Table sx={{
                                background: '#FFFFFF',
                                boxShadow: '0px 3px 6px #00000029',
                                borderRadius: '0px 0px 5px 5px'
                            }}>
                                <TableHead>
                                    <TableRow className='table-row'>
                                        <TableCell align="center" className='head-title'>Subject Name</TableCell>
                                        <TableCell align="center" className='head-title'>Assignment</TableCell>
                                        <TableCell align="center" className='head-title'>Assessment</TableCell>
                                        <TableCell align="center" className='head-title'>Total Videos Viewed</TableCell>
                                        <TableCell align="center" className='head-title'>Online Lecture Attended</TableCell>
                                        <TableCell align="center" className='head-title'>Onsite Lecture Attended</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {taskProgressData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    <TableCell align="center" className='table-data'>{row.subjectName}</TableCell>
                                                    <TableCell align="center" className='table-data'>{row.assignment}</TableCell>
                                                    <TableCell align="center" className='table-data'>{row.assessment}</TableCell>
                                                    <TableCell align="center" className='table-data'>{row.totalVideosViewed}</TableCell>
                                                    <TableCell align="center" className='table-data'>{row.onlineLectureAttende}</TableCell>
                                                    <TableCell align="center" className='table-data'>{row.onsiteLectureAttended}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={taskProgressData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </div>
    )
}
