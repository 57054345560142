import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from 'react-router-dom';
import './EducatorPerformance.scss';
import BackIcon from '../../../../Assets/commonImg/back.svg';
import downArrow from '../../../../Assets/commonImg/dropDownIcon.svg';
import SearchIcon from '../../../../Assets/commonImg/searchIcon.svg';
import viewIcon from '../../../../Assets/commonImg/view.svg';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarImage from '../../../../Assets/commonImg/calendar.svg';

const TabPanel = ({ children, value, index }) => {
    return (
        <div hidden={value !== index}>
            {value === index && <div>{children}</div>}
        </div>
    );
};
const CustomInput = ({ value, onClick }) => (
    <div className="custom-input-container" onClick={onClick}>
        <input type="text" className="custom-date-input" placeholder="Date" value={value} readOnly style={{padding:'0 0 0 15px'}} />
        <img src={calendarImage} alt="calendar" className="custom-icon" style={{position:'absolute', right:'15px', top:'9px'}}/>
    </div>
);
const taskProgressData = [
    { enrolmentNo: 5657, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med', assignedTask: '80%', completedTask: '40%', taskInProgresss: '20%' },
    { enrolmentNo: 5657, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med', assignedTask: '80%', completedTask: '40%', taskInProgresss: '20%' },
    { enrolmentNo: 5657, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med', assignedTask: '80%', completedTask: '40%', taskInProgresss: '20%' },
    { enrolmentNo: 5657, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med', assignedTask: '80%', completedTask: '40%', taskInProgresss: '20%' },
    { enrolmentNo: 5657, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med', assignedTask: '80%', completedTask: '40%', taskInProgresss: '20%' },
    { enrolmentNo: 5657, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med', assignedTask: '80%', completedTask: '40%', taskInProgresss: '20%' },
    { enrolmentNo: 5657, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med', assignedTask: '80%', completedTask: '40%', taskInProgresss: '20%' },
    { enrolmentNo: 5657, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med', assignedTask: '80%', completedTask: '40%', taskInProgresss: '20%' },
];
const studentMaterial = [
    { educatorName: 'Dr.Ajay', userName: 'Ajay-Med', subjectName: 'Anatomy', createdAssignment: '50%', createdVideo: '20%', createdNotes: '25%', createdAssessment: '15%' },
    { educatorName: 'Dr.Ajay', userName: 'Ajay-Med', subjectName: 'Anatomy', createdAssignment: '50%', createdVideo: '20%', createdNotes: '25%', createdAssessment: '15%' },
    { educatorName: 'Dr.Ajay', userName: 'Ajay-Med', subjectName: 'Anatomy', createdAssignment: '50%', createdVideo: '20%', createdNotes: '25%', createdAssessment: '15%' },
    { educatorName: 'Dr.Ajay', userName: 'Ajay-Med', subjectName: 'Anatomy', createdAssignment: '50%', createdVideo: '20%', createdNotes: '25%', createdAssessment: '15%' },
    { educatorName: 'Dr.Ajay', userName: 'Ajay-Med', subjectName: 'Anatomy', createdAssignment: '50%', createdVideo: '20%', createdNotes: '25%', createdAssessment: '15%' },
    { educatorName: 'Dr.Ajay', userName: 'Ajay-Med', subjectName: 'Anatomy', createdAssignment: '50%', createdVideo: '20%', createdNotes: '25%', createdAssessment: '15%' },
    { educatorName: 'Dr.Ajay', userName: 'Ajay-Med', subjectName: 'Anatomy', createdAssignment: '50%', createdVideo: '20%', createdNotes: '25%', createdAssessment: '15%' },
    { educatorName: 'Dr.Ajay', userName: 'Ajay-Med', subjectName: 'Anatomy', createdAssignment: '50%', createdVideo: '20%', createdNotes: '25%', createdAssessment: '15%' },
];
const engagingStudent = [
    { enrolmentNo: 5657, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med', assignment: '20%', assessment: '30%' },
    { enrolmentNo: 5657, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med', assignment: '20%', assessment: '30%' },
    { enrolmentNo: 5657, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med', assignment: '20%', assessment: '30%' },
    { enrolmentNo: 5657, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med', assignment: '20%', assessment: '30%' },
    { enrolmentNo: 5657, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med', assignment: '20%', assessment: '30%' },
    { enrolmentNo: 5657, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med', assignment: '20%', assessment: '30%' },
    { enrolmentNo: 5657, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med', assignment: '20%', assessment: '30%' },
    { enrolmentNo: 5657, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med', assignment: '20%', assessment: '30%' },
];
const overallPerformance = [
    { enrolmentNo: 7577, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med' },
    { enrolmentNo: 7577, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med' },
    { enrolmentNo: 7577, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med' },
    { enrolmentNo: 7577, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med' },
    { enrolmentNo: 7577, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med' },
    { enrolmentNo: 7577, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med' },
    { enrolmentNo: 7577, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med' },
    { enrolmentNo: 7577, educatorName: 'Dr. Sarang P.', username: 'Sarang_Med' },
];

export default function CollegePerformance({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/home/collegePerformance', { replace: true });
    }

    const studyMaterialData = () => {
        navigate('studyMaterialPerformance', { replace: true });
    }
    const engagingStudentView = () => {
        navigate('engagingStudentPerformance', { replace: true });
    }
    const overallPerformanceView = () => {
        navigate('overallPerformanceView', { replace: true });
    }
    const TaskProgressView = () => {
        navigate('TaskProgressView', { replace: true });
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newActiveTab) => {
        setActiveTab(newActiveTab);
    };

    const [selectedDate, setSelectedDate] = React.useState(null);

    return (
        <div className='educator-performance-container'
            style={{
                width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
                marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
                transition: "0.5s",
                position: "absolute",
                top: "4rem",
                padding: "1%",

            }}>
            <div className='backBtn' onClick={goBack}><img src={BackIcon} width={'12px'} height={'13px'} marginTop={'5px'} /><div className='backText'>Back</div></div>
            <div className='educator-performance-card p-3'>
                <div className='educator-performance-tracker-title'>
                    Performance Tracker
                </div>
                <div className='d-flex justify-content-start flex-wrap my-3 px-3' style={{ columnGap: '50px', rowGap: '10px' }}>
                    <div className='d-flex position-relative'>
                        <DatePicker
                            selected={selectedDate}
                            onChange={date => setSelectedDate(date)}
                            customInput={<CustomInput />}
                        />
                    </div>
                    <div className='d-flex'>
                        <Box>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{ height: '45px', width: '208px', maxWidth: '208px' }}
                                    onChange={handleChange}
                                >
                                    <div>
                                        <div className='select-search position-relative'>
                                            <input type='text' placeholder="Search here" style={{
                                                height: '36px',
                                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                boxShadow: '0px 3px 6px #00000029',
                                                maxWidth: '208px',
                                                width: '100%',
                                                border: 'none',
                                                outline: 'unset',
                                            }} />
                                            <button className="search-btn" style={{ backgroundColor: '#32496B' }}><img src={SearchIcon} width={'18px'} height={'18px'} marginRight={'10px'} /></button>
                                        </div>
                                    </div>
                                    <MenuItem value={20}>BGS College</MenuItem>
                                    <MenuItem value={30}>BGS College</MenuItem>
                                    <MenuItem value={30}>BGS College</MenuItem>
                                    <MenuItem value={30}>BGS College</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className='d-flex'>
                        <Box>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{ height: '45px', width: '208px', maxWidth: '208px' }}
                                    onChange={handleChange}
                                >
                                    <div>
                                        <div className='select-search position-relative'>
                                            <input type='text' placeholder="Search College" style={{
                                                height: '36px',
                                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                boxShadow: '0px 3px 6px #00000029',
                                                maxWidth: '208px',
                                                width: '100%',
                                                border: 'none',
                                                outline: 'unset',
                                            }} />
                                            <button className="search-btn" style={{ backgroundColor: '#32496B' }}><img src={SearchIcon} width={'18px'} height={'18px'} marginRight={'10px'} /></button>
                                        </div>
                                    </div>
                                    <MenuItem value={20}>BGS College</MenuItem>
                                    <MenuItem value={30}>BGS College</MenuItem>
                                    <MenuItem value={30}>BGS College</MenuItem>
                                    <MenuItem value={30}>BGS College</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>
                <div className='col-lg-5 col-12 mt-4 px-3'>
                    <div className="px-0">
                        <div className='video-search position-relative'>
                            <input className="custom-select-input px-3 mx-0" type='text' placeholder="Search by College name" />
                            <button className="search-btn"><img src={SearchIcon} width={'18px'} height={'18px'} marginRight={'10px'} />Search</button>
                        </div>
                    </div>
                </div>
                <div>
                    <Tabs value={activeTab} variant='fullWidth' onChange={handleTabChange} className='my-4 tab-section' TabIndicatorProps={{
                        style: {
                            background: '#32496B',
                            borderRadius: '29px',
                            border: '3px solid blue !important',
                            borderBottom: 'none',
                        }
                    }}>
                        <Tab className='col-3 tab' label="Task Progress" />
                        <Tab className='col-3 tab' label="Study Material" />
                        <Tab className='col-3 tab' label="Engaging Students" />
                        <Tab className='col-3 tab' label="Overall Performance" />
                    </Tabs>
                    <TabPanel value={activeTab} index={0}>
                        <div>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer>
                                    <Table sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '0px 3px 6px #00000029',
                                        borderRadius: '0px 0px 5px 5px'
                                    }}>
                                        <TableHead>
                                            <TableRow className='table-row'>
                                                <TableCell align="center" className='head-title'>Enrolment No.</TableCell>
                                                <TableCell align="center" className='head-title'>Educator Name</TableCell>
                                                <TableCell align="center" className='head-title'>User Name</TableCell>
                                                <TableCell align="center" className='head-title'>Assigned Task</TableCell>
                                                <TableCell align="center" className='head-title'>Completed Task</TableCell>
                                                <TableCell align="center" className='head-title'>No.Of Task in Progress</TableCell>
                                                <TableCell align="center" className='head-title'>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {taskProgressData
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                            <TableCell align="center" className='table-data' onClick={TaskProgressView}>{row.enrolmentNo}</TableCell>
                                                            <TableCell align="center" className='table-data' onClick={TaskProgressView}>{row.educatorName}</TableCell>
                                                            <TableCell align="center" className='table-data' onClick={TaskProgressView}>{row.username}</TableCell>
                                                            <TableCell align="center" className='table-data' onClick={TaskProgressView}>{row.assignedTask}</TableCell>
                                                            <TableCell align="center" className='table-data' onClick={TaskProgressView}>{row.completedTask}</TableCell>
                                                            <TableCell align="center" className='table-data' onClick={TaskProgressView}>{row.taskInProgresss}</TableCell>
                                                            <TableCell align="center" className='table-data' onClick={TaskProgressView}><img src={viewIcon} width={'16px'} height={'8px'} marginTop={'5px'} /></TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={taskProgressData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <div>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer>
                                    <Table sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '0px 3px 6px #00000029',
                                        borderRadius: '0px 0px 5px 5px'
                                    }}>
                                        <TableHead>
                                            <TableRow className='table-row'>
                                                <TableCell align="center">Educator Name</TableCell>
                                                <TableCell align="center">User Name</TableCell>
                                                <TableCell align="center">Subject Name</TableCell>
                                                <TableCell align="center">Created Assignment</TableCell>
                                                <TableCell align="center">Created Videos</TableCell>
                                                <TableCell align="center">Created Notes</TableCell>
                                                <TableCell align="center">Created Assessment</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {studentMaterial
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                            <TableCell align="center">{row.educatorName}</TableCell>
                                                            <TableCell align="center">{row.userName}</TableCell>
                                                            <TableCell align="center">{row.subjectName}</TableCell>
                                                            <TableCell align="center">{row.createdAssignment}</TableCell>
                                                            <TableCell align="center">{row.createdVideo}</TableCell>
                                                            <TableCell align="center">{row.createdNotes}</TableCell>
                                                            <TableCell align="center">{row.createdAssessment}</TableCell>
                                                            <TableCell align="center" onClick={studyMaterialData}><img src={viewIcon} width={'16px'} height={'8px'} marginTop={'5px'} /></TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={studentMaterial.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={activeTab} index={2}>
                        <div>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer>
                                    <Table sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '0px 3px 6px #00000029',
                                        borderRadius: '0px 0px 5px 5px'
                                    }}>
                                        <TableHead>
                                            <TableRow className='table-row'>
                                                <TableCell align="center">Enrolment No.</TableCell>
                                                <TableCell align="center">Educator Name</TableCell>
                                                <TableCell align="center">User Name</TableCell>
                                                <TableCell align="center">Assignment</TableCell>
                                                <TableCell align="center">Assessment</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {engagingStudent
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                            <TableCell align="center">{row.enrolmentNo}</TableCell>
                                                            <TableCell align="center">{row.educatorName}</TableCell>
                                                            <TableCell align="center">{row.username}</TableCell>
                                                            <TableCell align="center">{row.assignment}</TableCell>
                                                            <TableCell align="center">{row.assessment}</TableCell>
                                                            <TableCell align="center" onClick={engagingStudentView}><img src={viewIcon} width={'16px'} height={'8px'} marginTop={'5px'} /></TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={engagingStudent.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={activeTab} index={3}>
                        <div>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer>
                                    <Table sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '0px 3px 6px #00000029',
                                        borderRadius: '0px 0px 5px 5px'
                                    }}>
                                        <TableHead>
                                            <TableRow className='table-row'>
                                                <TableCell align="center">Enrolment No.</TableCell>
                                                <TableCell align="center">Educator Name</TableCell>
                                                <TableCell align="center">User Name</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {overallPerformance
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                            <TableCell align="center">{row.enrolmentNo}</TableCell>
                                                            <TableCell align="center">{row.educatorName}</TableCell>
                                                            <TableCell align="center">{row.username}</TableCell>
                                                            <TableCell align="center" onClick={overallPerformanceView}><img src={viewIcon} width={'16px'} height={'8px'} marginTop={'5px'} /></TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={overallPerformance.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </TabPanel>
                </div>
            </div>
        </div>
    )
}
