import Modal from 'react-bootstrap/Modal';
export default function CommonBootstrapModal({ modalProps, modalHeaderCloseButton = false, header, body }) {
    let modalHeader;
    if (header) {
        modalHeader = <>
            <Modal.Header closeButton={modalHeaderCloseButton}>
                {header}
            </Modal.Header>
        </>;
    }
    return (
        <Modal {...modalProps}  >
            {modalHeader}
            <Modal.Body>{body}</Modal.Body>
        </Modal>
    );
}