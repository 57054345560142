import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Task.scss';
import SearchIcon from '../../../../Assets/commonImg/searchIcon.svg';
import viewIcon from '../../../../Assets/commonImg/view.svg';
import assignIcon from '../../../../Assets/commonImg/add-circle-icon.svg';
import closeIcon from '../../../../Assets/commonImg/modal-close.svg';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
//   import faker from 'faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '8px',
    boxShadow: 24,
    width: '35%',
    border: 'none'
};
const bull = (
    <Box
        component="span"
        sx={{
            display: 'inline-block', mx: '2px', transform: 'scale(0.8)',
            background: '#FFFFFF',
            boxShadow: '10px 10px 30px #0000001A',
            borderRadius: '10px'
        }}
    >
        •
    </Box>
);

const taskProgressData = [
    { name: 'Ranjit', email: 'ranjit@zlipp.in', username: 'BGSA', phoneNumber: '+919538999454', dob: 'May 25th 1996', city: 'Pirganj' },
    { name: 'Ranjit', email: 'ranjit@zlipp.in', username: 'BGSA', phoneNumber: '+919538999454', dob: 'May 25th 1996', city: 'Pirganj' },
    { name: 'Ranjit', email: 'ranjit@zlipp.in', username: 'BGSA', phoneNumber: '+919538999454', dob: 'May 25th 1996', city: 'Pirganj' },
    { name: 'Ranjit', email: 'ranjit@zlipp.in', username: 'BGSA', phoneNumber: '+919538999454', dob: 'May 25th 1996', city: 'Pirganj' },
    { name: 'Ranjit', email: 'ranjit@zlipp.in', username: 'BGSA', phoneNumber: '+919538999454', dob: 'May 25th 1996', city: 'Pirganj' },
    { name: 'Ranjit', email: 'ranjit@zlipp.in', username: 'BGSA', phoneNumber: '+919538999454', dob: 'May 25th 1996', city: 'Pirganj' },
    { name: 'Ranjit', email: 'ranjit@zlipp.in', username: 'BGSA', phoneNumber: '+919538999454', dob: 'May 25th 1996', city: 'Pirganj' },
    { name: 'Ranjit', email: 'ranjit@zlipp.in', username: 'BGSA', phoneNumber: '+919538999454', dob: 'May 25th 1996', city: 'Pirganj' },
];

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: true,
            align: 'end',
            position: 'bottom',
            labels: {
                color: '#707070',
                font: 'normal normal normal 5px/6px Montserrat',
            },
        },
        // title: {
        //     display: true,
        //     text: 'Chart.js Line Chart',
        // },
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                    max: 60,
                    stepSize: 10
                }
            }
        ]
    },
};

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Assigned',
            data: [25, 42, 32, 41, 40, 36, 32, 42],
            borderColor: '#034FFD',
            backgroundColor: '#08D2EA',
            borderWidth: 3,
            pointRadius: 0.5,
            pointBackgroundColor: '#08D2EA',
            pointBorderColor: '#08D2EA',
            pointHoverRadius: 1,
            lineTension: 0.4,
        },
        {
            label: 'Completed',
            data: [10, 25, 18, 32, 28, 27, 32, 17],
            borderColor: '#08D2EA',
            backgroundColor: '#034FFD',
            borderWidth: 3,
            pointRadius: 0.5,
            pointBackgroundColor: '#204EF3',
            pointBorderColor: '#204EF3',
            pointHoverRadius: 1,
            lineTension: 0.4,
        },
    ],
};
export default function Task({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/home/collegePerformance', { replace: true });
    }
    const onAssign = () => {
        navigate('assignTask', { replace: true });
    }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div className={'dashboard-container ' + (sideNavState ? 'fullWidthOpenSideNav' : 'halfWidthOpenSideNav')}>
        <div className='task-container'>
            <div className='task-card py-4'>
                <div className='d-flex justify-content-start px-4'>

                    <div className='row'>
                        <div className='col-md-6 col-12'>
                        <p className="input-label mb-2" >Select College</p>
                        <select className="custom-select-input">
                            <option>PSGVP Medical College</option>
                            <option>College Name is here</option>
                            <option>College Name is here</option>
                        </select>
                        </div>
                        <div className='col-md-6 col-12'>
                        <p className="input-label mb-2" >Select Course</p>
                        <select className="custom-select-input">
                            <option>MBBS</option>
                            <option>College Name is here</option>
                            <option>College Name is here</option>
                        </select>
                        </div>
                    </div>

                    {/* <div className='d-flex flex-column'>
                        <p className="input-label mb-2" >Select College</p>
                        <select className="custom-select-input">
                            <option>PSGVP Medical College</option>
                            <option>College Name is here</option>
                            <option>College Name is here</option>
                        </select>
                    </div>
                    <div className='d-flex flex-column'>
                        <p className="input-label mb-2" >Select Course</p>
                        <select className="custom-select-input">
                            <option>MBBS</option>
                            <option>College Name is here</option>
                            <option>College Name is here</option>
                        </select>
                    </div> */}
                   
                </div>
                <div className='row chart-card-section mt-3 px-4'>
                    <div className='col-lg-4 col-12'>
                        <Card sx={{
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            boxShadow: '10px 10px 30px #0000001A',
                            borderRadius: '10px'
                        }}>
                            <div className='d-flex justify-content-between align-items-center card-header px-2'>
                                <div className='task'>Tasks</div>
                                <div className='principal'>Principal</div>
                                <div>
                                    <select className="custom-select-input">
                                        <option>Select Duration</option>
                                        <option>College Name is here</option>
                                        <option>College Name is here</option>
                                    </select>
                                </div>
                            </div>
                            <div className='mt-2' style={{ height: '209px', width: '312px' }} >
                                <Line options={options} data={data} />
                            </div>
                        </Card>
                    </div>
                    <div className='col-lg-4 col-12'>
                        <Card sx={{
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            boxShadow: '10px 10px 30px #0000001A',
                            borderRadius: '10px'
                        }}>
                            <div className='d-flex justify-content-between align-items-center card-header px-2'>
                                <div className='task'>Tasks</div>
                                <div className='principal'>HOD</div>
                                <div>
                                    <select className="custom-select-input">
                                        <option>Select Duration</option>
                                        <option>College Name is here</option>
                                        <option>College Name is here</option>
                                    </select>
                                </div>
                            </div>
                            <div className='mt-2' style={{ height: '209px', width: '312px' }} >
                                <Line options={options} data={data} />
                            </div>
                        </Card>
                    </div>
                    <div className='col-lg-4 col-12'>
                        <Card sx={{
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            boxShadow: '10px 10px 30px #0000001A',
                            borderRadius: '10px'
                        }}>
                            <div className='d-flex justify-content-between align-items-center card-header px-2'>
                                <div className='task'>Tasks</div>
                                <div className='principal'>Educator</div>
                                <div>
                                    <select className="custom-select-input">
                                        <option>Select Duration</option>
                                        <option>College Name is here</option>
                                        <option>College Name is here</option>
                                    </select>
                                </div>
                            </div>
                            <div className='mt-2' style={{ height: '209px', width: '312px' }} >
                                <Line options={options} data={data} />
                            </div>
                        </Card>
                    </div>
                </div>
                <div className='assign-task-title mt-4 px-4'>
                    Assign Task
                </div>
                <div className='faculty-detail-title mt-2 px-2'>
                    Faculty Details: To Assign Task
                </div>
                <div className='row mt-2 px-2'>
                    <div className='col-lg-4 col-12 mt-3'>
                        <div className="px-0">
                            <div className='video-search position-relative'>
                                <input className="custom-search-input px-3 mx-0" type='text' placeholder="Search here" />
                                <button className="search-btn"><img src={SearchIcon} width={'18px'} height={'18px'} marginRight={'10px'} />Search</button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-12'>
                        <p className="input-label mb-2" >Select Member</p>
                        <select className="custom-select-input">
                            <option>Educator</option>
                            <option>College Name is here</option>
                            <option>College Name is here</option>
                        </select>
                    </div>
                </div>
                <div className='mt-2 px-2'>
                    <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '40px' }}>
                        <TableContainer>
                            <Table sx={{
                                background: '#FFFFFF',
                                boxShadow: '0px 3px 6px #00000029',
                                borderRadius: '0px 0px 5px 5px'
                            }}>
                                <TableHead>
                                    <TableRow className='table-row'>
                                        <TableCell align="center" className='head-title'>Name</TableCell>
                                        <TableCell align="center" className='head-title'>Email</TableCell>
                                        <TableCell align="center" className='head-title'>Username</TableCell>
                                        <TableCell align="center" className='head-title'>Phone number</TableCell>
                                        <TableCell align="center" className='head-title'>DOB</TableCell>
                                        <TableCell align="center" className='head-title'>City</TableCell>
                                        <TableCell align="center" className='head-title'>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {taskProgressData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    <TableCell align="center" className='table-data'>{row.name}</TableCell>
                                                    <TableCell align="center" className='table-data'>{row.email}</TableCell>
                                                    <TableCell align="center" className='table-data'>{row.username}</TableCell>
                                                    <TableCell align="center" className='table-data'>{row.phoneNumber}</TableCell>
                                                    <TableCell align="center" className='table-data'>{row.dob}</TableCell>
                                                    <TableCell align="center" className='table-data'>{row.city}</TableCell>
                                                    <TableCell align="center" className='table-data'>
                                                        <div className='d-flex justify-content-center align-items-center gap-2'>
                                                            <div className='view-btn' onClick={handleOpen}>View <img src={viewIcon} width={'26px'} height={'14px'} style={{ marginLeft: '10px' }} /></div>

                                                            <div className='assign-btn' onClick={onAssign}>Assign <img src={assignIcon} width={'19px'} height={'19px'} style={{ marginLeft: '10px' }} /></div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={taskProgressData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className='view-modal'>
                            <div className='d-flex justify-content-between px-3 py-2' style={{ borderBottom: '0.5px solid #70707080' }}>
                                <div className='assign-subject'>Assign Subject</div>
                                <div onClick={handleClose}><img src={closeIcon} width={'20px'} height={'20px'} style={{ marginLeft: '10px', cursor: 'pointer' }} /></div>
                            </div>
                            <div className='modal-body p-3'>
                                <div className='d-flex justify-content-between align-items-center course-data-content p-2 mb-3'>
                                    <div className='d-flex flex-column'>
                                        <div className='course-title'>Course Name:</div>
                                        <div className='mt-2 course-title' >Subject:</div>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <div className='course-data-title'>MBBS Final Year</div>
                                        <div className='mt-2 course-data-title'>Patient Examination Final Year</div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center delete-btn'>Delete</div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center course-data-content p-2 mb-3'>
                                    <div className='d-flex flex-column'>
                                        <div className='course-title'>Course Name:</div>
                                        <div className='mt-2 course-title' >Subject:</div>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <div className='course-data-title'>MBBS Final Year</div>
                                        <div className='mt-2 course-data-title'>Patient Examination Final Year</div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center delete-btn'>Delete</div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center course-data-content p-2 mb-3'>
                                    <div className='d-flex flex-column'>
                                        <div className='course-title'>Course Name:</div>
                                        <div className='mt-2 course-title' >Subject:</div>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <div className='course-data-title'>MBBS Final Year</div>
                                        <div className='mt-2 course-data-title'>Patient Examination Final Year</div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center delete-btn'>Delete</div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center course-data-content p-2 mb-3'>
                                    <div className='d-flex flex-column'>
                                        <div className='course-title'>Course Name:</div>
                                        <div className='mt-2 course-title' >Subject:</div>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <div className='course-data-title'>MBBS Final Year</div>
                                        <div className='mt-2 course-data-title'>Patient Examination Final Year</div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center delete-btn'>Delete</div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center course-data-content p-2 mb-3'>
                                    <div className='d-flex flex-column'>
                                        <div className='course-title'>Course Name:</div>
                                        <div className='mt-2 course-title' >Subject:</div>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <div className='course-data-title'>MBBS Final Year</div>
                                        <div className='mt-2 course-data-title'>Patient Examination Final Year</div>
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center delete-btn'>Delete</div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
        </div>
    )
}
