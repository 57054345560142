import React, { useState, useEffect } from 'react'
import { createCollege, getByCollegeId, updateCollege } from '../../../Services/CollegeService';
import { getAddress } from '../../../Services/AddressService';
import './AddCollege.scss'
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Modal from 'react-bootstrap/Modal';
import SearchIcon from '../../../Assets/commonImg/searchIcon.svg';
import { getCourseList } from '../../../Services/memberService';
import TablePagination from '@mui/material/TablePagination';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import NavigateBack from '../../CommonScreens/NavigateBack/NavigateBack';
import CloseIcon from '@mui/icons-material/Close';
import Swal from "sweetalert2";

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function AddCollege({ sideNavState, setSideNavState }) {
  const location = useLocation();
  const navigate = useNavigate();
  const type = location.state?.type;
  const { id } = useParams();

  const initFromDataFields = {
    displayName: '',
    contactEmail: '',
    mobile: '',
    academicType: "medical",
    subscriptionType: "non-prime",
    courses: [],
    country: '',
    stateProvince: '',
    city: '',
    postalCode: '',
  }

  const [formValue, setFormValue] = useState(initFromDataFields);
  useEffect(() => {
    getCountry();
    if (type && id) {
      if (type === "edit") {
        setAllFieldDisabled(false)
      } else {
        setAllFieldDisabled(true)
      }
      getCollegeById(id);
    }
    getCoursesList(0, 10);
  }, [])

  const [courseName, setCourseName] = useState('');
  const [countryList, setCountryList] = useState([]);
  const [statesList, setStates] = useState([]);
  const [citiesList, setCities] = useState([]);
  const [isAllFieldDisabled, setAllFieldDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCourse, setTotalCourse] = React.useState(0);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [tempSelectedCourses, setTempSelectedCourses] = useState([]);
  const {
    displayName,
    contactEmail,
    mobile,
    academicType,
    subscriptionType,
    courses,
    country,
    stateProvince,
    city,
    postalCode,
  } = formValue;


  const handleCountryInput = (event) => {
    return new Promise((resolve, reject) => {
      getAddress({ addressCategory: 'state', countryName: event }).finally(() => {
      }).then((res) => {
        if (res?.data && Array.isArray(res?.data)) {
          setStates(res?.data)
        } else {
          setStates([])
        }
        resolve(true);
      }).catch((error) => {
        setStates([]);
        resolve(false);
      })
    })
  }

  const handleStateInput = (state, country) => {
    return new Promise((resolve, reject) => {
      getAddress({ addressCategory: 'city', countryName: country, stateName: state }).finally(() => {
      }).then((res) => {
        if (res?.data && Array.isArray(res?.data)) {
          setCities(res?.data)
        } else {
          setCities([])
        }
        resolve(true);
      }).catch((error) => {
        setCities([]);
        resolve(false);
        console.log("🚀 ~ getAddress ~ error:", error)
      })
    })
  }

  const handleShow = () => {
    setTempSelectedCourses(selectedCourses);  // Preserve selected courses in tempSelectedCourses
    setShow(true);
  };
  const handleCourseName = (event) => {
    setCourseName(event.target.value);
  }

  const handleCheckboxChange = (e, _id, courseName) => {
    if (e?.target?.checked) {
      setTempSelectedCourses([...tempSelectedCourses, { _id, courseName }]);
    } else {
      setTempSelectedCourses(tempSelectedCourses.filter(course => course._id !== _id));
    }
  };

  const applyFilter = () => {
    setSelectedCourses(tempSelectedCourses);
    setShow(false);
  };

  const handleDelete = (chipToDelete) => () => {
    setSelectedCourses((chips) => {
      const updatedChips = chips.filter((chip) => chip._id !== chipToDelete._id);
      return updatedChips;
    });
  };

  const handleClose = () => {
    setShow(false);
    setTempSelectedCourses([]);
  };

  const getCoursesList = async (offset, limit) => {
    try {
      const courseData = await getCourseList(offset, limit);
      const courseListData = courseData.data.course;
      const totalCourse = courseData.data.totalRecords;
      setRows(courseListData);
      setTotalCourse(totalCourse);
    } catch (error) {
    }
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getCoursesList(newPage * rowsPerPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getCoursesList(0, parseInt(event.target.value, 10));
  };

  const onFormChangeHandle = (e) => {
    const { name, value } = e.target;
    if (name === 'country') {
      const formValueData = { ...formValue, country: value, stateProvince: '', city: '' };
      setFormValue(formValueData);
      setStates([]);
      setCities([]);
      handleCountryInput(value)
      return;
    }
    if (name === 'stateProvince') {
      setFormValue({ ...formValue, stateProvince: value, city: '' });
      setCities([]);
      handleStateInput(value, country)
      return
    }
    setFormValue({ ...formValue, [name]: value });
  };

  const getCollegeById = (id) => {
    getByCollegeId(id).finally(() => { }).then(async (resData) => {
      if (typeof resData === 'object' && resData.hasOwnProperty('data')) {
        const collegeDetails = resData.data;
        collegeDetails?.courses?.map((course) => {
          course._id = course.id;
          course.courseName = course?.courseName;
          course.priority = course.priority;
        });
        setFormValue({

          displayName: collegeDetails.displayName,
          contactEmail: collegeDetails.contactEmail,
          mobile: parseInt(collegeDetails.mobile),
          academicType: collegeDetails.academicType,
          subscriptionType: collegeDetails.subscriptionType,
          courses: collegeDetails.courses || [],
          country: collegeDetails.country,
          stateProvince: collegeDetails.stateProvince,
          city: collegeDetails.city,
          postalCode: collegeDetails.postalCode,
        });

        setSelectedCourses(collegeDetails.courses || []);  // Set the selected courses correctly
        await handleCountryInput(collegeDetails.country);
        await handleStateInput(collegeDetails.stateProvince, collegeDetails.country);
      }
    }).catch((error) => { });
  }

  const onSubmitHandle = (e) => {
    e.preventDefault();
    formValue.courses = [];
    selectedCourses?.map((course, index) => {
      if (typeof course === 'object' && course !== null) {
        formValue.courses.push({
          id: course._id,
          priority: `${index}`
        });
      }
    });
    if (type === "edit") {
      updateCollege({ ...formValue, collegeId: id }).finally(() => {
      }).then((res) => {
        Swal.fire({
          title: "Successful",
          text: "College Update Successful!",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Okay"
      })
        navigate('/home/members', { replace: true });
        console.log(`🚀 ~ file: AddCollege.js:243 ~ updateCollege ~ res:`, res)
      }).catch((error) => {
        if(error?.response?.data){
          Swal.fire({
            title: "Error",
            text:  error.response.data,
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Okay"
          })
        }else{
          Swal.fire({
            title: "Error",
            text:  "Something went wrong please try again",
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Okay"
          })
        }
      });
    } else {
      createCollege({ ...formValue }).finally(() => {
      }).then((res) => {
      console.log(`🚀 ~ file: AddCollege.js:241 ~ createCollege ~ res:`, res)
      Swal.fire({
        title: "Successful",
        text: "New College Added Successful!",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Okay"
      });
      navigate('/home/members', { replace: true });
      }).catch((error) => {
        if(error?.response?.data){
          Swal.fire({
            title: "Error",
            text:  error.response.data,
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Okay"
          })
        }else{
          Swal.fire({
            title: "Error",
            text:  "Something went wrong please try again",
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Okay"
          })
        }
      });
    }
  }


  const getCountry = () => {
    getAddress({ addressCategory: 'country' }).finally(() => {
    }).then((res) => {
      if (res?.data && Array.isArray(res?.data)) {
        setCountryList(res?.data)
      } else {
        setCountryList([])
      }
    }).catch((error) => {
      setCountryList([]);
      console.log("🚀 ~ getAddress ~ error:", error)
    })
  }

  const onFormReset = () => {
    setFormValue(initFromDataFields);
    setSelectedCourses([]);
  }

  const SelectCourseModal = <>
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header>
        <div className='col-12'>
          <div className='row'>
            <div className='col-md-9'>
              <div className='row'>
                <div className='d-flex search-field position-relative'>
                  <input className="custom-select-input px-3" type='text' placeholder="Search by name" />
                  <button className="search-btn"><img src={SearchIcon} width={'18px'} height={'18px'} />Search</button>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className='row'>
                <div className='d-flex search-field'>
                  <button className="apply-btn" onClick={applyFilter}>
                    <img src={SearchIcon} width={'18px'} height={'18px'} /> Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='courseTable'>
          <table className='w-100'>
            <thead>
              <tr className='p-2'>
                <th className='p-2'>Select Course</th>
              </tr>
            </thead>
            <tbody>
              {rows?.map((row) => (
                <tr key={row?._id} >
                  <td >
                    <div>
                      <input
                        type="checkbox" id={row._id}
                        checked={tempSelectedCourses.some(course => course._id === row._id)}
                        onChange={(e) => handleCheckboxChange(e, row._id, row.courseName)}
                      />
                      <label for={row._id}>{row.courseName}</label>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <TablePagination
          component="div"
          count={totalCourse}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Modal.Body>
    </Modal>
  </>

  const SelectedCourse = () => {
    return (
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'left',
          flexWrap: 'wrap',
          listStyle: 'none',
          p: 0.5,
          m: 0,
        }}
        component="ul"
      >
        <div>
          <div className="input-label px-3">Selected Courses</div>
          <div className='d-flex flex-wrap m-4 gap-11-px'>
            {selectedCourses?.map((data) => (
              <li key={data?._id}>
                <Chip
                  label={data.courseName}
                  className='custom-chip'
                  deleteIcon={type !== 'view' ? <CloseIcon /> : null}
                  onDelete={type !== 'view' ? handleDelete(data) : null}
                />
              </li>
            ))}
          </div>
        </div>
      </Paper>
    )
  }

  return (
    <div className={'college-container ' + (sideNavState ? 'fullWidthOpenSideNav' : 'halfWidthOpenSideNav')}>
      <form onSubmit={onSubmitHandle} onReset={onFormReset} >
        <NavigateBack />
        <div className='college-form-card'>
          <div className='college-form-title'>
            <p>College Registration</p>
          </div>
          <div className='row mx-0'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-md-8 col-12'>
                  <p className="input-label" >College Name</p>
                  <input className="custom-input" type='text' name="displayName" value={displayName} onChange={onFormChangeHandle} required disabled={isAllFieldDisabled}></input>
                </div>
                <div className='col-md-4 col-12'>
                  <p className="input-label" >Mail ID</p>
                  <input className="custom-input" type='email' name="contactEmail" value={contactEmail} onChange={onFormChangeHandle} required disabled={isAllFieldDisabled}></input>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div className='row'>
                <div className='col-md-4 col-12'>
                  <p className="input-label" >Contact Number</p>
                  <div className='row'>
                    <div className='col-2'>
                      <input className="custom-input px-2" value="+91" readOnly></input>
                    </div>
                    <div className='col-10'>
                      <input className="custom-input" type='number' name="mobile" value={mobile} onChange={onFormChangeHandle} required disabled={isAllFieldDisabled}></input>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 col-12'>
                </div>
                <div className='col-md-4 col-12'>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div className='d-flex justify-content-between'>
                <div className='d-flex'>
                  <p className="input-label" >Course Name</p>
                </div>
                <div className='d-flex'>
                  <button className="input-label selectCourse" onClick={handleShow} disabled={isAllFieldDisabled}>Select Course</button>
                </div>
              </div>
              <div className="input-label">
                <SelectedCourse />
              </div>
            </div>
          </div>
          <div>
            <div className='college-form-title mt-1'>
              <p className='locality'>Locality</p>
            </div>
            <div className='row mx-0'>
              <div className='col-md-3 col-12'>
                <p className="input-label" >Country</p>
                <select className="custom-input" value={country} name="country" onChange={onFormChangeHandle} required disabled={isAllFieldDisabled}>
                  <option value="">Select country</option>
                  {countryList.map((country) => (
                    <option key={country?._id} value={country?.name}>
                      {country?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-md-3 col-12'>
                <p className="input-label" >State</p>
                <select className="custom-input" value={stateProvince} name='stateProvince' onChange={onFormChangeHandle} disabled={!country || isAllFieldDisabled || !statesList.length} required  >
                  <option value="">Select State</option>
                  {statesList.map((state) => (
                    <option key={state} value={state}>{state}</option>
                  ))
                  }
                </select>
              </div>
              <div className='col-md-3 col-12'>
                <p className="input-label" >City</p>
                <select className="custom-input" value={city} name='city' onChange={onFormChangeHandle} disabled={!stateProvince || isAllFieldDisabled || !citiesList.length} required>
                  <option value="">Select City</option>
                  {citiesList.map((city) => (
                    <option key={city} value={city}>{city}</option>
                  ))
                  }
                </select>
              </div>
              <div className='col-md-3 col-12'>
                <p className="input-label" >Postel Code</p>
                <input className="custom-input" type='number' name="postalCode" value={postalCode} onChange={onFormChangeHandle} required disabled={isAllFieldDisabled}></input>
              </div>
            </div>
          </div>
        </div>
        {
          type !== 'view' ?
            <div className='form-submit-card'>
              <button className='save-btn' type='submit' >{type === 'edit' ? 'Update' : 'Create'}</button>
              <button className='cancel-btn' type='reset'>Reset</button>
            </div>
            :
            <></>
        }

      </form>
      {SelectCourseModal}
    </div>
  )
}
