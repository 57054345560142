
import ViewDashboardReports from './ViewDashboardReports';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Col, Form, Row } from 'react-bootstrap';
import styles from './ViewDashboardReports.scss';

import tileLogo1 from '../../Assets/CountingTiles/tile-logo1.svg';
import tileLogo2 from '../../Assets/CountingTiles/tile-logo2.svg';
import tileLogo3 from '../../Assets/CountingTiles/tile-logo3.svg';
import tileLogo4 from '../../Assets/CountingTiles/tile-logo4.svg';

import topbox from '../../Assets/tempImg/task_icon.svg'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: '#348BF5',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    //   '&:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.action.hover,
    //   },
    //   // hide last border
    //   '&:last-child td, &:last-child th': {
    //     border: 0,
    //   },
    boxShadow: '0px 2px 1px #00000029',
    opacity: 1
}));

function createData(task_name, task_asnd_date, task_cmpltd_date, task_start_date, prgrs_status) {
    return { task_name, task_asnd_date, task_cmpltd_date, task_start_date, prgrs_status };
}

const rows = [
    createData('Anatomy', '1 June 2022', '7 June 2022', '20 June 2022', 'completed'),
    createData('Biotechnoloy', '3 June 2022', '17 June 2022', '27 June 2022', 'completed'),
    createData('Biotechnoloy', '3 June 2022', '17 June 2022', '27 June 2022', 'completed'),
    createData('Biotechnoloy', '3 June 2022', '17 June 2022', '27 June 2022', 'completed'),
    createData('Biotechnoloy', '3 June 2022', '17 June 2022', '27 June 2022', 'completed'),
    createData('Biotechnoloy', '3 June 2022', '17 June 2022', '27 June 2022', 'completed'),
];


function ViewDashboardReports1() {
    return (
        <div className="container"><br></br>
            <div className="row">

                <div className="col-md-3">

                    <div className="topbox_container">
                        <div className="row">
                            <div className="col-md-4 p-5">
                                <img src={topbox} />
                            </div>
                            <div className="col-md-8 p-5">

                                <h1>Course</h1>
                                <p>BGS</p>
                            </div>
                        </div>

                    </div>


                </div>
                <div className="col-md-3">
                    <div className="topbox_container">
                        <div className="row">
                            <div className="col-md-4 p-5">
                                <img src={topbox} />
                            </div>
                            <div className="col-md-8 p-5">

                                <h1>200</h1>
                                <p>Assigned Task</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-md-3">

                    <div className="topbox_container">
                        <div className="row">
                            <div className="col-md-4 p-5">
                                <img src={topbox} />
                            </div>
                            <div className="col-md-8 p-5">

                                <h1>200</h1>
                                <p>Task In Progress</p>
                            </div>
                        </div>

                    </div>


                </div>
                <div className="col-md-3">
                    <div className="topbox_container">
                        <div className="row">
                            <div className="col-md-4 p-5">
                                <img src={topbox} />
                            </div>
                            <div className="col-md-8 p-5">

                                <h1>200</h1>
                                <p>Completed Task</p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div className="row">
                <h1>View</h1>
                <Row className={styles.TableCustomizedBorder}>
                    <Box sx={{
                        minHeight: ' 46px',
                        background: '#EAF2FC 0% 0% no-repeat padding-box',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        gap: '10px',
                        justifyContent: 'space-between',
                        py: 2
                    }}>
                        <p className={styles.heading}>
                            Course Name : BGS Course   Dr. Sarang P.
                        </p>

                    </Box>
                    <Col className='py-4'>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Task Name </StyledTableCell>
                                        <StyledTableCell align="right">Assigned Date </StyledTableCell>
                                        <StyledTableCell align="right">Task Completed Date </StyledTableCell>
                                        <StyledTableCell align="right">Task Start Date</StyledTableCell>
                                        <StyledTableCell align="right">Progress Status</StyledTableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <StyledTableRow key={row.name}>
                                            <StyledTableCell component="th" scope="row">
                                                {row.task_name}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{row.task_asnd_date}</StyledTableCell>
                                            <StyledTableCell align="right">{row.task_cmpltd_date}</StyledTableCell>
                                            <StyledTableCell align="right">{row.task_start_date}</StyledTableCell>
                                            <StyledTableCell align="right">{row.prgrs_status}</StyledTableCell>

                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Col>
                </Row>

            </div>
        </div>
    );
}

export default ViewDashboardReports1;
