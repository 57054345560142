import React, { useState, useEffect } from "react";
import "./Report.scss";
import { Select, MenuItem, Checkbox, ListItemText, TextField, Divider, RadioGroup, FormControlLabel, Radio, FormHelperText, FormControl } from '@mui/material';
import { Controller, useForm } from "react-hook-form";
import downArrow from "../../Assets/commonImg/downArrow.svg";
import CommonBootstrapModal from "../../Components/CommonBootstrapModal/CommonBootstrapModal";
import CommonAdminTable from "../../Components/CommonAdminTable/CommonAdminTable";
import CommonEducatorTable from "../../Components/CommonEducatorTable/CommonEducatorTable";
import CommonStudentTable from "../../Components/CommonStudentTable/CommonStudentTable";
import CommonSearchFilter from "../../Components/CommonSearchFilter/ComonSearchFilter";
import { requestReport } from "../../Services/ReportService";
import Swal from "sweetalert2";

const radioButtonsData = [
  {
    type: "radio",
    className: "form-check-input",
    id: "college-admins",
    name: "userType",
    value: "college-admins",
    label: {
      className: "radio-label",
      htmlFor: "admin",
      text: "Admin",
    },
  },
  {
    type: "radio",
    className: "form-check-input",
    id: "educator",
    name: "userType",
    value: "educators",
    label: {
      className: "radio-label",
      htmlFor: "educator",
      text: "Educator",
    },
  },
  {
    type: "radio",
    className: "form-check-input",
    id: "student",
    name: "userType",
    value: "students",
    label: {
      className: "radio-label",
      htmlFor: "student",
      text: "Student",
    },
  },
];


const AuditReport = ({ college }) => {

  useEffect(() => {
    if (typeof college === 'object' && college?.collegeId) {
      console.log(`🚀 ~ file: AuditReport.js:55 ~ useEffect ~ college:`, college)
      setValue('collegeId', college?.collegeId)
    }
  }, [college])



  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    getValues,
    control,
    watch
  } = useForm(
    {
      defaultValues: {
        collegeId: '',
        startTs: '',
        endTs: '',
        userId: '',
        userType: 'college-admins',
        userName: '',
        reportType: 'audit',
        activity: [],
      }
    }
  );





  const [filteredOptions, setFilteredOptions] = useState([
    { value: "notes", label: "Notes" },
    { value: "login", label: "Login" },
    { value: "logout", label: "Logout" },
    { value: "assignment", label: "Assignment" },
    { value: "live-class", label: "Live Class" },
    { value: "lecture-video", label: "Videos" },
    { value: "groups", label: "Groups" },
  ]);
  const placeholder = 'Select activity';

  const handleOptionChange = (event) => {
    setValue('userId', '');
    setValue('userName', '');
  };

  const handleSelectAll = (event) => {
    console.log(`🚀 ~ file: AuditReport.js:105 ~ handleSelectAll ~ event:`, event)
    const checked = event.target.checked;
    console.log(`🚀 ~ file: AuditReport.js:107 ~ handleSelectAll ~ checked:`, checked)
    if (checked) {
      setValue('activity', filteredOptions.map(option => option.value));
    } else {
      setValue('activity', []);
    }
  };


  const onSubmit = () => {
    const payload = getValues();
    delete payload.userName;
    payload.startTs = new Date(payload.startTs).getTime();
    payload.endTs = new Date(payload.endTs).getTime();
    // requestReport(payload).then((resData) => {
    //   console.log(`🚀 ~ file: AuditReport.js:124 ~ requestReport ~ resData:`, resData)
    // }).catch((
    //   error
    // ) => {
    //   console.log(`🚀 ~ file: AuditReport.js:128 ~ onSubmit ~ error:`, error)
    // }
    // )
    if (!payload.userType || !payload.userId) {
      Swal.fire({
        title: 'Required filed(s) missing',
        text: 'Please select user before requesting report!',
        icon: 'warning',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#5284EE'
      });
      return;
    }
    requestReport(payload).then((resData) => {
      if (resData?.data?.message === "success") {
        Swal.fire('Report Requested', 'Your request has been accepted to generate the report', "success")
      }
    }).catch((error) => {
      if (error?.response?.data && typeof error?.response?.data === 'string') {
        Swal.fire(error?.response?.data, '', "error");
      }
    });
  };


  const [openCollegeAdmin, setCollegeOpen] = React.useState(false);
  const [SearchFilter, seatSearchFilter] = React.useState({});
  const { searchText, searchKey } = SearchFilter;
  const handleOpen = () => {
    setCollegeOpen(true);
    seatSearchFilter({});
  };
  const handleClose = () => {
    setCollegeOpen(false);
    seatSearchFilter({});
  };


  const onAdminSelected = (event) => {
    setValue('userId', event._id);
    setValue('userName', event.fullName);
    handleClose();
  };
  const onEducatorSelected = (event) => {
    setValue('userId', event._id);
    setValue('userName', event.fullName);
    handleClose();
  };

  const onStudentSelected = (event) => {
    setValue('userId', event._id);
    setValue('userName', event.fullName);
    handleClose();
  };

  const onSearchHandle = (e) => {
    seatSearchFilter(e);
  }

  let modalHeaders = <>
    <CommonSearchFilter onSearch={onSearchHandle} handleClose={handleClose} />
  </>;


  let modalBody = <></>;
  let selectFieldLabel = "";
  let selectFieldPlaceholder = "";
  const selectedUserType = watch('userType');



  if (selectedUserType === "college-admins") {
    modalBody = <>
      <CommonAdminTable
        collegeId={college.collegeId}
        onSelect={onAdminSelected}
        {...{ searchText, searchKey }}
      />
    </>
    selectFieldLabel = "Select Admin"
    selectFieldPlaceholder = "Select Admin"
  } else if (selectedUserType === "educators") {
    modalBody = <>
      <CommonEducatorTable
        collegeId={college.collegeId}
        onSelect={onEducatorSelected}
        {...{ searchText, searchKey }}
      />
    </>
    selectFieldLabel = "Select Educator"
    selectFieldPlaceholder = "Select Educator"
  } else if (selectedUserType === "students") {
    modalBody = <>
      <CommonStudentTable
        collegeId={college.collegeId}
        onSelect={onStudentSelected}
        {...{ searchText, searchKey }}
      />
    </>
    selectFieldLabel = "Select Student"
    selectFieldPlaceholder = "Select Student"
  }


  return (
    <div id="audit-report" className="container">

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            row
            defaultValue="college-admins"
            onChange={handleOptionChange}
          >
            <Controller
              name="userType"
              control={control}
              defaultValue="college-admins"
              render={({ field }) => (
                <>
                  {radioButtonsData.map(option => (
                    <FormControlLabel key={option.id} {...field} value={option.value} control={<Radio />} label={option.label.text} />
                  ))}
                </>
              )}
            />

          </RadioGroup>


        </div>
        <hr />
        <section>
          <main className="w-100 m-auto">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="card-body">
                    <div className="row mb-3">
                      <label
                        htmlFor="entity-select"
                        className="col-md-5 col-form-label"
                      >
                        {selectFieldLabel}
                      </label>
                      <div className="col-md-6 position-relative">
                        <input className="custom-input px-3" type="text" placeholder={selectFieldPlaceholder} value={getValues('userName')} onClick={handleOpen} readOnly />
                        <div className="admin-down-arrow">
                          <img src={downArrow} width={"15px"} height={"13px"} />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="activity"
                        className="col-md-5 col-form-label"
                      >
                        Select Activity (Optional)
                      </label>
                      <div className="col-md-6 position-relative">
                        <Controller
                          name="activity"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) => (
                            <Select
                              {...field}
                              labelId="multiselect-label"
                              className="w-100 custom-input hide-dropdown-arrow"
                              multiple
                              displayEmpty
                              renderValue={(selected) => {
                                if (selected.length === 0) {
                                  return 'Select Activity'; // Simple placeholder text
                                }
                                return selected.join(', ');
                              }}
                            >
                              <MenuItem value="" disabled>
                                Select Activity
                              </MenuItem>
                              <MenuItem>
                                <Checkbox
                                  checked={getValues('activity').length === filteredOptions.length}
                                  onChange={handleSelectAll}
                                />
                                <ListItemText primary="Select All" />
                              </MenuItem>
                              <Divider />
                              {filteredOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  <Checkbox checked={field.value.indexOf(option.value) > -1} />
                                  <ListItemText primary={option.label} />
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        <div className="admin-down-arrow">
                          <img src={downArrow} width={"15px"} height={"13px"} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="card-body">
                    <div className="row mb-3">
                      <label
                        htmlFor="startDate"
                        className="col-md-4 col-form-label"
                      >
                        Start Date
                      </label>
                      <div className="col-md-7">
                        <input
                          className="custom-input px-3" //is-invalid add this class for validation  
                          type="date"
                          {...register("startTs", {
                            required: "start date is required",
                            validate: (value) => new Date(value) <= new Date() || "",
                          })}
                        />
                        <div className="invalid-feedback">
                          Please Start Date.
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="endDate" className="col-md-4 col-form-label">
                        End Date
                      </label>
                      <div className="col-md-7">
                        <input
                          className="custom-input px-3" // is-invalid add this class for validation 
                          type="date"
                          placeholder="Select End Date"
                          {...register("endTs", {
                            required: "End date is required",
                            validate: (value) => new Date(value) <= new Date() || "",
                          })}
                        />
                        <div className="invalid-feedback">
                          Please Select End Date.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-1"></div>
              </div>

              <div className="btn-group">
                <button type="reset" className="clear-btn">Clear</button>
                <button type="submit" className="report-btn">Request Report</button>
              </div>
            </div>
          </main>
        </section>
      </form>

      <CommonBootstrapModal
        modalProps={{
          ...{
            show: openCollegeAdmin,
            onHide: handleClose,
            backdrop: "static",
            keyboard: false,
            scrollable: true
          }
        }}
        modalHeaderCloseButton={false}
        header={modalHeaders}
        body={modalBody}
      >


      </CommonBootstrapModal>
    </div>
  );
};

export default AuditReport;