import React, { useEffect } from 'react'
import './SubjectList.scss';
import { TablePagination } from '@mui/material';
import NavigateBack from '../../../CommonScreens/NavigateBack/NavigateBack';

export default function SubjectList({ sideNavState }) {
    useEffect(() => {
        // getSubjectList(0, 10)
    }, [])
    // const [subjectListData, setSubjectListData] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [count, setTotalSubjectList] = React.useState(0);

    // const getSubjectList = async (offset, limit) => {
    //     try {
    //        
    //     } catch (error) {
    //     }
    // }

    const subjectListData = [
        {Anatomy:'Anatomy', Physiology: 'Physiology', Biochemistry: 'Biochemistry', Biotechnology: 'Biotechnology' },
        {Anatomy:'Anatomy', Physiology: 'Physiology',Biochemistry: 'Biochemistry', Biotechnology: 'Biotechnology' },
        {Anatomy:'Anatomy', Physiology: 'Physiology',Biochemistry: 'Biochemistry', Biotechnology: 'Biotechnology' },
        {Anatomy:'Anatomy', Physiology: 'Physiology',Biochemistry: 'Biochemistry', Biotechnology: 'Biotechnology' },
        {Anatomy:'Anatomy', Physiology: 'Physiology',Biochemistry: 'Biochemistry', Biotechnology: 'Biotechnology' },
        {Anatomy:'Anatomy', Physiology: 'Physiology',Biochemistry: 'Biochemistry', Biotechnology: 'Biotechnology' },
        {Anatomy:'Anatomy', Physiology: 'Physiology',Biochemistry: 'Biochemistry', Biotechnology: 'Biotechnology' },
        {Anatomy:'Anatomy', Physiology: 'Physiology',Biochemistry: 'Biochemistry', Biotechnology: 'Biotechnology' },
        {Anatomy:'Anatomy', Physiology: 'Physiology',Biochemistry: 'Biochemistry', Biotechnology: 'Biotechnology' },
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        // getSubjectList(newPage * rowsPerPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        // getSubjectList(0, parseInt(event.target.value, 10));
    };

    return (
        <div className={'subject-list-container ' + (sideNavState ? 'fullWidthOpenSideNav' : 'halfWidthOpenSideNav')}>
            <NavigateBack />
            <div className='main-container'>
                <div className='d-flex justify-content-start py-4 px-3'>
                    <div className='listOfSubjects'>
                       List Of Subjects
                    </div>
                </div>
                <div className='subjectList-table-container px-5'>
                    <div>
                        {subjectListData.length > 0 ? (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Educator name</th>
                                        <th>Physiology</th>
                                        <th>Biochemistry </th>
                                        <th>Biotechnology</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {subjectListData.map((row) => (
                                        <tr key={row.id}>
                                            <td>{row.Anatomy}</td>
                                            <td>{row.Physiology}</td>
                                            <td>{row.Biochemistry}</td>
                                            <td>{row.Biotechnology}</td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>

                        ) : (
                            <p>No data available</p>
                        )}
                    </div>

                    <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Items per page" // Change labelRowsPerPage to customize the text
                    />
                </div>
            </div>
        </div>
    )
}
