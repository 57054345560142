import { readService } from './HttpService'
import { url } from '../Config/UrlRoute'
import { getDecryptData, decodeJWTToken } from './SessionStoreService'

const getcollegeList = (offset, limit, searchText = null, searchKey = null) => {
    const userData = JSON.parse(getDecryptData('uadminProfile'));
    if (userData) {
        const endPoint = url.university.college.getCollegeList;
        const params = {
            universityId: userData.universityId,
            offset: offset,
            limit: limit,
            searchKey,
            searchText
        };
        if(searchKey) params.searchKey = searchKey;
        if(searchText) params.searchText = searchText;
        return readService(endPoint, params);
    }
}

const getCourseList = (offset, limit, collegeId, searchKey, searchText) => {
    const cognitoUserSession = getDecryptData('cognitoUserSession');
    if (cognitoUserSession) {
        const token = JSON.parse(cognitoUserSession);
        const user = decodeJWTToken(token?.idToken?.jwtToken);
        const endPoint = url.university.admin.getCourseList;
        const params = {
            uid: user['custom:_id'],
            offset: offset,
            limit: limit,
            collegeId,
            searchKey,
            searchText
        };
        if (!searchKey || !searchText) {
            delete params.searchKey;
            delete params.searchText;
        }
        return readService(endPoint, params);
    }
}

const getAllCourseOfSelectedCollege = (collegeId) => {
    const userData = JSON.parse(getDecryptData('uadminProfile'));
    if (userData) {
        const endPoint = url.university.admin.getCourseList;
        const params = {
            uid: userData._id,
        };
        if(collegeId) params.collegeId = collegeId;
        return readService(endPoint, params);
    }
}

const getEducatorList = (offset, limit) => {
    const cognitoUserSession = getDecryptData('cognitoUserSession');
    if (cognitoUserSession) {
        const token = JSON.parse(cognitoUserSession);
        const user = decodeJWTToken(token?.idToken?.jwtToken);
        const endPoint = url.university.educator.getEducator;
        const params = {
            uid: user['custom:_id'],
            offset: offset,
            limit: limit
        };
        return readService(endPoint, params);
    }
}
const getStudentList = (offset, limit) => {
    const cognitoUserSession = getDecryptData('cognitoUserSession');
    if (cognitoUserSession) {
        const token = JSON.parse(cognitoUserSession);
        const user = decodeJWTToken(token?.idToken?.jwtToken);
        const endPoint = url.university.student.getStudent;
        const params = {
            uid: user['custom:_id'],
            offset: offset,
            limit: limit
        };
        return readService(endPoint, params);
    }
}
export {
    getcollegeList,
    getCourseList,
    getEducatorList,
    getStudentList,
    getAllCourseOfSelectedCollege
}