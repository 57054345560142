import React, { useEffect, useState } from "react";
import "./MyProfile.scss";
import profileImg from "./../../../Assets/commonImg/profilePic.png";
import { useForm } from "react-hook-form";
import { updateCurrentUniversityAdmin } from "../../../Services/UniversityAdminService";
import Swal from "sweetalert2";
import { getAddress } from "../../../Services/AddressService";

export default function MyProfile({ userProfile, sideNavState }) {
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
		reset,
		getValues,
	} = useForm();

	const [countryList, setCountryList] = useState([]);
	const [statesList, setStates] = useState([]);
	const [citiesList, setCities] = useState([]);
	const [adminId, setAdminId] = useState("");

	useEffect(() => {
		getCountry();
	}, []);

	useEffect(() => {
		if (userProfile && typeof userProfile === "object") {
			const { firstName, middleName, lastName, username, birthdate, gender, mobile, primaryEmail, country, stateProvince, city, postalCode, userThumbnailPath, universityId, _id } = userProfile;
			const userDetails = {
				firstName,
				middleName,
				lastName,
				username,
				primaryEmail,
				gender,
				mobile: mobile.toString(),
				birthdate: formatDate(birthdate),
				postalCode,
				country,
				stateProvince,
				city,
				_id,
			};
			setAdminId(_id);
			Object.keys(userDetails).forEach((key) => setValue(key, userDetails[key]));
			getState(country);
			getCity(country, stateProvince);
		}
	}, [userProfile]);

	const getCountry = () => {
		getAddress({ addressCategory: "country" })
			.finally(() => { })
			.then((res) => {
				if (res?.data && Array.isArray(res?.data)) {
					setCountryList(res?.data);
				} else {
					setCountryList([]);
				}
			})
			.catch((error) => {
				setCountryList([]);
				console.log("🚀 ~ getAddress ~ error:", error);
			});
	};
	const getState = (selectedCountryName) => {
		getAddress({ addressCategory: "state", countryName: selectedCountryName })
			.finally(() => { })
			.then((res) => {
				if (res?.data && Array.isArray(res?.data)) {
					setStates(res?.data);
					console.log("🚀 ~ getAddres?.datas ~ res?.data:", res?.data);
				} else {
					setStates([]);
				}
			})
			.catch((error) => {
				setStates([]);
				console.log("🚀 ~ getAddress ~ error:", error);
			});
	};

	const getCity = (country, selectedStateName) => {
		getAddress({ addressCategory: "city", countryName: country, stateName: selectedStateName })
			.finally(() => { })
			.then((res) => {
				if (res?.data && Array.isArray(res?.data)) {
					setCities(res?.data);
				} else {
					setCities([]);
				}
			})
			.catch((error) => {
				setCities([]);
				console.log("🚀 ~ getAddress ~ error:", error);
			});
	};

	const handleCountryInput = (event) => {
		const selectedCountryName = event;
		reset({ state: "", city: "" });
		setStates([]);
		setCities([]);
		getAddress({ addressCategory: "state", countryName: selectedCountryName })
			.finally(() => { })
			.then((res) => {
				if (res?.data && Array.isArray(res?.data)) {
					setStates(res?.data);
					console.log("🚀 ~ getAddres?.datas ~ res?.data:", res?.data);
				} else {
					setStates([]);
				}
			})
			.catch((error) => {
				setStates([]);
				console.log("🚀 ~ getAddress ~ error:", error);
			});
	};

	const handleStateInput = (state) => {
		const selectedStateName = state;
		const country = getValues("country");
		reset({ city: "" });
		getAddress({ addressCategory: "city", countryName: country, stateName: selectedStateName })
			.finally(() => { })
			.then((res) => {
				if (res?.data && Array.isArray(res?.data)) {
					setCities(res?.data);
				} else {
					setCities([]);
				}
			})
			.catch((error) => {
				setCities([]);
				console.log("🚀 ~ getAddress ~ error:", error);
			});
	};

	const onSubmit = (data) => {
		console.log(getValues());
		if (typeof data === "object" && data) {
			data.birthdate = new Date(data.birthdate).getTime();
			data = { ...data, mobile: userProfile.mobile || '' }
			updateCurrentUniversityAdmin(adminId, data)
				.then((resData) => {
					console.log(`🚀 ~ file: MyProfile.js:18 ~ updateCurrentUniversityAdmin ~ resData:`, resData);
					Swal.fire({
						icon: "success",
						title: "Update Successfully",
						showConfirmButton: false,
					});
				})
				.catch((error) => {
					console.log(`🚀 ~ file: MyProfile.js:20 ~ updateCurrentUniversityAdmin ~ error:`, error);
				});
		}
	};

	const formatDate = (date) => {
		if (date) {
			const d = new Date(date);
			const year = d.getFullYear();
			const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
			const day = String(d.getDate()).padStart(2, "0");

			return `${year}-${month}-${day}`;
		} else {
			return "";
		}
	};

	return (
		<div className={'dashboard-container ' + (sideNavState ? 'fullWidthOpenSideNav' : 'halfWidthOpenSideNav')}>
		<div className="profile-container">
			<div className="personalInfo">Personal Info</div>
			<div className="p-3">
				<div className="row photoSection">
					<div className="col-4 infoHeading">Your Photo</div>
					<div className="col-8">
						<img src={profileImg} alt="" style={{ width: "90px", height: "90px" }} />
					</div>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row photoSection mt-4">
						<div className="col-4 infoHeading">Name</div>
						<div className="col-8">
							<div className="row">
								<div className="col-4">
									<div>First Name</div>
									<div className="mt-2">
										<input
											className="custom-input"
											{...register("firstName", {
												required: "First name is required",
												minLength: { value: 2, message: "First name must be at least 2 characters" },
											})}
										/>
										{errors.firstName && <p>{errors.firstName.message}</p>}
									</div>
								</div>
								<div className="col-4">
									<div> Middle Name</div>
									<div className="mt-2">
										<input
											className="custom-input"
											{...register("middleName", {
												required: "First name is required",
												minLength: { value: 2, message: "Middle name must be at least 2 characters" },
											})}
										/>
										{errors.middleName && <p>{errors.middleName.message}</p>}
									</div>
								</div>
								<div className="col-4">
									<div> Last Name</div>
									<div className="mt-2">
										<input
											className="custom-input"
											{...register("lastName", {
												required: "Last name is required",
												minLength: { value: 2, message: "Last name must be at least 2 characters" },
											})}
										/>
										{errors.lastName && <p>{errors.lastName.message}</p>}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row photoSection mt-3">
						<div className="col-4 infoHeading">Username</div>
						<div className="col-8">
							<div className="row">
								<div className="col-8">
									<input
										disabled={true}
										className="custom-input"
										{...register("username")}
									/>
									{errors.username && <p>{errors.username.message}</p>}
								</div>
								<div className="col-4"></div>
							</div>
						</div>
					</div>
					<div className="row photoSection mt-3">
						<div className="col-4 infoHeading">Email</div>
						<div className="col-8">
							<div className="row">
								<div className="col-8">
									{" "}
									<input
										className="custom-input"
										type="primaryEmail"
										disabled={true}
										{...register("primaryEmail")}
									/>
									{errors.primaryEmail && <p>{errors.primaryEmail.message}</p>}
								</div>
								<div className="col-4"></div>
							</div>
						</div>
					</div>
					<div className="row photoSection mt-3">
						<div className="col-4 infoHeading">Gender</div>
						<div className="col-8">
							<div className="row">
								<div className="col-5">
									<select
										className="custom-input"
										{...register("gender", {
											required: "Gender is required",
										})}
									>
										<option value="">Select Gender</option>
										<option value="Male">Male</option>
										<option value="Female">Female</option>
									</select>
									{errors.gender && <p>{errors.gender.message}</p>}
								</div>
								<div className="col-7"></div>
							</div>
						</div>
					</div>
					<div className="row photoSection mt-3">
						<div className="col-4 infoHeading">Contact</div>
						<div className="col-8">
							<div className="row">
								<div className="col-5">
									<div className="row">
										<div className="col-3">

											<input disabled={true} className="custom-input" ></input>
										</div>
										<div className="col-9">
											<input
												className="custom-input"
												type="text"
												disabled={true}
												{...register("mobile")}
											/>
											{errors.mobile && <p>{errors.mobile.message}</p>}
										</div>
									</div>
								</div>
								<div className="col-7"></div>
							</div>
						</div>
					</div>
					<div className="row photoSection mt-3">
						<div className="col-4 infoHeading">Date Of Birth</div>
						<div className="col-8">
							<div className="row">
								<div className="col-5">
									<input
										className="custom-input"
										type="date"
										{...register("birthdate", {
											required: "Date of birth is required",
											validate: (value) => new Date(value) <= new Date() || "Date of birth cannot be in the future",
										})}
									/>
									{errors.birthdate && <p>{errors.birthdate.message}</p>}
								</div>
								<div className="col-7"></div>
							</div>
						</div>
					</div>
					<div className="row photoSection mt-3">
						<div className="col-4 infoHeading">Address</div>
						<div className="col-8">
							<div className="row">
								<div className="col-3">
									<div>Country</div>
									<div className="mt-2">
										<select
											className="custom-input"
											{...register("country", {
												required: "Country is required",
											})}
											onChange={(event) => handleCountryInput(event.target.value)}
										>
											<option value="">Select country</option>
											{countryList.map((country) => (
												<option key={country?._id} value={country?.name}>
													{country?.name}
												</option>
											))}
										</select>
										{errors.country && <p>{errors.country.message}</p>}
									</div>
								</div>
								<div className="col-3">
									<div>State</div>
									<div className="mt-2">
										<select
											className="custom-input"
											{...register("stateProvince", {
												required: "State is required",
											})}
											onChange={(event) => handleStateInput(event.target.value)}
										>
											<option value="">Select State</option>
											{statesList.map((state) => (
												<option key={state} value={state}>
													{state}
												</option>
											))}
										</select>
										{errors.stateProvince && <p>{errors.stateProvince.message}</p>}
									</div>
								</div>
								<div className="col-3">
									<div>City</div>
									<div className="mt-2">
										<select
											className="custom-input"
											{...register("city", {
												required: "City Code is required",
											})}
										>
											<option value="">Select City</option>
											{citiesList.map((city) => (
												<option key={city} value={city}>
													{city}
												</option>
											))}
										</select>
										{errors.city && <p>{errors.city.message}</p>}
									</div>
								</div>
								<div className="col-3">
									<div>Postal Code</div>
									<div className="mt-2">
										<input
											className="custom-input"
											{...register("postalCode", {
												required: "Postal Code is required",
											})}
										/>
										{errors.postalCode && <p>{errors.postalCode.message}</p>}
									</div>
								</div>
							</div>
						</div>
					</div>
					{console.log("error", errors)}
					<div className="d-flex justify-content-end align-items-center mt-4">
						<button type="submit" className="saveBtn">
							Save Changes
						</button>
					</div>
				</form>
			</div>
		</div>
		</div>
	);
}
