import React, { useEffect, useState } from 'react';
import styles from "./ViewDashboardOverview.module.scss";
import collegeIcon from './../../Assets/dashboard/collegeIcon.svg';
import collegeArrow from './../../Assets/dashboard/collegeArrow.svg';
import courseIcon from './../../Assets/dashboard/courseIcon.svg';
import courseArrow from './../../Assets/dashboard/courseArrow.svg';
import educatorIcon from './../../Assets/dashboard/educatorIcon.svg';
import educatorArrow from './../../Assets/dashboard/educatorArrow.svg';
import studentIcon from './../../Assets/dashboard/studentIcon.svg'
import studentArrow from './../../Assets/dashboard/studentArrow.svg';
import videoIcon from './../../Assets/dashboard/videoIcon.svg';
import videoArrow from './../../Assets/dashboard/videoArrow.svg';
import notesIcon from './../../Assets/dashboard/notesIcon.svg';
import notesArrow from './../../Assets/dashboard/notesArrow.svg';
import assignmentIcon from './../../Assets/dashboard/assignmentIcon.svg';
import assignmentArrow from './../../Assets/dashboard/assignmentArrow.svg';
import assessmentIcon from './../../Assets/dashboard/assessmentIcon.svg'
import assessmentArrow from './../../Assets/dashboard/assessmentArrow.svg';
import presentationIcon from './../../Assets/dashboard/presentationIcon.svg';
import presentationArrow from './../../Assets/dashboard/presentationArrow.svg';
import onSiteClassIcon from './../../Assets/dashboard/onSiteClassessIcon.svg';
import onSiteClassArrow from './../../Assets/dashboard/onSiteClassessArrow.svg';

const ViewDashboardOverview = ({ entityCount }) => {
    const initialValue = {
        "totalStudents": 0,
        "totalEducators": 0,
        "totalVideos": 0,
        "totalNotes": 0,
        "totalAssignments": 0,
        "totalAssessments": 0,
        "totalGroups": 0,
        "totalOnsiteClasses": 0,
        "totalCollege": 0,
        "totalPresentation": 0
    };
    const [totalCounts, setTotalCounts] = useState(initialValue);
    useEffect(() => {
        if (entityCount && typeof entityCount === 'object') {
            setTotalCounts(entityCount);
        }
    }, [entityCount]);

    const {
        totalStudents = 0,
        totalEducators = 0,
        totalVideos = 0,
        totalNotes = 0,
        totalAssignments = 0,
        totalAssessments = 0,
        totalGroups = 0,
        totalOnsiteClasses = 0,
        totalCollege = 0,
        totalPresentation = 0
    } = totalCounts;
    return (
        <div className='d-flex flex-wrap justify-content-center'>
            <div className={`${styles.cards} ${styles.collegeCard}`}>
                <div className={styles.cardContain}>
                    <div className='mt-1'><img src={collegeIcon} width={36} height={34} alt="" /></div>
                    <div className='d-flex flex-column align-items-center'>
                        <div className={`${styles.cardCount} ${styles.collegeCount}`}>{totalCollege}</div>
                        <div className={styles.cardsTitle}>College</div>
                    </div>
                </div>
                <div className={styles.arrow}>
                    <img src={collegeArrow} width={27} height={7} alt="" />
                </div>
            </div>
            <div className={`${styles.cards} ${styles.courseCard}`}>
                <div className={styles.cardContain}>
                    <div className='mt-1'><img src={courseIcon} width={36} height={34} alt="" /></div>
                    <div className='d-flex flex-column align-items-center'>
                        <div className={`${styles.cardCount} ${styles.courseCount}`}>N/A</div>
                        <div className={styles.cardsTitle}>Course</div>
                    </div>
                </div>
                <div className={styles.arrow}>
                    <img src={courseArrow} width={27} height={7} alt="" />
                </div>
            </div>
            <div className={`${styles.cards} ${styles.educatorCard}`}>
                <div className={styles.cardContain}>
                    <div className='mt-1'><img src={educatorIcon} width={36} height={34} alt="" /></div>
                    <div className='d-flex flex-column align-items-center'>
                        <div className={`${styles.cardCount} ${styles.educatorCount}`}>{totalEducators}</div>
                        <div className={styles.cardsTitle}>Educator</div>
                    </div>
                </div>
                <div className={styles.arrow}>
                    <img src={educatorArrow} width={27} height={7} alt="" />
                </div>
            </div>
            <div className={`${styles.cards} ${styles.studentCard}`}>
                <div className={styles.cardContain}>
                    <div className='mt-1'><img src={studentIcon} width={36} height={34} alt="" /></div>
                    <div className='d-flex flex-column align-items-center'>
                        <div className={`${styles.cardCount} ${styles.studentCount}`}>{totalStudents}</div>
                        <div className={styles.cardsTitle}>Student</div>
                    </div>
                </div>
                <div className={styles.arrow}>
                    <img src={studentArrow} width={27} height={7} alt="" />
                </div>
            </div>
            <div className={`${styles.cards} ${styles.videoCard}`}>
                <div className={styles.cardContain}>
                    <div className='mt-1'><img src={videoIcon} width={36} height={34} alt="" /></div>
                    <div className='d-flex flex-column align-items-center'>
                        <div className={`${styles.cardCount} ${styles.videoCount}`}>{totalVideos}</div>
                        <div className={styles.cardsTitle}>Video</div>
                    </div>
                </div>
                <div className={styles.arrow}>
                    <img src={videoArrow} width={27} height={7} alt="" />
                </div>
            </div>
            <div className={`${styles.cards} ${styles.notesCard}`}>
                <div className={styles.cardContain}>
                    <div className='mt-1'><img src={notesIcon} width={36} height={34} alt="" /></div>
                    <div className='d-flex flex-column align-items-center'>
                        <div className={`${styles.cardCount} ${styles.notesCount}`}>{totalNotes}</div>
                        <div className={styles.cardsTitle}>Notes</div>
                    </div>
                </div>
                <div className={styles.arrow}>
                    <img src={notesArrow} width={27} height={7} alt="" />
                </div>
            </div>
            <div className={`${styles.cards} ${styles.assignmentCard}`}>
                <div className={styles.cardContain}>
                    <div className='mt-1'><img src={assignmentIcon} width={36} height={34} alt="" /></div>
                    <div className='d-flex flex-column align-items-center'>
                        <div className={`${styles.cardCount} ${styles.assignmentCount}`}>{totalAssignments}</div>
                        <div className={styles.cardsTitle}>Assignment</div>
                    </div>
                </div>
                <div className={styles.arrow}>
                    <img src={assignmentArrow} width={27} height={7} alt="" />
                </div>
            </div>
            <div className={`${styles.cards} ${styles.assessmentCard}`}>
                <div className={styles.cardContain}>
                    <div className='mt-1'><img src={assessmentIcon} width={36} height={34} alt="" /></div>
                    <div className='d-flex flex-column align-items-center'>
                        <div className={`${styles.cardCount} ${styles.assessmentCount}`}>{totalAssessments}</div>
                        <div className={styles.cardsTitle}>Assessment</div>
                    </div>
                </div>
                <div className={styles.arrow}>
                    <img src={assessmentArrow} width={27} height={7} alt="" />
                </div>
            </div>
            <div className={`${styles.cards} ${styles.presentationCard}`}>
                <div className={styles.cardContain}>
                    <div className='mt-1'><img src={presentationIcon} width={36} height={34} alt="" /></div>
                    <div className='d-flex flex-column align-items-center'>
                        <div className={`${styles.cardCount} ${styles.presentationCount}`}>{totalPresentation}</div>
                        <div className={styles.cardsTitle}>Presentation</div>
                    </div>
                </div>
                <div className={styles.arrow}>
                    <img src={presentationArrow} width={27} height={7} alt="" />
                </div>
            </div>
            <div className={`${styles.cards} ${styles.onSiteClassCard}`}>
                <div className={styles.cardContain}>
                    <div className='mt-1'><img src={onSiteClassIcon} width={36} height={34} alt="" /></div>
                    <div className='d-flex flex-column align-items-center'>
                        <div className={`${styles.cardCount} ${styles.onSiteClassCount}`}>{totalOnsiteClasses}</div>
                        <div className={styles.cardsTitle}>Onsite Classes</div>
                    </div>
                </div>
                <div className={styles.arrow}>
                    <img src={onSiteClassArrow} width={27} height={7} alt="" />
                </div>
            </div>
        </div>
    )
}

export default ViewDashboardOverview
