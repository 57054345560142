import React, { useEffect, useState } from "react";
import styles from "./ViewDashboardDetails.scss";
import logo from "./../../Assets/dashboard/image1.png";
import collegeLogo from "./../../Assets/dashboard/government-icon.png";
import downArrowImg from "./../../Assets/dashboard/downArrow.svg";
import upArrowImg from "./../../Assets/dashboard/upArrow.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function ViewDashboardDetails({ taskProgress, setCollege, allCollegeList, selectedCollege }) {
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [taskProgressData, setTaskProgress] = useState({
        totalCount: 0,
        toDoCount: 0,
        inProgressCount: 0,
        doneCount: 0,
    });
    const widthInherit = { width: "inherit" };
    const { totalCount = 0, toDoCount = 0, inProgressCount = 0, doneCount = 0 } = taskProgressData;

    const handleFocus = () => {
        setIsSelectOpen(true);
    };

    const handleBlur = () => {
        setIsSelectOpen(false);
    };

    useEffect(() => {
        if (typeof taskProgress === "object" && taskProgress) {
            setTaskProgress(taskProgress);
        }
    }, [taskProgress]);

    // const totalTasks = 20;
    // const todo = 5;
    // const inprogress = 5;
    // const done = 10;

    return (
        <div className="row">
            <div className="universityNameDetails">
                <div className="d-flex flex-column justify-content-center py-3 px-4">
                    <div className="d-flex align-items-center">
                        <div>
                            <img src={logo} width="46px" />
                        </div>
                        <div className="universityName">Alliance University</div>
                    </div>
                    <div className="universityCode mt-2">
                        University Code: <span className="universityCode">575566869</span>
                    </div>
                </div>
                <div className="d-flex align-items-center firstBlockBgImg">
                    <img src={logo} alt="Logo" width="300px" height="200px" />
                </div>
            </div>
            <div className="d-flex p-0">
                <div className="collegeSection mt-3 mr-3 w-100">
                    <div className="d-flex align-items-center" style={widthInherit}>
                        <div>
                            <img src={collegeLogo} width="60px" />
                        </div>
                        <div className="d-flex select-container px-2">
                            <select
                                className="custom-select-input no-arrow w-100"
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    setCollege(e.target.value);
                                }}
                                value={selectedCollege}
                            >
                                {allCollegeList.map((x) => {
                                    return (
                                        <option key={x?._id} value={x?._id}>
                                            {x?.displayName}
                                        </option>
                                    );
                                })}
                            </select>
                            <img src={isSelectOpen ? upArrowImg : downArrowImg} alt="Arrow Icon" className="arrow-icon" />
                        </div>
                    </div>
                </div>
                <div className="taskSection mt-3 d-flex flex-column pt-1 px-2 ml-3 w-100">
                    <div className="taskAssigned">Task Assigned</div>
                    <div className="d-flex justify-content-center taskAssignedStatus">
                        <div className="progressStatusSection mx-4">
                            <div className="taskStatusHeading mb-1">Total Tasks</div>
                            <div style={{ width: 43, height: 43 }}>
                                <CircularProgressbar
                                    value={totalCount}
                                    text={`${totalCount}`}
                                    strokeWidth={15}
                                    styles={buildStyles({
                                        pathColor: `#2580EC`,
                                        textColor: "#000",
                                        trailColor: "#8199DB6C",
                                    })}
                                />
                            </div>
                        </div>
                        <div className="progressStatusSection mx-4">
                            <div className="taskStatusHeading mb-1">To Do</div>
                            <div style={{ width: 43, height: 43 }}>
                                <CircularProgressbar
                                    value={toDoCount}
                                    text={`${toDoCount}`}
                                    strokeWidth={15}
                                    styles={buildStyles({
                                        pathColor: `#2580EC`,
                                        textColor: "#000",
                                        trailColor: "#8199DB6C",
                                    })}
                                />
                            </div>
                        </div>
                        <div className="progressStatusSection mx-4">
                            <div className="taskStatusHeading mb-1">In Progress</div>
                            <div style={{ width: 43, height: 43 }}>
                                <CircularProgressbar
                                    value={inProgressCount}
                                    text={`${inProgressCount}`}
                                    strokeWidth={15}
                                    styles={buildStyles({
                                        pathColor: `#2580EC`,
                                        textColor: "#000",
                                        trailColor: "#8199DB6C",
                                    })}
                                />
                            </div>
                        </div>
                        <div className="progressStatusSection mx-4">
                            <div className="taskStatusHeading mb-1">Completed</div>
                            <div style={{ width: 43, height: 43 }}>
                                <CircularProgressbar
                                    value={doneCount}
                                    text={`${doneCount}`}
                                    strokeWidth={15}
                                    styles={buildStyles({
                                        pathColor: `#2580EC`,
                                        textColor: "#000",
                                        trailColor: "#8199DB6C",
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
