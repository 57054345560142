import React from 'react';
import { useNavigate } from 'react-router-dom';
import BackIcon from '../../../Assets/commonImg/back.svg';
import './NavigateBack.scss'

const NavigateBack = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/home/members', { replace: true });
  };

  return (
    <div className="go-back" onClick={goBack}>
      <img src={BackIcon} className="back-icon" alt="Back" />
      <span className="back-text">Back</span>
    </div>
  );
};

export default NavigateBack;
