import React, { useEffect, useState } from 'react'
import './RegisteredEducator.scss';
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import Swal from 'sweetalert2';
import NavigateBack from '../../../CommonScreens/NavigateBack/NavigateBack';
import ViewIcon from '../../../../Assets/commonImg/view.svg';
import EditIcon from '../../../../Assets/commonImg/edit.svg';
import DeleteIcon from '../../../../Assets/common/delete.png';
import { createEducator, deleteRegisteredEducator, getRegisteredEducatorList } from '../../../../Services/EducatorService';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useForm } from "react-hook-form";
import { getAddress } from '../../../../Services/AddressService';
import downArrow from "../../../../Assets/commonImg/select-dropdown-arrow.svg";
import MembersContainer from '../../../../Components/MembersContainer/MembersContainer';
import { SelectCollegeModal } from '../../../../Components/SelectCollegeModal/SelectCollegeModal';
import SearchByEntityName from '../../../../Components/SearchByEntityName/SearchByEntityName';
import { format } from 'date-fns';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    border: 'none',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '8px',
    padding: '0',
};

export default function RegisteredEducator({ sideNavState, setSideNavState }) {
    useEffect(() => {
        getCountry();
        getRegisteredEducatorListData(0, 10);
    }, [])

    const initialState = {
        firstName: "",
        lastName: "",
        fullName: "",
        birthdate: "",
        gender: "",
        mobile: "",
        primaryEmail: "",
        biometric: "",
        username: "",
        password: "",
        qualification: "",
        country: "",
        stateProvince: "",
        city: "",
        postalCode: "",
        secondaryEmails: [],
        collegeId: ""
    };
    const [formData, setFormData] = useState(initialState);
    const { firstName, lastName, fullName, birthdate, gender, prefix, mobile, primaryEmail, username, country, stateProvince, city, postalCode, qualification, collegeId, secondaryEmails = [''] } = formData;

    const [registeredEducatorData, setRegisteredEducatorData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [count, setTotalRegisteredEducator] = React.useState(0);
    const [countryList, setCountryList] = useState([]);
    const [statesList, setStates] = useState([]);
    const [citiesList, setCities] = useState([]);
    const [isDeleteAllDisabled, setIsDeleteAllDisabled] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [selectedEducatorId, setSelectedEducatorId] = useState('');


    const getRegisteredEducatorListData = async (offset, limit, searchKey = null, searchText = null) => {
        try {
            setLoading(true);
            const registeredEducatorData = await getRegisteredEducatorList(offset, limit, searchKey, searchText);
            console.log(`🚀 ~ getEducatorListData ~ registeredEducatorData:`, registeredEducatorData);
            setLoading(false);
            if (registeredEducatorData && registeredEducatorData?.data) {
                const registeredEducatorListData = registeredEducatorData.data.educators || [];
                const count = registeredEducatorData.data.count || 0;
                setRegisteredEducatorData(registeredEducatorListData);
                setTotalRegisteredEducator(count);
            } else {
                setRegisteredEducatorData([]);
                setTotalRegisteredEducator(0);
            }
        } catch (error) {
            setLoading(false);
            console.log("getEducator List", error);
        }
    };

    const [viewEditOpen, setOpen] = React.useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const handleModalOpen = () => setOpen(true);
    const handleModalClose = () => setOpen(false);

    /**
     * @param {string} educatorId 
     * @param {boolean} showActionResponse showActionResponse present flag
     * to show or hide display of success or error after educator delete.
     */
    const handleDeleteEducator = (educatorId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRegisteredEducator(educatorId).then((res) => {
                    if(res.status === 200) {
                            Swal.fire(
                                'Deleted!',
                                'Waiting Educator deleted Successfully',
                                'success'
                            )
                    } else {
                        Swal.fire(
                            'Oops!',
                            'Something went wrong!',
                            'error')
                        }
                }).catch((error) => {
                    Swal.fire({
                        title: "Error",
						text: error.response.data,
						icon: "error"
                    });
                }).finally(() => {
                    getRegisteredEducatorListData(0, 10);
                })
            }
        });
    };

    const handleHeaderCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setSelectedRows(isChecked ? registeredEducatorData.map(row => row._id) : []);
        setIsDeleteAllDisabled(!isChecked);
    };

    // const handleCheckboxChange = (e, id) => {
    //     console.log('handleCheckboxChange')
    //     const isChecked = e.target.checked;
    //     setSelectedEducatorId(id);
    //     if (isChecked) {
    //         setSelectedRows([...selectedRows, id]);
    //     } else {
    //         setSelectedRows(selectedRows.filter(rowId => rowId !== id));
    //     }
    //     setIsDeleteAllDisabled(!isChecked && selectedRows.length === 0);
    // };

    const handleCheckboxChange = (e, selectedEducator) => {
        const isChecked = e.target.checked;
        setSelectedEducatorId(selectedEducator._id);
        if (isChecked) {
            setSelectedRows([selectedEducator]);
        } else {
            setSelectedRows([]);
        }
        setIsDeleteAllDisabled(!isChecked && selectedRows.length === 0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPage(newPage);
        const { searchKey, searchText } = filter || {};
        getRegisteredEducatorListData(newPage * rowsPerPage, rowsPerPage, searchKey, searchText);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        const { searchKey, searchText } = filter || {};
        getRegisteredEducatorListData(0, parseInt(event.target.value, 10), searchKey, searchText);
    };

    const getCountry = () => {
        getAddress({ addressCategory: 'country' }).finally(() => {
        }).then((res) => {
            if (res?.data && Array.isArray(res?.data)) {
                setCountryList(res?.data);
            } else {
                setCountryList([]);
            }
        }).catch((error) => {
            setCountryList([]);
            console.log("🚀 ~ getAddress ~ error:", error);
        });
    };

    const handleStateInput = (state, country) => {
        return new Promise((resolve, reject) => {
            getAddress({ addressCategory: 'city', countryName: country, stateName: state }).finally(() => {
            }).then((res) => {
                if (res?.data && Array.isArray(res?.data)) {
                    setCities(res?.data);
                } else {
                    setCities([]);
                }
                resolve(true);
            }).catch((error) => {
                setCities([]);
                resolve(false);
                console.log("🚀 ~ getAddress ~ error:", error);
            });
        });
    };

    const handleCountryInput = (event) => {
        return new Promise((resolve, reject) => {
            getAddress({ addressCategory: 'state', countryName: event }).finally(() => {
            }).then((res) => {
                if (res?.data && Array.isArray(res?.data)) {
                    setStates(res?.data)
                } else {
                    setStates([])
                }
                resolve(true);
            }).catch((error) => {
                setStates([]);
                resolve(false);
            })
        })
    }

    // const [isEditMode, setIsEditMode] = useState(false);

    const onFormValueChange = (key, value) => {
        const obj = { [key]: value };
        if (key === 'country') {
            const formValueData = { ...formData, country: value, stateProvince: '', city: '' };
            setFormData(formValueData);
            setStates([]);
            setCities([]);
            handleCountryInput(value);
            return;
        }

        if (key === 'stateProvince') {
            setFormData({ ...formData, stateProvince: value, city: '' });
            setCities([]);
            handleStateInput(value, formData.country);
            return;
        }

        if (key === 'secondaryEmails') {
            setFormData({ ...formData, secondaryEmails: [value] });
            return;
        }
        setFormData((prevState) => {
            const updatedFormValues = { ...prevState, ...obj };
            updatedFormValues.fullName = `${updatedFormValues?.firstName || ''} ${updatedFormValues?.lastName || ''}`.trim();
            return updatedFormValues;
        });
    };

    const handleOpenModal = async (editMode = false, educator = null) => {
        setSelectedEducatorId(educator._id)
        setIsEditMode(editMode);
        handleModalOpen();
        const parsedDate = new Date(educator?.birthdate);
        if (educator?.country) {
            await handleCountryInput(educator.country);
        }
        if (educator?.stateProvince) {
            await handleStateInput(educator.stateProvince, educator.country);
        }
        if (educator) {
            setFormData({
                firstName: educator.firstName,
                lastName: educator.lastName,
                fullName: educator.fullName,
                birthdate: !isNaN(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : '',
                gender: educator.gender,
                prefix: educator?.mobile.length > 10 ? educator?.mobile?.substring(0, educator?.mobile.length - 10) : '',
                mobile: educator?.mobile.length > 10 ? educator?.mobile?.substring(educator?.mobile.length - 10) : educator?.mobile,
                primaryEmail: educator.primaryEmail,
                username: educator.username,
                qualification: educator.qualification,
                country: educator.country,
                stateProvince: educator.stateProvince,
                city: educator.city,
                postalCode: educator.postalCode,
                secondaryEmails: educator.secondaryEmails,
                collegeId: educator.collegeId
            });
        }
    };
    const [filter, setFilter] = React.useState({});
    const [openCollege, setCollegeOpen] = React.useState(false);
    const [collegeDetails, setCollegeDetails] = React.useState({});
    const handleOpen = () => setCollegeOpen(true);
    const handleClose = () => setCollegeOpen(false);


    const handleCollegeDetails = (collegeDetails) => {
        if (typeof collegeDetails === "object") {
            setCollegeDetails(collegeDetails);
            setRowsPerPage(10);
            setPage(0);
            setFilter({
                searchKey: "collegeId",
                searchText: collegeDetails?.collegeId,
            });
            getRegisteredEducatorListData(0, 10, "collegeId", collegeDetails?.collegeId);
        }
    };

    const handleSearchEntityChange = (data) => {
		let offset = 0;
		let limit = 10; 
		setPage(0);
		getRegisteredEducatorListData(offset, limit, data.searchKey, data.searchText)
	}

    const approveRegisteredEducator = (selectedRows = []) => {
        let educatorDetails = selectedRows.length > 0 ? selectedRows[0] : formData;
        const formValues = Object.assign({}, educatorDetails);
		formValues.birthdate = new Date(formValues.birthdate).getTime();
		formValues.mobile = formValues.prefix + formValues.mobile;

		if (!formValues.firstName || formValues.firstName.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "First Name is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		} else if (!formValues.birthdate) {
			Swal.fire({
				title: "Empty",
				text: "Birth date is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else if (!formValues.gender || formValues.gender.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "Gender is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else if (!formValues.mobile || formValues.mobile.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "Mobile Number is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else if (!formValues.primaryEmail || formValues.primaryEmail.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "primary email is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else if (!formValues.username || formValues.username.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "username is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else if (!formValues.collegeId || formValues.collegeId.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "College is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		} else {
            createEducator({ ...formValues })
            .then((res) => {
                if(res.status === 200) {
                    handleModalClose();
                    deleteRegisteredEducator(selectedEducatorId)
                    Swal.fire({
                        title: "Successful",
                        text: "Educator Approved Successful!",
                        icon: "success",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Okay"
                    }).finally(() => {
                        getRegisteredEducatorListData(0, 10);
                    })
                }
            })
            .catch((error) => {
                if (error?.response?.data) {
                    Swal.fire({
                        title: "Error",
                        text: error.response.data,
                        icon: "error",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Okay"
                    }).finally(() => {
                        handleModalClose();
                    })
                } else {
                    Swal.fire({
                        title: "Error",
                        text: "Something went wrong please try again",
                        icon: "error",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Okay"
                    }).finally(() => {
                        handleModalClose();
                    })
                }
            });
        }
    }

    const filters = (
        <div className="pt-3 flex-grow-1">
            <div className="row">
                <div className="col-md-3 col-12">
                    <div className="position-relative">
                        <input className="custom-input" type="text" placeholder="Select College" onClick={handleOpen} readOnly value={collegeDetails?.collegeName} />
                        <div className="down-arrow">
                            {" "}
                            <img src={downArrow} width={"12px"} height={"13px"} alt='^'/>
                        </div>
                    </div>
                </div>
                <SearchByEntityName sendSearchEntityData={handleSearchEntityChange} isRegistredMember={true}></SearchByEntityName>
            </div>
        </div>
    )

    return (
        <div className={'view-registered-educator-container ' + (sideNavState ? 'fullWidthOpenSideNav' : 'halfWidthOpenSideNav')}>
            <NavigateBack />
            <MembersContainer>
                <div className='px-3 d-flex align-items-center flex-wrap'>
                    {filters}
                    {selectedRows?.length > 0 && (
                        <>
                            <button className='approve-btn' onClick={() => approveRegisteredEducator(selectedRows)}>
                                Approve
                            </button>
                            <button className='decline-btn' onClick={() => console.log("Decline clicked for rows:", selectedRows)}>
                                Decline
                            </button>
                        </>
                    )}
                </div>
                <div className='table-container p-3'>
                    <div>
                        {registeredEducatorData.length > 0 ? (
                            <TableContainer component={Paper} sx={{ maxHeight: " 70vh" }}>
                                <Table stickyHeader sx={{ minWidth: 800, borderCollapse: "separate", borderSpacing: "0 10px" }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                <Checkbox onChange={(e) => handleHeaderCheckboxChange(e)} />
                                            </TableCell>
                                            <TableCell className="tableHeaders" align="center">Educator name</TableCell>
                                            <TableCell className="tableHeaders" align="center">Email</TableCell>
                                            <TableCell className="tableHeaders" align="center">Phone Number</TableCell>
                                            <TableCell className="tableHeaders" align="center">College Name</TableCell>
                                            <TableCell className="tableHeaders" align="center">Action</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {registeredEducatorData.map((row, index) => {
                                            return (<TableRow key={row._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover role="checkbox" tabIndex={-1}>

                                                <TableCell align="center">
                                                    <Checkbox onChange={(e) => handleCheckboxChange(e, row)} />

                                                </TableCell>
                                                <TableCell align="center">{row.fullName}</TableCell>
                                                <TableCell align="center">{row.primaryEmail}</TableCell>
                                                <TableCell align="center">{row.mobile}</TableCell>
                                                <TableCell align="center">{row.collegeName}</TableCell>
                                                <TableCell align="center" sx={{ width: 178 }}>
                                                    <div className='d-flex justify-content-center align-items-center gap-10'>
                                                        <div className='cursor-pointer' onClick={() => handleOpenModal(false, row)}><img src={ViewIcon} width={'25px'} height={'12px'} style={{ marginTop: '5px' }} alt='view-icon'/>
                                                        </div>
                                                        <div className='mx-2 cursor-pointer' onClick={() => handleOpenModal(true, row)}><img src={EditIcon} width={'19px'} height={'20px'} style={{ marginTop: '5px' }} alt='edit-icon'/></div>
                                                        <div className='cursor-pointer' onClick={() => handleDeleteEducator(row._id)}><img src={DeleteIcon} width={'22px'} height={'22px'} style={{ marginTop: '5px' }} alt='deleteIcon'/></div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>)
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <p>No data available</p>
                        )}
                    </div>

                    <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Items per page"
                    />
                </div>
            </MembersContainer>
            <Modal open={openCollege} onClose={handleClose} backdrop="static">
                <div>
                    <SelectCollegeModal {...{ handleClose, setCollegeName: handleCollegeDetails }} />
                </div>
            </Modal>
            <div>
                <Modal
                    open={viewEditOpen}
                    onClose={handleModalClose}
                >
                    <Box sx={style}>
                        <div className="d-flex justify-content-between align-items-center viewEducatorHeader">
                            <div className='viewEducatorDetails px-2'>
                                {isEditMode ? 'Edit Educator Details' : 'View Educator Details'}
                            </div>
                            <button onClick={() => handleModalClose()} style={{
                                background: 'none',
                                border: 'none',
                                fontSize: '1.5rem',
                                lineHeight: '1',
                                cursor: 'pointer',
                                color: '#000',
                            }}>&times;</button>
                        </div>
                        <div className='modal-content-wrapper'>
                        <div className='p-3'>
                                <div className='educatorProfileForm row'>
                                    <div className='col-12'>
                                        <div className='educatorProfile mb-2'>Educator Profile</div>
                                        <div className='px-2 row'>
                                            <div className='col-4'>
                                                <p className="input-label">First Name</p>
                                                <input
                                                    className="custom-input"
                                                    type="text"
                                                    name="firstName"
                                                    value={firstName}
                                                    onChange={(event) => {
                                                        const key = event.target.name;
                                                        const value = event.target.value;
                                                        onFormValueChange(key, value);
                                                    }} disabled={!isEditMode}
                                                />
                                            </div>
                                            <div className='col-4'>
                                                <p className="input-label">Last Name</p>
                                                <input
                                                    className="custom-input"
                                                    type="text"
                                                    name="lastName"
                                                    value={lastName}
                                                    onChange={(event) => {
                                                        const key = event.target.name;
                                                        const value = event.target.value;

                                                        onFormValueChange(key, value);
                                                    }} disabled={!isEditMode}
                                                />
                                            </div>
                                            <div className='col-4'>
                                                <p className="input-label">Full Name</p>
                                                <input className="custom-input" disabled={true} type="text" name="fullName" value={fullName} />
                                            </div>
                                        </div>
                                        <div className='px-2 row'>
                                            <div className='col-4'>
                                                <p className="input-label">Date Of Birth</p>
                                                <input
                                                    className="custom-input"
                                                    type="date"
                                                    name="birthdate"
                                                    value={birthdate}
                                                    onChange={(event) => {
                                                        const key = event.target.name;
                                                        const value = event.target.value;

                                                        onFormValueChange(key, value);
                                                    }} disabled={!isEditMode}
                                                />
                                            </div>
                                            <div className='col-4'>
                                                <p className="input-label">Gender</p>
                                                <select className="custom-input" name="gender" value={gender} onChange={(event) => {
                                                    const key = event.target.name;
                                                    const value = event.target.value;
                                                    onFormValueChange(key, value);
                                                }} disabled={!isEditMode}>
                                                    <option value="" disabled>Select Gender</option>
                                                    <option value='Male'>Male</option>
                                                    <option value='Female'>Female</option>
                                                </select>
                                            </div>
                                            <div className='col-4'>
                                                <p className="input-label">Mobile No.</p>
                                                <div className="row">
                                                    <div className="col-3 pe-0">
                                                    <input className="custom-input px-2" name="prefix" value={prefix} defaultValue={'+91'} readOnly></input>
                                                    </div>
                                                    <div className="col-9">
                                                        <input
                                                            className="custom-input"
                                                            type="text"
                                                            name="mobile"
                                                            value={mobile}
                                                            onChange={(event) => {
                                                                const key = event.target.name;
                                                                const value = event.target.value;
                                                                onFormValueChange(key, value);
                                                            }} disabled={!isEditMode}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='px-2 row'>
                                            <div className='col-4'>
                                                <p className="input-label">Primary Email</p>
                                                <input
                                                    className="custom-input"
                                                    type="email"
                                                    name="primaryEmail"
                                                    value={primaryEmail}
                                                    onChange={(event) => {
                                                        const key = event.target.name;
                                                        const value = event.target.value;

                                                        onFormValueChange(key, value);
                                                    }} disabled={!isEditMode}
                                                />
                                            </div>
                                            <div className='col-4'>
                                                <p className="input-label">Secondary Email</p>
                                                <input
                                                    className="custom-input"
                                                    type="email"
                                                    name="secondaryEmails"
                                                    value={secondaryEmails}
                                                    onChange={(event) => {
                                                        const key = event.target.name;
                                                        const value = event.target.value;

                                                        onFormValueChange(key, value);
                                                    }} disabled={!isEditMode}
                                                />
                                            </div>
                                            <div className='col-4'>
                                                <p className="input-label">Username</p>
                                                <input
                                                    className="custom-input"
                                                    type="text"
                                                    name="username"
                                                    value={username}
                                                    onChange={(event) => {
                                                        const key = event.target.name;
                                                        const value = event.target.value;

                                                        onFormValueChange(key, value);
                                                    }} disabled={!isEditMode}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className='px-2 row'>
                                            <div className='col-4'>
                                                <p className="input-label">Username</p>
                                                <input
                                                    className="custom-input"
                                                    type="text"
                                                    name="username"
                                                    value={username}
                                                    onChange={(event) => {
                                                        const key = event.target.name;
                                                        const value = event.target.value;

                                                        onFormValueChange(key, value);
                                                    }} disabled={!isEditMode}
                                                />
                                            </div>
                                            <div className='col-4'>
                                                <p className="input-label">Password</p>
                                                <input
                                                    className="custom-input"
                                                    type="password"
                                                    name="password"
                                                    value={password}
                                                    onChange={(event) => {
                                                        const key = event.target.name;
                                                        const value = event.target.value;

                                                        onFormValueChange(key, value);
                                                    }} disabled={!isEditMode}
                                                />
                                            </div>
                                            <div className='col-4'>
                                            </div>
                                        </div> */}
                                        <div className='educatorProfile my-2'>Locality</div>
                                        <div className='px-2 row'>
                                            <div className='col-3'>
                                                <p className="input-label">Country</p>
                                                <select className="custom-input" value={country} name="country" onChange={(event) => {
                                                    const key = event.target.name;
                                                    const value = event.target.value;
                                                    onFormValueChange(key, value);
                                                }} required disabled={!isEditMode}>
                                                    <option value="">Select country</option>
                                                    {countryList.map((country) => (
                                                        <option key={country?._id} value={country?.name}>
                                                            {country?.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-3'>
                                                <p className="input-label">State</p>
                                                <select className="custom-input" value={stateProvince} name="stateProvince" onChange={(event) => {
                                                    const key = event.target.name;
                                                    const value = event.target.value;
                                                    onFormValueChange(key, value);
                                                }} disabled={!country || !isEditMode || !statesList.length} required >
                                                    <option value="">Select State</option>
                                                    {statesList.map((state) => (
                                                        <option key={state} value={state}>
                                                            {state}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-3'>
                                                <p className="input-label">City</p>
                                                <select className="custom-input" name='city' value={city} onChange={(event) => {
                                                    const key = event.target.name;
                                                    const value = event.target.value;
                                                    onFormValueChange(key, value);
                                                }} disabled={!stateProvince || !isEditMode || !citiesList.length} required>
                                                    <option value="">Select City</option>
                                                    {citiesList.map((city) => (
                                                        <option key={city} value={city}>
                                                            {city}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-3'>
                                                <p className="input-label">Postal Code</p>
                                                <input className="custom-input" type='number' name="postalCode" value={postalCode} onChange={(event) => {
                                                    const key = event.target.name;
                                                    const value = event.target.value;
                                                    onFormValueChange(key, value);
                                                }} required disabled={!isEditMode}></input>
                                            </div>
                                        </div>
                                        <div className='educatorProfile my-2'>Academics</div>
                                        <div className='px-2 row'>
                                            <div className='col-4'>
                                                <p className="input-label">Qualification</p>
                                                <input
                                                    className="custom-input"
                                                    type="text"
                                                    name="qualification"
                                                    value={qualification}
                                                    onChange={(event) => {
                                                        const key = event.target.name;
                                                        const value = event.target.value;

                                                        onFormValueChange(key, value);
                                                    }} disabled={!isEditMode}
                                                />
                                            </div>
                                            <div className='col-4'>
                                            </div>
                                            <div className='col-4'>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isEditMode && (
                                    <div className="form-submit-card mb-2 mt-3 d-flex justify-content-end">
                                        <button className="approve-btn" onClick={() => approveRegisteredEducator()}>
                                            Approve
                                        </button>
                                        <button className="decline-btn" type="button" onClick={handleModalClose}>
                                            Close
                                        </button>
                                    </div>
                                )}
                        </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    );

}
