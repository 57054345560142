import React, { useEffect, useState } from "react";
import { deleteByCollegeId } from "../../../../Services/CollegeService";
import "./ViewCollege.scss";
import searchIcon from "../../../../Assets/common/search_icon.svg";
import searchCloseIcon from "../../../../Assets/common/search_close_icon.png";
import { Checkbox, IconButton, TablePagination } from "@mui/material";
import { getcollegeList } from "../../../../Services/memberService";
import viewCollegeIcon from "./../../../../Assets/memberImg/view.svg";
import editCollegeIcon from "./../../../../Assets/memberImg/edit.svg";
import deleteCollegeIcon from "./../../../../Assets/memberImg/delete.png";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import contactIcon from "../../../../Assets/common/phoneIcon.png";
import NavigateBack from "../../../CommonScreens/NavigateBack/NavigateBack";
import noDataFoundImg from "../../../../Assets/memberImg/noDataFound.svg";
import MembersContainer from "../../../../Components/MembersContainer/MembersContainer";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function ViewCollege({ sideNavState, setSideNavState }) {
   
    const navigate = useNavigate();

    const [collegeData, setCollegeData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [count, setTotalCollege] = React.useState(0);
    const [isLoading, setLoading] = React.useState(false);
    const [searchKey, setSearchKey] = React.useState("displayName");

    useEffect(() => {
        getMembreCollegeList(0, 10, searchText);
    }, []);

    const getMembreCollegeList = async (offset, limit, searchTextValue = null) => {
        try {
            setLoading(true);
            const collegeData = await getcollegeList(offset, limit, searchTextValue, searchKey);
            setLoading(false);
            const collegeListData = collegeData.data.collegeList;
            const count = collegeData.data.totalRecords;
            setCollegeData(collegeListData);
            setTotalCollege(count);
        } catch (error) {
            setLoading(false);
            console.log("🚀 ~ getMembreCollegeList ~ collegeList", error);
        }
    };

    const handleSearchInput = (event) => {
        setSearchText(event?.target?.value);
    };

    const handleSearchClick = () => {
        setRowsPerPage(10);
        setPage(0);
        getMembreCollegeList(0, 10, searchText);
    };

    const handleSearchClear = () => {
        setSearchText("");
        setRowsPerPage(10);
        setPage(0);
        getMembreCollegeList(0, 10, "");
    };

    const handleActionToNavigate = ({ id, type }) => {
        navigate("/home/college/" + id, { state: { type }, replace: true });
    };

    const handleDelete = (id) => {
        console.log("🚀 ~ handleDelete ~ id:", id);
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteByCollegeId(id)
                    .then((res) => {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success",
                        });
                        getMembreCollegeList(0, 10, searchText);
                    })
                    .catch((error) => {
                        console.log("🚀 ~ deleteByCollegeId ~ error:", error);
                    })
                    .finally(() => { });
            }
        });
    };

    const handleHeaderCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setSelectedRows(isChecked ? collegeData.map((row) => row.id) : []);
    };
    const handleCheckboxChange = (e, id) => {
        if (e.target.checked) {
            setSelectedRows([...selectedRows, id]);
        } else {
            setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getMembreCollegeList(newPage * rowsPerPage, rowsPerPage, searchText);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, event));
        setPage(0);
        getMembreCollegeList(0, parseInt(event.target.value, 10), searchText);
    };

    return (
        <div className={"view-college-container " + (sideNavState ? "fullWidthOpenSideNav" : "halfWidthOpenSideNav")}>
            <NavigateBack />
            <MembersContainer>
                <div className="d-flex row-gap-19px flex-column">
                    <div className="d-flex flex-wrap justify-content-between ">
                        <div className="d-flex flex-wrap">
                            <div className="d-flex">
                                <label className="label-input">
                                    <input type="text" className="form-control search-input" placeholder="Search by name" onChange={handleSearchInput} value={searchText} />
                                    {searchText && (
                                        <button className="search-btn-close" onClick={handleSearchClear}>
                                            <img src={searchCloseIcon} alt="" width={"13px"} />
                                        </button>
                                    )}
                                    <button className="search-btn" onClick={handleSearchClick}>
                                        <img src={searchIcon} alt="" />
                                        <span>Search</span>
                                    </button>
                                </label>
                            </div>
                        </div>
                        <div className="d-flex p-2">
                            {selectedRows?.length > 0 && (
                                <>
                                    <Button variant="contained" sx={{ background: "#5284EE" }} color="primary">
                                        Delete All
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                    {collegeData?.length > 0 ? (
                        <div className="table-container p-0">
                            <TableContainer component={Paper} sx={{ maxHeight: " 70vh" }}>
                                <Table stickyHeader sx={{ minWidth: 800, borderCollapse: "separate", borderSpacing: "0 10px" }} aria-label="simple table">
                                    <TableHead> 
                                        <TableRow>
                                            <TableCell className="tableHeaders" >College</TableCell>
                                            <TableCell className="tableHeaders"  align="center">State</TableCell>
                                            <TableCell className="tableHeaders"  align="center">District</TableCell>
                                            <TableCell className="tableHeaders"  align="center">City</TableCell>
                                            <TableCell className="tableHeaders"  align="center">Contact</TableCell>
                                            <TableCell className="tableHeaders"  align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {collegeData.map((row) => {
                                            let phone;
                                            if (row?.contactPhone) {
                                                phone = <> <img src={contactIcon} alt="" width={"15px"} /> {row?.contactPhone}   </>
                                            }

                                            return (<TableRow key={row._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover role="checkbox" tabIndex={-1}>
                                                <TableCell>{row.displayName}</TableCell>
                                                <TableCell align="center">{row?.stateProvince}</TableCell>
                                                <TableCell align="center">{row?.district}</TableCell>
                                                <TableCell align="center">{row?.city}</TableCell>
                                                <TableCell align="center">
                                                    {phone}
                                                </TableCell>
                                                <TableCell align="center" sx={{ width: 178 }}>
                                                    <IconButton aria-label="view" onClick={() => handleActionToNavigate({ id: row._id, type: "view" })}>
                                                        <img src={viewCollegeIcon} alt="" width={"25px"} />
                                                    </IconButton>
                                                    <IconButton aria-label="view" onClick={() => handleActionToNavigate({ id: row._id, type: "edit" })}>
                                                        <img src={editCollegeIcon} alt="" width={"19px"} />
                                                    </IconButton>
                                                    <IconButton aria-label="view" onClick={() => handleDelete(row._id)}>
                                                        <img src={deleteCollegeIcon} alt="" width={"22px"} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>)
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination component="div" count={count} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} disabled={isLoading} />
                        </div>
                    ) : (
                        <div className="noDataFoundSection mt-5">
                            <div>
                                <img src={noDataFoundImg} alt="" />
                            </div>
                            <div className="noDataFound mt-5">No College(s) Found</div>
                        </div>
                    )}
                </div>
            </MembersContainer>
        </div>
    );
}
