import './App.scss';
import { BrowserRouter } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import awsConfig from './Config/amplifyConfig'
import Router from './Config/Router';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

Amplify.configure(awsConfig);

function App() {
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </LocalizationProvider>
    </div>
  );
}

export default App;
