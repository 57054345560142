import { readService } from './HttpService'
import { url } from '../Config/UrlRoute'

const getGroupList = ({
    collegeId,
    offset,
    limit,
    searchKey,
    searchText
}) => {

    const params = new URLSearchParams();
    if (collegeId) {
        params.append('collegeId', collegeId);
    }
    if (offset || offset == 0) {
        params.append('offset', offset);
    }
    if (limit) {
        params.append('limit', limit);
    }
    if (searchKey && searchText) {
        params.append('searchKey', searchKey);
        params.append('searchText', searchText);
    }

    const endPoint = url.university.groups.groups;
    return readService(endPoint, params);
}
export { getGroupList };