import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { getStudentList } from "../../Services/StudentService";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

const CommonStudentTable = (args) => {
    const { collegeId, searchKey, searchText, onSelect } = args;
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 800,
        border: "none",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "8px",
        padding: "10px",
    };

    useEffect(() => {
        getStudentsListData(0, 10, collegeId);
    }, []);

    useEffect(() => {
        if (onSelect && searchText || searchText === '') {
            setPage(0);
            setRowsPerPage(10);
            getStudentsListData(0, 10, collegeId);
        }
    }, [searchText, onSelect]);

    const [studentData, setStudentData] = useState([]);
    const [count, setTotalStudents] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const getStudentsListData = async (offset, limit, collegeId) => {
        try {
            const studentData = await getStudentList(offset, limit, searchKey, searchText, collegeId);
            if (studentData && studentData?.data) {
                const studentListData = studentData.data.students.map((student) => ({
                    ...student,
                }));
                const count = studentData.data.count || 0;
                setStudentData(studentListData);
                setTotalStudents(count);
            } else {
                setStudentData([]);
                setTotalStudents(0);
            }
        } catch (error) {
            console.log("getStudent List", error);
        }
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getStudentsListData(newPage * rowsPerPage, rowsPerPage, collegeId);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getStudentsListData(0, parseInt(event.target.value, 10, collegeId));
    };

    const [selectedValue, setSelectedValue] = useState(null); // State to manage selected radio button value

    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value); // Update the selected value based on radio button change
        const selected = studentData.find(x => x._id === event.target.value);
        if (onSelect && typeof onSelect === 'function') {
            onSelect(selected);
        }
    };

    return (
        <>
            <div sx={style}>
                <div className="p-3">
                    <div>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow sx={{
                                        backgroundColor: "#E7EEFE",
                                        border: "none",
                                        font: "normal normal medium 15px/20px Roboto",
                                        color: "#FFFFFF !important",
                                    }}>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="start">Name</TableCell>
                                        <TableCell align="start">User Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {studentData.map((row, id) => (
                                        <TableRow key={row._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover role="checkbox" tabIndex={-1}>

                                            <TableCell align="center">
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            checked={selectedValue === row._id}
                                                            onChange={handleRadioChange}
                                                            value={row._id}
                                                        />
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell align="start">{row.fullName}</TableCell>
                                            <TableCell align="start">{row.username}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination component="div" count={count} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CommonStudentTable;