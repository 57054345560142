import { createService, readService } from './HttpService';
import { url } from '../Config/UrlRoute';

const getReport = async (offset, limit, collegeId) => {
    try {
        if (collegeId) {
            const endPoint = url.university.report.report;
            const params = {
                collegeId,
                offset: offset,
                limit: limit
            };
            return await readService(endPoint, params);
        } else {
            throw ('Please pass the college id');
        }
    } catch (error) {
        throw (error);
    }

}

const requestReport = async ({
    collegeId,
    startTs,
    endTs,
    userId,
    userType,
    reportType,
    activity,
    entity,
    attendanceType,
    courseId,
    subjectId
}) => {
    try {
        console.log(`🚀 ~ file: ReportService.js:32 ~ activity:`, JSON.stringify(activity))

        const endPoint = url.university.report.report;
        const params = new URLSearchParams();
        if (collegeId) {
            params.append('collegeId', collegeId)
        }
        if (startTs) {
            params.append('startTs', startTs)
        }
        if (endTs) {
            params.append('endTs', endTs)
        }
        if (userId) {
            params.append('userId', userId)
        }
        if (userType) {
            params.append('userType', userType)
        }
        if (reportType) {
            params.append('reportType', reportType)
        }
        if (activity) {
            params.append('activity', JSON.stringify(activity))
        }
        if (entity) {
            params.append('entity', entity)
        }
        if (attendanceType) {
            params.append('attendanceType', attendanceType)
        }
        if (courseId) {
            params.append('courseId', courseId)
        }
        if (subjectId) {
            params.append('subjectId', subjectId)
        }
        return await createService(endPoint, {}, params);

    } catch (error) {
        throw (error);
    }

}

export {
    getReport,
    requestReport
};