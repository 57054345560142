import React from 'react';
import './Member.scss';
import {useNavigate} from 'react-router-dom';
import CollegeIcon from '../../../Assets/memberImg/college-Icon.svg';
import CollegeAdminIcon from '../../../Assets/memberImg/admin-icon.svg';
import EducatorIcon from '../../../Assets/memberImg/educator-icon.svg';
import StudentIcon from '../../../Assets/memberImg/student-icon.svg';
import AddCollegeIcon from '../../../Assets/memberImg/plus-add-svgrepo-com.svg';
import ViewIcon from '../../../Assets/memberImg/view-icon.svg';
import ViewSubjectIcon from '../../../Assets/memberImg/view-subject.svg';
import RegisteredIcon from '../../../Assets/memberImg/registered.svg';
import BulkStudentIcon from '../../../Assets/memberImg/promotion.svg';
import StudentCsvIcon from '../../../Assets/memberImg/csv.svg';

export default function Member({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
	const onAddCollege = () => {
        navigate('/home/college', {replace: true});
	}
    const onViewCollege = () => {
        navigate('/home/viewcollege', {replace: true});
	}
    const onAddCollegeAdmin = () => {
        navigate('/home/collegeAdmin', {replace: true});
	}
    const onViewCollegeAdmin = () => {
        navigate('/home/viewCollegeAdmin', {replace: true});
	}
    // const onregisteredEducator = () => {
    //     navigate('/home/college', {replace: true});
	// }
    // const onViewSubject = () => {
    //     navigate('/home/college', {replace: true});
	// }
    const onAddEducator= () => {
        navigate('/home/addEducator', {replace: true});
	}
    const onViewEducator= () => {
        navigate('/home/viewEducator', {replace: true});
	}
    const onRegisteredEducator= () => {
        navigate('/home/registeredEducator', {replace: true});
	}
    const onViewSubject= () => {
        navigate('/home/viewSubject', {replace: true});
	}
    const onAddStudent = () => {
        navigate('/home/student', {replace: true});
	}
    const onViewStudent = () => {
        navigate('/home/viewStudent', {replace: true});
	}
    const onRegisteredStudent = () => {
        navigate('/home/registeredStudent', {replace: true});
	}
    const onBulkStudPromo = () => {
        navigate('/home/bulkStudentPromotion', {replace: true});
	}
    const onCreateCsv = () => {
        navigate('/home/CreatingStudentUsingCSV', {replace: true});
	}

    return (
        <div className={'dashboard-container ' + (sideNavState ? 'fullWidthOpenSideNav' : 'halfWidthOpenSideNav')}>
        <div className='member-container'>
            <div className='d-flex flex-column align-items-center member-content  '>
                <div className='member-sub-content'>
                    <div className='d-flex justify-content-center align-items-center content-title my-2'>College<div className='mx-2'><img src={CollegeIcon} width={'21px'} height={'21px'} /></div></div>
                    <div className='d-flex justify-content-center  box-content'>
                        <div className='d-flex flex-column justify-content-center align-items-center box'  onClick={onAddCollege}>
                            <div className='my-3'><img src={AddCollegeIcon} width={'24px'} height={'24px'} /></div>
                            <div className='d-flex justify-content-center content-sub-title'>Add College</div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center box' onClick={onViewCollege}>
                            <div className='my-3'><img src={ViewIcon} width={'39px'} height={'22px'} /></div>
                            <div className='d-flex justify-content-center content-sub-title'>View College</div>
                        </div>
                    </div>
                </div>
                <div className='member-sub-content'>
                    <div className='d-flex justify-content-center align-items-center content-title my-2'>College Admin<div className='mx-2'><img src={CollegeAdminIcon} width={'29px'} height={'28px'} /></div></div>
                    <div className='d-flex justify-content-center box-content'>
                        <div className='d-flex flex-column justify-content-center align-items-center box' onClick={onAddCollegeAdmin}>
                            <div className='my-3'><img src={AddCollegeIcon} width={'24px'} height={'24px'} /></div>
                            <div className='d-flex justify-content-center content-sub-title'>Add College Admin</div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center box' onClick={onViewCollegeAdmin}>
                            <div className='my-3'><img src={ViewIcon} width={'39px'} height={'22px'} /></div>
                            <div className='d-flex justify-content-center content-sub-title'>View College Admin</div>
                        </div>
                    </div>
                </div>
                <div className='member-sub-content'>
                    <div className='d-flex justify-content-center align-items-center content-title my-2'>Educator<div className='mx-2'><img src={EducatorIcon} width={'20px'} height={'22px'} /></div></div>
                    <div className='d-flex justify-content-center  box-content'>
                        <div className='d-flex flex-column justify-content-center align-items-center box' onClick={onAddEducator}>
                            <div className='my-3'><img src={AddCollegeIcon} width={'24px'} height={'24px'} /></div>
                            <div className='d-flex justify-content-center content-sub-title'>Add Educator</div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center box' onClick={onViewEducator}>
                            <div className='my-3'><img src={ViewIcon} width={'39px'} height={'22px'} /></div>
                            <div className='d-flex justify-content-center content-sub-title'>View Educator</div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center box' onClick={onRegisteredEducator}>
                            <div className='my-3'><img src={RegisteredIcon} width={'44px'} height={'25px'} /></div>
                            <div className='d-flex justify-content-center content-sub-title'>Registered Educator</div>
                        </div>
                        {/* <div className='d-flex flex-column justify-content-center align-items-center box' onClick={onViewSubject}>
                            <div className='my-3'><img src={ViewSubjectIcon} width={'21px'} height={'28px'} /></div>
                            <div className='d-flex justify-content-center content-sub-title'>View Subject</div>
                        </div> */}
                    </div>
                </div>
                <div className='member-sub-content'>
                    <div className='d-flex justify-content-center align-items-center content-title my-2'>Student<div className='mx-2'><img src={StudentIcon} width={'21px'} height={'21px'} /></div></div>
                    <div className='d-flex justify-content-center  box-content'>
                        <div className='d-flex flex-column justify-content-center align-items-center box' onClick={onAddStudent}>
                            <div className='my-3'><img src={AddCollegeIcon} width={'24px'} height={'24px'} /></div>
                            <div className='content-sub-title'>Add Student</div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center box' onClick={onViewStudent}>
                            <div className='my-3'><img src={ViewIcon} width={'39px'} height={'22px'} /></div>
                            <div className='content-sub-title'>View Student</div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center box' onClick={onRegisteredStudent}>
                            <div className='my-3'><img src={RegisteredIcon} width={'44px'} height={'25px'} /></div>
                            <div className='content-sub-title'>Registered Student</div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center box' onClick={onBulkStudPromo}>
                            <div className='my-3'><img src={BulkStudentIcon} width={'26px'} height={'28px'} /></div>
                            <div className='content-sub-title'>Bulk Student Promotion</div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center box' onClick={onCreateCsv}>
                            <div className='my-3'><img src={StudentCsvIcon} width={'22px'} height={'27px'} /></div>
                            <div className='content-sub-title'>Creating Student using CSV</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
 

        </div>
    )
}

