import React from 'react';
import { Chart } from "react-google-charts";

export const data = [
  ["Task", "Hours per Day"],
  ["Total Student 95%", 25],
  ["Merit Students 25%", 25],
  ["Passout Students 70%", 25],
  ["Fail Students 10%", 25],
];

export const options = {
  pieHole: 0.4,
  is3D: false,
  display:true,
  position:'right',
  width: "100%",
  slices: {0: {color: '#EDDE83'}, 1:{color: '#DB5FCD'}, 2:{color: '#8EEA82'}, 3: {color: '#1477D1'}}
};


  const StudentPassOutChart = () => {
    return (
      <Chart
        chartType="PieChart"
        width="100%"
        height="150px"
        data={data}
        options={options}
      />
    );
}


export default StudentPassOutChart