import { createService, updateService, readService, deleteService } from './HttpService'
import { url } from '../Config/UrlRoute'
import { getDecryptData, decodeJWTToken } from './SessionStoreService'


const createCollege = (payload) => {
    const endPoint = url.university.college.createCollege;
    const params = { type: 'create' }
    return createService(endPoint, payload, params);
}

const updateCollege = (payload) => {
    const endPoint = url.university.college.createCollege;
    return updateService(endPoint, {}, payload);
}

const getByCollegeId = (id) => {
    const endPoint = url.university.college.createCollege;
    const params = { collegeId: id }
    return readService(endPoint, params);
}

const deleteByCollegeId = (id) => {
    const endPoint = url.university.college.createCollege + `/${id}`;
    return deleteService(endPoint);
}

const getPaginatedCollegeList = async ({
    limit = 10,
    offset = 0,
    universityId = null
}) => {
    if (!universityId) {
        try {
            const userData = JSON.parse(getDecryptData('uadminProfile'));
            console.log(`🚀 ~ file: CollegeService.js:36 ~ userData:`, userData)
            if (typeof userData === 'object') {
                // eslint-disable-next-line no-unused-expressions
                if (userData?.universityId) {
                    universityId = userData.universityId;
                } else {
                    throw ({ error: 'please send universityId' });
                }
            }

        } catch (error) {
            throw (error);
        }
    }
    const params = {
        limit,
        offset,
        universityId
    }
    const endPoint = url.university.college.createCollege;
    return readService(endPoint, params);
}


const createCollegeAdmin = (payload) => {
    const endPoint = url.university.collegeAdmin.createCollegeAdmin;
    return createService(endPoint, payload);
}
const updateCollegeAdmin = (payload, id) => {
    const params = { 'clgAdminId': id }
    const endPoint = url.university.collegeAdmin.createCollegeAdmin;
    return updateService(endPoint, {}, { ...payload, clgAdminId: id });
}

const getCollegeAdmin = (offset, limit, searchKey = null, searchText = null, collegeId) => {
    const userData = JSON.parse(getDecryptData('uadminProfile'));
    if (userData) {
        const endPoint = url.university.collegeAdmin.getCollegeAdmin;
        const params = {
            universityId: userData.universityId,
            searchKey,
            searchText,
            offset: offset,
            limit: limit
        };
        if (!searchKey || !searchText) {
            delete params.searchKey;
            delete params.searchText;
        }
        if(collegeId) params.collegeId = collegeId;
        return readService(endPoint, params);
    }
}
const deleteCollegeAdmin = (id) => {
    const endPoint = url.university.collegeAdmin.createCollegeAdmin + `/${id}`;
    const requestBody = { clgAdminId: id };
    return deleteService(endPoint, {}, requestBody);
}

const getByCollegeAdminId = (id) => {
    const endPoint = url.university.collegeAdmin.getCollegeAdmin;
    const params = { clgAdminId: id }
    return readService(endPoint, params);
}
export {
    createCollege,
    updateCollege,
    getByCollegeId,
    deleteByCollegeId,
    getPaginatedCollegeList,
    createCollegeAdmin,
    getCollegeAdmin,
    deleteCollegeAdmin,
    updateCollegeAdmin,
    getByCollegeAdminId
}