import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './ViewEditStudentProfile.scss';
import Swal from 'sweetalert2';
import BackIcon from '../../../Assets/commonImg/back.svg';
import downArrow from '../../../Assets/commonImg/select-dropdown-arrow.svg';
import SearchIcon from '../../../Assets/commonImg/searchIcon.svg';
import UserIcon from '../../../Assets/commonImg/user.png';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useForm } from "react-hook-form";
import TablePagination from '@mui/material/TablePagination';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    // bgcolor: 'background.paper',
    border: 'none',
    // boxShadow: 24,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
boxShadow: '0px 3px 6px #00000029',
borderRadius: '8px',

    p: 4,
};

const selectCollege = [
    {collegeName: 'College Name Will Be Here',},
    {collegeName: 'College Name Will Be Here',},
    {collegeName: 'College Name Will Be Here',},
    {collegeName: 'College Name Will Be Here',},
    {collegeName: 'College Name Will Be Here',},
    {collegeName: 'College Name Will Be Here',}
];

const selectCourse = [
    {courseName: 'Course Name Will Be Here',},
    {courseName: 'Course Name Will Be Here',},
    {courseName: 'Course Name Will Be Here',},
    {courseName: 'Course Name Will Be Here',},
    {courseName: 'Course Name Will Be Here',},
    {courseName: 'Course Name Will Be Here',}
];
export default function ViewEditStudentProfile({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/home/members', { replace: true });
    }

    const [openCollege, setCollegeOpen] = React.useState(false);
    const handleOpen = () => setCollegeOpen(true);
    const handleClose = () => setCollegeOpen(false);
    const [openCourse, setCourseOpen] = React.useState(false);
    const courseHandleOpen = () => setCourseOpen(true);
    const courseHandleClose = () => setCourseOpen(false);

    const [selectedFile, setSelectedFile] = useState(null);
    const handleFileInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onSaveClick = () => {
        Swal.fire({
            title: 'successfully!',
            text: 'College student added successfully!',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: '  Ok  ',
            confirmButtonColor: '#32496B',
            confirmButtonWidth: '50px',
            reverseButtons: true
        })
    }

    return (
        <div className='view-student-container'
            style={{
                width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
                marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
                transition: "0.5s",
                position: "absolute",
                top: "4rem",
                padding: "1%",
            }}>
            <div className='backBtn' onClick={goBack}><img src={BackIcon} width={'12px'} height={'13px'} marginTop={'5px'} /><div className='backText'>Back</div></div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='view-student-form-card'>
                    <div className='view-student-form-title'>
                        <p>View Student</p>
                    </div>
                    <div className='row mx-0'>
                        <div className='col-lg-2 col-md-2 col-12 userImg'>
                            <img src={UserIcon} width={'133px'} height={'133px'} />
                        </div>
                        <div className='col-lg-10 col-md-10 col-12 p-0'>
                            <div className='row mx-0'>
                                <div className='col-lg-4 col-md-6 col-12'>
                                    <p className="input-label" >First Name</p>
                                    <input className="custom-input" type='text' {...register("firstName", { required: true, maxLength: 50 })} />
                                    {errors.firstName && errors.firstName.type === "required" && <span className='validationError'>This field is required</span>}
                                </div>
                                <div className='col-lg-4 col-md-6 col-12'>
                                    <p className="input-label" >Middle Name</p>
                                    <input className="custom-input" type='text' />
                                </div>
                                <div className='col-lg-4 col-md-6 col-12'>
                                    <p className="input-label" >Last Name</p>
                                    <input className="custom-input" type='text' />
                                </div>
                            </div>
                            <div className='row mx-0'>
                                <div className='col-lg-4 col-md-6 col-12'>
                                    <p className="input-label" >Full Name</p>
                                    <input className="custom-input" type='text' {...register("fullname", { required: true, maxLength: 50 })} />
                                    {errors.fullname && errors.fullname.type === "required" && <span className='validationError'>This field is required</span>}
                                </div>
                                <div className='col-lg-4 col-md-6 col-12'>
                                    <p className="input-label" >Date of Birth</p>
                                    <input className="custom-input" type='date' {...register("dob", { required: true, maxLength: 50 })} />
                                    {errors.dob && errors.dob.type === "required" && <span className='validationError'>This field is required</span>}
                                </div>
                                <div className='col-lg-4 col-md-6 col-12'>
                                    <p className="input-label" >Gender</p>
                                    <div className='position-relative'>
                                        <select select className="custom-input"  {...register("gender", { required: true, maxLength: 50 })}>
                                            <option></option>
                                            <option>Male</option>
                                            <option>Female</option>
                                        </select>
                                        {errors.gender && errors.gender.type === "required" && <span className='validationError'>This field is required</span>}
                                        <div className='down-arrow'> <img src={downArrow} width={'12px'} height={'13px'} marginTop={'5px'} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mx-0'>
                        <div className='col-md-4 col-12'>
                            <p className="input-label" >Mobile No.</p>
                            <div className='d-flex'>
                                <div style={{ width: '15%', marginRight: '8px' }}>
                                    <select className="custom-input" >
                                        <option className="custom-input">+91</option>
                                    </select>
                                </div>
                                <div style={{ width: '82%' }}>
                                    <input className="custom-input" type='text' {...register("mobileNo", { required: true, maxLength: 50 })} />
                                    {errors.mobileNo && errors.mobileNo.type === "required" && <span className='validationError'>This field is required</span>}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 col-12'>
                            <p className="input-label" >Primary Email Id</p>
                            <input className="custom-input" type='text' {...register("primaryEmail", { required: true, maxLength: 50 })} />
                            {errors.primaryEmail && errors.primaryEmail.type === "required" && <span className='validationError'>This field is required</span>}
                        </div>
                        <div className='col-md-4 col-12'>
                            <p className="input-label" >Secondary Email Id</p>
                            <input className="custom-input" type='text' />
                        </div>
                    </div>
                    <div className='row mx-0'>
                        <div className='col-md-4 col-12'>
                            <p className="input-label" >Aadhar Card No.</p>
                            <input className="custom-input" type='text' {...register("adharNo", { required: true, maxLength: 50 })} />
                            {errors.adharNo && errors.adharNo.type === "required" && <span className='validationError'>This field is required</span>}
                        </div>
                        <div className='col-md-4 col-12'>
                            <p className="input-label" >Username</p>
                            <input className="custom-input" type='text' {...register("username", { required: true, maxLength: 50 })} />
                            {errors.username && errors.username.type === "required" && <span className='validationError'>This field is required</span>}
                        </div>
                        <div className='col-md-4 col-12'>
                            <p className="input-label" >Password</p>
                            <input className="custom-input" type='text' {...register("password", { required: true, maxLength: 50 })} />
                            {errors.password && errors.password.type === "required" && <span className='validationError'>This field is required</span>}
                        </div>
                    </div>
                    <div className='row mx-0'>
                        <div className='col-md-4 col-12'>
                            <p className="input-label" >University Serial No</p>
                            <input className="custom-input" type='text' {...register("universityNo", { required: true, maxLength: 50 })} />
                            {errors.universityNo && errors.universityNo.type === "required" && <span className='validationError'>This field is required</span>}
                        </div>
                        <div className='col-md-4 col-12'>
                        </div>
                        <div className='col-md-4 col-12'>
                        </div>
                    </div>
                    <div className='row mx-0'>
                        <div className='subHeading'>Parent Information</div>
                        <div className='col-md-4 col-12'>
                            <p className="input-label" >Father/Guardian Name</p>
                            <input className="custom-input" type='text' {...register("guardianName", { required: true, maxLength: 50 })} />
                            {errors.guardianName && errors.guardianName.type === "required" && <span className='validationError'>This field is required</span>}
                        </div>
                        <div className='col-md-4 col-12'>
                            <p className="input-label" >Father/Guardian Mobile No.</p>
                            <input className="custom-input" type='text' {...register("guardianNo", { required: true, maxLength: 50 })} />
                            {errors.guardianNo && errors.guardianNo.type === "required" && <span className='validationError'>This field is required</span>}
                        </div>
                        <div className='col-md-4 col-12'>
                            <p className="input-label" >Father/Guardian Mail ID</p>
                            <input className="custom-input" type='text' {...register("guardianMail", { required: true, maxLength: 50 })} />
                            {errors.guardianMail && errors.guardianMail.type === "required" && <span className='validationError'>This field is required</span>}
                        </div>
                    </div>
                    <div className='row mx-0'>
                        <div className='subHeading'>Parent Information</div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <p className="input-label" >Country</p>
                            <div className='position-relative'>
                                <select className="custom-input">
                                    <option></option>
                                </select>
                                <div className='down-arrow'> <img src={downArrow} width={'12px'} height={'13px'} marginTop={'5px'} /></div>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <p className="input-label" >State</p>
                            <div className='position-relative'>
                                <select className="custom-input">
                                    <option></option>
                                </select>
                                <div className='down-arrow'> <img src={downArrow} width={'12px'} height={'13px'} marginTop={'5px'} /></div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <p className="input-label" >City</p>
                            <div className='position-relative'>
                                <select className="custom-input">
                                    <option></option>
                                </select>
                                <div className='down-arrow'> <img src={downArrow} width={'12px'} height={'13px'} marginTop={'5px'} /></div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <p className="input-label" >Postal Code</p>
                            <input className="custom-input" type='text'></input>
                        </div>
                    </div>
                    <div className='row mx-0'>
                        <div className='subHeading'>Locality</div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <p className="input-label" >College Name</p>
                            <div className='position-relative'>
                            <input className="custom-input" type='text' onClick={handleOpen} {...register("collegeName", { required: true, maxLength: 50 })} readOnly/>
                            {errors.collegeName && errors.collegeName.type === "required" && <span className='validationError'>This field is required</span>}
                                <div className='down-arrow'> <img src={downArrow} width={'12px'} height={'13px'} marginTop={'5px'} /></div>
                            </div>
                            
                            <Modal
                                open={openCollege}
                                onClose={handleClose}
                            >
                                <Box sx={style}>
                                    <div className="mb-3">
                                        <div className="d-flex justify-content-center">
                                            <div className="position-relative">
                                                <input type="text" placeholder="Search by college name" style={{
                                                     height: '36px',
                                                     background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                     boxShadow: '0px 3px 6px #00000029',
                                                     borderRadius: '5px',
                                                     border: 'none',
                                                     padding: '0 25px',
                                                }} />
                                                <button style={{
                                                    height: '36px',
                                                    position: 'absolute',
                                                    right: '0',
                                                    background: '#2580EC 0% 0% no-repeat padding-box',
                                                    boxShadow: '0px 3px 6px #00000029',
                                                    border: '1px solid #FFFFFF',
                                                    borderRadius: '5px',
                                                    color:'#FFFFFF'
                                                }}><img src={SearchIcon} width={'18px'} height={'18px'} marginRight={'10px'} />Search</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{
                                                            backgroundColor: '#76A5DE',
                                                            border: '1px solid #707070',
                                                            font: 'normal normal medium 15px/20px Roboto',
                                                            color: '#FFFFFF !important'
                                                        }}>College Name</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {selectCollege.map((row) => (
                                                        <TableRow
                                                            key={row.collegeName}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row"
                                                            sx={{ border: 'none', px:'17px', py:'9px'}}>
                                                                {row.collegeName}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            component="div"
                                            count={selectCollege.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </div>
                                </Box>
                            </Modal>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <p className="input-label" >Course</p>
                            <div className='position-relative'>
                            <input className="custom-input" type='text' onClick={courseHandleOpen} {...register("course", { required: true, maxLength: 50 })} readOnly/>
                            {errors.course && errors.course.type === "required" && <span className='validationError'>This field is required</span>}
                                <div className='down-arrow'> <img src={downArrow} width={'12px'} height={'13px'} marginTop={'5px'} /></div>
                            </div>
                            <Modal
                                open={openCourse}
                                onClose={courseHandleClose}
                            >
                                <Box sx={style}>
                                    <div className="mb-3">
                                        <div className="d-flex justify-content-center">
                                        <div className="position-relative">
                                                <input type="text" placeholder="Search by Course name" style={{
                                                     height: '36px',
                                                     background: '#FFFFFF 0% 0% no-repeat padding-box',
                                                     boxShadow: '0px 3px 6px #00000029',
                                                     borderRadius: '5px',
                                                     border: 'none',
                                                     padding: '0 25px',
                                                }} />
                                                <button style={{
                                                    height: '36px',
                                                    position: 'absolute',
                                                    right: '0',
                                                    background: '#2580EC 0% 0% no-repeat padding-box',
                                                    boxShadow: '0px 3px 6px #00000029',
                                                    border: '1px solid #FFFFFF',
                                                    borderRadius: '5px',
                                                    color:'#FFFFFF'
                                                }}><img src={SearchIcon} width={'18px'} height={'18px'} marginRight={'10px'} />Search</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{
                                                            backgroundColor: '#76A5DE',
                                                            border: '1px solid #707070',
                                                            font: 'normal normal medium 15px/20px Roboto',
                                                            color: '#FFFFFF'
                                                        }}>Select Course</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {selectCourse
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((row) => (
                                                            <TableRow
                                                                key={row.courseName}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row"
                                                                sx={{ border: 'none', px:'17px', py:'9px'}}>
                                                                    {row.courseName}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            component="div"
                                            count={selectCourse.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </div>
                                </Box>
                            </Modal>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <p className="input-label" >Start Date</p>
                            <input className="custom-input" type='date' {...register("startDate", { required: true, maxLength: 50 })} />
                            {errors.startDate && errors.startDate.type === "required" && <span className='validationError'>This field is required</span>}
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <p className="input-label" >End Date</p>
                            <input className="custom-input" type='date' {...register("endDate", { required: true, maxLength: 50 })} />
                            {errors.endDate && errors.endDate.type === "required" && <span className='validationError'>This field is required</span>}
                        </div>
                    </div>
                    <div className='row mx-0'>
                        <div className='subHeading'>Academic Profile</div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <p className="input-label">Upload Photo 150 px X 150 px</p>
                            <label htmlFor="filePicker" className="d-flex align-items-center justify-content-center custom-input" style={{ padding: "5px 10px", color: "#707070" }}>
                                Brows or Upload
                            </label>
                            <div className='mt-0'>{selectedFile && <p>{selectedFile.name}</p>}</div>
                            <input id="filePicker" style={{ visibility: "hidden" }} type={"file"} onChange={handleFileInputChange} />
                            <div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                        </div>
                    </div>
                </div>
                <div className='d-flex mt-3 gap-3'>
                    <div><Button className='update-btn' type="submit" value="Register" onClick={onSaveClick}>Update</Button></div>
                </div>
            </form>
        </div>
    )
}
