import React, { useEffect, useState } from "react";
import { getSubjectList } from "../../Services/SubjectService";
import { requestReport } from "../../Services/ReportService";
import "./Report.scss";
import { Controller, useForm } from "react-hook-form";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import CommonBootstrapModal from "../../Components/CommonBootstrapModal/CommonBootstrapModal";
import CommonSearchFilter from "../../Components/CommonSearchFilter/ComonSearchFilter";
import CommonStudentTable from "../../Components/CommonStudentTable/CommonStudentTable";
import downArrow from "../../Assets/commonImg/downArrow.svg";
import { getAllCourseOfSelectedCollege } from "../../Services/memberService";
import CommonGroupTable from "../../Components/CommonGroupTable/CommonGroupTable";
import Swal from "sweetalert2";

const radioButtons = [
  {
    type: "radio",
    className: "form-check-input",
    id: "studentAssessment",
    name: "redoOptions",
    value: "studentAssessment",
    label: {
      className: "radio-label",
      htmlFor: "studentAssessment",
      text: "Student Assessment",
    },
  },
  {
    type: "radio",
    className: "form-check-input",
    id: "studentAssignment",
    name: "redoOptions",
    value: "studentAssignment",
    label: {
      className: "radio-label",
      htmlFor: "studentAssignment",
      text: "Student Assignment",
    },
  },
  {
    type: "radio",
    className: "form-check-input",
    id: "studentAttendance",
    name: "redoOptions",
    value: "studentAttendance",
    label: {
      className: "radio-label",
      htmlFor: "studentAttendance",
      text: "Student Attendance",
    },
  },
];



const SpecificReport = ({ college }) => {

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    getValues,
    control,
    watch,
    resetField
  } = useForm(
    {
      defaultValues: {
        collegeId: '',
        startTs: '',
        endTs: '',
        reportType: 'specific',
        entity: 'studentAssessment',
        courseId: '',
        subjectId: '',
        groupId: '',
        groupName: '',
        studentId: '',
        studentName: '',
        activeTab: 'individual',
        attendanceType: '',
      }
    }
  );
  const activeTab = watch('activeTab');
  const entity = watch('entity');
  const studentName = watch('studentName');
  const groupName = watch('groupName');
  const attendanceType = watch('attendanceType');
  useEffect(() => {
    if (typeof college === 'object' && college?.collegeId) {
      setValue('collegeId', college?.collegeId)
      getCoursesList(college?.collegeId);
    }
  }, [college])

  const [isShowSearchBy, setShowSearchBy] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const getCoursesList = async (collegeId) => {
    try {
      const courseListData = await getAllCourseOfSelectedCollege(collegeId);
      setCourseData(courseListData.data);
    } catch (error) {
      console.log('error in college list', error)
    }
  }


  const handleOptionChange = (event) => {
    resetField('startTs');
    resetField('endTs');
    resetField('courseId');
    resetField('subjectId');
    resetField('groupId');
    resetField('studentId');
    resetField('studentName');
    resetField('attendanceType');
    setValue('studentName', "");
  };
  const handleTabClick = (tab) => {
    setValue('activeTab', tab)
    resetField('startTs');
    resetField('endTs');
    resetField('courseId');
    resetField('subjectId');
    resetField('groupId');
    resetField('studentId');
    resetField('studentName');
    resetField('attendanceType');
    setValue('studentName', "");
  };
  const [openStudent, setStudentOpen] = React.useState(false);
  const [SearchFilter, seatSearchFilter] = React.useState({});
  const { searchText, searchKey } = SearchFilter;
  const handleOpen = ({ isShowSearchBy = true }) => {
    setStudentOpen(true);
    seatSearchFilter({});
    setShowSearchBy(isShowSearchBy)
  };

  const handleClose = () => {
    setStudentOpen(false);
    seatSearchFilter({});
  };

  const onSearchHandle = (e) => {
    seatSearchFilter(e);
  }



  const onStudentSelected = (event) => {
    console.log(`🚀 ~ file: SpecificReport.js:147 ~ onStudentSelected ~ event:`, event)
    setValue('studentId', event._id);
    setValue('studentName', event.fullName);
    getSubjectByCourseId(
      {
        courseId: event?.academics[0]?.courseId
      }
    )
    handleClose();
  };
  const onGroupSelected = (event) => {
    setValue('groupId', event?._id);
    setValue('groupName', event?.groupName);
    handleClose();
  };
  const [subjectList, setSubjectList] = React.useState([]);
  const getSubjectByCourseId = ({
    courseId,
  }) => {
    getSubjectList({ courseId, collegeAdminId: getValues('collegeId') }).then((resData) => {
      if (resData?.data) {
        setSubjectList(resData?.data)
      }
    }).catch((error) => {
      console.log(`🚀 ~ file: SpecificReport.js:167 ~ getSubjectList ~ error:`, error)
    })
  };

  let modalBody = <></>;

  let modalHeaders = <> <CommonSearchFilter onSearch={onSearchHandle} handleClose={handleClose} showSearchBy={isShowSearchBy} /></>;

  if (activeTab === 'individual') {
    modalBody = <CommonStudentTable
      collegeId={college.collegeId}
      onSelect={onStudentSelected}
      {...{ searchText, searchKey }}
    />
  } else if (activeTab === 'aggregate') {
    modalBody = <CommonGroupTable
      collegeId={college.collegeId}
      onSelect={onGroupSelected}
      {...{ searchText, searchKey: 'groupName' }}
    />
  }

  const onSubmit = () => {
    let payload = getValues();
    payload.startTs = new Date(payload.startTs).getTime();
    payload.endTs = new Date(payload.endTs).getTime();
    requestReport(payload).then((resData) => {
      if (resData?.data?.message === "success") {
        Swal.fire('Report Requested', 'Your request has been accepted to generate the report', "success")
      }
    }).catch((error) => {
      if (error?.response?.data && typeof error?.response?.data === 'string') {
        Swal.fire(error?.response?.data, '', "error");
      }
    });

  };
  return (
    <div id="specific-report" className="container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row radio-box">
          <div className="row">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="entity"
              row
              defaultValue="studentAssessment"
              onChange={handleOptionChange}
            >
              <Controller
                name="entity"
                control={control}
                defaultValue="studentAssessment"
                render={({ field }) => (
                  <>
                    {radioButtons.map(option => (
                      <FormControlLabel {...field} value={option.value} control={<Radio />} label={option.label.text} />
                    ))}
                  </>
                )}
              />
            </RadioGroup>


          </div>
        </div>
        <section>
          <header className="d-flex report-tabs">
            <div>
              <h3
                className={`individual-report ${activeTab === "individual" ? "active" : ""
                  }`}
                onClick={() => handleTabClick("individual")}
              >
                Individual Report
              </h3>
            </div>
            <div>
              <h3
                className={`aggregate-report ${activeTab === "aggregate" ? "active" : ""
                  }`}
                onClick={() => handleTabClick("aggregate")}
              >
                Aggregate Report
              </h3>
            </div>
          </header>
        </section>
        {activeTab === "individual" && (
          <section className="individual-report">
            <section>
              <main class="w-100 m-auto">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-md-6">
                      <div class="card-body">
                        <div class="row mb-3">
                          <label
                            for="username"
                            class="col-md-5 col-form-label text-md-start"
                          >
                            Select Student
                          </label>
                          <div class="col-md-6 position-relative">
                            <input className="custom-input px-3" type="text" placeholder='Select Student' value={studentName} onClick={handleOpen} readOnly />
                            <div className="down-arrow">
                              <img src={downArrow} width={"15px"} height={"13px"} />
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label
                            for="email"
                            class="col-md-5 col-form-label text-md-start"
                          >
                            Select Subject (Optional)
                          </label>
                          <div class="col-md-6 position-relative">
                            <select className="form-select custom-input"  {...register("subjectId")}>
                              <option value="" disabled >Select Subject</option>
                              {subjectList.map((subject) => {
                                <option value={subject?._id}>{subject.subjectName}</option>
                              })}
                            </select>
                            <div className="down-arrow">
                              <img src={downArrow} width={"15px"} height={"13px"} />
                            </div>
                          </div>
                        </div>

                        {
                          entity === "studentAttendance" ?
                            <div class="row mb-3" >
                              <label
                                for="email"
                                class="col-md-5 col-form-label text-md-start"
                              >
                                Class Type
                              </label>
                              <div class="col-md-6 position-relative">
                                <select className="form-select custom-input"  {...register("attendanceType")} onChange={() => {
                                  resetField('groupId');
                                  resetField('groupName');
                                }} >
                                  <option value="">Select</option>
                                  <option value="live-class">Live Class</option>
                                  <option value="onsite-class">Onsite Class</option>
                                </select>
                                <div className="down-arrow">
                                  <img src={downArrow} width={"15px"} height={"13px"} />
                                </div>
                              </div>
                            </div>
                            : <></>
                        }
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="card-body">
                        <div className="row mb-3">
                          <label
                            htmlFor="startDate"
                            className="col-md-4 col-form-label text-md-start"
                          >
                            Start Date
                          </label>
                          <div className="col-md-7">
                            <input
                              className="form-control custom-input" //is-invalid add this class for validation  
                              type="date"
                              {...register("startTs", {
                                required: "start date is required",

                              })}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label
                            htmlFor="endDate"
                            className="col-md-4 col-form-label text-md-start"
                          >
                            End Date
                          </label>
                          <div className="col-md-7">
                            <input
                              className="form-control custom-input" //is-invalid add this class for validation 
                              type="date"
                              {...register("endTs", {
                                required: "End date is required",

                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                  </div>

                  <div className="btn-group">
                    <button className="clear-btn">Clear</button>
                    <button className="report-btn">Request Report</button>
                  </div>
                </div>
              </main>
            </section>
          </section>
        )}
        {activeTab === "aggregate" && (
          <section className="aggregate-report">
            <section>
              <main class="w-100 m-auto">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-md-6">
                      <div class="card-body">
                        <div class="row mb-3">
                          <label
                            for="username"
                            class="col-md-5 col-form-label text-md-start"
                          >
                            Select Course
                          </label>
                          <div class="col-md-6 position-relative">
                            <select className="form-select custom-input"  {...register("courseId")} onChange={(e) => {
                              getSubjectByCourseId(
                                {
                                  courseId: e.target.value
                                }
                              )
                            }}>
                              {courseData.length ? (<option value='' >Select Course</option>) : <option disabled>No courses</option>}
                              {
                                courseData.map((course, index) => (
                                  <option key={index} value={course?._id}>{course?.courseName}</option>
                                ))
                              }
                            </select>
                            <div className="down-arrow">
                              <img src={downArrow} width={"15px"} height={"13px"} />
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label
                            for="email"
                            class="col-md-5 col-form-label text-md-start"
                          >
                            Select Subject (Optional)
                          </label>
                          <div class="col-md-6 position-relative">
                            <select className="form-select custom-input" {...register("subjectId")}>
                              <option value="">Select Subject</option>
                              {subjectList.map((subject) => {
                                <option value={subject?._id}>{subject.subjectName}</option>
                              })}
                            </select>
                            <div className="down-arrow">
                              <img src={downArrow} width={"15px"} height={"13px"} />
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        entity === "studentAttendance" ?
                          <div class="row mb-3" >
                            <label
                              for="email"
                              class="col-md-5 col-form-label text-md-start"
                            >
                              Class Type
                            </label>
                            <div class="col-md-6 position-relative">
                              <select className="form-select custom-input"  {...register("attendanceType")}>
                                <option value="">Select</option>
                                <option value="live-class">Live Class</option>
                                <option value="onsite-class">Onsite Class</option>
                              </select>
                              <div className="down-arrow">
                                <img src={downArrow} width={"15px"} height={"13px"} />
                              </div>
                            </div>
                          </div>
                          : <></>
                      }
                    </div>

                    <div className="col-md-6">
                      <div className="card-body">
                        {
                          attendanceType === "live-class" ?
                            <div class="row mb-3">
                              <label
                                for="username"
                                class="col-md-5 col-form-label text-md-start"
                              >
                                Select Group (Optional)
                              </label>
                              <div class="col-md-7 position-relative">
                                <input className="custom-input px-3" type="text" placeholder='Select Group' value={groupName} onClick={() => { handleOpen({ isShowSearchBy: false }) }} readOnly />
                                <div className="down-arrow">
                                  <img src={downArrow} width={"15px"} height={"13px"} />
                                </div>
                              </div>
                            </div>
                            : ''
                        }
                        <div className="row mb-3">
                          <label
                            htmlFor="startDate"
                            className="col-md-5 col-form-label text-md-start"
                          >
                            Start Date & End Date
                          </label>
                          <div className="col-md-6">
                            <div className="d-flex gap-3">
                              <div className="d-flex">
                                <input
                                  className="form-control custom-input" //is-invalid add this class for validation  
                                  type="date"
                                  {...register("startTs", {
                                    required: "start date is required",

                                  })}
                                />
                              </div>
                              <div className="d-flex">
                                <input
                                  className="form-control custom-input" //is-invalid add this class for validation 
                                  type="date"
                                  {...register("endTs", {
                                    required: "End date is required",

                                  })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="btn-group">
                    <button className="clear-btn">Clear</button>
                    <button className="report-btn">Request Report</button>
                  </div>
                </div>
              </main>
            </section>
          </section>
        )}
      </form>
      <CommonBootstrapModal
        modalProps={{
          ...{
            show: openStudent,
            onHide: handleClose,
            backdrop: "static",
            keyboard: false,
            scrollable: true
          }
        }}
        modalHeaderCloseButton={false}
        header={modalHeaders}
        body={modalBody}
      >
      </CommonBootstrapModal>
    </div >
  );
};

export default SpecificReport;
