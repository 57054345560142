import React, { useState } from "react";
import { Box } from "@mui/material";
import { Col } from "react-bootstrap";
import { getCurrentUniversityAdmin } from "../../../Services/UniversityAdminService";
import BasicColumnChart from "../../../Components/BasicColumnChart/BasicColumnChart";
import ColumnComparisonChart from "../../../Components/ColumnComparisonChart/ColumnComparisonChart";
import MultipleKpiGaugeChart from "../../../Components/MultipleKPIGaugeChart/MultipleKPIGaugeChart";
import * as DashboardService from "../../../Services/DashboardService";
import "./Dashboard.scss";
import UploadedContent from "../../../Components/UploadedContent/UploadedContent";
import AnnouncementAndUpcomingExam from "../../../Components/AnnouncementAndUpcomingExam/AnnouncementAndUpcomingExam";
import { useEffect } from "react";
import ViewDashboardDetails from "../../../Components/ViewDashboardDetails/ViewDashboardDetails";
import ViewDashboardOverview from "../../../Components/ViewDashboardOverview/ViewDashboardOverview";
import MonthlyProgressChart from "../../../Components/MonthlyProgressChart/MonthlyProgressChart";
import OnlineClassImage from "../../../Assets/CountingTiles/OnlineClass.svg";
import AssignmentImage from "../../../Assets/CountingTiles/Assignment.svg";
import AssessmentImage from "../../../Assets/CountingTiles/Assessment.svg";
import VideoImage from "../../../Assets/CountingTiles/Video.svg";
import NoteImage from "../../../Assets/CountingTiles/Note.svg";
import { getPaginatedCollegeList } from "../../../Services/CollegeService";

export default function Dashboard({ sideNavState, setSideNavState }) {
	const [collegeId, setCollegeId] = useState(null);
	const [collegeList, setCollegeList] = useState([]);
	const [assignedTaskProgress, setAssignedTaskProgress] = useState(null);
	const [recentEntityCount, setRecentEntityCount] = useState(null);
	const [contentGraph, setContentGraph] = useState(null);
	const [attendanceGraph, setAttendanceGraph] = useState(null);

	useEffect(() => {
		getUniversityAdmin();
		getCollegeList();
	}, []);

	useEffect(() => {
		console.log(`🚀 ~ file: Dashboard.js:43 ~ useEffect ~ collegeId:`, collegeId);
		if (collegeId) {
			getAssignedTaskProgress(collegeId);
			getRecentEntityCount(collegeId);
		}
	}, [collegeId]);

	const getCollegeList = () => {
		getPaginatedCollegeList({})
			.then((resData) => {
				console.log(`🚀 ~ file: Dashboard.js:49 ~ getPaginatedCollegeList ~ resData:`,resData?.data);
				if (resData?.data?.collegeList && resData?.data?.collegeList?.length) {
					setCollegeList(resData?.data?.collegeList);
					setCollegeId(resData?.data?.collegeList[0]._id);
				}
			})
			.catch((error) => {
				console.log(`🚀 ~ file: Dashboard.js:51 ~ getPaginatedCollegeList ~ error:`, error);
			});
	};

	const getAssignedTaskProgress = (collegeId) => {
		if (collegeId) {
			DashboardService.getAssignedTaskProgress(collegeId)
				.then((resData) => {
					if (resData.data && resData.status === 200) {
						setAssignedTaskProgress(resData.data);
					} else {
						setAssignedTaskProgress(null);
					}
				})
				.catch((error) => {
					setAssignedTaskProgress(null);
				});
		}
	};
	const getRecentEntityCount = (collegeId) => {
		if (collegeId) {
			DashboardService.getRecentEntityCount(collegeId)
				.then((resData) => {
					if (resData.data && resData.status === 200) {
						setRecentEntityCount(resData.data);
					} else {
						setRecentEntityCount(null);
					}
				})
				.catch((error) => {
					setRecentEntityCount(null);
				});
		}
	};
	const getContentGraph = (payload) => {
		const { year = new Date().getFullYear() } = payload || {};
		DashboardService.getContentGraph({ year })
			.then((resData) => {
				if (resData.data && resData.status === 200) {
					setContentGraph(resData.data);
				} else {
					setContentGraph(null);
				}
			})
			.catch((error) => {
				setContentGraph(null);
			});
	};
	const getAttendanceGraph = (payload) => {
		const { year = new Date().getFullYear(), educatorId } = payload || {};
		DashboardService.getAttendanceGraph({ year, educatorId })
			.then((resData) => {
				if (resData.data && resData.status === 200) {
					setAttendanceGraph(resData.data);
				} else {
					setAttendanceGraph(null);
				}
			})
			.catch((error) => {
				setAttendanceGraph(null);
			});
	};


	const getUniversityAdmin = async () => {
		try {
			const universityAdmin = await getCurrentUniversityAdmin();
			console.log("🚀 ~ getUniversityAdmin ~ universityAdmin:", universityAdmin);
		} catch (error) {
			console.log("🚀 ~ getUniversityAdmin ~ error:", error);
		}
	};
	return (
		<div className={"dashboard-container " + (sideNavState ? "fullWidthOpenSideNav" : "halfWidthOpenSideNav")}>
			<div className="dashboard-content">
				<Box>
					<Box>{/* <CountingTiles /> */}</Box>
					<Box display={"flex"} flexWrap={"wrap"}>
						<Col className="col-12 col-sm-12 col-lg-8">
							<Box sx={{ p: 3, display: "flex", flexDirection: "column", gap: "20px" }}>
								{/* <DashboardRadarChart /> */}
								<ViewDashboardDetails taskProgress={assignedTaskProgress} setCollege={setCollegeId} allCollegeList={collegeList} selectedCollege={collegeId} />
								{/* <ViewDashboardRecentlyAdded /> */}
								{/* <ViewDashboardAssignments /> */}
								{/* <ViewDashboardContentAnalytics /> */}
								<MonthlyProgressChart attendanceGraphCount={attendanceGraph} getAttendanceGraphByYear={getAttendanceGraph} />

								<BasicColumnChart contentGraphCount={contentGraph} getContentGraphByYear={getContentGraph} />
								<ColumnComparisonChart />
								<Box className="d-flex gap-19-px overflow-auto">
									<MultipleKpiGaugeChart headers={{ title: "Online class", url: OnlineClassImage }} entityCount={recentEntityCount?.entityCounts?.liveClass} />
									<MultipleKpiGaugeChart headers={{ title: "Assignment", url: AssignmentImage }} entityCount={recentEntityCount?.entityCounts?.assignment} />
									<MultipleKpiGaugeChart headers={{ title: "Assessment", url: AssessmentImage }} entityCount={recentEntityCount?.entityCounts?.assessment} />
									<MultipleKpiGaugeChart headers={{ title: "Video", url: VideoImage }} entityCount={recentEntityCount?.entityCounts?.videos} />
									<MultipleKpiGaugeChart headers={{ title: "Node", url: NoteImage }} entityCount={recentEntityCount?.entityCounts?.notes} />
								</Box>
							</Box>
						</Col>
						<Col className="col-12 col-sm-12 col-lg-4">
							<Box sx={{ py: 3, px: 1, display: "flex", flexDirection: "column", gap: "10px" }}>
								<AnnouncementAndUpcomingExam />
								<UploadedContent />
								{/* <TodaysOnlineClasses /> */}
								<ViewDashboardOverview entityCount={recentEntityCount?.totatCounts} />
							</Box>
						</Col>
					</Box>
					<Box></Box>
				</Box>
			</div>
		</div>
	);
}
