import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './TaskProgressView.scss';
import BackIcon from '../../../../Assets/commonImg/back.svg';
import DownloadIcon from '../../../../Assets/commonImg/downloadIcon.svg';
import TaskProgressIcon from '../../../../Assets/commonImg/task-progress.svg';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const taskReport = [
    { taskName: 'Biotechnology', assigedDate: '1 June 2022', taskCompletedDate: '7 June 2022', taskStartDate: '7 June 2022', taskEndDate: '20 June 2022', progressStatus: 'Completed', onsiteClass: 35, completedTask: 75 },
    { taskName: 'Anatomy', assigedDate: '1 June 2022', taskCompletedDate: '7 June 2022', taskStartDate: '7 June 2022', taskEndDate: '20 June 2022', progressStatus: 'In Progress', onsiteClass: 35, completedTask: 75 },
    { taskName: 'Biotechnology', assigedDate: '1 June 2022', taskCompletedDate: '7 June 2022', taskStartDate: '7 June 2022', taskEndDate: '20 June 2022', progressStatus: 'To Do', onsiteClass: 35, completedTask: 75 },
    { taskName: 'Anatomy', assigedDate: '1 June 2022', taskCompletedDate: '7 June 2022', taskStartDate: '7 June 2022', taskEndDate: '20 June 2022', progressStatus: 'Completed', onsiteClass: 35, completedTask: 75 },
    { taskName: 'Biotechnology', assigedDate: '1 June 2022', taskCompletedDate: '7 June 2022', taskStartDate: '7 June 2022', taskEndDate: '20 June 2022', progressStatus: 'In Progress', onsiteClass: 35, completedTask: 75 },
    { taskName: 'Anatomy', assigedDate: '1 June 2022', taskCompletedDate: '7 June 2022', taskStartDate: '7 June 2022', taskEndDate: '20 June 2022', progressStatus: 'To Do', onsiteClass: 35, completedTask: 75 },
    { taskName: 'Biotechnology', assigedDate: '1 June 2022', taskCompletedDate: '7 June 2022', taskStartDate: '7 June 2022', taskEndDate: '20 June 2022', progressStatus: 'To Do', onsiteClass: 35, completedTask: 75 },
    { taskName: 'Anatomy', assigedDate: '1 June 2022', taskCompletedDate: '7 June 2022', taskStartDate: '7 June 2022', taskEndDate: '20 June 2022', progressStatus: 'Completed', onsiteClass: 35, completedTask: 75 },
];
export default function TaskProgressView({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/home/educatorPerformance', { replace: true });
    }

    return (
        <div className='task-progress-view-container'
            style={{
                width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
                marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
                transition: "0.5s",
                position: "absolute",
                top: "4rem",
                padding: "1%",

            }}>
            <div className='backBtn' onClick={goBack}><img src={BackIcon} width={'12px'} height={'13px'} marginTop={'5px'} /><div className='backText'>Back</div></div>
            <div className='task-progress-view-card p-3'>
                <div className='task-progress-view-tracker-title'>
                    Report
                </div>
                <div className='d-flex justify-content-start flex-wrap gap-3 my-4 course-detail-section'>
                    <div className='d-flex justify-content-start align-items-center flex-wrap course-detail p-2'>
                        <div><img src={TaskProgressIcon} width={'40px'} height={'40px'} marginTop={'5px'} style={{ marginRight: '20px' }} /></div>
                        <div className='d-flex flex-column'>
                            <div className='total-course'>Course</div>
                            <div className='course-title mt-2'>BGS</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center flex-wrap course-detail p-2'>
                        <div><img src={TaskProgressIcon} width={'40px'} height={'40px'} marginTop={'5px'} /></div>
                        <div className='d-flex flex-column'>
                            <div className='d-flex justify-content-between'>
                                <div className='total-course'>200</div>
                                <div><img src={DownloadIcon} width={'18px'} height={'18px'} marginTop={'5px'} /></div>
                            </div>
                            <div className='course-title mt-2'>Assigned Task</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center flex-wrap course-detail p-2'>
                        <div><img src={TaskProgressIcon} width={'40px'} height={'40px'} marginTop={'5px'} /></div>
                        <div className='d-flex flex-column'>
                            <div className='d-flex justify-content-between'>
                                <div className='total-course'>200</div>
                                <div><img src={DownloadIcon} width={'18px'} height={'18px'} marginTop={'5px'} /></div>
                            </div>
                            <div className='course-title mt-2'>Task In Progress</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center flex-wrap course-detail p-2'>
                        <div><img src={TaskProgressIcon} width={'40px'} height={'40px'} marginTop={'5px'} /></div>
                        <div className='d-flex flex-column'>
                            <div className='d-flex justify-content-between'>
                                <div className='total-course'>200</div>
                                <div><img src={DownloadIcon} width={'18px'} height={'18px'} marginTop={'5px'} /></div>
                            </div>
                            <div className='course-title mt-2'>Completed Task</div>
                        </div>
                    </div>
                </div>
                <div className='active-course-name'>
                    <div className='row'>
                        <div className='col-6'><div className='py-3 px-4'>Course Name : BGS Course</div></div>
                        <div className='col-6'><div className='py-3 px-4'>Dr. Sarang P.</div></div>
                    </div>
                </div>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer>
                                    <Table sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '0px 3px 6px #00000029',
                                        borderRadius: '0px 0px 5px 5px'
                                    }}>
                                        <TableHead>
                                            <TableRow className='table-row'>
                                                <TableCell align="center" className='head-title'>Task Name</TableCell>
                                                <TableCell align="center" className='head-title'>Assigned Date</TableCell>
                                                <TableCell align="center" className='head-title'>Task Completed Date</TableCell>
                                                <TableCell align="center" className='head-title'>Task Start Date</TableCell>
                                                <TableCell align="center" className='head-title'>Task End Date</TableCell>
                                                <TableCell align="center" className='head-title'>Progress Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {taskReport
                                                .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                            <TableCell align="center" className='table-data'>{row.taskName}</TableCell>
                                                            <TableCell align="center" className='table-data'>{row.assigedDate}</TableCell>
                                                            <TableCell align="center" className='table-data'>{row.taskCompletedDate}</TableCell>
                                                            <TableCell align="center" className='table-data'>{row.taskStartDate}</TableCell>
                                                            <TableCell align="center" className='table-data'>{row.taskEndDate}</TableCell>
                                                            <TableCell align="center" className='table-data' style={{color:'#2580EC', fontWeight:'bold'}}>{row.progressStatus}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
            </div>
        </div>
    )
}
