import React, { useEffect, useState } from "react";
import Highcharts from 'highcharts'
import style from "./BasicColumnChart.module.scss";
import HighchartsReact from 'highcharts-react-official';
import { Col, Row } from 'react-bootstrap';
import { NextPreviousNavButton } from "../NextPreviousNavButton/NextPreviousNavButton";

const BasicColumnChart = ({
    contentGraphCount,
    getContentGraphByYear
}) => {
    const [contentGraph, setContentGraph] = useState({});
    const [displayYear, setDisplayYear] = useState(new Date());
    const {
        assessment = [],
        assignment = [],
        video = [],
        note = [],
    } = contentGraph || {};
    useEffect(() => {
        if (contentGraphCount && typeof contentGraphCount === 'object') {
            setContentGraph(contentGraphCount);
        }
    }, [contentGraphCount]);

    useEffect(() => {
        getContentGraphByYear({ year: displayYear.getFullYear() })
    }, []);

    useEffect(() => {
        if (displayYear) {
            getContentGraphByYear({ year: displayYear.getFullYear() })
        }
    }, [displayYear])


    const onYearChange = (event) => {
        const { isNext, isPrevious } = event;
        let selectedYear = new Date(displayYear);
        console.log(`🚀 ~ file: MonthlyProgressChart.js:26 ~ onYearChange ~ selectedYear:`, isNext, isPrevious)
        if (isNext) {
            const data = selectedYear.setFullYear(selectedYear.getFullYear() + 1);
            setDisplayYear(new Date(data));
        }
        if (isPrevious) {
            const data = selectedYear.setFullYear(selectedYear.getFullYear() - 1);
            setDisplayYear(new Date(data));
        }
    }


    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: '',
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        },
        yAxis: {
            title: {
                text: 'Counts',
            },
        },
        legend: {
            align: 'right',
            verticalAlign: 'top',
            x: 0,
            y: 0,
            floating: true,
            borderWidth: 0,
            backgroundColor: 'none',
            shadow: false,
            symbolRadius: 0,
            width: '300px'
        },
        series: [
            {
                name: 'Assignment',
                data: assignment,
                color: '#EBC73B'
            },
            {
                name: 'Assessment',
                data: assessment,
                color: '#5586D4'
            },
            {
                name: 'Videos',
                data: video,
                color: '#B8CE14'
            },
            {
                name: 'Notes',
                data: note,
                color: '#FF0066'
            },
        ],
    };
    return (
        <Row>
            <Col className="col-12 p-0">
                <div className={[style.gap10Px]}>
                    <span className={[style.title]}> Created Content</span>
                    <span className={[style.titleImg]} />
                </div>
            </Col>
            <Col className={[style.basicColumnChart, 'col-12']}>
                <div className="d-flex justify-content-end mt-2 align-items-center">
                    <NextPreviousNavButton label={displayYear.getFullYear()} handleNavigationChange={onYearChange} />
                </div>
                <div>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                </div>
            </Col>
        </Row>
    );
};

export default BasicColumnChart;
