import React, { useState } from 'react';
import styles from "./AnnouncementAndUpcomingExam.module.scss";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import leftArrowBtn from '../../Assets/dashboard/leftArrowBtn.svg';
import rightArrowBtn from '../../Assets/dashboard/rightArrowBtn.svg'
import { Row } from 'react-bootstrap';
import { Avatar } from '@mui/material';

const AnnouncementAndUpcomingExam = () => {
    const [currentDate, setCurrentDate] = useState(new Date());

    function formatDate(date) {
        return new Date(date).toLocaleDateString('en-US', { weekday: 'short' });
    }
    function formatDay(date) {
        return new Date(date).toLocaleDateString('en-US', { day: 'numeric' });
    }

    const getWeekDays = (date) => {
        const weekDays = [];
        const currentDayOfWeek = date.getDay();
        const startOfWeek = new Date(date);
        startOfWeek.setDate(date.getDate() - currentDayOfWeek);

        for (let i = 0; i < 7; i++) {
            const day = new Date(startOfWeek);
            day.setDate(startOfWeek.getDate() + i);
            const dayString = formatDate(day);
            const dayOfWeek = formatDay(day);
            const isCurrentDay = day.toDateString() === new Date().toDateString();
            weekDays.push({ day: dayString, dayOfWeek, isCurrentDay });
        }
        return weekDays;
    };
    const announcements = [{}, {}];
    const upcomingExams = [{}];
    const weekDays = getWeekDays(currentDate);
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const year = currentDate.getFullYear();

    const goToPreviousWeek = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() - 7);
        setCurrentDate(newDate);
    };

    const goToNextWeek = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + 7);
        setCurrentDate(newDate);
    };

    return (
        <div>
            <Box className={styles.calendarAndAnnoucementView}>
                <Box sx={{ p: 2 }}>
                    <div className='d-flex justify-content-between'>
                        <div sx={{ display: 'flex' }}>
                            <Typography className={styles.monthHeading}>
                                {month} {year}
                            </Typography>
                        </div>
                        <div sx={{ display: 'flex' }}>
                            <button className={styles.arrowBtn} onClick={goToPreviousWeek}><img src={leftArrowBtn} /></button>
                            <button className={styles.arrowBtn} onClick={goToNextWeek}><img src={rightArrowBtn} /></button>
                        </div>
                    </div>

                    <Box sx={{ my: 2, display: 'flex', justifyContent: 'center', p: 0, overflow: 'scroll' }}>
                        {weekDays.map(({ day, dayOfWeek, isCurrentDay }) => (
                            <Typography sx={{ mx: 1, alignItems: 'center', alignContent: 'center', textAlign: 'center' }} key={day} className={isCurrentDay ? styles.currentday : styles.otherday} >
                                {day} {dayOfWeek}
                            </Typography>
                        ))}
                    </Box>
                </Box>
                <Box sx={{ mb: 2, px: 2 }}>
                    <Row >
                        <Box sx={{ py: 1, display:'flex', justifyContent:'space-between' }}>
                            <Box> <p className={styles.heading}>
                                University Announcement
                            </p></Box>
                            <Box className={styles?.skipAll}>See All</Box>
                        </Box>
                        <Box  display={'flex'} flexDirection={'column'} gap={'13px'}>
                            {announcements.map((data,index) => {

                                return <Box 
                                key={index}
                                sx={{
                                    // background: ' #F7F9FA 0% 0% no-repeat padding-box',
                                    // borderRadius: '15px',
                                    minHeight: '74px',
                                    width: '100%',
                                    display: 'flex',
                                    p: 2,
                                    gap: '10px',
                                    borderBottom: '1px solid #E5E5E5',
                                }}>

                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        <Avatar
                                            alt="Remy Sharp"
                                            src="./assets/dashboard/Mask Group 15.png"

                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap-reverse' }}>
                                            <Typography variant='subtitle2' className={styles.announcementheading}>
                                                Pooja Jha
                                            </Typography>
                                            <Typography variant='caption' className={styles.announcementTime}>
                                                9.43 Am
                                            </Typography>
                                        </Box>
                                        <Typography variant='caption' className={styles.announcementDescription}>
                                            Hello, PSGVP Medical college is going to organise paper presentation event on Wed.
                                        </Typography>
                                    </Box>

                                </Box>
                            })
                            }
                        </Box>
                    </Row >
                </Box>
            </Box >

        </div >
    )
}

export default AnnouncementAndUpcomingExam
