import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CalendarView.scss';
import BackIcon from '../../../../Assets/commonImg/back.svg';
import addEventIcon from '../../../../Assets/commonImg/addEvent.svg';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);
const events = [
  {
    title: 'Event 1',
    start: new Date(2023, 3, 28),
    end: new Date(2023, 3, 29),
  },
  {
    title: 'Event 2',
    start: new Date(2023, 3, 30),
    end: new Date(2023, 3, 31),
  },
];

export default function CalendarView({ sideNavState, setSideNavState }) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate('/home/grievance', { replace: true });
  }
  const addEvent = () => {
    navigate('addNewEvent', { replace: true });
  }

  return (
    <div className='calendar-container'
      style={{
        width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
        marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
        transition: "0.5s",
        position: "absolute",
        top: "4rem",
        padding: "1%",

      }}>
      <div className='backBtn'><img src={BackIcon} width={'12px'} height={'13px'} marginTop={'5px'} /><div className='backText' onClick={goBack}>Back</div></div>
      <div className='calendar-card mt-2'>
        <div className='d-flex justify-content-between'>
        <div className='d-flex gap-5'>
        <div>
          <select className='calendar-select-input'>
            <option>Select Year</option>
            <option>2019</option>
            <option>2020</option>
            <option>2021</option>
          </select>
        </div>
        <div>
          <select className='calendar-select-input'>
            <option>Select Month</option>
            <option>Jan</option>
            <option>Feb</option>
            <option>Mar</option>
            <option>Apr</option>
          </select>
        </div>
        </div>
        <div className='add-event-btn'><div onClick={addEvent}><img src={addEventIcon} width={'21px'} height={'21px'} style={{marginRight:'10px'}} />New Event</div></div>
        </div>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          views={['month']}
          endAccessor="end"
          style={{ height: 500 }}
        />
      </div>
    </div>
  )
}
