import React, { useEffect, useState } from "react";
import "./ChangePhone.scss";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { currentAuthenticatedUser, updateUserAttributes, verifyCurrentUserAttribute, verifyCurrentUserAttributeSubmit } from "../../../Services/AuthService";

export default function ChangePhone() {
	const [isChangePhoneClicked, setIsChangePhoneClicked] = useState(false);

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors, isValid },
	} = useForm();

	const {
		register: registerOtp,
		handleSubmit: handleSubmitOtp,
		getValues: getValuesOtp,
		formState: { errors: errorsOtp, isValid: isValidOtp },
	} = useForm();

	const onSubmit = (event) => {
		if (isValid) {
			currentAuthenticatedUser()
				.then((user) => {
					updateUserAttributes(user, getValues())
						.then((resData) => {
							verifyCurrentUserAttribute("phone_number")
								.then((isSend) => {
									setIsChangePhoneClicked(true);
									Swal.fire({
										icon: "success",
										title: "success",
										text: `Otp send phone number  ${getValues()?.phone_number}`,
									});
								})
								.catch((e) => {
									Swal.fire({
										icon: "error",
										title: "Error",
										text: e.message,
									});
								});
						})
						.catch((err) => {
							Swal.fire({
								icon: "error",
								title: "Error",
								text: err.message,
							});
						});
				})
				.catch((error) => {
					Swal.fire({
						icon: "error",
						title: "Error",
						text: error.message,
					});
				});
		}
	};

	const onSubmitOtp = async (event) => {
		try {
			const isVerified = await verifyCurrentUserAttributeSubmit("phone_number", event.otp);
			if (isVerified) {
				Swal.fire({
					icon: "success",
					title: "Success",
					text: "Phone Verified Successful",
				});
				setIsChangePhoneClicked(false);
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error.message,
			});
		}
	};

	return (
		<div className="changeEmail-container">
			<div className="changeEmailHeading">Change Email</div>
			{!isChangePhoneClicked && (
				<form className="p-3" onSubmit={handleSubmit(onSubmit)}>
					{/* <div className='row emailField'>
                        <div className='col-4 infoHeading'>Current Password</div>
                        <div className='col-8'>
                            <div className='row'>
                                <div className='col-7'>
                                    <div className='mt-2'><input className="custom-input" type='currentPassword' name="currentPassword" required ></input></div>
                                </div>
                                <div className='col-5'>
                                </div>
                            </div>
                        </div>
                    </div> */}
					<div className="row emailField">
						<div className="col-4 infoHeading">New Phone</div>
						<div className="col-8">
							<div className="row">
								<div className="col-7">
									<div className="mt-2">
										<input
											type="text"
											className="custom-input"
											{...register("phone_number", {
												required: "phone is required",
												
											})}
										></input>
										{errors.phone_number && <p>{errors.phone_number.message}</p>}
									</div>
								</div>
								<div className="col-5"></div>
							</div>
						</div>
					</div>
					<div className="d-flex justify-content-end align-items-center mt-4">
						<Button className="requestOtp" type="submit" disabled={!isValid}>
							Request OTP
						</Button>
					</div>
				</form>
			)}
			{isChangePhoneClicked && (
				<form className="p-4" onSubmit={handleSubmitOtp(onSubmitOtp)}>
					<div className="row emailField">
						<div className="col-4 infoHeading">Enter OTP</div>
						<div className="col-8">
							<div className="row">
								<div className="col-7">
									<input
										className="input-block"
										type="number"
										{...registerOtp("otp", {
											required: "OTP is required",
										})}
									></input>
								</div>
								<div className="col-5"></div>
							</div>
						</div>
					</div>
					<div className="d-flex justify-content-end align-items-center mt-4">
						<Button className="requestOtp" type="submit" disabled={!isValidOtp}>
							Verify
						</Button>
					</div>
				</form>
			)}
		</div>
	);
}
