const awsConfig = {
    Auth: {
      region: 'ap-south-1',
      userPoolId: 'ap-south-1_ukt6cDkud',
      userPoolWebClientId: 'sssgpue7bvnl4af84pbesnl9m',
      identityPoolId: 'ap-south-1:6ae30471-cb7d-4fe4-b8c1-b62bf2d4be1e',
    },
    Storage: {
      AWSS3: {
        bucket: 'cloud.lms.study.assets',
        accessKeyId: 'AKIA2SM7JXMSN75VOQP5',
        secretKey: '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',
        region: 'ap-south-1',
        identityPoolId: 'ap-south-1:6ae30471-cb7d-4fe4-b8c1-b62bf2d4be1e',
      },
    },
  };

export default awsConfig;