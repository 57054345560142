import React, { useState } from 'react';
import './NewPassword.scss';
import blueCurve from '../../../Assets/login/blueCurve.svg';
import resetPasswordImg from '../../../Assets/login/resetPasswordKey.png'
import appleStoreImg from '../../../Assets/login/app.svg'
import playStoreImg from '../../../Assets/login/play.png'
import mobileImg from '../../../Assets/login/mobile.png'

export default function NewPassword({ onNewPasswordHandler }) {
  const [newPassword, setNewPassword] = useState('')
  const onPasswordChange = () => {
    onNewPasswordHandler(newPassword)
  }

  return (
    <div className='container-fluid create-password-container'>
      <div className='row my-0'>
        <div className="col-md-1">
          <img className='blue-curve-img' src={blueCurve} alt='' />
        </div>
        <div className="col-md-4">
          <div className='create-password-form-container'>
            <img src={resetPasswordImg} alt='' className='create-password-circle'></img>
            <p className="main-text d-flex justify-content-center mt-4"><span className="createText">Create</span>&nbsp;&nbsp;<span className="passwordText">New Password</span></p>
            <p className="enterUserNameText mt-5">Enter New password</p>
            <input className='input-block' type='text' placeholder='Enter password here' onChange={(e) => setNewPassword(e.target.value)}></input>
            <div className='text-center'>
              <input type="submit" value="Create Password" className="btn btn-create-password" id="login_btn" onClick={onPasswordChange} />
            </div>
          </div>
        </div>
        <div className="col-md-7 welcome-container">
          <div className="container content ">
            <div className="lms-content">
              <p className='welcome-text'>Welcome to ZLIPP</p>
              <p className='welcome-des'>India's leading online learning platform for all medical aspirants. Download the application by
                clicking on the link below and learn anytime, anywhere.</p><br />
              <div className="row p-2" style={{ gap: '1%' }}>
                <div className="col-lg-4"></div>
                <div className="col-lg-3 col-sm-12 d-flex justify-content-center">
                  <a className='pr-2' href="https://apps.apple.com/in/app/zlipp/id6471843198" target="_blank">
                    <img src={appleStoreImg} alt='' className="float-right apple" />
                  </a>
                </div>
                <div className="col-lg-3 col-sm-12 d-flex justify-content-center">
                  <a href="https://play.google.com/store/apps/details?id=com.zlipp&pli=1" target="_blank">
                    <img src={playStoreImg} alt='' />
                  </a>
                </div>
              </div>
              <img src={mobileImg} className="mobile-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}