import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Col, Row } from 'react-bootstrap';
import style from "./AssignmentPieChart.module.scss"; // Assuming you have styles imported
import { Margin, Padding, PaddingRounded, PaddingSharp, SpaceBar } from "@mui/icons-material";

const AssignmentPieChart = ({ assignmentCount }) => {
    const { assigned = 0,
        submitted = 0 } = assignmentCount || {};
    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            width: 350,
            height: 320,
        },
        title: {
            text: '',
            align: ''
        },
        legend: {
            align: 'left',
            itemStyle: {
                fontSize: 'normal normal normal 14px/19px Roboto;',
                fontWeight: 'bold',
            },
           
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                showInLegend: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '{point.percentage:.1f} %',
                    distance: -10,
                    filter: {
                        property: 'percentage',
                        operator: '>',
                        margin: 15,
                        value: 4
                    },
                }
            }
        },
        series: [{
            name: 'Brands',
            colorByPoint: true,
            data: [
                {
                    name: 'Assigned Assessment',
                    y: assigned,
                    color: '#2580EC'
                },
                {
                    name: 'Submitted Assignment',
                    y: submitted,
                    color: '#E91067'
                },
            ]
        }]
    };

    return (
        <Row>
            <Col className={['col-12']}>
                <div>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                </div>
            </Col>
        </Row>
    );
};

export default AssignmentPieChart;