import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";
import style from "./MultipleKPIGaugeChart.module.scss"

highchartsMore(Highcharts);
solidGauge(Highcharts);

// Initialize the highcharts-more module


const KpiGauge = (props) => {
  const { headers, entityCount } = props;
  const [kpiGaugeCount, SetKpiGaugeCount] = useState();
  useEffect(() => {
    if (entityCount && typeof entityCount === 'object') {
      SetKpiGaugeCount(entityCount)
    }
  }, [entityCount])


  const {
    label = "",
    graph = {}
  } = kpiGaugeCount || {};

  const {
    sectionOne: sectionOneGraph = {},
    sectionTwo: sectionTwoGraph = {},
    sectionThree: sectionThreeGraph = {}
  } = graph || {};

  const {
    value: sectionOneGraphValue = 0,
    text: sectionOneGraphText = ""
  } = sectionOneGraph || {};

  const {
    value: sectionTwoGraphValue = 0,
    text: sectionTwoGraphText = ""
  } = sectionTwoGraph || {};

  const {
    value: sectionThreeGraphValue = 0,
    text: sectionThreeGraphText = ""
  } = sectionThreeGraph || {};

  const options = {
    chart: {
      type: "solidgauge",
      width: 279,
      height: 279,
      plotBackgroundColor: null,
      // marginTop: 12, // Set top margin to 0
      // marginBottom: 60, // Set bottom margin to 0
    },
    title: {
      text: "",
      style: {
        display: "none",
      },
    },

    tooltip: {
      borderWidth: 0,
      backgroundColor: "none",
      shadow: false,
      style: {
        fontSize: "12px",
        textAlign: "center",
      },
      // padding:70,
      useHTML: true,
      format:
        '<div>{series.name}<br><span style="color: {point.color}; font-weight: bold">{point.y}</span></div>',
      positioner: function (labelWidth) {
        return {
          x: (this.chart.chartWidth - labelWidth) / 2,
          y: this.chart.plotHeight / 2.2,
        };
      },
    },

    pane: {
      size: "150px",
      padding: 0,
      background: [
        {
          backgroundColor: "#e031683d",
          outerRadius: "110%",
          innerRadius: "100%",
          borderWidth: 0
        },
        {
          backgroundColor: "#FEB5463d",
          outerRadius: "90%",
          innerRadius: "80%",
          borderWidth: 0
        },
        {
          backgroundColor: "#02CCC03d",
          outerRadius: "70%",
          innerRadius: "60%",
          borderWidth: 0
        }
      ]
    },

    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: [],
    },

    plotOptions: {
      solidgauge: {
        colorByPoint: false,
        dataLabels: {
          enabled: false,
        },
        stickyTracking: true,
      },
    },

    series: [
      {
        name: sectionOneGraphText,
        showInLegend: true,
        rounded: true,
        color: '#E03168',
        data: [
          {
            radius: `${110}%`,
            innerRadius: `${100}%`,
            y: sectionOneGraphValue,
          },
        ],
      },
      {
        name: sectionTwoGraphText,
        showInLegend: true,
        rounded: true,
        color: '#FEB546',
        data: [
          {
            radius: `${90}%`,
            innerRadius: `${80}%`,
            y: sectionTwoGraphValue,
          },
        ],
      },
      {
        name: sectionThreeGraphText,
        showInLegend: true,
        rounded: true,
        color: '#02CCC0',
        data: [
          {
            radius: `${70}%`,
            innerRadius: `${60}%`,
            y: sectionThreeGraphValue,
          },

        ],
      }
    ],
    legend: {
      enabled: true,
      borderWidth: 0,
      symbolRadius: 0,
      // itemMarginTop: -50, // Set margin to 0
      // // itemMarginBottom: -20, // Set margin to 0
      // margin: -100,
      // symbolPadding: 5,
      // padding: 80, // Set padding to 0
      // itemStyle: {
      //   width: 250,
      //   height: 300,
      // },

    },
    credits: {
      enabled: false, // Hide Highcharts credits
    },
  };

  let Headers = <div className={[style.solidgaugeTitleWrapper]}>
    <img src={headers?.url} alt="" />
    <div >
      {label || headers?.title}
    </div>
  </div>;

  return (
    <div className={[style.solidgaugeWrapper]}>
      {Headers}
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default KpiGauge;