import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './OverallPerformanceView.scss';
import BackIcon from '../../../../Assets/commonImg/back.svg';
import downArrow from '../../../../Assets/commonImg/select-dropdown-arrow.svg';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';

const assignments = [
    { subjectName: 'Anatomy', assignment: 50, assessment: 40, givenTask: 80, notes: 75, liveClass: 30, onsiteClass: 35, completedTask: 75 },
    { subjectName: 'Anatomy', assignment: 50, assessment: 40, givenTask: 80, notes: 75, liveClass: 30, onsiteClass: 35, completedTask: 75 },
    { subjectName: 'Anatomy', assignment: 50, assessment: 40, givenTask: 80, notes: 75, liveClass: 30, onsiteClass: 35, completedTask: 75 },
    { subjectName: 'Anatomy', assignment: 50, assessment: 40, givenTask: 80, notes: 75, liveClass: 30, onsiteClass: 35, completedTask: 75 },
    { subjectName: 'Anatomy', assignment: 50, assessment: 40, givenTask: 80, notes: 75, liveClass: 30, onsiteClass: 35, completedTask: 75 },
    { subjectName: 'Anatomy', assignment: 50, assessment: 40, givenTask: 80, notes: 75, liveClass: 30, onsiteClass: 35, completedTask: 75 },
    { subjectName: 'Anatomy', assignment: 50, assessment: 40, givenTask: 80, notes: 75, liveClass: 30, onsiteClass: 35, completedTask: 75 },
    { subjectName: 'Anatomy', assignment: 50, assessment: 40, givenTask: 80, notes: 75, liveClass: 30, onsiteClass: 35, completedTask: 75 },
];
export default function OverallPerformanceView({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/home/educatorPerformance', { replace: true });
    }
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const open = Boolean(anchorEl);
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    return (
        <div className='overall-performance-view-container'
            style={{
                width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
                marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
                transition: "0.5s",
                position: "absolute",
                top: "4rem",
                padding: "1%",

            }}>
            <div className='backBtn' onClick={goBack}><img src={BackIcon} width={'12px'} height={'13px'} marginTop={'5px'} /><div className='backText'>Back</div></div>
            <div className='overall-performance-view-card p-3'>
                <div className='overall-performance-view-tracker-title'>
                    Performance Tracker
                </div>
                <div className='course-section py-3 px-5'>
                    <div className='d-flex justify-content-center flex-wrap mt-3 gap-3' >
                        <div className='d-flex course-column position-relative'
                            aria-describedby={id} type="button" onClick={handleClick}>
                            <input className='custom-course-select-input' placeholder='Course   MBBS 1st Year' readOnly />
                            <div className='down-arrow'> <img src={downArrow} width={'12px'} height={'13px'} marginTop={'5px'} /></div>
                        </div>
                        <Popper id={id} open={open} anchorEl={anchorEl} style={{marginLeft:'100px', zIndex:'11111'}}>
                            <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                                <Paper sx={{ width: '100%', overflow: 'scroll' }}>
                                    <TableContainer>
                                        <Table sx={{
                                            background: '#FFFFFF',
                                            boxShadow: '0px 3px 6px #00000029',
                                            borderRadius: '0px 0px 5px 5px'
                                        }}>
                                            <TableHead>
                                                <TableRow className='table-row'>
                                                    <TableCell align="center">Subject Name</TableCell>
                                                    <TableCell align="center">Assignment</TableCell>
                                                    <TableCell align="center">Assessment</TableCell>
                                                    <TableCell align="center">Given Task</TableCell>
                                                    <TableCell align="center">Notes</TableCell>
                                                    <TableCell align="center">Live Class</TableCell>
                                                    <TableCell align="center">Onsite Class</TableCell>
                                                    <TableCell align="center">Completed Task</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {assignments
                                                    .map((row) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                <TableCell align="center">{row.subjectName}</TableCell>
                                                                <TableCell align="center">{row.assignment}</TableCell>
                                                                <TableCell align="center">{row.assessment}</TableCell>
                                                                <TableCell align="center">{row.givenTask}</TableCell>
                                                                <TableCell align="center">{row.notes}</TableCell>
                                                                <TableCell align="center">{row.liveClass}</TableCell>
                                                                <TableCell align="center">{row.onsiteClass}</TableCell>
                                                                <TableCell align="center">{row.completedTask}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Box>
                        </Popper>
                        <div className='d-flex course-column position-relative'>
                            <input className='custom-course-select-input' placeholder='Course   MBBS 1st Year' readOnly />
                            <div className='down-arrow'> <img src={downArrow} width={'12px'} height={'13px'} marginTop={'5px'} /></div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center flex-wrap my-3 gap-3' >
                        <div className='d-flex course-column position-relative'>
                            <input className='custom-course-select-input' placeholder='Course   MBBS 1st Year' readOnly />
                            <div className='down-arrow'> <img src={downArrow} width={'12px'} height={'13px'} marginTop={'5px'} /></div>
                        </div>
                        <div className='d-flex course-column position-relative'>
                            <input className='custom-course-select-input' placeholder='Course   MBBS 1st Year' readOnly />
                            <div className='down-arrow'> <img src={downArrow} width={'12px'} height={'13px'} marginTop={'5px'} /></div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center flex-wrap mb-3 gap-3' >
                        <div className='d-flex course-column position-relative'>
                            <input className='custom-course-select-input' placeholder='Course   MBBS 1st Year' readOnly />
                            <div className='down-arrow'> <img src={downArrow} width={'12px'} height={'13px'} marginTop={'5px'} /></div>
                        </div>
                        <div className='d-flex course-column position-relative'>
                            <input className='custom-course-select-input' placeholder='Course   MBBS 1st Year' readOnly />
                            <div className='down-arrow'> <img src={downArrow} width={'12px'} height={'13px'} marginTop={'5px'} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
