import React, { useState, useEffect } from "react";
import "./Report.scss";
import BackIcon from "../../Assets/commonImg/back.svg";
import { useNavigate } from "react-router-dom";
import AuditReport from "./AuditReport";
import SpecificReport from "./SpecificReport";
import TimeTableReport from "./TimeTableReport";
import LogbookReport from "./LogbookReport";
import ExportDocument from "./ExportDocument";
import downArrow from '../../Assets/commonImg/downArrow.svg';
import { SelectCollegeModal } from "../../Components/SelectCollegeModal/SelectCollegeModal";
import { getcollegeList } from "../../Services/memberService";
import CommonBootstrap from "../../Components/CommonBootstrapModal/CommonBootstrapModal";

const Report = ({ sideNavState, setSideNavState }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/home/report");
  };

  useEffect(() => {
    getCollegeList(0, 10);
  }, []);

  const [selectedReport, setSelectedReport] = useState("auditReport");
  const [selectedCollege, setSelectedCollege] = useState("auditReport");
  const [collegeData, setCollegeData] = useState([]);
  const [selectedCollegeDetails, setCollegeDetails] = useState({ collegeId: '', collegeName: '' });
  const handleSelectChange = (event) => {
    setSelectedReport(event.target.value);
  };

  const handleSelectCollegeChange = (event) => {
    console.log("🚀 ~ handleSelectCollegeChange ~ event:", event)
    setSelectedCollege(event.target.value);
  }

  const getCollegeList = async (offset, limit, searchText) => {
    try {
      const collegeData = await getcollegeList(offset, limit, 'displayName', searchText);
      console.log("🚀 ~ getCollegeList ~ collegeData:", collegeData)
      const collegeListData = collegeData.data.collegeList;
      console.log("🚀 ~ getCollegeList ~ collegeListData:", collegeListData)
      setCollegeData(collegeListData);
    } catch (error) {
      console.log("error in college list", error);
    }
  };

  const [showCollegeModal, setShowCollegeModal] = useState(false);
  const collegeModalColes = (e) => {
    setShowCollegeModal(false);
  }


  const onCollegeSelected = (e) => {
    if (typeof e === 'object') {
      setCollegeDetails(e);
    }
  }

  const collegeTable = <>
    <div>
      <SelectCollegeModal {...{ handleClose: collegeModalColes, setCollegeName: onCollegeSelected, isStyleUsed: false }} />
    </div>
  </>
  return (
    <div
      id="request-report"
      className={sideNavState ? "fullWidthOpenSideNav" : "halfWidthOpenSideNav"}
    >
      <div className="d-flex back-btn" onClick={goBack}>
        <img src={BackIcon} className="btn-icon" alt="Back" />
        <div className="backText">Back</div>
      </div>
      <div className="main-container row m-4">
        <div className="container my-3">
          <div className="row mb-3">
            <div className="col-md-3">
              <label onClick={() => setShowCollegeModal(true)}>Select College</label>
              <div className='position-relative'>
                <div className='position-relative'>
                  <input className="form-control custom-input w-100" type='text' onClick={() => setShowCollegeModal(true)} readOnly value={selectedCollegeDetails.collegeName} placeholder="Select College" />
                  <div className='down-arrow'> <img src={downArrow} width={'15px'} height={'13px'} /></div>
                </div>
              </div>
            </div>
            <div className="col-md-3 position-relative">
              <label>Select Report Type</label>
              <div className='position-relative'>
                <select
                  id="reports"
                  className="custom-input px-3"
                  onChange={handleSelectChange}
                >
                  <option value="auditReport">Audit Report</option>
                  <option value="specificReport">Specific Report</option>
                  <option value="exportDocument">Export Document</option>
                </select>
              </div>
              <div className='select-down-arrow'> <img src={downArrow} width={'15px'} height={'13px'} /></div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {selectedReport === "auditReport" && (
                <section className="auditReport">
                  <AuditReport college={selectedCollegeDetails} />
                </section>
              )}
              {selectedReport === "specificReport" && (
                <section className="specificReport">
                  <SpecificReport college={selectedCollegeDetails} />
                </section>
              )}
              {selectedReport === "exportDocument" && (
                <section className="exportDocument">
                  <ExportDocument college={selectedCollegeDetails} />
                </section>
              )}
              {selectedReport === "timeTableReport" && (
                <section className="timeTableReport">
                  <TimeTableReport college={selectedCollegeDetails} />
                </section>
              )}
              {selectedReport === "logbookReport" && (
                <section className="logbookReport">
                  <LogbookReport college={selectedCollegeDetails} />
                </section>
              )}
            </div>
          </div>
        </div>
      </div>
      <CommonBootstrap
        modalProps={{
          ...{
            show: showCollegeModal,
            onHide: collegeModalColes,
            size: 'md',
            scrollable: true,
            backdrop: "static",
            keyboard: false,
          }
        }}
        body={collegeTable}
      />
    </div>
  );
};

export default Report;
