import React, { useEffect } from "react";
import SearchField from "../searchField/searchField";
import CloseIcon from "@mui/icons-material/Close";

const CommonSearchFilter = ({ onSearch, handleClose, showSearchBy = true }) => {
    let options = [
        { id: 'fullName', displayText: 'Search By Name' },
        { id: 'mobile', displayText: 'Search By Phone Number' },
        { id: 'username', displayText: 'Search By User Name' },
        { id: 'primaryEmail', displayText: 'Search By Email' },
    ]
    const [searchKey, setSearchKey] = React.useState(options[0].id);
    const [filterForm, SetFilterForm] = React.useState({});

    useEffect(() => {
        if (filterForm && typeof filterForm === 'object') {
            onSearch(filterForm)
        }
    }, [filterForm])


    const { searchText } = filterForm;
    const handleSearchByName = (e) => {
        if (searchText) {
            SetFilterForm((previousValue) => { return { ...previousValue, searchText, searchKey: e } });
        }
        setSearchKey(e);
    };
    const placeHolderForSearchBy = options.find((x) => x.id === searchKey).displayText;
    const handleSearchButtonClick = (e) => {
        SetFilterForm((previousValue) => { return { ...previousValue, searchText: e, searchKey } })
    };
    return (
        <>
            <div className="row w-100">
                <div className="col-12 d-flex justify-content-end mb-2">
                    <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
                </div>
                <div className={showSearchBy ? "col-sm-12 col-md-6 col-lg-4" : "d-none"}>
                    <select className="custom-input ps-3" name="stateProvince" value={searchKey} onChange={(e) => handleSearchByName(e.target.value)}>
                        {
                            options.map((option, index) => <>
                                <option key={option?.id + index} value={option?.id}>{option?.displayText}</option>
                            </>)
                        }
                    </select>
                </div>
                <div className={showSearchBy ? "col-sm-12 col-md-6 col-lg-8" : "col-12"}>
                    <span className="position-relative">
                        <SearchField placeholderText={`${placeHolderForSearchBy}`} onSearchFun={handleSearchButtonClick} isCovered={true} />
                    </span>
                </div>
            </div>
        </>
    )
}
export default CommonSearchFilter;