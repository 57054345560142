import React from 'react';
import './MostViewedDigitalBook.scss';
import {useNavigate} from 'react-router-dom';
import pdfIcon from '../../../../Assets/commonImg/pdf.svg';
import viewIcon from '../../../../Assets/commonImg/view.svg';
import BackIcon from '../../../../Assets/commonImg/back.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import star from '../../../../Assets/commonImg/star.svg';
import Tooltip from '@mui/material/Tooltip';

function createCollegeData(bookTitle, educator, username, college, course, view, rating, grade) {
  return { bookTitle, educator, username, college, course, view, rating, grade };
}
const collegeRows = [
  createCollegeData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A+'),
  createCollegeData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A-'),
  createCollegeData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
  createCollegeData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'B+'),
  createCollegeData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
  createCollegeData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
  createCollegeData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
  createCollegeData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
  createCollegeData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
];

export default function MostViewedVideo({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
	const goBack = () => {
        navigate('/home/collegePerformance', {replace: true});
	}
  return (
    <div className='view-digital-book-container'
      style={{
        width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
        marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
        transition: "0.5s",
        position: "absolute",
        top: "4rem",
        padding: "1%",

      }}>
        <div className='backBtn' onClick={goBack}><img src={BackIcon} width={'12px'} height={'13px'} marginTop={'5px'} /><div className='backText'>Back</div></div>
      <div className='view-digital-book-card p-3'>
        <div className=''>
          <div className='view-digital-book-title'>
          Most Viewed Digital Book
          </div>
          <div className='view-digital-book-content p-2 my-2'>
            <div className='my-4 px-2'>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead  style={{borderBottom:'1px solid #707070'}}>
                    <TableRow sx={{
                      backgroundColor: '#EAF2FC',
                      font: 'normal normal normal 16px/21px Roboto',
                      color: '#000000',
                    }}>
                      <TableCell align="center" className='head-title'>Book Title</TableCell>
                      <TableCell align="center" className='head-title'>Educator</TableCell>
                      <TableCell align="center" className='head-title'>Username</TableCell>
                      <TableCell align="center" className='head-title'>College</TableCell>
                      <TableCell align="center" className='head-title'>Course</TableCell>
                      <TableCell align="center" className='head-title'>Views</TableCell>
                      <TableCell align="center" className='head-title'>Ratings</TableCell>
                      <TableCell align="center" className='head-title'>Grade</TableCell>
                      <TableCell align="center" className='head-title'>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{border:'1px solid #707070'}}>
                    {collegeRows.map((collegeRows) => (
                      <TableRow
                        key={collegeRows.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" align="center" className='table-data'><img src={pdfIcon} width={'21px'} height={'27px'} marginTop={'5px'} style={{marginRight:'10px'}} />{collegeRows.bookTitle}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{collegeRows.educator}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{collegeRows.username}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{collegeRows.college}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{collegeRows.course}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{collegeRows.view}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{collegeRows.rating}<img src={star} width={'15px'} height={'15px'} style={{marginLeft:'5px'}}/></TableCell>
                        <TableCell component="th" align="center" className='table-data'>{collegeRows.grade}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>
                        <Tooltip title="view">
                          <img src={viewIcon} width={'16px'} height={'8px'} marginTop={'5px'} />
                          </Tooltip>
                          </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
