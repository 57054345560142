import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CollegePerformance.scss';
import star from '../../../../Assets/commonImg/star.svg';
import SearchIcon from '../../../../Assets/commonImg/searchIcon.svg';
import videoIcon from '../../../../Assets/commonImg/videoImg.png';
import viewIcon from '../../../../Assets/commonImg/view.svg';
import pdfIcon from '../../../../Assets/commonImg/pdf.svg';
import { Box, Typography } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import PerformanceChart from '../CollegePerformance/PerformanceChart'
import StudentPassOutChart from './StudentPassOutChart';
import CollegeCotentDevelopedChart from './CollegeCotentDevelopedChart';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';


function createVideoData(videoTitle, educator, username, college, course, view, rating, grade) {
  return { videoTitle, educator, username, college, course, view, rating, grade };
}
const videoList = [
  createVideoData('Video Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A+'),
  createVideoData('Video Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A-'),
  createVideoData('Video Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
  createVideoData('Video Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
  createVideoData('Video Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
];

function createBookData(bookTitle, educator, username, college, course, view, rating, grade) {
  return { bookTitle, educator, username, college, course, view, rating, grade };
}
const digitBookList = [
  createBookData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A+'),
  createBookData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A-'),
  createBookData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
  createBookData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'B+'),
  createBookData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
  createBookData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
  createBookData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
  createBookData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
  createBookData('Book Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
];
const options = [
  { value: 'apple', label: 'Apple' },
  { value: 'banana', label: 'Banana' },
  { value: 'orange', label: 'Orange' },
  { value: 'pear', label: 'Pear' },
];

export default function CollegePerformance({ sideNavState, setSideNavState }) {
  const navigate = useNavigate();
  const viewVideos = () => {
    navigate('/home/collegePerformance/mostViewedVideo', { replace: true });
  }
  const viewDigitalBook = () => {
    navigate('/home/collegePerformance/mostViewedDigitalBook', { replace: true });
  }

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [value, setValue] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <div className='college-performance-container'
      style={{
        width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
        marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
        transition: "0.5s",
        position: "absolute",
        top: "4rem",
        padding: "1%",

      }}>
      <div className='college-performance-card p-3'>
        <div className='performance-tracker-title'>
          Performance Tracker
        </div>
        <div className='d-flex justify-content-start flex-wrap my-3' style={{ columnGap: '50px', rowGap: '10px' }}>
          <div className='d-flex'>
            <Box>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  style={{ height: '45px', width: '208px', maxWidth: '208px' }}
                  onChange={handleChange}
                >
                  <MenuItem selected>BGS College</MenuItem>

                  <div>
                    <div className='select-search position-relative'>
                      <input type='text' placeholder="Search College" style={{
                        height: '36px',
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: '0px 3px 6px #00000029',
                        maxWidth: '208px',
                        width: '100%',
                        border: 'none',
                        outline: 'unset',
                      }} />
                      <button className="search-btn" style={{ backgroundColor: '#32496B' }}><img src={SearchIcon} width={'18px'} height={'18px'} marginRight={'10px'} /></button>
                    </div>
                  </div>
                  <MenuItem value={20}>BGS College</MenuItem>
                  <MenuItem value={30}>BGS College</MenuItem>
                  <MenuItem value={30}>BGS College</MenuItem>
                  <MenuItem value={30}>BGS College</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className='d-flex'>
            <Box>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  style={{ height: '45px', width: '208px', maxWidth: '208px' }}
                  onChange={handleChange}
                >
                  <div>
                    <div className='select-search position-relative'>
                      <input type='text' placeholder="Search College" style={{
                        height: '36px',
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: '0px 3px 6px #00000029',
                        maxWidth: '208px',
                        width: '100%',
                        border: 'none',
                        outline: 'unset',
                      }} />
                      <button className="search-btn" style={{ backgroundColor: '#32496B' }}><img src={SearchIcon} width={'18px'} height={'18px'} marginRight={'10px'} /></button>
                    </div>
                  </div>
                  <MenuItem value={20}>BGS College</MenuItem>
                  <MenuItem value={30}>BGS College</MenuItem>
                  <MenuItem value={30}>BGS College</MenuItem>
                  <MenuItem value={30}>BGS College</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className='d-flex'>
            <Box>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  style={{ height: '45px', width: '208px', maxWidth: '208px' }}
                  onChange={handleChange}
                >
                  <MenuItem value={20}>Monthly</MenuItem>
                  <MenuItem value={30}>Weekly</MenuItem>
                  <MenuItem value={30}>Daily</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
        <div className=''>
          <div className='row'>
            <div className='chartBox'>
              <div className='row'>
                <Col className='col-xs-12 col-sm-12 col-lg-12 col-xl-4 p-0' style={{ borderRight: '0.5px solid #707070' }}>
                  <div className='d-flex justify-content-center align-items-center chart-title' style={{ borderRight: '0.5px solid #707070' }}>
                    Courses Completion Progress
                  </div>
                  <div>
                    <PerformanceChart />
                  </div>
                </Col>
                <Col className='col-xs-12 col-sm-12 col-lg-12 col-xl-4 p-0' style={{ borderRight: '0.5px solid #707070' }}>
                  <div className='d-flex justify-content-center align-items-center chart-title' style={{ borderRight: '0.5px solid #707070' }}>
                    Student Passout
                  </div>
                  <div style={{ width: '222px', height: '222px' }}>
                    <StudentPassOutChart />
                  </div>
                </Col>
                <Col className='col-xs-12 col-sm-12 col-lg-12 col-xl-4 p-0'>
                  <div className='d-flex justify-content-center align-items-center chart-title'>
                    College Content Developed
                  </div>
                  <div style={{ width: '222px', height: '222px', }}>
                    <CollegeCotentDevelopedChart />
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </div>
        <div className='my-3'>
          <div className='performance-tracker-title'>
            Most Viewed videos
          </div>
          <div className='video-content p-2 my-2'>
            <div className='row px-2 my-3'>
              <div className='col-lg-5 col-12 mt-2'>
                <div className="px-0">
                  <div className='video-search position-relative'>
                    <input className="custom-select-input px-3 mx-0" type='text' placeholder="Search by College name" />
                    <button className="search-btn"><img src={SearchIcon} width={'18px'} height={'18px'} marginRight={'10px'} />Search</button>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-12 mt-2 select-section'>
                <Box>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      style={{ height: '45px', width: '100%', maxWidth: '208px' }}
                      onChange={handleChange}
                    >
                      {/* <MenuItem value={10}> */}
                      <div >
                        <div className='select-search position-relative'>
                          <input type='text' placeholder="Search College" style={{
                            height: '36px',
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            boxShadow: '0px 3px 6px #00000029',
                            maxWidth: '208px',
                            width: '100%',
                            border: 'none',
                            outline: 'unset',
                          }} />
                          <button className="search-btn" style={{ backgroundColor: '#32496B' }}><img src={SearchIcon} width={'18px'} height={'18px'} marginRight={'10px'} /></button>
                        </div>
                      </div>
                      {/* </MenuItem> */}
                      <MenuItem value={20}>BGS College</MenuItem>
                      <MenuItem value={30}>BGS College</MenuItem>
                      <MenuItem value={30}>BGS College</MenuItem>
                      <MenuItem value={30}>BGS College</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className='col-lg-4 col-12'>
              </div>
            </div>
            <div className='my-4 px-2'>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{
                      backgroundColor: '#EAF2FC',
                      font: 'normal normal normal 16px/21px Roboto',
                      color: '#000000',
                    }}>
                      <TableCell align="center" className='head-title'>Video Title</TableCell>
                      <TableCell align="center" className='head-title'>Educator</TableCell>
                      <TableCell align="center" className='head-title'>Username</TableCell>
                      <TableCell align="center" className='head-title'>College</TableCell>
                      <TableCell align="center" className='head-title'>Course</TableCell>
                      <TableCell align="center" className='head-title'>Views</TableCell>
                      <TableCell align="center" className='head-title'>Ratings</TableCell>
                      <TableCell align="center" className='head-title'>Grade</TableCell>
                      <TableCell align="center" className='head-title'>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {videoList.map((videoList) => (
                      <TableRow

                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" align="center" className='table-data'><img src={videoIcon} width={'44px'} height={'30px'} marginTop={'5px'} style={{ marginRight: '10px' }} />{videoList.videoTitle}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{videoList.educator}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{videoList.username}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{videoList.college}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{videoList.course}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{videoList.view}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{videoList.rating}<img src={star} width={'15px'} height={'15px'} style={{marginLeft:'5px'}}/></TableCell>
                        <TableCell component="th" align="center" className='table-data'>{videoList.grade}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>
                        <Tooltip title="view">
                          <img src={viewIcon} width={'16px'} height={'8px'} marginTop={'5px'} />
                          </Tooltip>
                          </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className='d-flex justify-content-end my-2'>
                <div onClick={viewVideos} style={{ font: 'normal normal bold 14px/19px Roboto', color: '#2580EC', cursor: 'pointer' }}> View More</div>
              </div>
            </div>
          </div>
        </div>
        <div className='my-3'>
          <div className='performance-tracker-title'>
            Most Viewed Digital Book
          </div>
          <div className='video-content p-2 my-2'>
            <div className='row px-2 my-3'>
              <div className='col-lg-5 col-12 mt-2'>
                <div className="px-0">
                  <div className='video-search position-relative'>
                    <input className="custom-select-input px-3 mx-0" type='text' placeholder="Search by College name" />
                    <button className="search-btn"><img src={SearchIcon} width={'18px'} height={'18px'} marginRight={'10px'} />Search</button>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-12 mt-2'>
                <div className='position-relative'>
                  <Box>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        style={{ height: '45px', width: '208px', maxWidth: '208px' }}
                        onChange={handleChange}
                      >
                        <div>
                          <div className='select-search position-relative'>
                            <input type='text' placeholder="Search College" style={{
                              height: '36px',
                              background: '#FFFFFF 0% 0% no-repeat padding-box',
                              boxShadow: '0px 3px 6px #00000029',
                              maxWidth: '208px',
                              border: 'none',
                              outline: 'unset',
                            }} />
                            <button className="search-btn" style={{ backgroundColor: '#32496B' }}><img src={SearchIcon} width={'18px'} height={'18px'} marginRight={'10px'} /></button>
                          </div>
                        </div>
                        <MenuItem value={20}>BGS College</MenuItem>
                        <MenuItem value={30}>BGS College</MenuItem>
                        <MenuItem value={30}>BGS College</MenuItem>
                        <MenuItem value={30}>BGS College</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </div>
              <div className='col-lg-4 col-12'>
              </div>
            </div>
            <div className='my-4 px-2'>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{
                      backgroundColor: '#EAF2FC',
                      font: 'normal normal normal 16px/21px Roboto',
                      color: '#000000',
                    }}>
                      <TableCell align="center" className='head-title'>Book Title</TableCell>
                      <TableCell align="center" className='head-title'>Educator</TableCell>
                      <TableCell align="center" className='head-title'>Username</TableCell>
                      <TableCell align="center" className='head-title'>College</TableCell>
                      <TableCell align="center" className='head-title'>Course</TableCell>
                      <TableCell align="center" className='head-title'>Views</TableCell>
                      <TableCell align="center" className='head-title'>Ratings</TableCell>
                      <TableCell align="center" className='head-title'>Grade</TableCell>
                      <TableCell align="center" className='head-title'>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {digitBookList.map((digitBookList) => (
                      <TableRow
                        key={digitBookList.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" align="center" className='table-data'><img src={pdfIcon} width={'21px'} height={'27px'} marginTop={'5px'} style={{ marginRight: '10px' }} />{digitBookList.bookTitle}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{digitBookList.educator}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{digitBookList.username}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{digitBookList.college}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{digitBookList.course}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{digitBookList.view}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{digitBookList.rating}<img src={star} width={'15px'} height={'15px'} style={{marginLeft:'5px'}}/></TableCell>
                        <TableCell component="th" align="center" className='table-data'>{digitBookList.grade}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>
                        <Tooltip title="view">
                          <img src={viewIcon} width={'16px'} height={'8px'} marginTop={'5px'} />
                          </Tooltip>
                          </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className='d-flex justify-content-end my-2'>
                <div onClick={viewDigitalBook} style={{ font: 'normal normal bold 14px/19px Roboto', color: '#2580EC', cursor: 'pointer' }}> View More</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
