import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ComplaintStatus.scss';
import BackIcon from '../../../../Assets/commonImg/back.svg';
import ticket from '../../../../Assets/commonImg/ticket.svg';
import eyeIcon from '../../../../Assets/commonImg/eye.svg';
import addMore from '../../../../Assets/commonImg/plus-circle.svg';
import imageIcon from '../../../../Assets/commonImg/image-con.png';
import Calendar from '../../../../Assets/commonImg/calendar.svg';
import Swal from 'sweetalert2';

export default function ComplaintStatus({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/home/grievance', { replace: true });
    }
    const goHistory = () => {
        navigate('history', { replace: true });
    }
    const showAlert = () => {
        Swal.fire(
            '',
            'Comment Sent!',
            ''
          )
      };
    return (
        <div className='complaint-status-container'
            style={{
                width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
                marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
                transition: "0.5s",
                position: "absolute",
                top: "4rem",
                padding: "1%",

            }}>
            <div className='backBtn' onClick={goBack}><img src={BackIcon} width={'12px'} height={'13px'} marginTop={'5px'} /><div className='backText'>Back</div></div>
            <div className='complaint-status-card mt-2'>
                <div className='d-flex flex-wrap justify-content-between status-details gap-3 p-3'>
                    <div className='d-flex align-items-start flex-column'>
                        <div className='cid-number mb-2'><img src={ticket} width={'25px'} height={'25px'} style={{ marginRight: '10px' }} />CID : MWZ#234</div>
                        <div className='status'>Ragging In College</div>
                    </div>
                    <div className='d-flex align-items-center flex-column'>
                        <div className='status mb-2'>Status</div>
                        <div className='open-button '>Open</div>
                        {/* <div className='closed-button '>Closed</div> */}
                    </div>
                    <div className='d-flex justify-content-center align-items-end'>
                        <button className='history-btn' onClick={goHistory}>History</button>
                    </div>
                </div>
                <div className='row status-body'>
                    <div className='col-lg-8 col-12'>
                        <div className='my-3 p-3'>
                            <div className='heading '>University Committee Member</div>
                            <p className='status-description mt-3'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s,
                                when an unknown printer took a galley of type and scrambled it to make a type
                                specimen book.Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry’s standard dummy text ever since
                                the 1500s, when an unknown printer took a galley of type and scrambled it to
                                make a type specimen book.Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been the industry’s standard dummy
                                text ever since the 1500s, when an unknown printer took a galley of type and
                                scrambled it to make a type specimen book.Lorem Ipsum is simply dummy text of the
                                printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy
                                text ever since the 1500s, when an unknown printer took a galley of type and scrambled
                                it to make a type specimen book.</p>
                        </div>
                        <div className='heading mb-2 px-3'>Attached Files</div>
                        <div className='d-flex justify-content-between align-items-center attached-show-file mx-3'>
                            <div className='d-flex align-items-center mx-2'>
                                <div className=''><img src={imageIcon} width={'16px'} height={'16px'} style={{ marginRight: '10px' }} /></div>
                                <div>Screenshot001.JPEG</div>
                            </div>
                            <div className='eye-icon'><img src={eyeIcon} width={'27px'} height={'27px'} /></div>
                        </div>
                        <hr />
                        <div className='px-3 mb-2'>
                            <div className='heading mb-2'>Add Comment</div>
                            <textarea className='comment-description' />
                        </div>
                        <div className='d-flex align-items-center px-3'>
                            <div><img src={addMore} width={'23px'} height={'23px'} /></div>
                            <div className='add-more-file'>Add more files</div>
                        </div>
                        <div className='send-btn m-3' onClick={showAlert}>Send</div>
                    </div>
                    <div className='col-lg-4 col-12'>
                       <div className='ticket-status m-3 px-3'>
                       <div className='ticket-heading mt-1'>Ticket Status</div>
                        <div className='mt-3'>
                            <select className='status-select-input'>
                                <option>In Progress</option>
                                <option>Closed</option>
                                <option>Open</option>
                            </select>
                        </div>
                        <div className='ticket-heading mt-4'>Assigned To</div>
                        <div className='mt-2'>
                            <select className='status-select-input'>
                                <option>Mr. Jayesh Gupta</option>
                                <option>Ms. Jyoti Ramesh</option>
                                <option>Mr. Karan Sharma</option>
                            </select>
                        </div>
                        <div className='ticket-heading mt-4'>Expected Resolution Date</div>
                        <div className='mt-2 position-relative'>
                            <input type='date' className='status-select-input' />
                            <div style={{position:'absolute', top:'15%', right:'5%'}}><img src={Calendar} width={'30px'} height={'30px'} marginTop={'5px'} /></div>
                        </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
