import { useEffect, useState } from "react";
import './SearchByEntityName.scss';
import SearchField from "../searchField/searchField";

export default function SearchByEntityName({ sendSearchEntityData, isRegistredMember = false }) {

    let options = [
        { id: 'fullName', displayText: 'Search By Name' },
        { id: 'username', displayText: 'Search By User Name' },
        { id: 'mobile', displayText: 'Search By Phone Number' },
        { id: 'primaryEmail', displayText: 'Search By Email' },
    ]

    /** If member is registered member, them username option 
     * should be eliminated from filter search option
     * isRegistredMember is true is member is registered member */
    if(isRegistredMember && options) {
        options?.splice(options?.findIndex(({id}) => id === "username"), 1);
    }
    

    const [filterForm, SetFilterForm] = useState({ searchKey: 'fullName' });
    const [searchKey, setSearchKey] = useState(options[0].id);
    const { searchText } = filterForm;

    const placeHolderForSearchBy = options.find((x) => x.id === searchKey).displayText;

    const handleSearchByName = (e) => {
        if (searchText) {
            SetFilterForm((previousValue) => { return { ...previousValue, searchText, searchKey: e } });
        }
        setSearchKey(e);
    };
    const handleSearchButtonClick = (e) => {
        SetFilterForm((previousValue) => { return { ...previousValue, searchText: e, searchKey } })
    };

    useEffect(() => {
        if (filterForm) {
            if (typeof sendSearchEntityData === 'function') {
                sendSearchEntityData(filterForm);
            }
        }
    }, [filterForm])

    return (<>
        <div className="col-md-4 col-lg-3 col-xl-3 col-sm-12 col-12">
            <select className="custom-input ps-3" name="stateProvince" value={searchKey} onChange={(e) => handleSearchByName(e.target.value)}>
                {
                    options.map((option, index) => <>
                        <option key={option?.id + index} value={option?.id}>{option?.displayText}</option>
                    </>)
                }
            </select>
        </div>
        <div className="col-md-6 col-lg-3 col-xl-4 col-sm-12 col-12">
            <span className="position-relative">
                <SearchField placeholderText={`${placeHolderForSearchBy}`} onSearchFun={handleSearchButtonClick} isCovered={true} />
            </span>
        </div>
        {/* <div className="col-md-2 col-lg-2 col-xl-2 col-sm-12 col-12 clear-btn-card">
           <button className="clear-btn">Clear</button>
        </div> */}
    </>)
}