import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AssignTask.scss';
import BackIcon from '../../../../Assets/commonImg/back.svg';
import facultyProfile from '../../../../Assets/commonImg/faculty-profile.png';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Swal from 'sweetalert2';

const assignments = [
    { subjectName: 'Anatomy', assignment: 50, assessment: 40, givenTask: 80, notes: 75, liveClass: 30, onsiteClass: 35, completedTask: 75 },
    { subjectName: 'Anatomy', assignment: 50, assessment: 40, givenTask: 80, notes: 75, liveClass: 30, onsiteClass: 35, completedTask: 75 },
    { subjectName: 'Anatomy', assignment: 50, assessment: 40, givenTask: 80, notes: 75, liveClass: 30, onsiteClass: 35, completedTask: 75 },
    { subjectName: 'Anatomy', assignment: 50, assessment: 40, givenTask: 80, notes: 75, liveClass: 30, onsiteClass: 35, completedTask: 75 },
    { subjectName: 'Anatomy', assignment: 50, assessment: 40, givenTask: 80, notes: 75, liveClass: 30, onsiteClass: 35, completedTask: 75 },
    { subjectName: 'Anatomy', assignment: 50, assessment: 40, givenTask: 80, notes: 75, liveClass: 30, onsiteClass: 35, completedTask: 75 },
    { subjectName: 'Anatomy', assignment: 50, assessment: 40, givenTask: 80, notes: 75, liveClass: 30, onsiteClass: 35, completedTask: 75 },
    { subjectName: 'Anatomy', assignment: 50, assessment: 40, givenTask: 80, notes: 75, liveClass: 30, onsiteClass: 35, completedTask: 75 },
];
export default function AssignTask({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/home/task', { replace: true });
    }

    const [showDiv, setShowDiv] = useState(false);

    const handleButtonClick = () => {
        setShowDiv(!showDiv);
    };

    const [showSelected, setSelectedDiv] = useState(false);

    const showSelectedClick = () => {
        setSelectedDiv(!showSelected);
    };
    const showAlert = () => {
        Swal.fire({
            title: 'Task Assigned successful',
            //   html: '<img src="{{assignSuccess}}" alt="My Image">',
            //   confirmButtonText: 'OK'
        });
    };
    return (
        <div className='assign-task-container'
            style={{
                width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
                marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
                transition: "0.5s",
                position: "absolute",
                top: "4rem",
                padding: "1%",

            }}>
            <div className='backBtn' onClick={goBack}><img src={BackIcon} width={'12px'} height={'13px'} marginTop={'5px'} /><div className='backText'>Back</div></div>
            <div className='assign-task-card mx-3 p-2 mt-2'>
                <div className='row faculty-details mx-1 py-2'>
                    <div className='col-5'>Faculty Name</div>
                    <div className='col-7'>Dr. Ashok Kumar</div>
                </div>
                <div className='row faculty-details mx-1 pb-2 mt-2'>
                    <div className='col-xl-3 col-12 mt-3'>
                        <p className="input-label mb-2" >Course</p>
                        <select className="custom-select-input">
                            <option></option>
                            <option>Course</option>
                            <option>Bgs Course</option>
                            <option>Course name</option>
                            <option>Course name</option>
                        </select>
                    </div>
                    <div className='col-xl-9 col-12 position-relative mt-3'>
                        <div className='selectedCourseField position-relative'></div>
                        <div className='d-flex justify-content-center align-items-center clearBtn'>
                            Clear
                        </div>
                    </div>
                </div>
                <div className='d-flex  gap-4'>
                    <div className='subject-content mt-3'>
                        <div className='d-flex justify-content-start align-items-center flex-wrap gap-4 subject-head-section px-3'>
                            <div className='subject-text' onClick={showSelectedClick}>Subject</div>
                            <div className='subject-search position-relative'>
                                <input className="custom-search-input px-3 mx-0" type='text' />
                                <button className="search-btn">Search</button>
                            </div>
                            {showSelected &&
                                <div>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox defaultChecked size="small" />} label="Select all" />
                                    </FormGroup>
                                </div>
                            }
                        </div>
                        {showSelected &&
                            <div className='p-2'>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked size="small" />} label="Select all" />
                                </FormGroup>
                            </div>
                        }
                    </div>
                    {showSelected &&
                        <div className='subjected-content mt-3'>
                            <div className='d-flex justify-content-center align-items-center flex-wrap gap-4 subject-head-section px-3 py-1'>
                                <div className='subject-text'>Selected</div>
                            </div>
                            <div className='p-2'>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked size="small" />} label="Select all" />
                                </FormGroup>
                            </div>
                        </div>
                    }
                </div>
                <div className='row mb-4'>
                    <div className='col-lg-3 col-md-6 col-12 mt-3'>
                        <p className='date-label mb-0'>Start Date</p>
                        <input type='date' className='date-input-field' />
                    </div>
                    <div className='col-lg-3 col-md-6 col-12 mt-3'>
                        <p className='date-label mb-0'>End Date</p>
                        <input type='date' className='date-input-field' />
                    </div>
                    <div className='col-lg-3 d-none'></div>
                    <div className='col-lg-3 d-none'></div>
                </div>
            </div>
            {showSelected && <div className='d-flex justify-content-center mt-3'>
                <button className='preview-btn' onClick={handleButtonClick}>Preview</button>
            </div>}
            {showDiv &&
                <div>
                    <div className='faculty-content my-3 mx-3' style={{ boxShadow: '0px 3px 6px #00000029', border: '0.5px solid #707070', borderRadius: '8px'}}>
                        <div className='px-4 py-2 subject-head-section'>
                            <div className='row'>
                            <div className='faculty-title col-md-2 col-12'>Faculty Details</div>
                            <div className='faculty-title col-md-2 col-12'>Course</div>
                            <div className='faculty-title col-md-4 col-12'>Subject</div>
                            <div className='faculty-title col-md-2 col-12'>Start Date</div>
                            <div className='faculty-title col-md-2 col-12'>End Date</div>
                            </div>
                        </div>
                        <div className='row align-items-center px-4 py-2'>
                            <div className='col-md-2 col-12'>
                                <div className='my-3'><img src={facultyProfile} width={'108px'} height={'108px'} marginTop={'5px'} /></div>
                                <div className='my-2 faculty-sub-title'>Dr. Ashok Kumar</div>
                            </div>
                            <div className='faculty-sub-title col-md-2 col-12'>BGS Course</div>
                            <div className='col-md-4 col-12'>
                                <div className='row'>
                                <div className='col-6'>
                                    <div className='suject-topics'>Anatomy</div>
                                    <div className='suject-topics'>Heart</div>
                                    <div className='suject-topics'>Lungs</div>
                                    <div className='suject-topics'>Liver</div>
                                    <div className='suject-topics'>Liver</div>
                                    <div className='suject-topics'>Liver</div>
                                </div>
                                <div className='col-6'>
                                <div className='suject-topics'>Anatomy</div>
                                    <div className='suject-topics'>Heart</div>
                                    <div className='suject-topics'>Lungs</div>
                                    <div className='suject-topics'>Liver</div>
                                    <div className='suject-topics'>Liver</div>
                                    <div className='suject-topics'>Liver</div>
                                </div>
                                </div>
                            </div>
                            <div className='faculty-sub-title col-md-2 col-12'>12th May 2020</div>
                            <div className='faculty-sub-title col-md-2 col-12'>18th May 2020</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center flex-wrap gap-4'>
                        <div className='cancel-btn'>Cancel</div>
                        <div className='assigntassk-btn' onClick={showAlert}>Assign Task</div>
                    </div>
                </div>
            }
        </div>
    )
}
