import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { verifyCurrentUserAttributeSubmit, getCurrentSession } from '../../../Services/AuthService';
import './VerificationCode.scss';
import blueCurve from '../../../Assets/login/blueCurve.svg';
import appleStoreImg from '../../../Assets/login/app.svg'
import playStoreImg from '../../../Assets/login/play.png'
import mobileImg from '../../../Assets/login/mobile.png'
import resetPasswordImg from '../../../Assets/login/resetPasswordKey.png'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function ForgotPassword() {

    const [otp, setOtp] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    const [snackbarState, setSnackbarState] = React.useState({
        vertical: 'top',
        horizontal: 'right',
        message: '',
        alertSeverity: 'success',
        open: false
    });

    const { vertical, horizontal, open, message, alertSeverity } = snackbarState;

    const otpHandler = (e) => {
        setOtp(e.target.value);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarState({ ...snackbarState, open: false, message: '' });
    };

    const submitOtpHandler = async () => {
        try {
            const isVerified = await verifyCurrentUserAttributeSubmit(location.state.attribute, otp);
            if (isVerified) {
                getCurrentSession();
                navigate('../home/dashboard');
            }
        } catch (error) {
            console.log("🚀 ~ submitOtpHandler ~ error:", error)
        }
    };

    return (
        <>
            <div className='container-fluid forgot-password-container'>
                <div className='row my-0'>
                    <div className="col-md-1">
                        <img className='blue-curve-img' src={blueCurve} alt='' />
                    </div>
                    <div className="col-md-4">
                        <div className='login-form-container'>
                            <img src={resetPasswordImg} alt='' className='reset-password-circle'></img>
                            <p className="main-text d-flex justify-content-center mt-4"><span className="forgotText">Verify</span>&nbsp;&nbsp;<span className="passwordText">OTP</span></p>
                            <p className="enterUserNameText mt-5">Please enter OTP</p>
                            <input className='input-block' type='text' placeholder='OTP' value={otp || ''} onChange={otpHandler}></input>
                            <div className='text-center'>
                                <input type="submit" value="Verify" className="btn btn-reset" id="login_btn" onClick={submitOtpHandler} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 welcome-container">
                        <div className="container content ">
                            <div className="lms-content">
                                <p className='welcome-text'>Welcome to ZLIPP</p>
                                <p className='welcome-des'>India's leading online learning platform for all medical aspirants. Download the application by
                                    clicking on the link below and learn anytime, anywhere.</p><br />
                                <div className="row p-2" style={{ gap: '1%' }}>
                                    <div className="col-lg-4"></div>
                                    <div className="col-lg-3 col-sm-12 d-flex justify-content-center">
                                        <a className='pr-2' href="https://apps.apple.com/in/app/zlipp/id6471843198" target="_blank">
                                            <img src={appleStoreImg} alt='' className="float-right apple" />
                                        </a>
                                    </div>
                                    <div className="col-lg-3 col-sm-12 d-flex justify-content-center">
                                        <a href="https://play.google.com/store/apps/details?id=com.zlipp&pli=1" target="_blank">
                                            <img src={playStoreImg} alt='' />
                                        </a>
                                    </div>
                                </div>
                                <img src={mobileImg} className="mobile-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
            >
                <Alert
                    onClose={handleClose}
                    severity={alertSeverity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
};
