import React, { useEffect, useState } from "react";
import "./ViewCollegeAdmin.scss";
import { Table, Paper, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import viewCollegeIcon from "./../../../../Assets/memberImg/view.svg";
import editCollegeIcon from "./../../../../Assets/memberImg/edit.svg";
import deleteCollegeIcon from "./../../../../Assets/memberImg/delete.png";
import resetPasswordICon from "./../../../../Assets/common/resetPassword.svg";
import Swal from "sweetalert2";
import actionBtnIcon from "./../../../../Assets/common/actionBtn.svg";
import Dropdown from "react-bootstrap/Dropdown";
import NavigateBack from "../../../CommonScreens/NavigateBack/NavigateBack";
import { getCollegeAdmin } from "../../../../Services/CollegeService";
import noDataFoundImg from "../../../../Assets/memberImg/noDataFound.svg";
import { deleteCollegeAdmin } from "../../../../Services/CollegeService";
import { useNavigate } from "react-router-dom";
import downArrow from "../../../../Assets/commonImg/select-dropdown-arrow.svg";
import { SelectCollegeModal } from "../../../../Components/SelectCollegeModal/SelectCollegeModal";
import Modal from "@mui/material/Modal";
import MembersContainer from "../../../../Components/MembersContainer/MembersContainer";
import SearchByEntityName from '../../../../Components/SearchByEntityName/SearchByEntityName';

export default function ViewCollegeAdmin({ sideNavState, setSideNavState }) {
	useEffect(() => {
		getCollegeAdminData(0, 10, null, null);
	}, []);
	const navigate = useNavigate();
	const [collegeAdminData, setCollegeAdminData] = useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [count, setTotalCollegeAdmin] = React.useState(0);
	const [isLoading, setLoading] = React.useState(false);
	const [openCollege, setCollegeOpen] = React.useState(false);
	const handleOpen = () => setCollegeOpen(true);
	const handleClose = () => setCollegeOpen(false);
	const [collegeDetails, setCollegeDetails] = React.useState({});
	const [filter, setFilter] = React.useState({});
	const getCollegeAdminData = async (offset, limit, searchKey, searchText) => {
		try {
			setLoading(true);
			const collegeAdminData = await getCollegeAdmin(offset, limit, searchKey, searchText);
			setLoading(false);
			console.log("🚀 ~ getMembreCollegeList ~ collegeList:", collegeAdminData);
			const collegeAdminListData = collegeAdminData.data.adminList;
			const count = collegeAdminData.data.totalRecords;
			setCollegeAdminData(collegeAdminListData);
			setTotalCollegeAdmin(count);
		} catch (error) {
			setLoading(false);
			console.log("🚀 ~ getMembreCollegeList ~ collegeList", error);
		}
	};

	const handleView = (id, type) => {
		navigate("/home/collegeAdmin/" + id, { state: { type }, replace: true });
	};

	const handleEdit = (id, type) => {
		navigate("/home/collegeAdmin/" + id, { state: { type }, replace: true });
	};

	const handleCollegeDetails = (collegeDetails) => {
		if (typeof collegeDetails === "object") {
			setCollegeDetails(collegeDetails);
			setRowsPerPage(10);
			setPage(0);
			setFilter({
				searchKey: "collegeId",
				searchText: collegeDetails?.collegeId,
			});
			getCollegeAdminData(0, 10, "collegeId", collegeDetails?.collegeId);
		}
	};

	const handleDeleteCollegeAdmin = (id) => {
		console.log("🚀 ~ handleDelete ~ id:", id);
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then((result) => {
			if (result.isConfirmed) {
				deleteCollegeAdmin(id)
					.then((resp) => {
						console.log(`Delete clicked for ID ${id}`);
						Swal.fire({
							title: "Deleted!",
							text: "Your file has been deleted.",
							icon: "success",
						});
						getCollegeAdminData(0, 10);
					})
					.catch((error) => {
						console.log("🚀 ~ handleDeleteCollegeAdmin ~ error:", error);
					})
					.finally(() => {});
			}
		});
	};
	const onResetClick = (id)=>{
		console.log(id);
		Swal.fire({
			title: "Are you sure?",
			text: "You want to reset the password",
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: "#32496B",
			confirmButtonText: "Reset",
			cancelButtonText: "Cancel",
		})
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		const { searchKey, searchText } = filter || {};
		getCollegeAdminData(newPage * rowsPerPage, rowsPerPage, searchKey, searchText);
	};
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		const { searchKey, searchText } = filter || {};
		getCollegeAdminData(0, parseInt(event.target.value, 10), searchKey, searchText);
	};

	const handleSearchEntityChange = (data) => {
		let offset = 0;
		let limit = 10; 
		setPage(0);
		getCollegeAdminData(offset, limit, data.searchKey, data.searchText)
	 }

	const filters = (
		<div className="pt-3">
			<div className="row">
				<div className="col-md-3 col-12">
					<div className="position-relative">
						<input className="custom-input" type="text" placeholder="Select College" onClick={handleOpen} readOnly value={collegeDetails?.collegeName} />
						<div className="down-arrow">
							{" "}
							<img src={downArrow} width={"12px"} height={"13px"} />
						</div>
					</div>
				</div>
				<SearchByEntityName sendSearchEntityData={handleSearchEntityChange}></SearchByEntityName>
			</div>
		</div>
	);

	return (
		<div className={"view-college-container " + (sideNavState ? "fullWidthOpenSideNav" : "halfWidthOpenSideNav")}>
			<NavigateBack />
			<MembersContainer>
					{filters}
				<div className="">
					{collegeAdminData && collegeAdminData.length > 0 ? (
						<div >
							<TableContainer component={Paper} sx={{ maxHeight: " 70vh" }}>
								<Table stickyHeader sx={{ minWidth: 800, borderCollapse: "separate", borderSpacing: "0 10px" }} aria-label="simple table">
									<TableHead>
										<TableRow className="TableRow">
											<TableCell className="tableHeaders" align="center">USN</TableCell>
											<TableCell className="tableHeaders" align="center">Admin name</TableCell>
											<TableCell className="tableHeaders" align="center">Email</TableCell>
											<TableCell className="tableHeaders" align="center">Contact</TableCell>
											<TableCell className="tableHeaders" align="center">Username</TableCell>
											<TableCell className="tableHeaders" align="center">College</TableCell>
											<TableCell className="tableHeaders" align="center">Action</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{collegeAdminData.map((row, index) => {
											return (
												<TableRow className="TableRow" key={row._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover role="checkbox" tabIndex={-1}>
													<TableCell>{page * rowsPerPage + index + 1}</TableCell>
													<TableCell align="center">{row.fullName}</TableCell>
													<TableCell align="center">{row.primaryEmail}</TableCell>
													<TableCell align="center">{row.mobile}</TableCell>
													<TableCell align="center">{row.username}</TableCell>
													<TableCell align="center">{row.collegeName}</TableCell>
													<TableCell align="center">
														<Dropdown>
															<Dropdown.Toggle id="dropdown-basic" className="custom-dropdown">
																<img src={actionBtnIcon} alt="" width={"28px"} className="actionBtnIcon" />
															</Dropdown.Toggle>

															<Dropdown.Menu>
																<Dropdown.Item onClick={() => handleView(row._id, { type: "view" })}>
																	<button className="actionBtn">
																		<img src={viewCollegeIcon} alt="" width={"25px"} /> View
																	</button>
																</Dropdown.Item>
																<Dropdown.Item onClick={() => handleEdit(row._id, { type: "edit" })}>
																	<button className="actionBtn">
																		<img src={editCollegeIcon} alt="" width={"19px"} /> Edit Profile
																	</button>
																</Dropdown.Item>
																<Dropdown.Item onClick={() => handleDeleteCollegeAdmin(row._id)}>
																	<button className="actionBtn">
																		<img src={deleteCollegeIcon} alt="" width={"22px"} /> Delete
																	</button>
																</Dropdown.Item>

																<Dropdown.Item onClick={() => onResetClick(row.id)}>
																	<button className="actionBtn">
																		<img src={resetPasswordICon} alt="" width={"22px"} /> Reset Password
																	</button>
																</Dropdown.Item>
															</Dropdown.Menu>
															<div></div>
														</Dropdown>
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination component="div" count={count} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} disabled={isLoading} />
						</div>
					) : (
						<div className="noDataFoundSection mt-5">
							<div>
								<img src={noDataFoundImg} alt="" />
							</div>
							<div className="noDataFound mt-5">No College Admin(s) Found</div>
						</div>
					)}
				</div>
			</MembersContainer>
			<Modal open={openCollege} onClose={handleClose} backdrop="static">
				<div>
					<SelectCollegeModal {...{ handleClose, setCollegeName: handleCollegeDetails }} />
				</div>
			</Modal>
		</div>
	);
}
