import React, { useState } from 'react'
import './AssignCollege.scss'
import searchIcon from '../../../../Assets/common/search_icon.svg';
import searchCloseIcon from '../../../../Assets/common/search_close_icon.png';
import { TablePagination } from '@mui/material';

export default function AssignCollege({ sideNavState, setSideNavState }) {

    const collegeData = [
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
        { collegeName: 'PSGVP Medical College, Bangalore', state: 'Karnataka', city: 'Bangalore', district: 'Bangalore', contact: '7865573737' },
    ]
    const [searchText, setSearchText] = useState('')
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [offset, setOffset] = useState(0);

    const handleSearchInput = (event) => {
        // setSearchText(event.target.value);
    }

    const handleSearchClick = () => {
        // setPage(0);
        // let offset = 0;
        // getHealthCampList(offset, searchText, filterDataObj)
    }

    const handleSearchClear = () => {
        //  setSearchText('');
        //  setPage(0);
        //  let offset = 0;
        //  getHealthCampList(offset, '', filterDataObj);
    }

    const handleChangePage = (event, newPage) => {
        // let pageIndex = newPage;
        // setPage(newPage);
        // let offset = pageSize * pageIndex;
        // getHealthCampList(offset, searchText.filterDataObj)
        // setOffset(offset)
    };

    const handleChangeRowsPerPage = (event) => {
        // setPageSize(parseInt(event.target.value, 10));
        // setPage(0);
    };

    return (
        <div className={'assign-college-container ' + (sideNavState ? 'fullWidthOpenSideNav' : 'halfWidthOpenSideNav')}>
            <div className='main-container'>
                <div className='row mx-2 mb-3'>
                    <div className='col-md-3 col-12'>
                        <p className='assign-text'>Assign College</p>
                    </div>
                    <div className='col-md-5 col-12 my-md-0 my-3'>
                        <label className='label-input'>
                            <input type='text' className='form-control search-input' placeholder='Search by name'
                                onChange={handleSearchInput} value={searchText} />
                            {searchText && <button className='search-btn-close' onClick={handleSearchClear}>
                                <img src={searchCloseIcon} alt='' width={'24px'} />
                            </button>}
                            <button className='search-btn' onClick={handleSearchClick}>
                                <img src={searchIcon} alt='' />
                                <span>Search</span>
                            </button>
                        </label>
                    </div>
                    <div className='col-md-4 col-12 justify-content-md-center'>
                        <div className='custom-btn-card'>
                            <button className='custom-btn btn-primary'>Assign</button>
                            <button className='custom-btn btn-secondary'>Cancel</button>
                        </div>
                    </div>
                </div>
                <div className='table-container'>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>Assign</th>
                                <th>College Name</th>
                                <th>State</th>
                                <th>District</th>
                                <th>City</th>
                                <th>Contact</th>
                            </tr>
                        </thead>
                        <tbody>
                            {collegeData && collegeData.map((items, index) => {
                                return (
                                    <tr>
                                        <td>
                                            <div className="round">
                                                <input type="checkbox" id="checkbox" />
                                                <label for="checkbox"></label>
                                            </div>
                                        </td>
                                        <td>{items.collegeName}</td>
                                        <td>{items.state}</td>
                                        <td>{items.district}</td>
                                        <td>{items.city}</td>
                                        <td>{items.contact}</td>
                                        <td></td>
                                        {/* <td>
                                            <img src={assign} alt='' onClick={() => onViewPatientClick(items)} />
                                        </td> */}
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
                <TablePagination
                    component="div"
                    count={totalRecords}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[5, 10, 20, 40]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    )
}
