import { createService, readService, deleteService, updateService, getMentorId } from './HttpService'
import { url } from '../Config/UrlRoute'
import { getDecryptData, decodeJWTToken } from './SessionStoreService'

const getStudentList = (offset, limit, searchKey = null, searchText = null, collegeId = null, courseId = null) => {
    const cognitoUserSession = getDecryptData('cognitoUserSession');
    const userData = JSON.parse(getDecryptData('uadminProfile'));
    if (cognitoUserSession) {
        const token = JSON.parse(cognitoUserSession);
        const user = decodeJWTToken(token?.idToken?.jwtToken);
        const endPoint = url.university.student.getStudent;
        const params = {
            // uid: user['custom:_id'],
            universityId: userData.universityId,
            offset: offset,
            limit: limit,
            searchKey,
            searchText,
        };
        if(collegeId) params.collegeId = collegeId;
        if(courseId) params.courseId = courseId;
        if (!searchKey || !searchText) {
            delete params.searchKey;
            delete params.searchText;
        }
        return readService(endPoint, params);
    }
}

const createStudent = (payload) => {
    const endPoint = url.university.student.getStudent;
    const params = { collegeId: payload.collegeId }
    return createService(endPoint, payload, params);
}

const updateStudent = (id, payload) => {
    const endPoint = url.university.student.getStudent;
    return updateService(endPoint, {}, { ...payload, studentId: id });
}

const deleteStudent = (id) => {
    const endPoint = url.university.student.getStudent + `/${id}`;
    return deleteService(endPoint);
}
const getStudentById = (id) => {
    const endPoint = url.university.student.getStudent;
    const params = { studentId: id }
    return readService(endPoint, params);
}

const promoteBulkStudent = async (params, body) => {
    const endPoint = url.university.student.promotion;
    return updateService(endPoint, params, body);
};

const getRegisteredStudentList = (offset, limit, collegeId, courseId, searchText, searchKey) => {
    const cognitoUserSession = getDecryptData('cognitoUserSession');
    if (cognitoUserSession) {
        const token = JSON.parse(cognitoUserSession);
        const user = decodeJWTToken(token?.idToken?.jwtToken);
        const endPoint = url.university.student.getRegisteredStudent;
        const params = {
            offset: offset,
            limit: limit,
            collegeId,
            courseId,
            searchText,
            searchKey
        };
        if (!searchText || !searchKey) {
            delete params.searchText;
            delete params.searchKey;

        }
        return readService(endPoint, params);
    }
}

const createBulkStudents = async (formatData, studentDetails, type) => {
    const endPoint = url.university.student.createBulkStudents;
    const params = {
        collegeId: formatData.collegeId,
        courseId: formatData.courseId,
        startYear: formatData.startYear,
        endYear: formatData.endYear,
        usernamePattern: formatData.usernamePattern,
        countryCode: formatData.countryCode,
        type: type
    }

    let payload;
    if(type === 'preview') {
        const formData = new FormData();
        formData.append("data", studentDetails);
        payload = formData;
    } else {
        payload = {"studentList": studentDetails}
    }
    return createService(endPoint, payload, params);
}

const updateStudentState = (payload) => {
    return updateService(url.college.student.updateStudent, {}, payload)
}

const resetStudentPassword = (params) => {
    const userData = JSON.parse(getDecryptData('uadminProfile'));
    params = {...params, adminId: userData._id}
    return createService(url.university.student.resetPassword, {},  params)
}

const deleteRegisteredStudent = (studentId) => {
    const params = {studentId: studentId}
    return deleteService(url.university.student.getRegisteredStudent, params)
}


export {
    getStudentList,
    createStudent,
    deleteStudent,
    getStudentById,
    updateStudent,
    promoteBulkStudent,
    getRegisteredStudentList,
    createBulkStudents,
    updateStudentState,
    resetStudentPassword,
    deleteRegisteredStudent
}