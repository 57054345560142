import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ViewStudent.scss";
import ActionIcon from "../../../Assets/commonImg/actions.svg";
import ViewIcon from "../../../Assets/commonImg/view.svg";
import EditIcon from "../../../Assets/commonImg/edit.svg";
import DeleteIcon from "../../../Assets/commonImg/delete.svg";
import ResetPasswordIcon from "../../../Assets/commonImg/resetPassword.svg";
import PrmotionIcon from "../../../Assets/commonImg/promotion.svg";
import chatIcon from "../../../Assets/common/chatIcon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import studProfile from "../../../Assets/common/studProfile.png";
import NavigateBack from "../../CommonScreens/NavigateBack/NavigateBack";
import { getStudentList, deleteStudent, updateStudentState, resetStudentPassword, promoteBulkStudent } from "../../../Services/StudentService";
import noDataFoundImg from "../../../Assets/memberImg/noStudentFound.png";
import StudentFilter from "../../../Components/StudentFilter/StudentFilter";
import MembersContainer from "../../../Components/MembersContainer/MembersContainer";
import { Table, Paper, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Checkbox } from "@mui/material";
import { getAllCourseOfSelectedCollege } from "../../../Services/memberService";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  width: "45%",
};

const promoteModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 0,
  width: "45%",
};

const onResetClick = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to reset the password",
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#32496B",
    confirmButtonText: "Reset",
    cancelButtonText: "Cancel",
  }).then((result) => {
    if (result.isConfirmed) {
      resetStudentPassword({ studentId: id, permanent: false }).then((res) => {
        if (res.status == 202) {
          Swal.fire({
            icon: "success",
            text: "Successful!",
            showConfirmButton: true,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "Something went wrong!",
            showConfirmButton: false,
          });
        }
      }).catch(error => {
        Swal.fire({
          icon: "error",
          text: "Something went wrong!",
          showConfirmButton: false,
        });
      })
    }
  });
};

export default function ViewStudent({ sideNavState }) {
  const navigate = useNavigate();
  const [openPromote, setPromoteOpen] = React.useState(false);
  const PromoteHandleOpen = (selectedStudent) => {
    setSelectedStudentDeatils({
      collegeId: selectedStudent?.academics[0]?.collegeId,
      currentCourseId: selectedStudent?.academics[0]?.courseId,
      studentId: selectedStudent._id,
      promoteStudentData:selectedStudent,
    })
    setPromoteOpen(true);
    getCoursesList(selectedStudent?.academics[0]?.collegeId)
  };
  const PromoteHandleClose = () => setPromoteOpen(false);

  const [openContact, setContactOpen] = React.useState(false);
  const ContactHandleOpen = () => setContactOpen(true);
  const ContactHandleClose = () => setContactOpen(false);

  useEffect(() => {
    getStudentsListData(0, 10);
  }, []);

  const [studentData, setStudentData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setTotalStudents] = React.useState(0);
  const [isDeleteAllDisabled, setIsDeleteAllDisabled] = useState(true);
  const [isLoading, setLoading] = React.useState(false);
  const [filter, setFilter] = React.useState({});
  const [offset, setOffset] = React.useState(0)
  const [limit, setLimit] = React.useState(10) 
  const { collegeId, courseId, searchText, searchKey } = filter;
  const [courseData, setCourseData] = React.useState([]);
  const [promotedCourseId, setPromotedCourseId] = React.useState('');
  // const [previousCourseId, setPreviousCourseId] = useState('');
  // const [selectedCollegeId, setSelectedCollegeId] = useState('');
  const [selectedStudentDeatils, setSelectedStudentDeatils] = useState('');
  const getStudentsListData = async (offset, limit, searchByKey = null, search = null, collegeId = null, courseId = null) => {
    try {
        setLoading(true);
      const studentData = await getStudentList(offset, limit, searchByKey, search, collegeId, courseId);
        setLoading(false);
      if (studentData && studentData?.data) {
        const studentListData = studentData.data.students.map((student) => ({
          ...student,
          collegeName: student.academics[0]?.collegeName || "N/A",
        }));
        const count = studentData.data.count || 0;
        setStudentData(studentListData);
        setTotalStudents(count);
        } else {
        setStudentData([]);
        setTotalStudents(0);
        }
    } catch (error) {
        setLoading(false);
      console.log("getStudent List", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getStudentsListData(newPage * rowsPerPage, rowsPerPage, collegeId, courseId, searchText, searchKey);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getStudentsListData(0, parseInt(event.target.value, 10), collegeId, courseId, searchText, searchKey);
  };

  const handleHeaderCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setSelectedRows(isChecked ? studentData.map((row) => row.id) : []);
    // Update the disabled state of "Delete All" button
    setIsDeleteAllDisabled(!isChecked);
  };

  const handleCheckboxChange = (e, id) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    }

    // Update the disabled state of "Delete All" button
    setIsDeleteAllDisabled(!isChecked && selectedRows.length === 0);
  };

  const handleView = (id, type) => {
    navigate("/home/student/" + id, { state: { type }, replace: true });
  };

  const handleEdit = (id, type) => {
    navigate("/home/student/" + id, { state: { type }, replace: true });
  };

  const handleDelete = (id) => {
    console.log("🚀 ~ handleDelete ~ id:", id);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteStudent(id)
          .then(() => {
            console.log(`Delete clicked for ID ${id}`);
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
            getStudentList(0, 10);
          })
          .catch((error) => {
            console.log("student delete error", error);
          });
      }
    });
  };


  const handleFilterChange = (e) => {
    setFilter(e);
    const { collegeId, collegeName, courseId, courseName, searchText, searchKey } = e || {};
    setRowsPerPage(10);
    setPage(0);
    getStudentsListData(0, 10, searchKey, searchText, collegeId, courseId);
  };

  const activateStudent = (row, state) => {
    const payload = {
      accountStatus: state ? "enable" : "disable",
      collegeId: row?.academics[0]?.collegeId,
        studentId: row._id,
        userName: row.username,
    };
      row.isActive = !row.isActive;
    updateStudentState(payload)
    .then((res) => {
        console.log("🚀 ~ createCollege ~ res:", res);
        Swal.fire({
            title: "Successful",
            text: res.data.description,
            icon: res.data.message,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Okay"
        }).finally(() => {
          getStudentsListData(offset, limit)
        })
    })
    .catch((error) => {
        if (error?.response?.data) {
            Swal.fire({
                title: "Error",
                text: error.response.data,
                icon: "error",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Okay"
            })
        } else {
            Swal.fire({
                title: "Error",
                text: "Something went wrong please try again",
                icon: "error",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Okay"
            })
        }
    });
}

  const getCoursesList = async (collegeId) => {
    try {
      const courseListData = await getAllCourseOfSelectedCollege(collegeId);
      setCourseData(courseListData.data);
    } catch (error) {
      console.log('error in college list', error)
    }
  }

  const onUpdateClick = () => {
    const params = {
        collegeId: selectedStudentDeatils.collegeId,
        currentCourseId: selectedStudentDeatils.currentCourseId,
        newCourseId: promotedCourseId,
    }
    const body = {
        studentList: [selectedStudentDeatils.studentId]
    }
    promoteBulkStudent(params, body).then((resData) => {
        if (resData?.data?.message === "success") {
            Swal.fire({
                title: 'successfully!',
                text: 'Student promoted successfully!',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: '  Ok  ',
                confirmButtonColor: '#5284EE',
                confirmButtonWidth: '50px',
                reverseButtons: true
            }).then((resData) => {
                goBack();
            })
        }
    }).catch((error) => {
    }).finally(() => {
    })
    PromoteHandleClose();
}

const goBack = () => {
  navigate('/home/viewStudent', { replace: true });
}

  return (
    <div className={"view-student-container " + (sideNavState ? "fullWidthOpenSideNav" : "halfWidthOpenSideNav")}>
      <NavigateBack />
      <MembersContainer>
        <StudentFilter onFilterChange={handleFilterChange} />
        {studentData && studentData.length > 0 ? (
          <div>
            <TableContainer component={Paper} sx={{ maxHeight: " 70vh" }}>
              <Table stickyHeader sx={{ minWidth: 800, borderCollapse: "separate", borderSpacing: "0 10px" }} aria-label="simple table">
                <TableHead>
                  <TableRow className="TableRow">
                    {/* <TableCell align="center">
                      <Checkbox checked={selectedRows?.length === studentData?.length} onChange={(e) => handleHeaderCheckboxChange(e)} />
                    </TableCell> */}
                    <TableCell className="tableHeaders" align="center">USN</TableCell>
                    <TableCell className="tableHeaders" align="center">Student name</TableCell>
                    <TableCell className="tableHeaders" align="center">Email</TableCell>
                    <TableCell className="tableHeaders" align="center">Contact</TableCell>
                    <TableCell className="tableHeaders" align="center">Username</TableCell>
                    <TableCell className="tableHeaders" align="center">College Name</TableCell>
                    <TableCell className="tableHeaders" align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentData.map((row, index) => {
                    return (
                      <TableRow className="TableRow" key={row._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover role="checkbox" tabIndex={-1}>
                        {/* <TableCell align="center">
                          <Checkbox checked={selectedRows.includes(row._id)} onChange={(e) => handleCheckboxChange(e, row._id)} />
                        </TableCell> */}
                        <TableCell align="center">{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell align="center"><div className='d-flex justify-content-center flex-column align-items-center nowrap'>
                          <div>{row.fullName}</div>
                          {(row.isActive || row.isActive === undefined) ? <div className='studentStatus'>Active</div> : <div className='studentStatus deactivate'>Deactive</div>}
                      </div>
                      </TableCell>
                        <TableCell align="center">{row.primaryEmail}</TableCell>
                        <TableCell align="center">{row.mobile}</TableCell>
                        <TableCell align="center">{row.username}</TableCell>
                        <TableCell align="center">{row.collegeName}</TableCell>
                        <TableCell align="center">
                          <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" className="custom-dropdown">
                              <img src={ActionIcon} alt="" width={"28px"} className="actionBtnIcon" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => handleView(row._id, { type: "view" })}>
                                <button className="actionBtn">
                                  <img src={ViewIcon} alt="" width={"25px"} /> View
                                </button>
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => handleEdit(row._id, { type: "edit" })}>
                                <button className="actionBtn">
                                  <img src={EditIcon} alt="" width={"19px"} /> Edit Profile
                                </button>
                              </Dropdown.Item>
                              {/* <Dropdown.Item onClick={() => handleDelete(row._id)}>
                                <button className="actionBtn">
                                  <img src={DeleteIcon} alt="" width={"22px"} /> Delete
                                </button>
                              </Dropdown.Item> */}

                              <Dropdown.Item onClick={() => onResetClick(row._id)}>
                                <button className="actionBtn">
                                  <img src={ResetPasswordIcon} alt="" width={"22px"} /> Reset Password
                                </button>
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => PromoteHandleOpen(row)}>
                                <button className="actionBtn">
                                  <img src={PrmotionIcon} alt="" width={"22px"} /> Promote
                                </button>
                              </Dropdown.Item>
                              {/* <Dropdown.Item onClick={() => ContactHandleOpen(row._id)}>
                                <button className="actionBtn">
                                  <img src={chatIcon} alt="" width={"22px"} /> Contact
                                </button>
                              </Dropdown.Item> */}
                              <Dropdown.Item>
                                <div className="col-12">
                                  <div className="row">
                                    <button className='col-6 activeBtn' onClick={() => activateStudent(row, true)}>Active</button>
                                    <button className='col-6 deActiveBtn' onClick={() => activateStudent(row, false)}>Deactive</button>
                                  </div>
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                            <div></div>
                          </Dropdown>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination component="div" count={count} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} disabled={isLoading} />
          </div>
        ) : (
          <div className="noDataFoundSection">
            <div>
              <img src={noDataFoundImg} alt="img" />
            </div>
            <div className="noDataFound mt-5">No Student(s) Found</div>
          </div>
        )}
        <div>
        <Modal open={openPromote} onClose={PromoteHandleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
  <Box sx={promoteModalStyle}>
    <Typography id="modal-modal-title" variant="h6" component="h2" className="promoteHeader py-2 px-3">
      Promote Modal
    </Typography>
    <Typography id="modal-modal-description" className="p-3">
    {selectedStudentDeatils?.promoteStudentData ? (
        <div className="row my-2" style={{ borderBottom: "1px solid yellow",padding:"10px"}}>
          <div className="col-3">
            <img src={studProfile} width={"100px"} height={"100px"} alt="Student Profile" />
          </div>
          <div className="col-9">
            <div className="row mt-2">
              <div className="col-4">Full Name</div>
              <div className="col-8">{selectedStudentDeatils.promoteStudentData.fullName}</div>
            </div>
            <div className="row mt-2">
              <div className="col-4">Date of birth</div>
              <div className="col-8">{selectedStudentDeatils.promoteStudentData.birthdate || "N/A"}</div>
            </div>
            <div className="row mt-2">
              <div className="col-4">Gender</div>
              <div className="col-8">{selectedStudentDeatils.promoteStudentData.gender || "N/A"}</div>
            </div>
            <div className="row mt-2">
              <div className="col-4">Phone Number</div>
              <div className="col-8">{selectedStudentDeatils.promoteStudentData.mobile}</div>
            </div>
            <div className="row mt-2">
              <div className="col-4">Email</div>
              <div className="col-8">{selectedStudentDeatils.promoteStudentData.primaryEmail}</div>
            </div>
            <div className="row my-2">
              <div className="col-4">College Name</div>
              <div className="col-8">{selectedStudentDeatils.promoteStudentData.collegeName}</div>
            </div>
            <div className="row mt-2">
            <div className="col-4">Current Course</div>
            <div className="col-8">{selectedStudentDeatils.promoteStudentData?.academics[0].courseName || 'Not Available'}</div>
          </div>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
      <div className="row">
        
        <div className="col-3" style={{ color: "blue" }}>
          Academic
        </div>
        <div className="col-9">
         
          <div className="row mt-2">
            <div className="col-4">Change to:</div>
            <div className="col-8">
                        <select className="form-select custom-input" onChange={(e) => {
                           setPromotedCourseId(e.target.value)
                        }}>
                {courseData.length ? (<option value='' >Select Course</option>) : <option disabled>No courses</option>}
                          {
                            courseData.map((course, index) => (
                              <option key={index} value={course?._id}>{course?.courseName}</option>
                            ))
                          }
              </select>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <button className="btnCancel" onClick={PromoteHandleClose}>Cancel</button>
                </div>
                <div className="col-6">
                  <button className="btnPromote" onClick={onUpdateClick}>Promote</button>
                </div>
              </div>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
    </Typography>
  </Box>
</Modal>
          <Modal open={openContact} onClose={ContactHandleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={modalStyle}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  font: "normal normal normal 40px / 53px Roboto",
                  color: "#000000",
                  textAlign: "center",
                }}
              >
                Contact Student
              </Typography>
              <div className="d-flex flex-column justify-content-start my-3">
                <div className="contact-detail">
                  Name <span className="contact-detail mx-3">Arun</span>
                </div>
                <div className="contact-detail">
                  Email <span className="contact-detail mx-3">arun123@gmail.com</span>
                </div>
              </div>
              <div className="d-flex justify-content-center gap-4 contact-box">
                <div className="box-content flex-column d-flex justify-content-center align-items-center">
                  <div>
                    <FormControl>
                      <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="Message to student"
                          sx={{
                            font: "normal normal normal 16px/21px Roboto",
                          }}
                        />
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Message to parent"
                          sx={{
                            font: "normal normal normal 16px/21px Roboto",
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="message-btn mt-3">Message</div>
                </div>
                <div className="box-content flex-column d-flex justify-content-center align-items-center">
                  <div style={{ font: "normal normal normal 16px/21px Roboto", marginBottom: "25px" }}>Send E-Mail to Student’s mail Id</div>
                  <div className="message-btn mt-5">E-mail</div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </MembersContainer>
    </div>
  );
}
