import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import './AddStudent.scss';
import Swal from 'sweetalert2';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useForm } from "react-hook-form";
import NavigateBack from '../../CommonScreens/NavigateBack/NavigateBack';
import { useParams, useLocation } from 'react-router-dom';
import { getAddress } from '../../../Services/AddressService';
import { createStudent, getStudentById, updateStudent } from '../../../Services/StudentService';
import { SelectCollegeModal } from '../../../Components/SelectCollegeModal/SelectCollegeModal';
import { SelectCourseModal } from '../../../Components/SelectCourseModal/SelectCourseModal';
import { parse, format } from 'date-fns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import passIcon from "../../../Assets/commonImg/view.svg";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    border: 'none',

    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '8px',
    padding: '10px',
};

export default function AddStudent({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
    const location = useLocation();
    const type = location.state?.type;
    const { id } = useParams();

    const initialState = {
        firstName: "",
        middleName: "",
        lastName: "",
        fullName: "",
        birthdate: "",
        gender: "",
        prefix: '+91',
        mobile: "",
        primaryEmail: "",
        username: "",
        password: "",
        academics: [],
        courseId: "",
        startYear: "",
        endYear: "",
        collegeId: "",
        country: "",
        stateProvince: "",
        city: "",
        courseName: '',
        collegeName: '',
        postalCode: "",
        secondaryEmails: "",
        parentInfo: {
            fatherName: "",
            parentPhone: "",
            parentEmail: "",
        }
    };


    const [openCollege, setCollegeOpen] = useState(false);
    const handleOpen = () => setCollegeOpen(true);
    const handleClose = () => setCollegeOpen(false);

    const [openCourse, setCourseOpen] = useState(false);
    const handleCourseOpen = () => setCourseOpen(true);
    const handleCourseClose = () => setCourseOpen(false);

    const [selectedFile, setSelectedFile] = useState(null);
    const handleFileInputChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const [formData, setFormData] = useState(initialState);
    const [countryList, setCountryList] = useState([]);
    const [statesList, setStates] = useState([]);
    const [citiesList, setCities] = useState([]);
    const [isAllFieldDisabled, setAllFieldDisabled] = useState(false);
    const { firstName, middleName, lastName, fullName, birthdate, gender, prefix, mobile, primaryEmail, username, password, academics, courseId, startYear, endYear, collegeId, country, stateProvince, city, postalCode, parentInfo = {}, courseName, collegeName, secondaryEmails } = formData;
    const { fatherName = '', parentPhone = '', parentEmail = '' } = parentInfo;
    const operationType = type?.type || "create";


    useEffect(() => {
        getCountry();
        if (type && id) {
            if (type.type === "edit") {
                setAllFieldDisabled(false)
            } else {
                setAllFieldDisabled(true)
            }
            getStudentDetailsById(id);
        }
    }, [])

    const getCountry = () => {
        getAddress({ addressCategory: 'country' }).finally(() => {
        }).then((res) => {
            if (res?.data && Array.isArray(res?.data)) {
                setCountryList(res?.data)
            } else {
                setCountryList([])
            }
        }).catch((error) => {
            setCountryList([]);
            console.log("🚀 ~ getAddress ~ error:", error)
        })
    }
    const handleStateInput = (state, country) => {
        return new Promise((resolve, reject) => {
            getAddress({ addressCategory: 'city', countryName: country, stateName: state }).finally(() => {
            }).then((res) => {
                if (res?.data && Array.isArray(res?.data)) {
                    setCities(res?.data)
                } else {
                    setCities([])
                }
                resolve(true);
            }).catch((error) => {
                setCities([]);
                resolve(false);
                console.log("🚀 ~ getAddress ~ error:", error)
            })
        })
    }

    const handleCountryInput = (event) => {
        return new Promise((resolve, reject) => {
            getAddress({ addressCategory: 'state', countryName: event }).finally(() => {
            }).then((res) => {
                if (res?.data && Array.isArray(res?.data)) {
                    setStates(res?.data)
                } else {
                    setStates([])
                }
                resolve(true);
            }).catch((error) => {
                setStates([]);
                resolve(false);
            })
        })
    }

    const getStudentDetailsById = (id) => {
        getStudentById(id).finally(() => { }).then(async (resData) => {
            if (typeof resData === 'object' && resData.hasOwnProperty('data')) {
                const studentDetails = resData.data;
                const parsedDate = new Date(studentDetails?.birthdate);
                if (studentDetails.country) {
                    await handleCountryInput(studentDetails.country);
                }
                if (studentDetails.stateProvince) {
                    await handleStateInput(studentDetails.stateProvince, studentDetails.country);
                }
                const patchValue =
                {
                    firstName: studentDetails?.firstName,
                    middleName: studentDetails?.middleName,
                    lastName: studentDetails?.lastName,
                    fullName: studentDetails?.fullName,
                    birthdate: !isNaN(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : '',
                    gender: studentDetails?.gender,
                    prefix: studentDetails?.mobile.length > 10 ? studentDetails?.mobile?.substring(0, studentDetails?.mobile.length - 10) : '',
                    mobile: studentDetails?.mobile.length > 10 ? studentDetails?.mobile?.substring(studentDetails?.mobile.length - 10) : studentDetails?.mobile,
                    primaryEmail: studentDetails?.primaryEmail,
                    secondaryEmails: studentDetails.secondaryEmails || '',
                    username: studentDetails?.username,
                    password: '',
                    academics: [],
                    courseId: studentDetails?.academics[0]?.courseId || '',
                    startYear: studentDetails?.academics[0]?.startYear || '',
                    endYear: studentDetails?.academics[0]?.endYear || '',
                    collegeId: studentDetails?.academics[0]?.collegeId || '',
                    country: studentDetails?.country,
                    stateProvince: studentDetails?.stateProvince,
                    city: studentDetails?.city,
                    courseName: studentDetails.courseName,
                    collegeName: studentDetails.collegeName,
                    postalCode: studentDetails?.postalCode,
                    parentInfo: {
                        fatherName: studentDetails?.parentInfo?.fatherName,
                        parentPhone: studentDetails?.parentInfo?.parentPhone,
                        parentEmail: studentDetails?.parentInfo?.parentEmail,
                    }
                }
                setFormData((previousData) => {
                    return { ...previousData, ...patchValue };
                });
            }
        }).catch((error) => { });
    }

    const onFormValueChange = (key, value) => {
        const obj = { [key]: value };
        if (key === 'country') {
            const formValueData = { ...formData, country: value, stateProvince: '', city: '' };
            setFormData(formValueData);
            setStates([]);
            setCities([]);
            handleCountryInput(value);
            return;
        }

        if (key === 'stateProvince') {
            setFormData({ ...formData, stateProvince: value, city: '' });
            setCities([]);
            handleStateInput(value, formData.country);
            return;
        }

        if (key === 'secondaryEmails') {
            setFormData({ ...formData, secondaryEmails: value });
            return;
        }

        setFormData((prevState) => {
            const updatedFormValues = { ...prevState, ...obj };
            updatedFormValues.fullName = `${updatedFormValues?.firstName || ''} ${updatedFormValues?.middleName || ''} ${updatedFormValues?.lastName || ''}`.trim();
            return updatedFormValues;
        });
    };
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        console.log(data);
    };

    const onFatherNameChange = (value) => {
        setFormData({
            ...formData, parentInfo: {
                ...parentInfo,
                fatherName: value,
            }
        })
    }
    const onFatherMobile = (value) => {
        setFormData({
            ...formData, parentInfo: {
                ...parentInfo,
                parentPhone: value,
            }
        })
    }

    const onParentEmail = (value) => {
        setFormData({
            ...formData, parentInfo: {
                ...parentInfo,
                parentEmail: value,
            }
        })
    }

    const onSaveStudent = (e) => {
        e.preventDefault();
        const formValues = Object.assign({}, formData);
        if (formValues?.birthdate) {
            formValues.birthdate = new Date(formValues.birthdate).getTime();
        }
        formValues.mobile = formValues.prefix + formValues.mobile;
        if (formValues?.startYear) {
            const startYearFullDate = new Date(formValues?.startYear);
            formValues.startYear = format(startYearFullDate, 'yyyy');
        }
        if (formValues?.endYear) {
            const endYearFullDate = new Date(formValues?.endYear);
            formValues.endYear = format(endYearFullDate, 'yyyy');
        }

        if (!formValues.firstName || formValues.firstName.trim() === "") {
            Swal.fire({
                title: "Empty",
                text: "First Name is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        } else if (!formValues.birthdate) {
            Swal.fire({
                title: "Empty",
                text: "Birth date is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        }
        else if (!formValues.gender || formValues.gender.trim() === "") {
            Swal.fire({
                title: "Empty",
                text: "Gender is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        }
        else if (!formValues.mobile || formValues.mobile.trim() === "") {
            Swal.fire({
                title: "Empty",
                text: "Mobile Number is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        }
        else if (!formValues.primaryEmail || formValues.primaryEmail.trim() === "") {
            Swal.fire({
                title: "Empty",
                text: "Primary email is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        }
        else if (!formValues.username || formValues.username.trim() === "") {
            Swal.fire({
                title: "Empty",
                text: "Username is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        }
        else if (operationType !== "edit" && (!formValues.password || formValues.password.trim() === "")) {
            Swal.fire({
                title: "Empty",
                text: "Password is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        }
        else if (!formValues.collegeId || formValues.collegeId.trim() === "") {
            Swal.fire({
                title: "Empty",
                text: "College is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        }
        else if (!formValues.courseId || formValues.courseId.trim() === "") {
            Swal.fire({
                title: "Empty",
                text: "Course name is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        }
        else if (!formValues.startYear) {
            Swal.fire({
                title: "Empty",
                text: "Start Year is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        }
        else if (!formValues.endYear) {
            Swal.fire({
                title: "Empty",
                text: "End year is required!",
                icon: "error",
                confirmButtonColor: "#3F64AE",
                confirmButtonText: "Okay"
            });
        }
        else {
            if (!type) {
                createStudent({ ...formValues })
                    .finally(() => {
                        console.log("🚀 ~ createCollege ~ finally:");
                    })
                    .then((res) => {
                        Swal.fire({
                            title: "Successful",
                            text: "New Student Added Successful!",
                            icon: "success",
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "Okay"
                        }).finally(() => {
                            navigate('/home/viewStudent', { replace: true });
                        })
                    })
                    .catch((error) => {
                        if (error?.response?.data) {
                            Swal.fire({
                                title: "Error",
                                text: error.response.data,
                                icon: "error",
                                confirmButtonColor: "#3085d6",
                                confirmButtonText: "Okay"
                            })
                        } else {
                            Swal.fire({
                                title: "Error",
                                text: "Something went wrong please try again",
                                icon: "error",
                                confirmButtonColor: "#3085d6",
                                confirmButtonText: "Okay"
                            })
                        }
                    });
            } else if (operationType === "edit") {
                delete formValues.password;
                updateStudent(id, formValues)
                    .finally(() => {

                    })
                    .then((res) => {
                        console.log("🚀 ~ createCollege ~ res:", res);
                        Swal.fire({
                            title: "Successful",
                            text: "Student Update Successful!",
                            icon: "success",
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "Okay"
                        }).finally(() => {
                            navigate('/home/viewStudent', { replace: true });
                        })
                    })
                    .catch((error) => {
                        if (error?.response?.data) {
                            Swal.fire({
                                title: "Error",
                                text: error.response.data,
                                icon: "error",
                                confirmButtonColor: "#3085d6",
                                confirmButtonText: "Okay"
                            })
                        } else {
                            Swal.fire({
                                title: "Error",
                                text: "Something went wrong please try again",
                                icon: "error",
                                confirmButtonColor: "#3085d6",
                                confirmButtonText: "Okay"
                            })
                        }
                    });
            }
        }
    };
    const onResetCollegeAdmin = () => {
        setFormData(initialState);
    };

    const setCollegeName = (event) => {
        /**Reset the course details if exists */
        formData.courseId = '';
        formData.courseName = '';
        setFormData({ ...formData, ...event })
    };

    const setCourseName = (event) => {
        setFormData({ ...formData, ...event })
    };

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const inputType = isPasswordVisible ? 'text' : 'password';
    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
    };

    let passwordField = <></>;
    if (!type?.type) {
        passwordField = (
            <div className="col-md-4 col-12">
                <p className="input-label">Password<b style={{ color: 'red' }}>*</b></p>
                <div className="input-container" style={{ position: 'relative' }}>
                    <input
                        className="custom-input"
                        type={inputType}
                        name="password"
                        value={password}
                        onChange={(event) => {
                            const key = event.target.name;
                            const value = event.target.value;
                            onFormValueChange(key, value);
                        }}
                        disabled={isAllFieldDisabled || (type && type.type == 'edit')}
                    />
                    <img
                        src={passIcon}
                        alt="Toggle Password Visibility"
                        onClick={togglePasswordVisibility}
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer'
                        }}
                    />
                </div>
            </div>
        )
    }

    return (

        <div className={'student-container ' + (sideNavState ? 'fullWidthOpenSideNav' : 'halfWidthOpenSideNav')}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <NavigateBack />
                <div className='student-form-card'>
                    <div className='student-form-title'>
                        {(operationType == 'create') ? <p className='subHeading mt-1 mb-3'>Add Student</p> :
                            (type && type?.type == 'edit') ? <p className='subHeading mt-1 mb-3'>Edit Student</p> :
                            <p className='subHeading mt-1 mb-3'>View Student</p>
                        }
                    </div>
                    <div className="row mx-0">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-4 col-12">
                                    <p className="input-label">First Name<b style={{ color: 'red' }}>*</b></p>
                                    <input
                                        className="custom-input"
                                        type="text"
                                        name="firstName"
                                        value={firstName}
                                        onChange={(event) => {
                                            const key = event.target.name;
                                            const value = event.target.value;

                                            onFormValueChange(key, value);
                                        }} disabled={isAllFieldDisabled}
                                    />
                                </div>
                                <div className="col-md-4 col-12">
                                    <p className="input-label">Middle Name</p>
                                    <input
                                        className="custom-input"
                                        type="text"
                                        name="middleName"
                                        value={middleName}
                                        onChange={(event) => {
                                            const key = event.target.name;
                                            const value = event.target.value;
                                            onFormValueChange(key, value);
                                        }} disabled={isAllFieldDisabled}
                                    />
                                </div>
                                <div className="col-md-4 col-12">
                                    <p className="input-label">Last Name</p>
                                    <input
                                        className="custom-input"
                                        type="text"
                                        name="lastName"
                                        value={lastName}
                                        onChange={(event) => {
                                            const key = event.target.name;
                                            const value = event.target.value;
                                            onFormValueChange(key, value);
                                        }} disabled={isAllFieldDisabled}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-8 col-12">
                                    <p className="input-label">Full Name</p>
                                    <input className="custom-input" disabled={true} type="text" name="fullName" value={fullName} />
                                </div>
                                <div className="col-md-4 col-12">
                                    <p className="input-label">Date Of Birth<b style={{ color: 'red' }}>*</b></p>
                                    <input
                                        className="custom-input"
                                        type='date'
                                        name="birthdate"
                                        value={birthdate}
                                        onChange={(event) => {
                                            const key = event.target.name;
                                            const value = event.target.value;
                                            onFormValueChange(key, value);
                                        }} disabled={isAllFieldDisabled || (type && type.type == 'edit')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-4 col-12">
                                    <p className="input-label">Gender<b style={{ color: 'red' }}>*</b></p>
                                    <select className="custom-input" name="gender" value={gender} onChange={(event) => {
                                        const key = event.target.name;
                                        const value = event.target.value;
                                        onFormValueChange(key, value);
                                    }} disabled={isAllFieldDisabled}>
                                        <option value="" disabled>Select Gender</option>
                                        <option value='Male'>Male</option>
                                        <option value='Female'>Female</option>
                                    </select>
                                </div>
                                <div className="col-md-4 col-12">
                                    <p className="input-label">Mobile No.<b style={{ color: 'red' }}>*</b></p>
                                    <div className="row">
                                        <div className="col-2">
                                            <input className="custom-input px-2" name="prefix" value={prefix} defaultValue={'+91'}
                                                onChange={(event) => {
                                                    const key = event.target.name;
                                                    const value = event.target.value;
                                                    onFormValueChange(key, value);
                                                }} readOnlydisabled={isAllFieldDisabled || (type && type.type == 'edit')}></input>
                                        </div>
                                        <div className="col-10">
                                            <input
                                                className="custom-input"
                                                type="text"
                                                name="mobile"
                                                value={mobile}
                                                onChange={(event) => {
                                                    const key = event.target.name;
                                                    const value = event.target.value;
                                                    onFormValueChange(key, value);
                                                }}
                                                disabled={isAllFieldDisabled || (type && type.type == 'edit')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <p className="input-label">Primary Email Id<b style={{ color: 'red' }}>*</b></p>
                                    <input
                                        className="custom-input"
                                        type="email"
                                        name="primaryEmail"
                                        value={primaryEmail}
                                        onChange={(event) => {
                                            const key = event.target.name;
                                            const value = event.target.value;
                                            onFormValueChange(key, value);
                                        }} disabled={isAllFieldDisabled || (type && type.type == 'edit')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-4 col-12">
                                    <p className="input-label">Secondary Email Id</p>
                                    <input
                                        className="custom-input"
                                        type="email"
                                        name="secondaryEmails"
                                        value={secondaryEmails}
                                        onChange={(event) => {
                                            const key = event.target.name;
                                            const value = event.target.value;
                                            onFormValueChange(key, value);
                                        }} disabled={isAllFieldDisabled}
                                    />
                                </div>
                                <div className="col-md-4 col-12">
                                    <p className="input-label">Username<b style={{ color: 'red' }}>*</b></p>
                                    <input
                                        className="custom-input"
                                        type="text"
                                        name="username"
                                        value={username}
                                        onChange={(event) => {
                                            const key = event.target.name;
                                            const value = event.target.value;
                                            onFormValueChange(key, value);
                                        }} disabled={isAllFieldDisabled || (type && type.type == 'edit')}
                                    />
                                </div>
                                {passwordField}
                            </div>
                        </div>
                    </div>
                    <div className='row mx-0'>
                        <div className='subHeading'>Parent Information</div>
                        <div className='col-md-4 col-12'>
                            <p className="input-label" >Father/Guardian Name</p>
                            <input className="custom-input" type='text'
                                name="fatherName"
                                value={fatherName}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    onFatherNameChange(value);
                                }} disabled={isAllFieldDisabled}
                            />
                        </div>
                        <div className='col-md-4 col-12'>
                            <p className="input-label" >Father/Guardian Mobile No.</p>
                            <input className="custom-input" type='number'
                                name="parentPhone"
                                value={parentPhone}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    onFatherMobile(value);
                                }} disabled={isAllFieldDisabled}
                            />
                        </div>
                        <div className='col-md-4 col-12'>
                            <p className="input-label" >Father/Guardian Mail ID</p>
                            <input className="custom-input" type='text' value={parentEmail} name='parentEmail'
                                onChange={(event) => {
                                    const value = event.target.value;
                                    onParentEmail(value);
                                }} disabled={isAllFieldDisabled} />
                            {errors.guardianMail && errors.guardianMail.type === "required" && <span className='validationError'>This field is required</span>}
                        </div>
                    </div>
                    <div className='row mx-0'>
                        <div className='subHeading'>Locality</div>
                        <div className="row mx-0">
                            <div className="col-md-3 col-12">
                                <p className="input-label">Country</p>
                                <select className="custom-input" value={country} name="country" onChange={(event) => {
                                    const key = event.target.name;
                                    const value = event.target.value;
                                    onFormValueChange(key, value);
                                }} required disabled={isAllFieldDisabled}>
                                    <option value="">Select country</option>
                                    {countryList.map((country) => (
                                        <option key={country?._id} value={country?.name}>
                                            {country?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-3 col-12">
                                <p className="input-label">State</p>
                                <select className="custom-input" value={stateProvince} name="stateProvince" onChange={(event) => {
                                    const key = event.target.name;
                                    const value = event.target.value;
                                    onFormValueChange(key, value);
                                }} disabled={!country || isAllFieldDisabled || !statesList.length} required >
                                    <option value="">Select State</option>
                                    {statesList.map((state) => (
                                        <option key={state} value={state}>
                                            {state}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-3 col-12">
                                <p className="input-label">City</p>
                                <select className="custom-input" name='city' value={city} onChange={(event) => {
                                    const key = event.target.name;
                                    const value = event.target.value;
                                    onFormValueChange(key, value);
                                }} disabled={!stateProvince || isAllFieldDisabled || !citiesList.length} required>
                                    <option value="">Select City</option>
                                    {citiesList.map((city) => (
                                        <option key={city} value={city}>
                                            {city}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-3 col-12">
                                <p className="input-label">Postal Code</p>
                                <input className="custom-input" type='number' name="postalCode" value={postalCode} onChange={(event) => {
                                    const key = event.target.name;
                                    const value = event.target.value;
                                    onFormValueChange(key, value);
                                }} required disabled={isAllFieldDisabled}></input>
                            </div>
                        </div>
                    </div>
                    <div className='row mx-0'>
                        <div className='subHeading'>Academic Profile</div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <p className="input-label" >College Name<b style={{ color: 'red' }}>*</b></p>
                            <div className='position-relative'>
                                <input className="custom-input" type='text' value={collegeName} onClick={handleOpen} readOnly disabled={isAllFieldDisabled || (type && type.type == 'edit')} />
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <p className="input-label" >Course<b style={{ color: 'red' }}>*</b></p>
                            <div className='position-relative'>
                                <input className="custom-input" type='text' value={courseName} onClick={handleCourseOpen} readOnly disabled={(isAllFieldDisabled || type?.type === "edit" || !collegeId)} />
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <p className="input-label" >Start Date<b style={{ color: 'red' }}>*</b></p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker views={['year']} value={dayjs(startYear)}
                                    className="form-control"
                                    onChange={(event) => {
                                        onFormValueChange('startYear', new Date(event).getFullYear().toString());
                                    }}
                                    disabled={isAllFieldDisabled || (type && type.type == 'edit')}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <p className="input-label" >End Date</p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker views={['year']} value={dayjs(endYear)}
                                    className="form-control"
                                    onChange={(event) => {
                                        console.log(new Date(event).getFullYear().toString());
                                        onFormValueChange('endYear', new Date(event).getFullYear().toString());
                                    }}
                                    disabled={isAllFieldDisabled || (type && type.type == 'edit')}
                                />
                            </LocalizationProvider>

                        </div>
                    </div>

                    <div className='row mx-0'>

                        <div className='col-lg-3 col-md-6 col-12'>
                            <p className="input-label">Upload Photo 150 px X 150 px</p>
                            <label htmlFor="filePicker" className="d-flex align-items-center justify-content-center custom-input" style={{ padding: "5px 10px", color: "#707070" }}>
                                Browse or Upload
                            </label>
                            <div className='mt-0'>{selectedFile && <p>{selectedFile.name}</p>}</div>
                            <input id="filePicker" style={{ visibility: "hidden" }} type={"file"} onChange={handleFileInputChange} disabled={isAllFieldDisabled} />
                            <div>
                            </div>
                        </div>
                        <div className='text-danger'><i>Note:* Marked Fields are mandatory!</i></div>
                    </div>
                </div>
                {type?.type !== 'view' ?
                    <div className='form-submit-card'>
                        <div><Button className='saveBtn' type="submit" value="Register" onClick={onSaveStudent}>
                            {type?.type === 'edit' ? 'Update' : 'Save'}
                        </Button></div>
                        <div><Button className='resetBtn' onClick={onResetCollegeAdmin}>Reset</Button></div>
                    </div>
                    : <></>
                }
            </form>
            <Modal
                open={openCollege}
                onClose={handleClose}
            >
                <div>
                    <SelectCollegeModal {...{ handleClose, collegeId, setCollegeName }} />
                </div>
            </Modal>
            <Modal
                open={openCourse}
                onClose={handleCourseClose}
            >
                <div>
                    <SelectCourseModal {...{ handleCourseClose, courseId, setCourseName, collegeId }} />
                </div>
            </Modal>
        </div>
    )
}
