import React, { useEffect, useState } from "react";
import style from "./searchField.module.scss";
import SearchIcon from '../../Assets/commonImg/searchIcon.svg';

const SearchField = ({
  placeholderText = 'Search',
  onSearchFun,
  parentSearchText,
  clearInputField,
  isCovered
}) => {

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    clearText();
  }, [clearInputField])

  const clearText = () => {
    setSearchText("");
  }

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleClearClick = () => {
    setSearchText("");
    if (typeof onSearchFun === 'function') {
      onSearchFun('');
    }
  };

  const handleSearchClick = () => {
    if (typeof onSearchFun === 'function') {
      onSearchFun(searchText);
    }
  };

  return (
    <div className={`${style["search-field-container"]} `}>
      <input type="text" value={searchText} onChange={handleInputChange} placeholder={placeholderText} className={isCovered?style["search-input-covered"]:style["search-input"]} />
      <button className={isCovered?style["search-button-covered"]:style["search-button"]} onClick={handleSearchClick}>
      <img src={SearchIcon} width={'18px'} height={'18px'} /> Search
      </button>
      {searchText && (
        <div className={style["clear-button"]} >
        <button className={style["clear-icon"]}  onClick={handleClearClick}>
         <span>&times;</span> 
        </button>
        </div>
      )}
    </div>
  );
};

export default SearchField;
