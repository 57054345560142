import React from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

export const data = {
    options: {
        plugins: {
            legend: {
                display: true,
                labels: {
                    color: 'rgb(255, 99, 132)'
                },
                position: 'bottom',
                align: "bottom"
            }
        }
    },
    labels: ['Pending', 'In Progress', 'Completed'],
    datasets: [
        {
            label: 'In Progress',
            data: [8, 8, 7],
            backgroundColor: 'rgba(255, 255, 255, 0)',
            borderColor: 'rgb(139,169,251)',
            borderWidth: 1,
        },
        {
            label: 'Completed',
            data: [9, 9, 8],
            backgroundColor: 'rgba(255, 255, 255, 0)',
            borderColor: 'rgb(130,234,214)',
            borderWidth: 1,
        },
        {
            label: 'Pending',
            data: [10, 10, 9],
            backgroundColor: 'rgba(255, 255, 255, 0)',
            borderColor: 'rgb(20,117,214)',
            borderWidth: 1,
        },
    ],
};


const PerformanceChart = () => {
    return (<Radar data={data} />);
}


export default PerformanceChart