import React, { useState } from "react";
import Highcharts from 'highcharts';
import style from "./ColumnComparisonChart.module.scss";
import HighchartsReact from 'highcharts-react-official';
import { Col, Row } from 'react-bootstrap';
import downDarkArrow from './../../Assets/commonImg/down-dark-arrow.svg';
import searchIcon from './../../Assets/commonImg/searchIcon.png';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

function createData(name, email, username, phone) {
    return { name, email, username, phone };
}

const rows = [
    createData('Atul Sharma', 'Abc@sample.in', 'Atul_Shar', 9876544567),
    createData('Ajay Singh', 'Abc@sample.in', 'Ajay_Sin', 9876544567),
    createData('Sathish Gupta', 'Abc@sample.in', 'Sathish_G', 9876544567),
    createData('Rohit Sharma', 'Abc@sample.in', 'Rohit_Shar', 9876544567),
    createData('Virat Kohli', 'Abc@sample.in', 'Virat_Koh', 9876544567),
    createData('MS Dhoni', 'Abc@sample.in', 'MS_Dhoni', 9876544567),
    createData('Shikhar Dhawan', 'Abc@sample.in', 'Shikhar_Dha', 9876544567),
    createData('Ravindra Jadeja', 'Abc@sample.in', 'Ravindra_Jad', 9876544567),
];

const ColumnComparison = () => {
    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: '',
        },
        subtitle: {
            text: 'No. of Members',
            align: 'left',
        },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            crosshair: true,
        },
        yAxis: {
            min: 0,
            title: {
                text: '',
            },
        },
        legend: {
            align: 'right',
            verticalAlign: 'top',
            x: 0,
            y: 0,
            floating: true,
            borderWidth: 0,
            backgroundColor: 'none',
            shadow: false,
            symbolRadius: 0,
            width: '100px',
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} Count</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true,
        },
        plotOptions: {
            column: {
                pointPadding: 0,
                borderWidth: 0,
                groupPadding: 0.7, // Adjust this value to control spacing between groups
                pointWidth: 20, // Adjust this value to control column width
            },
        },
        series: [{
            name: 'Student',
            color: '#E266E6',
            data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
        }, {
            name: 'Educator',
            color: '#5385EF',
            data: [42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8, 51.1],
        }],
    };

    const styles = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '8px',
        p: 3,
        width: '624px',
    };

    const [openStudent, setOpenStudent] = useState(false);
    const [openEducator, setOpenEducator] = useState(false);

    const handleOpenStudent = () => setOpenStudent(true);
    const handleCloseStudent = () => setOpenStudent(false);

    const handleOpenEducator = () => setOpenEducator(true);
    const handleCloseEducator = () => setOpenEducator(false);

    const [pageStudent, setPageStudent] = useState(0);
    const [rowsPerPageStudent, setRowsPerPageStudent] = useState(5);
    const [pageEducator, setPageEducator] = useState(0);
    const [rowsPerPageEducator, setRowsPerPageEducator] = useState(5);

    const handleChangePageStudent = (event, newPage) => {
        setPageStudent(newPage);
    };

    const handleChangeRowsPerPageStudent = (event) => {
        setRowsPerPageStudent(parseInt(event.target.value, 10));
        setPageStudent(0);
    };

    const handleChangePageEducator = (event, newPage) => {
        setPageEducator(newPage);
    };

    const handleChangeRowsPerPageEducator = (event) => {
        setRowsPerPageEducator(parseInt(event.target.value, 10));
        setPageEducator(0);
    };

    const paginatedRowsStudent = rows.slice(pageStudent * rowsPerPageStudent, pageStudent * rowsPerPageStudent + rowsPerPageStudent);
    const paginatedRowsEducator = rows.slice(pageEducator * rowsPerPageEducator, pageEducator * rowsPerPageEducator + rowsPerPageEducator);

    return (
        <Row>
            <Col className="col-12 p-0">
                <div className={style.gap10Px}>
                    <span className={style.title}> Attendance Overview</span>
                    <span className={style.titleImg} />
                </div>
            </Col>
            <Col className={`${style.basicColumnChart} col-12`}>
                <div>
                    <div className="d-flex justify-content-end gap-3 mt-2">
                        <div onClick={handleOpenStudent} className={style.studentEducator}>
                            <div className={style.selectField}>Student</div>
                            <div className="ml-2"><img src={downDarkArrow} width={15} height={9} /></div>
                        </div>
                        <div onClick={handleOpenEducator} className={style.studentEducator}>
                            <div className={style.selectField}>Educator</div>
                            <div className="ml-2"><img src={downDarkArrow} width={15} height={9} /></div>
                        </div>
                    </div>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                </div>
            </Col>
            <Modal
                open={openStudent}
                onClose={handleCloseStudent}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles}>
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <select className={style.customSelectInput}>
                                <option>Search By Name</option>
                                <option>Dr. Prasad</option>
                                <option>Dr. Prashant</option>
                                <option>Dr. Pooja</option>
                                <option>Dr. Jayanti</option>
                            </select>
                        </div>
                        <div className="d-flex mx-2 position-relative">
                            <input type="text" placeholder="Search here" className={style.searchField} />
                            <div className={style.searchIcon}><img src={searchIcon} width={18} height={18} /></div>
                        </div>
                        <div className="d-flex">
                            <select className={style.customSelectInput}>
                                <option>MBBS 1st Year</option>
                                <option>MBBS 2nd Year</option>
                                <option>MBBS 3rd Year</option>
                                <option>MBBS 4th Year</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <TableContainer component={Paper}>
                            <Table className={style.table} aria-label="simple table">
                                <TableHead className={style.tableHeader}>
                                    <TableRow>
                                        <TableCell align="left">Student Name</TableCell>
                                        <TableCell align="left">Email</TableCell>
                                        <TableCell align="center">Username</TableCell>
                                        <TableCell align="left">Phone</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedRowsStudent.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">{row.name}</TableCell>
                                            <TableCell align="left">{row.email}</TableCell>
                                            <TableCell align="center">{row.username}</TableCell>
                                            <TableCell align="left">{row.phone}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPageStudent}
                            page={pageStudent}
                            onPageChange={handleChangePageStudent}
                            onRowsPerPageChange={handleChangeRowsPerPageStudent}
                        />
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openEducator}
                onClose={handleCloseEducator}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles}>
                    <div className="d-flex justify-content-start">
                        <div className="d-flex">
                            <select className={style.customSelectInput}>
                                <option>Search By Name</option>
                                <option>Dr. Prasad</option>
                                <option>Dr. Prashant</option>
                                <option>Dr. Pooja</option>
                                <option>Dr. Jayanti</option>
                            </select>
                        </div>
                        <div className="d-flex mx-2 position-relative">
                            <input type="text" placeholder="Search here" className={style.searchField} />
                            <div className={style.searchIcon}><img src={searchIcon} width={18} height={18} /></div>
                        </div>
                    </div>
                    <div>
                        <TableContainer component={Paper}>
                            <Table className={style.table} aria-label="simple table">
                                <TableHead className={style.tableHeader}>
                                    <TableRow>
                                        <TableCell align="left">Educator Name</TableCell>
                                        <TableCell align="left">Email</TableCell>
                                        <TableCell align="center">Username</TableCell>
                                        <TableCell align="left">Phone</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedRowsEducator.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">{row.name}</TableCell>
                                            <TableCell align="left">{row.email}</TableCell>
                                            <TableCell align="center">{row.username}</TableCell>
                                            <TableCell align="left">{row.phone}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPageEducator}
                            page={pageEducator}
                            onPageChange={handleChangePageEducator}
                            onRowsPerPageChange={handleChangeRowsPerPageEducator}
                        />
                    </div>
                </Box>
            </Modal>
        </Row>
    );
};

export default ColumnComparison;