import { readService, updateService } from './HttpService'
import { url } from '../Config/UrlRoute'
import { getDecryptData, decodeJWTToken } from './SessionStoreService'

const getCurrentUniversityAdmin = () => {
    const cognitoUserSession = getDecryptData('cognitoUserSession');
    if (cognitoUserSession) {
        const token = JSON.parse(cognitoUserSession);
        const user = decodeJWTToken(token?.idToken?.jwtToken)
        const endPoint = url.university.admin.get
        return readService(endPoint, { adminId: user['custom:_id'] });
    }

}
const updateCurrentUniversityAdmin = async (id, payload) => {
    if (id && typeof payload === 'object') {
        const endPoint = url.university.admin.get;
        delete payload._id;
        return await updateService(endPoint, { universityAdminId: id }, payload);
    } else {
        throw ('please check your payload')
    }
}
export { getCurrentUniversityAdmin, updateCurrentUniversityAdmin }