import React, { useState } from "react";
import "./Report.scss";

const radioButtonsData = {
  auditReport: {
    radioButtons: [
      {
        type: "radio",
        className: "form-check-input",
        id: "admin",
        name: "redoOptions",
        value: "admin",
        label: {
          className: "form-check-label",
          htmlFor: "admin",
          text: "Admin",
        },
      },
      {
        type: "radio",
        className: "form-check-input",
        id: "educator",
        name: "redoOptions",
        value: "educator",
        label: {
          className: "form-check-label",
          htmlFor: "educator",
          text: "Educator",
        },
      },
      {
        type: "radio",
        className: "form-check-input",
        id: "student",
        name: "redoOptions",
        value: "student",
        label: {
          className: "form-check-label",
          htmlFor: "student",
          text: "Student",
        },
      },
    ],
  },
};

const TimeTableReport = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div id="audit-report" className="container">
      <hr/>
      <section>
        <div className="row my-3">
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <label>Select Course</label>
            <select className="form-select">
              <option value="college">Select</option>
              <option value="college">BGS College</option>
              <option value="college">BGS College</option>
            </select>
          </div>
          <div className="col-12 col-md-4">
            <label>Select Student</label>
            <select className="form-select">
              <option value="college">Select</option>
              <option value="college">BGS College</option>
              <option value="college">BGS College</option>
            </select>
          </div>
          <div className="col-12 col-md-4">
            <label>Select Subject</label>
            <select className="form-select">
              <option value="college">Select</option>
              <option value="college">BGS College</option>
              <option value="college">BGS College</option>
            </select>
          </div>
        </div>

        <div className="row my-3">
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <label>Select Category</label>
            <select className="form-select">
              <option value="college">Select</option>
              <option value="college">BGS College</option>
              <option value="college">BGS College</option>
            </select>
          </div>
          <div className="col-12 col-md-4">
            <label>From Date</label>
            <select className="form-select">
              <option value="college">Select</option>
              <option value="college">BGS College</option>
              <option value="college">BGS College</option>
            </select>
          </div>
          <div className="col-12 col-md-4">
            <label>To Date</label>
            <select className="form-select">
              <option value="college">Select</option>
              <option value="college">BGS College</option>
              <option value="college">BGS College</option>
            </select>
          </div>
        </div>

        <div className="btn-group">
                  <button className="clear-btn">Clear</button>
                  <button className="report-btn">Request Report</button>
                </div>
      </section>
    </div>
  );
};

export default TimeTableReport;
