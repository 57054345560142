import React, { useEffect, useState } from 'react'
import './RegisteredStudent.scss'
import { Checkbox, IconButton, TablePagination } from '@mui/material';
import { createStudent, deleteRegisteredStudent, getRegisteredStudentList } from '../../../Services/StudentService';
import viewIcon from '../../../Assets/memberImg/view.svg';
import editIcon from '../../../Assets/memberImg/edit.svg';
import deleteIcon from '../../../Assets/memberImg/delete.png';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import NavigateBack from '../../CommonScreens/NavigateBack/NavigateBack';
import noDataFoundImg from '../../../Assets/memberImg/noStudentFound.png';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useForm } from "react-hook-form";
import { getAddress } from '../../../Services/AddressService';
import StudentFilter from '../../../Components/StudentFilter/StudentFilter';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MembersContainer from '../../../Components/MembersContainer/MembersContainer';
import { format } from 'date-fns';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    border: 'none',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '8px',
    padding: '0',
};


export default function RegisteredStudent({ sideNavState, setSideNavState }) {

    useEffect(() => {
        getCountry();
        getRegisteredStudentListData(0, 10)
    }, [])

    const initialState = {
        firstName: "",
        lastName: "",
        middleName: "",
        fullName: "",
        birthdate: "",
        gender: "",
        mobile: "",
        primaryEmail: "",
        username: "",
        courseName: "",
        startYear: "",
        endYear: "",
        country: "",
        stateProvince: "",
        city: "",
        postalCode: "",
        secondaryEmails: [],
    };
    const [formData, setFormData] = useState(initialState);
    const { firstName, middleName, lastName, fullName, birthdate, gender, prefix, mobile, primaryEmail, username, country, stateProvince, city, postalCode, courseName, startYear, endYear, secondaryEmails = [''] } = formData;
    const [registeredStudentData, setRegisteredStudentData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [count, setTotalRegisteredStudent] = React.useState(0);
    const [isLoading, setLoading] = React.useState(false);
    const [countryList, setCountryList] = useState([]);
    const [statesList, setStates] = useState([]);
    const [citiesList, setCities] = useState([]);
    const [filter, setFilter] = useState({});
    const {
        collegeId,
        courseId,
        searchText,
        searchKey
    } = filter;
    const [selectedStudentId, setSelectedStudentId] = useState('')

    const getRegisteredStudentListData = async (offset, limit, collegeId, courseId, searchText, searchKey) => {
        try {
            setLoading(true);
            const registeredStudentData = await getRegisteredStudentList(offset, limit, collegeId, courseId, searchText, searchKey);
            console.log(`🚀 ~ getStudentListData ~ registeredStudentData:`, registeredStudentData)
            setLoading(false);
            if (registeredStudentData && registeredStudentData?.data) {
                const registeredStudentListData = registeredStudentData.data.students || [];
                const count = registeredStudentData.data.count || 0;
                setRegisteredStudentData(registeredStudentListData);
                setTotalRegisteredStudent(count);
            } else {
                setRegisteredStudentData([]);
                setTotalRegisteredStudent(0);
            }
        } catch (error) {
            setLoading(false);
            console.log("getEducator List", error);
        }
    }

    const handleDeleteStudent = (studentId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRegisteredStudent(studentId).then((res) => {
                    if(res.status === 200) {
                            Swal.fire(
                                'Deleted!',
                                'Student deleted Successfully',
                                'success'
                            )
                    } else {
                        Swal.fire(
                            'Oops!',
                            'Something went wrong!',
                            'error')
                        }
                }).catch((error) => {
                    Swal.fire({
                        title: "Error",
						text: error.response.data,
						icon: "error"
                    });
                }).finally(() => {
                    getRegisteredStudentListData(0, 10);
                })
            }
        });
    };

    const handleHeaderCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setSelectedRows(isChecked ? registeredStudentData.map(row => row.id) : []);
    };
    // const handleCheckboxChange = (e, id) => {
    //     if (e.target.checked) {
    //         setSelectedRows([...selectedRows, id]);
    //     } else {
    //         setSelectedRows(selectedRows.filter(rowId => rowId !== id));
    //     }
    // };

    const handleCheckboxChange = (e, selectedStudent) => {
        setSelectedStudentId(selectedStudent._id);
        if (e.target.checked) {
            setSelectedRows([selectedStudent]);
        } else {
            setSelectedRows([]);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getRegisteredStudentListData(newPage * rowsPerPage, rowsPerPage, collegeId, courseId, searchText, searchKey);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getRegisteredStudentListData(0, parseInt(event.target.value, 10), collegeId, courseId, searchText, searchKey);
    };

    const [viewEditOpen, setOpen] = React.useState(false);
    const handleModalOpen = () => setOpen(true);
    const handleModalClose = () => setOpen(false);

    const getCountry = () => {
        getAddress({ addressCategory: 'country' }).finally(() => {
        }).then((res) => {
            if (res?.data && Array.isArray(res?.data)) {
                setCountryList(res?.data)
            } else {
                setCountryList([])
            }
        }).catch((error) => {
            setCountryList([]);
            console.log("🚀 ~ getAddress ~ error:", error)
        })
    }
    const handleStateInput = (state, country) => {
        return new Promise((resolve, reject) => {
            getAddress({ addressCategory: 'city', countryName: country, stateName: state }).finally(() => {
            }).then((res) => {
                if (res?.data && Array.isArray(res?.data)) {
                    setCities(res?.data)
                } else {
                    setCities([])
                }
                resolve(true);
            }).catch((error) => {
                setCities([]);
                resolve(false);
                console.log("🚀 ~ getAddress ~ error:", error)
            })
        })
    }

    const handleCountryInput = (event) => {
        return new Promise((resolve, reject) => {
            getAddress({ addressCategory: 'state', countryName: event }).finally(() => {
            }).then((res) => {
                if (res?.data && Array.isArray(res?.data)) {
                    setStates(res?.data)
                } else {
                    setStates([])
                }
                resolve(true);
            }).catch((error) => {
                setStates([]);
                resolve(false);
            })
        })
    }

    const onFormValueChange = (key, value) => {
        const obj = { [key]: value };
        if (key === 'country') {
            const formValueData = { ...formData, country: value, stateProvince: '', city: '' };
            setFormData(formValueData);
            setStates([]);
            setCities([]);
            handleCountryInput(value);
            return;
        }

        if (key === 'stateProvince') {
            setFormData({ ...formData, stateProvince: value, city: '' });
            setCities([]);
            handleStateInput(value, formData.country);
            return;
        }

        if (key === 'secondaryEmails') {
            setFormData({ ...formData, secondaryEmails: [value] });
            return;
        }
        setFormData((prevState) => {
            const updatedFormValues = { ...prevState, ...obj };
            updatedFormValues.fullName = `${updatedFormValues?.firstName || ''} ${updatedFormValues?.lastName || ''}`.trim();
            return updatedFormValues;
        });
    };

    const [isEditMode, setIsEditMode] = useState(false);

    const handleOpenModal = (editMode = false, student = null) => {
        setSelectedStudentId(student._id)
        setIsEditMode(editMode);
        const parsedDate = new Date(student?.birthdate);
        if (student) {
            setFormData({
                firstName: student.firstName,
                lastName: student.lastName,
                fullName: student.fullName,
                birthdate: !isNaN(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : '',
                gender: student.gender,
                prefix: student?.mobile.length > 10 ? student?.mobile?.substring(0, student?.mobile.length - 10) : '',
                mobile: student?.mobile.length > 10 ? student?.mobile?.substring(student?.mobile.length - 10) : student?.mobile,
                primaryEmail: student.primaryEmail,
                biometric: student.biometric,
                username: student.username,
                password: student.password,
                qualification: student.qualification,
                country: student.country,
                stateProvince: student.stateProvince,
                city: student.city,
                postalCode: student.postalCode,
                secondaryEmails: student.secondaryEmails,
                collegeId: student.academics[0].collegeId,
                courseId: student.academics[0].courseId,
                courseName: student.courseName,
                startYear: student.academics[0].startYear,
                endYear: student.academics[0].endYear,
            });
        }
        handleModalOpen();
    };

    const handleFilterChange = (e) => {
        console.log(`🚀 ~ file: RegisteredStudent.js:258 ~ handleFilterChange ~ e:`, e)
        setFilter(e);
        const {
            collegeId,
            courseId,
            searchKey
        } = e || {};
        setRowsPerPage(10);
        setPage(0);
        getRegisteredStudentListData(0, 10, collegeId, courseId, searchText, searchKey);
    }

    const approveRegisteredStudent = (selectedRows = []) => {
        let studentDetails = selectedRows.length > 0 ? selectedRows[0] : formData;
        const formValues = Object.assign({}, studentDetails);
		formValues.birthdate = new Date(formValues.birthdate).getTime();
		formValues.mobile = formValues.prefix + formValues.mobile;
        formValues.academics = [];
        if(formValues.secondaryEmails.length == 0) {
            delete formValues.secondaryEmails;
        }

		if (!formValues.firstName || formValues.firstName.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "First Name is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		} else if (!formValues.birthdate) {
			Swal.fire({
				title: "Empty",
				text: "Birth date is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else if (!formValues.gender || formValues.gender.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "Gender is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else if (!formValues.mobile || formValues.mobile.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "Mobile Number is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else if (!formValues.primaryEmail || formValues.primaryEmail.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "primary email is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else if (!formValues.username || formValues.username.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "username is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		}
		else if (!formValues.collegeId || formValues.collegeId.trim() === "") {
			Swal.fire({
				title: "Empty",
				text: "College is required!",
				icon: "error",
				confirmButtonColor: "#3F64AE",
				confirmButtonText: "Okay"
			});
		} else {
            createStudent({ ...formValues })
            .then((res) => {
                if(res.status === 200) {
                    handleModalClose();
                    deleteRegisteredStudent(selectedStudentId)
                    Swal.fire({
                        title: "Successful",
                        text: "Educator Approved Successful!",
                        icon: "success",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Okay"
                    }).finally(() => {
                        getRegisteredStudentListData(0, 10);
                    })
                }
            })
            .catch((error) => {
                if (error?.response?.data) {
                    Swal.fire({
                        title: "Error",
                        text: error.response.data,
                        icon: "error",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Okay"
                    }).finally(() => {
                        handleModalClose();
                    })
                } else {
                    Swal.fire({
                        title: "Error",
                        text: "Something went wrong please try again",
                        icon: "error",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Okay"
                    }).finally(() => {
                        handleModalClose();
                    })
                }
            });
        }
    }

    return (
        <div className={'view-registered-student-container ' + (sideNavState ? 'fullWidthOpenSideNav' : 'halfWidthOpenSideNav')}>
            <NavigateBack />
            <MembersContainer>
                <div>
                    <StudentFilter onFilterChange={handleFilterChange} isRegistredMember={true}/>
                    {registeredStudentData?.length > 0 ? (
                        <div >
                            <TableContainer component={Paper} sx={{ maxHeight: " 70vh" }}>
                                <Table stickyHeader sx={{ minWidth: 800, borderCollapse: "separate", borderSpacing: "0 10px" }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow className="TableRow">
                                            <TableCell align="center">
                                                <Checkbox checked={selectedRows?.length === registeredStudentData?.length} onChange={(e) => handleHeaderCheckboxChange(e)} />
                                            </TableCell>
                                            <TableCell className="tableHeaders" align="center">Student name</TableCell>
                                            <TableCell className="tableHeaders" align="center">Email</TableCell>
                                            <TableCell className="tableHeaders" align="center">Phone Number</TableCell>
                                            <TableCell className="tableHeaders" align="center">Course Name</TableCell>
                                            <TableCell className="tableHeaders" align="center">College Name</TableCell>
                                            <TableCell className="tableHeaders" align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {registeredStudentData.map((row) => {
                                            return (
                                                <TableRow className="TableRow" key={row._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover role="checkbox" tabIndex={-1}>
                                                    <TableCell align="center">
                                                        <Checkbox checked={selectedRows.includes(row._id)} onChange={(e) => handleCheckboxChange(e, row._id)} />
                                                    </TableCell>
                                                    <TableCell align="center">{row.firstName}</TableCell>
                                                    <TableCell align="center">{row.primaryEmail}</TableCell>
                                                    <TableCell align="center">{row.mobile}</TableCell>
                                                    <TableCell align="center">{row.courseName}</TableCell>
                                                    <TableCell align="center">{row.collegeName}</TableCell>
                                                    <TableCell align="right" sx={{ width: 178 }}>
                                                        <IconButton aria-label="view" onClick={() => handleOpenModal(false, row)}>
                                                            <img src={viewIcon} alt="" width={"25px"} />
                                                        </IconButton>
                                                        <IconButton aria-label="view" onClick={() => handleOpenModal(true, row)}>
                                                            <img src={editIcon} alt="" width={"19px"} />
                                                        </IconButton>
                                                        <IconButton aria-label="view" onClick={() => handleDeleteStudent(row._id)}>
                                                            <img src={deleteIcon} alt="" width={"22px"} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination component="div" count={count} page={page} onPageChange={handleChangePage} rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage} disabled={isLoading} />
                        </div>
                    ) : (
                        <div className='noDataFoundSection mt-5'>
                            <div>
                                <img src={noDataFoundImg} alt='' />
                            </div>
                            <div className='noDataFound mt-5'>No Registered Student(s) Found</div>
                        </div>
                    )}
                </div>
                <div>
                    <Modal
                        open={viewEditOpen}
                        onClose={handleModalClose}
                    >
                        <Box sx={style}>
                            <div className="d-flex justify-content-between align-items-center viewStudentHeader">
                                <div className='viewStudentDetails px-2'>
                                    {isEditMode ? 'Edit Student Details' : 'View Student Details'}
                                </div>
                                <button onClick={() => handleModalClose()} style={{
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '1.5rem',
                                    lineHeight: '1',
                                    cursor: 'pointer',
                                    color: '#000',
                                }}>&times;</button>
                            </div>
                            <div className='p-3 studentModal'>
                                    <div className='studentProfileForm row'>
                                        <div className='col-12'>
                                            <div className='studentProfile mb-2'>Student Profile</div>
                                            <div className='px-2 row'>
                                                <div className='col-3'>
                                                    <p className="input-label">First Name</p>
                                                    <input
                                                        className="custom-input"
                                                        type="text"
                                                        name="firstName"
                                                        value={firstName}
                                                        onChange={(event) => {
                                                            const key = event.target.name;
                                                            const value = event.target.value;
                                                            onFormValueChange(key, value);
                                                        }} disabled={!isEditMode}
                                                    />
                                                </div>
                                                <div className='col-3'>
                                                    <p className="input-label">Middle Name</p>
                                                    <input
                                                        className="custom-input"
                                                        type="text"
                                                        name="middleName"
                                                        value={middleName}
                                                        onChange={(event) => {
                                                            const key = event.target.name;
                                                            const value = event.target.value;
                                                            onFormValueChange(key, value);
                                                        }} disabled={!isEditMode}
                                                    />
                                                </div>
                                                <div className='col-3'>
                                                    <p className="input-label">Last Name</p>
                                                    <input
                                                        className="custom-input"
                                                        type="text"
                                                        name="lastName"
                                                        value={lastName}
                                                        onChange={(event) => {
                                                            const key = event.target.name;
                                                            const value = event.target.value;

                                                            onFormValueChange(key, value);
                                                        }} disabled={!isEditMode}
                                                    />
                                                </div>
                                                <div className='col-3'>
                                                    <p className="input-label">Full Name</p>
                                                    <input className="custom-input" disabled={true} type="text" name="fullName" value={fullName} />
                                                </div>
                                            </div>
                                            <div className='px-2 row'>
                                                <div className='col-4'>
                                                    <p className="input-label">Date Of Birth</p>
                                                    <input
                                                        className="custom-input"
                                                        type="date"
                                                        name="birthdate"
                                                        value={birthdate}
                                                        onChange={(event) => {
                                                            const key = event.target.name;
                                                            const value = event.target.value;

                                                            onFormValueChange(key, value);
                                                        }} disabled={!isEditMode}
                                                    />
                                                </div>
                                                <div className='col-4'>
                                                    <p className="input-label">Gender</p>
                                                    <select className="custom-input" name="gender" value={gender} onChange={(event) => {
                                                        const key = event.target.name;
                                                        const value = event.target.value;
                                                        onFormValueChange(key, value);
                                                    }} disabled={!isEditMode}>
                                                        <option value="" disabled>Select Gender</option>
                                                        <option value='Male'>Male</option>
                                                        <option value='Female'>Female</option>
                                                    </select>
                                                </div>
                                                <div className='col-4'>
                                                    <p className="input-label">Mobile No.</p>
                                                    <div className="row">
                                                        <div className="col-3 pe-0">
                                                        <input className="custom-input px-2" name="prefix" value={prefix} defaultValue={'+91'} readOnly></input>
                                                        </div>
                                                        <div className="col-9">
                                                            <input
                                                                className="custom-input"
                                                                type="text"
                                                                name="mobile"
                                                                value={mobile}
                                                                onChange={(event) => {
                                                                    const key = event.target.name;
                                                                    const value = event.target.value;
                                                                    onFormValueChange(key, value);
                                                                }} disabled={!isEditMode}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='px-2 row'>
                                                <div className='col-4'>
                                                    <p className="input-label">Primary Email</p>
                                                    <input
                                                        className="custom-input"
                                                        type="email"
                                                        name="primaryEmail"
                                                        value={primaryEmail}
                                                        onChange={(event) => {
                                                            const key = event.target.name;
                                                            const value = event.target.value;

                                                            onFormValueChange(key, value);
                                                        }} disabled={!isEditMode}
                                                    />
                                                </div>
                                                <div className='col-4'>
                                                    <p className="input-label">Secondary Email</p>
                                                    <input
                                                        className="custom-input"
                                                        type="email"
                                                        name="secondaryEmails"
                                                        value={secondaryEmails}
                                                        onChange={(event) => {
                                                            const key = event.target.name;
                                                            const value = event.target.value;

                                                            onFormValueChange(key, value);
                                                        }} disabled={!isEditMode}
                                                    />
                                                </div>
                                                <div className='col-4'>
                                                    <p className="input-label">Username</p>
                                                    <input
                                                        className="custom-input"
                                                        type="text"
                                                        name="username"
                                                        value={username}
                                                        onChange={(event) => {
                                                            const key = event.target.name;
                                                            const value = event.target.value;

                                                            onFormValueChange(key, value);
                                                        }} disabled={!isEditMode}
                                                    />
                                                </div>
                                            </div>
                                            <div className='studentProfile my-2'>Locality</div>
                                            <div className='px-2 row'>
                                                <div className='col-3'>
                                                    <p className="input-label">Country</p>
                                                    <select className="custom-input" value={country} name="country" onChange={(event) => {
                                                        const key = event.target.name;
                                                        const value = event.target.value;
                                                        onFormValueChange(key, value);
                                                    }} required disabled={!isEditMode}>
                                                        <option value="">Select country</option>
                                                        {countryList.map((country) => (
                                                            <option key={country?._id} value={country?.name}>
                                                                {country?.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className='col-3'>
                                                    <p className="input-label">State</p>
                                                    <select className="custom-input" value={stateProvince} name="stateProvince" onChange={(event) => {
                                                        const key = event.target.name;
                                                        const value = event.target.value;
                                                        onFormValueChange(key, value);
                                                    }} disabled={!country || !isEditMode || !statesList.length} required >
                                                        <option value="">Select State</option>
                                                        {statesList.map((state) => (
                                                            <option key={state} value={state}>
                                                                {state}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className='col-3'>
                                                    <p className="input-label">City</p>
                                                    <select className="custom-input" name='city' value={city} onChange={(event) => {
                                                        const key = event.target.name;
                                                        const value = event.target.value;
                                                        onFormValueChange(key, value);
                                                    }} disabled={!stateProvince || !isEditMode || !citiesList.length} required>
                                                        <option value="">Select City</option>
                                                        {citiesList.map((city) => (
                                                            <option key={city} value={city}>
                                                                {city}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className='col-3'>
                                                    <p className="input-label">Postal Code</p>
                                                    <input className="custom-input" type='number' name="postalCode" value={postalCode} onChange={(event) => {
                                                        const key = event.target.name;
                                                        const value = event.target.value;
                                                        onFormValueChange(key, value);
                                                    }} required disabled={!isEditMode}></input>
                                                </div>
                                            </div>
                                            <div className='studentProfile my-2'>Academics</div>
                                            <div className='px-2 row'>
                                                <div className='col-4'>
                                                    <p className="input-label">Course</p>
                                                    <input
                                                        className="custom-input"
                                                        type="text"
                                                        name="courseName"
                                                        value={courseName}
                                                        onChange={(event) => {
                                                            const key = event.target.name;
                                                            const value = event.target.value;

                                                            onFormValueChange(key, value);
                                                        }} disabled={!isEditMode}
                                                    />
                                                </div>
                                                <div className='col-4'>
                                                    <p className="input-label">Start Year</p>
                                                    <input
                                                        className="custom-input"
                                                        type="text"
                                                        name="startYear"
                                                        value={startYear}
                                                        onChange={(event) => {
                                                            const key = event.target.name;
                                                            const value = event.target.value;

                                                            onFormValueChange(key, value);
                                                        }} disabled={!isEditMode}
                                                    />
                                                </div>
                                                <div className='col-4'>
                                                    <p className="input-label">End Year</p>
                                                    <input
                                                        className="custom-input"
                                                        type="text"
                                                        name="endYear"
                                                        value={endYear}
                                                        onChange={(event) => {
                                                            const key = event.target.name;
                                                            const value = event.target.value;

                                                            onFormValueChange(key, value);
                                                        }} disabled={!isEditMode}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {isEditMode && (
                                        <div className="form-submit-card mb-2 mt-3 d-flex justify-content-end">
                                            <button className="approve-btn" onClick={() => {approveRegisteredStudent()}}>
                                                Approve
                                            </button>
                                            <button className="decline-btn" type="button" onClick={handleModalClose}>
                                                Close
                                            </button>
                                        </div>
                                    )}
                            </div>
                        </Box>
                    </Modal>
                </div>
            </MembersContainer>
        </div>
    )
}
