import React, { useState, useEffect } from 'react';
import './BulkStudentPromotion.scss';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../../Assets/commonImg/searchIcon.svg';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Swal from 'sweetalert2';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '../../../Assets/commonImg/crossImg.svg';
import downArrow from '../../../Assets/commonImg/down-dark-arrow.svg';
import NavigateBack from '../../CommonScreens/NavigateBack/NavigateBack';
import { getStudentList, promoteBulkStudent } from '../../../Services/StudentService';
import { SelectCollegeModal } from '../../../Components/SelectCollegeModal/SelectCollegeModal';
import { SelectCourseModal } from '../../../Components/SelectCourseModal/SelectCourseModal';
import { getAllCourseOfSelectedCollege, getCourseList } from '../../../Services/memberService';
import MembersContainer from '../../../Components/MembersContainer/MembersContainer';
import noDataFoundImg from "../../../Assets/memberImg/noStudentFound.png";
import { Checkbox } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    width: '50%'
};
const promoteStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 200,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    width: '20%'
}

export default function BulkStudentPromotion({ sideNavState, setSideNavState }) {

    const navigate = useNavigate();
    const [bulkStudentData, setBulkStudentData] = useState([]);
    const [searchText, setSearchText] = useState('')
    const [students, setStudents] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setTotalBulkStudents] = useState(0);
    const [selectedNewCourse, setNewCourse] = useState({});
    const [isLoading, setLoading] = useState(false);

    const [courseListData, setCourseListData] = useState([]);
    const [showUpdateStudentModal, setShowUpdateStudentModal] = useState(false);
    const [showPromoteModal, setShowPromoteModal] = useState(false);
    const [openCollege, setCollegeOpen] = useState(false);
    const [openCourse, setCourseOpen] = useState(false);

    const initialState = {
        collegeName: '',
        collegeId: '',
        courseName: '',
        courseId: ''
    };
    const [formData, setFormData] = useState(initialState);
    const { collegeName, courseName, collegeId, courseId } = formData;

    const goBack = () => {
        navigate('/home/members', { replace: true });
    }

    useEffect(() => {
        if (collegeId && courseId) {
            getBulkStudentsListData(0, 10);
        }
    }, [])

    const getBulkStudentsListData = async (offset, limit, searchText = null, collegeId = null, courseId = null) => {
        try {
            setLoading(true);
            const studentData = await getStudentList(offset, limit, "fullName", searchText, collegeId, courseId);
            setLoading(false);
            if (studentData && studentData?.data) {
                const studentListData = studentData.data.students.map(student => ({
                    ...student,
                    collegeName: student.academics[0]?.collegeName || 'N/A',
                    courseName: student.academics[0]?.courseName || 'N/A',
                }));
                const count = studentData.data.count || 0;
                setBulkStudentData(studentListData);
                setTotalBulkStudents(count);
            } else {
                setBulkStudentData([]);
                setTotalBulkStudents(0);
            }
        } catch (error) {
            setLoading(false);
            console.log("getStudent List", error);
        }
    }


    const getcourseList = async (collegeId) => {
        try {
            const courseData = await getAllCourseOfSelectedCollege(collegeId);
            const courseListData = courseData.data;
            setCourseListData(courseListData);
        } catch (error) {
            console.log('errorssss in college list', error)
        }
    }

    const handleHeaderCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setStudents(isChecked ? bulkStudentData : []);
        // setIsDeleteAllDisabled(!isChecked);
    };

    const handleCheckboxChange = (e, selectedStudent) => {
        const index = students.findIndex(item => item?._id === selectedStudent?._id);
        if (index === -1) {
            setStudents([...students, { ...selectedStudent }]);
        } else {
            setStudents(students.filter(student => student._id !== selectedStudent?._id));
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getBulkStudentsListData(newPage * rowsPerPage, rowsPerPage, searchText, collegeId, courseId);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getBulkStudentsListData(0, (parseInt(event.target.value, 10)), searchText, collegeId, courseId)
    };

    const handleUpdateStudentClose = () => setShowUpdateStudentModal(false);

    const handlePromoteModalClose = () => setShowPromoteModal(false);

    const handleNewCourseChange = (event) => {
        const course = courseListData.find((x) => x._id === event.target.value);
        setNewCourse(course);
    }

    const handleButtonClick = () => {
        if (!collegeId) {
            Swal.fire({
                title: 'Validation Error',
                text: 'Please select a college.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#5284EE'
            });
            return;
        }
        
        if (!courseId) {
            Swal.fire({
                title: 'Validation Error',
                text: 'Please select the present course name.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#5284EE'
            });
            return;
        }
        
        setNewCourse({});
        if (students?.length) {
            Swal.fire({
                title: 'warning!',
                text: 'After changing the course student will loose the access To his previous course notes, assignments, lecture Videos, live class videos',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: '  Ok  ',
                confirmButtonColor: '#5284EE',
                confirmButtonWidth: '89px',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    // setShowUpdateStudentModal(true);
                    setShowPromoteModal(true);
                }
            });
        } else {
            Swal.fire({
                title: 'warning!',
                text: 'Please select the student first',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: '  Ok  ',
                confirmButtonColor: '#5284EE',
                confirmButtonWidth: '89px',
                reverseButtons: true
            })
        }
    };

    const handleModalClose = () => {
        setShowUpdateStudentModal(false);
    };

    const onUpdateClick = () => {
        const params = {
            collegeId: collegeId,
            currentCourseId: courseId,
            newCourseId: selectedNewCourse?._id
        }
        const body = {
            studentList: students?.map((x) => x._id)
        }
        promoteBulkStudent(params, body).then((resData) => {
            if (resData?.data?.message === "success") {
                Swal.fire({
                    title: 'successfully!',
                    text: 'Student promoted successfully!',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: '  Ok  ',
                    confirmButtonColor: '#5284EE',
                    confirmButtonWidth: '50px',
                    reverseButtons: true
                }).then((resData) => {
                    goBack();
                })
            }
        }).catch((error) => {
            console.log('Error while promoting student ', error)
        });
    }

    const onContinueClick = () => {
        setShowPromoteModal(false)
        setShowUpdateStudentModal(true);
    }

    const handleOpen = () => {
        setCollegeOpen(true);
    };

    const handleClose = () => setCollegeOpen(false);
    const handleCourseOpen = () => { setCourseOpen(true) };
    const handleCourseClose = () => setCourseOpen(false);

    const setCollegeName = (value) => {
        console.log(`🚀 ~ file: BulkStudentPromotion.js:239 ~ setCollegeName ~ value:`, value)
        setFormData((prevState) => {
            const updatedFormValues = { ...prevState, ...value };
            return updatedFormValues;
        });
        setRowsPerPage(10);
        setPage(0);
        setCourseName('');
        setBulkStudentData([]);
        setTotalBulkStudents(0);
        getcourseList(value.collegeId)
    }

    const setCourseName = (value) => {
        if (!value) {
            value = {
                courseId: '',
                courseName: '',
            }
        }
        setFormData((prevState) => {
            const updatedFormValues = { ...prevState, ...value };
            return updatedFormValues;
        });
        setRowsPerPage(10);
        setPage(0);
        if (collegeId && value && value?.courseId) {
            getBulkStudentsListData(0, 10, searchText, collegeId, value.courseId);
        }
        const updatedArray = courseListData.filter(item => item._id !== value.courseId);
        setCourseListData(updatedArray);
        setStudents([]);
    }

    const commonValues = bulkStudentData?.filter(obj1 =>
        students?.some(obj2 => obj1._id === obj2._id)
    );

    const filters = (
        <div className='filter-section py-2 mb-3'>
            <div className='row mb-3'>
                <div className='col-xl-3 col-12'>
                    <p className="input-label" >Select College</p>
                    <div className='position-relative'>
                        <input className="custom-input" type='text' value={collegeName} onClick={handleOpen} readOnly placeholder="Select College" />
                        <div className='down-arrow'> {" "} <img src={downArrow} width={'12px'} height={'13px'} marginTop={'5px'} /></div>
                    </div>
                </div>
                <div className='col-xl-3 col-12'>
                    <p className="input-label" >Present Course Name</p>
                    <div className='position-relative'>
                        <input className="custom-input" type='text' value={courseName} onClick={handleCourseOpen} readOnly disabled={!collegeId} placeholder="Select Course" />
                        <div className='down-arrow'> {" "} <img src={downArrow} width={'12px'} height={'13px'} marginTop={'5px'} /></div>
                    </div>
                </div>
                <div className='col-xl-6 col-12'>
                    <div className='col-xl-8 col-12'>
                        <div className="mt-4 px-0">
                            <div className='search-field position-relative'>
                                <input className="custom-select-input px-3 mx-0" type='text' placeholder="Search by name" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} />
                                {/* <button className="search-btn" onClick={() => { getBulkStudentsListData(0, 10, searchText, collegeId, courseId); }}><img src={SearchIcon} width={'18px'} height={'18px'} marginRight={'10px'} />Search</button> */}
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-10 d-none'></div>
                </div>
            </div>
            {(count > 0) && <div className='d-flex justify-content-center gap-3'>
                <div className='d-flex'>{<button className='preview-submit-btn' onClick={handleButtonClick}>Preview & Submit</button>}</div>
                <div className='d-flex'>{<button className='reset-btn'>Reset</button>}</div>
            </div>}
        </div>
    );

    return (
        <div className={'bulk-student-promotion-container ' + (sideNavState ? 'fullWidthOpenSideNav' : 'halfWidthOpenSideNav')}>
            <NavigateBack />
            <MembersContainer>
                {filters}
                {(collegeId && courseId && count > 0) ? <div>
                    <TableContainer component={Paper} sx={{ maxHeight: " 70vh" }}>
                        <Table stickyHeader sx={{ minWidth: 800, borderCollapse: "separate", borderSpacing: "0 10px" }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            onChange={handleHeaderCheckboxChange}
                                            checked={(commonValues?.length && students?.length && bulkStudentData?.length === students?.length) || false}
                                            indeterminate={((commonValues?.length || students?.length) && bulkStudentData?.length !== students?.length) || false}
                                        />
                                    </TableCell>
                                    <TableCell className="tableHeaders" align="center">Student Name
                                    </TableCell>
                                    <TableCell className="tableHeaders" align="center">Email</TableCell>
                                    <TableCell className="tableHeaders" align="center">College Name</TableCell>
                                    <TableCell className="tableHeaders" align="center">Course Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bulkStudentData.map((row) => {
                                    return (
                                        <TableRow>
                                            <TableCell align="left">
                                                <input type='checkbox' value={row?._id} checked={students.some(student => student._id === row._id)} onChange={(e) => handleCheckboxChange(e, row)} />
                                            </TableCell>
                                            <TableCell align="center">{row.fullName}</TableCell>
                                            <TableCell align="center">{row.primaryEmail}</TableCell>
                                            <TableCell align="center">{row.collegeName}</TableCell>
                                            <TableCell align="center">{row.courseName}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        disabled={isLoading}
                    />
                </div> :
                    <div>
                        {(!collegeId || !courseId) && <p className='text-center font-24'>Please select college and course to preview bulk students data.</p>}
                        <div className='text-center'><img style={{ height: '22rem' }} src={noDataFoundImg} alt="img" /></div>
                        {(collegeId && courseId && count == 0) && <p className='text-center font-20'>No Student(s) Found!</p>}
                    </div>
                }
                <Modal open={showUpdateStudentModal} onClose={handleModalClose}>
                    <Box sx={style}>
                        <div className='update-student-course-modal'>
                            <div className='d-flex justify-content-between px-2 pb-2 pt-3 mx-2 update-student-course-header' style={{ borderBottom: '1px solid #70707080' }}>
                                <div className='update-student-course-detail'>Update Student Course</div>
                                <div onClick={handleUpdateStudentClose} style={{ cursor: 'pointer' }}><img src={CloseIcon} width={'26px'} height={'26px'} marginTop={'5px'} /></div>
                            </div>
                            <div className='px-3 py-2 update-student-course-body'>
                                <p className='note'><b>Note</b>: After Changing the course student will loose access to his previous course notes, assignments, lecture Videos, live class videos</p>
                                <div>
                                    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
                                        <TableContainer>
                                            <Table sx={{
                                                background: '#FFFFFF',
                                                boxShadow: 'none !important',
                                                borderRadius: 'none !important',
                                                marginTop: '1px',
                                            }}>
                                                <TableHead >
                                                    <TableRow className='header-row'>
                                                        <TableCell align="center">Name</TableCell>
                                                        <TableCell align="center">Email</TableCell>
                                                        <TableCell align="center">Current Course</TableCell>
                                                        <TableCell align="center">Change Into</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody sx={{
                                                    overflow: 'scroll',
                                                }}>
                                                    {students
                                                        .map((row) => {
                                                            return (
                                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                    <TableCell align="center">{row?.fullName}</TableCell>
                                                                    <TableCell align="center">{row?.primaryEmail}</TableCell>
                                                                    <TableCell align="center">{row?.courseName}</TableCell>
                                                                    <TableCell align="center">{selectedNewCourse?.courseName || ''}</TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </div>
                                <div className='d-flex justify-content-end my-2 gap-3'>
                                    <button className='update-btn' onClick={onUpdateClick}>Update</button>
                                    <button className='close-btn' onClick={handleClose}>Close</button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
                <Modal open={showPromoteModal} onClose={handlePromoteModalClose}>
                    <Box sx={promoteStyle}>
                        <div className='update-student-promote-modal'>
                            <div className='d-flex justify-content-end px-2 mx-2'>
                                <div onClick={() => { handlePromoteModalClose(); setNewCourse({}) }} style={{ cursor: 'pointer', fontSize: '35px' }}>&times;</div>
                            </div>
                            <div className='px-3 py-2 update-student-promote-body'>
                                <p className='note'>Please Select the Course Where You want to Promote</p>
                                <div className='position-relative w-100'>
                                    <select className="custom-select-course-input px-4" onChange={handleNewCourseChange} value={selectedNewCourse?._id || ''}>
                                        {courseListData && courseListData.length ? (<option value='' >Select Course</option>) : <option disabled>No courses</option>}
                                        {courseListData && courseListData.map((course, index) => (
                                            <option key={index} value={course?._id}>{course?.courseName}</option> ))
                                        }
                                    </select>
                                </div>
                                <div className='d-flex justify-content-end my-4'>
                                    <button className='continue-btn' disabled={!selectedNewCourse?._id} onClick={onContinueClick}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
                <Modal
                    open={openCollege}
                    onClose={handleClose}
                    centered backdrop="static"
                >
                    <SelectCollegeModal {...{ handleClose, collegeId, setCollegeName }} />
                </Modal>
                <Modal
                    open={openCourse}
                    onClose={handleCourseClose}
                    centered backdrop="static"
                >
                    <SelectCourseModal {...{ handleCourseClose: handleCourseClose, setCourseName, collegeId }} />
                </Modal>
            </MembersContainer>
        </div>

    )
}
