import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState, useEffect } from 'react';
import SearchIcon from '../../Assets/commonImg/searchIcon.svg';
import Paper from '@mui/material/Paper';
import { getAllCourseOfSelectedCollege } from '../../Services/memberService';
import "./SelectCourseModal.scss";
export const SelectCourseModal = (args) => {
    const {
        onFormValueChange,
        handleCourseClose,
        collegeId,
        setCourseName
    } = args;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        // bgcolor: 'background.paper',
        border: 'none',
        // boxShadow: 24,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '8px',
        padding: '10px',
    };

    useEffect(() => {
        getCoursesList(collegeId);
    }, []);

    const [courseData, setCourseData] = useState([]);
    const [searchVal, setSearchVal] = useState("");

    const handleSearchClick = () => {
        if (searchVal === "") { setCourseData(courseData); return; }
        const filterBySearch = courseData.filter((item) => {
            if (item.courseName.toLowerCase()
                .includes(searchVal.toLowerCase())) { return item; }
        })
        console.log(filterBySearch)
        setCourseData(filterBySearch, searchVal);
    }

    const getCoursesList = async (collegeId) => {
        try {
            const courseListData = await getAllCourseOfSelectedCollege(collegeId);
            setCourseData(courseListData.data);
        } catch (error) {
            console.log('error in college list', error)
        }
    }

    const onValueChange = (uuid, courseName) => {
        if (onFormValueChange) {
            onFormValueChange('courseId', uuid);
        }
        if (setCourseName) {
            setCourseName({ 'courseId': uuid, courseName: courseName });
        }
        handleCourseClose();
    }

    return (<>
        <Box sx={style}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className='collegeList px-2'>Course List</div>
                <button onClick={() => handleCourseClose()} style={{
                    background: 'none',
                    border: 'none',
                    fontSize: '1.5rem',
                    lineHeight: '1',
                    cursor: 'pointer',
                    color: '#000',
                }}>&times;</button>
            </div>
            <div className="px-3">
                <div className="position-relative mb-3">
                    <input type="text" placeholder="Search by course name"
                     onChange={e => setSearchVal(e.target.value)}
                     style={{
                        height: '36px',
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '5px',
                        border: 'none',
                        padding: '0 25px',
                    }} />
                    <button style={{
                        height: '36px',
                        position: 'absolute',
                        right: '0',
                        top: '0',
                        background: '#2580EC 0% 0% no-repeat padding-box',
                        boxShadow: '0px 3px 6px #00000029',
                        border: '1px solid #FFFFFF',
                        borderRadius: '5px',
                        color: '#FFFFFF'
                    }} onClick={handleSearchClick}><img src={SearchIcon} width={'18px'} height={'18px'} />Search</button>
                </div>
                <div>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{
                                        backgroundColor: '#76A5DE',
                                        border: '1px solid #707070',
                                        font: 'normal normal medium 15px/20px Roboto',
                                        color: '#FFFFFF !important'
                                    }}>Course Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(courseData) && courseData.length > 0 ? (
                                    courseData.map((row, id) => (
                                        <TableRow
                                            key={row?.courseName + id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            onClick={() => { onValueChange(row._id, row?.courseName); }}
                                        >
                                            <TableCell component="th" scope="row"
                                                sx={{ border: 'none', px: '17px', py: '9px', cursor: 'pointer' }} >
                                                {row?.courseName}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={1} align="center">
                                            No courses found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <TablePagination
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                </div>
            </div>
        </Box>
    </>);
}