import React, { useState } from 'react'
import './HomePage.scss'
import Header from '../../../Components/Header/Header';
import SideNav from '../../../Components/SideNav/SideNav';
import { Outlet } from 'react-router';


export default function HomePage({sideNavState, setSideNavState}) {
  return (
    <div className='homepage-container'>
        <Header sideNavState={sideNavState} setSideNavState={setSideNavState}></Header>
        <SideNav sideNavState={sideNavState} setSideNavState={setSideNavState}></SideNav>
        <Outlet/>
    </div>
  ) 
}
