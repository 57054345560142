import React from 'react';
import './MostViewedVideo.scss';
import { useNavigate } from 'react-router-dom';
import videoIcon from '../../../../Assets/commonImg/videoImg.png';
import viewIcon from '../../../../Assets/commonImg/view.svg';
import BackIcon from '../../../../Assets/commonImg/back.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import star from '../../../../Assets/commonImg/star.svg';
import { Tooltip } from '@mui/material';


function createCollegeData(videoTitle, educator, username, college, course, view, rating, grade) {
  return { videoTitle, educator, username, college, course, view, rating, grade };
}
const collegeRows = [
  createCollegeData('Video Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A+'),
  createCollegeData('Video Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A-'),
  createCollegeData('Video Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
  createCollegeData('Video Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
  createCollegeData('Video Title', 'Dr. Pradeep', 'Dr. Pradeep', 'BGS College', 'BDS 1st Year', '4.5 K', '4', 'A'),
];

export default function MostViewedVideo({ sideNavState, setSideNavState }) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate('/home/collegePerformance', { replace: true });
  }
  return (
    <div className='view-videos-container'
      style={{
        width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
        marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
        transition: "0.5s",
        position: "absolute",
        top: "4rem",
        padding: "1%",

      }}>
      <div className='backBtn' onClick={goBack}><img src={BackIcon} width={'12px'} height={'13px'} marginTop={'5px'} /><div className='backText'>Back</div></div>
      <div className='view-videos-card p-3'>
        <div className=''>
          <div className='view-videos-title'>
            Most Viewed videos
          </div>
          <div className='view-videos-content p-2 my-2'>
            <div className='my-4 px-2'>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow sx={{
                      backgroundColor: '#EAF2FC',
                      font: 'normal normal normal 16px/21px Roboto',
                      color: '#000000',
                    }}>
                      <TableCell align="center" className='head-title'>Video Title</TableCell>
                      <TableCell align="center" className='head-title'>Educator</TableCell>
                      <TableCell align="center" className='head-title'>Username</TableCell>
                      <TableCell align="center" className='head-title'>College</TableCell>
                      <TableCell align="center" className='head-title'>Course</TableCell>
                      <TableCell align="center" className='head-title'>Views</TableCell>
                      <TableCell align="center" className='head-title'>Ratings</TableCell>
                      <TableCell align="center" className='head-title'>Grade</TableCell>
                      <TableCell align="center" className='head-title'>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {collegeRows.map((collegeRows) => (
                      <TableRow
                        key={collegeRows.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" align="center" className='table-data'><img src={videoIcon} width={'44px'} height={'30px'} marginTop={'5px'} />{collegeRows.videoTitle}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{collegeRows.educator}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{collegeRows.username}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{collegeRows.college}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{collegeRows.course}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{collegeRows.view}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>{collegeRows.rating}<img src={star} width={'15px'} height={'15px'} style={{ marginLeft: '5px' }} /></TableCell>
                        <TableCell component="th" align="center" className='table-data'>{collegeRows.grade}</TableCell>
                        <TableCell component="th" align="center" className='table-data'>
                          <Tooltip title="view">
                            <img src={viewIcon} width={'16px'} height={'8px'} marginTop={'5px'} />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
