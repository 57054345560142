import React, { useState } from "react";
import "./Report.scss";
import educatorIcon from "../../Assets/commonImg/educator-icon.svg";
import StudentIcon from "../../Assets/commonImg/student-iocn.svg";
import trainingIcon from "../../Assets/commonImg/training-video-icon.svg";
import { requestReport } from "../../Services/ReportService";
import Swal from "sweetalert2";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
const radioButtonsData = {
  auditReport: {
    radioButtons: [
      {
        type: "radio",
        className: "form-check-input",
        id: "admin",
        name: "redoOptions",
        value: "admin",
        label: {
          className: "form-check-label",
          htmlFor: "admin",
          text: "Admin",
        },
      },
      {
        type: "radio",
        className: "form-check-input",
        id: "educator",
        name: "redoOptions",
        value: "educator",
        label: {
          className: "form-check-label",
          htmlFor: "educator",
          text: "Educator",
        },
      },
      {
        type: "radio",
        className: "form-check-input",
        id: "student",
        name: "redoOptions",
        value: "student",
        label: {
          className: "form-check-label",
          htmlFor: "student",
          text: "Student",
        },
      },
    ],
  },
};

const ExportDocument = ({ college }) => {
  const { collegeId } = college;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };
  const getEducatorRequestReport = () => {
    if (collegeId) {
      const payload = {
        collegeId,
        reportType: 'export',
        entity: 'educator'
      }
      requestReport(payload).then((resData) => {
        if (resData?.data?.message === "success") {
          Swal.fire('Report Requested', 'Your request has been accepted to generate the report', "success")
        }
      }).catch((error) => {
        if (error?.response?.data && typeof error?.response?.data === 'string') {
          Swal.fire(error?.response?.data, '', "error");
        }
      });
    } else {
      Swal.fire('Please Select College', '', "error")
    }

  }

  const getStudentRequestReport = () => {
    if (collegeId) {
      const payload = {
        collegeId,
        reportType: 'export',
        entity: 'student'
      }
      requestReport(payload).then((resData) => {
        if (resData?.data?.message === "success") {
          Swal.fire('Report Requested', 'Your request has been accepted to generate the report', "success")
        }
      }).catch((error) => {
        if (error?.response?.data && typeof error?.response?.data === 'string') {
          Swal.fire(error?.response?.data, '', "error");
        }
      });
    } else {
      Swal.fire('Please Select College', '', "error")
    }

  }

  const getLectureVideoReport = () => {
    if (collegeId) {
      const payload = {
        collegeId,
        reportType: 'export',
        entity: 'lecture-video',
        startTs: new Date(endDate).getTime(),
        endTs: new Date(startDate).getTime()
      }
      requestReport(payload).then((resData) => {
        if (resData?.data?.message === "success") {
          Swal.fire('Report Requested', 'Your request has been accepted to generate the report', "success")
        }
      }).catch((error) => {
        if (error?.response?.data && typeof error?.response?.data === 'string') {
          Swal.fire(error?.response?.data, '', "error");
        }
      });
    } else {
      Swal.fire('Please Select College', '', "error")
    }

  }



  return (
    <div id="export-document" className="container">
      <hr />
      <div className="row card-row">
        <div className="col-12 col-md-3 mb-3 mb-md-0">
          <div class="card-top1">
            <div className="card-section">
              <img src={educatorIcon} className="card-icon" />
            </div>
          </div>

          <div class="card-wrapper">
            <div class="card-top">
              <h2>Educator</h2>
            </div>

            <div class="card-bottom" style={{ display: 'flex', justifyContent: 'center' }}>
              <button class="button" onClick={getEducatorRequestReport}><DescriptionOutlinedIcon/> Request Report</button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div class="card-top1">
            <div className="card-section">
              <img src={StudentIcon} className="card-icon" />
            </div>
          </div>

          <div class="card-wrapper">
            <div class="card-top">
              <h2>Student</h2>
            </div>

            <div class="card-bottom" style={{ display: 'flex', justifyContent: 'center' }}>
              <button class="button" onClick={getStudentRequestReport}><DescriptionOutlinedIcon/> Request Report</button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div class="card-top1">
            <div className="card-section">
              <img src={trainingIcon} className="card-icon" />
            </div>
          </div>

          <div class="card-wrapper">
            <div class="card-top">
              <h2>Lecture Video</h2>
            </div>

            <div class="card-bottom mt-4">
              <div>
                <label>End Date</label>
                <input
                  id="endDate"
                  type="date"
                  className="form-control"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
              <div>
                <label>Start Date</label>
                <input
                  id="startDate"
                  type="date"
                  className="form-control"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '-10px' }}>
                <button class="button" onClick={getLectureVideoReport} disabled={!endDate || !startDate}><DescriptionOutlinedIcon/> Request Report</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportDocument;
