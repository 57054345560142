const url = {
    college: {
        educator: {
            updateEducator: 'college/educator',
        },
        student: {
            updateStudent: 'college/student'
        }
    },
    university: {
        admin: {
            get: 'university/admin',
            getCollegeList: 'university/college',
            getCourseList: 'course',
            getCountry: 'address'
        },
        college: {
            getCollegeList: 'university/college',
            createCollege: 'university/college',
        },
        collegeAdmin: {
            createCollegeAdmin: 'collegeadmin',
            getCollegeAdmin: 'collegeadmin'
        },
        address: 'address',
        educator: {
            createEducator: 'educator',
            getEducator: 'educator',
            getRegisteredEducator: 'educator/waiting',
            resetPassword: 'educator/reset'
        },
        student: {
            createStudent: 'student',
            getStudent: 'student',
            promotion: 'student/promotion',
            getRegisteredStudent: 'student/waiting',
            createBulkStudents: 'student/create',
            resetPassword: 'student/reset'
        },
        dashboard: {
            dashboard: 'university/dashboard'
        },
        report: {
            report: 'report'
        },
        subject: {
            subject: 'subject'
        },
        groups: {
            groups: 'groups'
        }
    },
};


export { url }