import { useEffect, useState } from "react";
import downArrow from "../../Assets/commonImg/select-dropdown-arrow.svg";
import SearchField from "../searchField/searchField";
import { SelectCourseModal } from "../SelectCourseModal/SelectCourseModal";
import { SelectCollegeModal } from "../SelectCollegeModal/SelectCollegeModal";
import { Modal } from "@mui/material";

const StudentFilter = ({ onFilterChange, isRegistredMember = false }) => {
    let options = [
        { id: 'fullName', displayText: 'Search By Name' },
        { id: 'mobile', displayText: 'Search By Phone Number' },
        { id: 'username', displayText: 'Search By User Name' },
        { id: 'primaryEmail', displayText: 'Search By Email' },
    ]

    /** If member is registered member, them username option 
     * should be eliminated from filter search option
     * isRegistredMember is true is member is registered member */
    if(isRegistredMember && options) {
        options?.splice(options?.findIndex(({id}) => id === "username"), 1);
    }

    const [filterForm, SetFilterForm] = useState({});
    const [openCourseModal, setOpenCourseModal] = useState(false)
    const [openCollegeModal, setOpenCollegeModal] = useState(false)
    const [searchKey, setSearchKey] = useState(options[0].id);
    const { collegeName = '', courseName = '', collegeId, courseId, searchText } = filterForm;

    const handleCollegeOpen = () => setOpenCollegeModal(true);
    const handleCourseOpen = () => setOpenCourseModal(true);
    const handleCloseModalCourse = () => setOpenCourseModal(false);
    const handleCloseModalCollege = () => setOpenCollegeModal(false);
    const handleSearchByName = (e) => {
        if (searchText) {
            SetFilterForm((previousValue) => { return { ...previousValue, searchText, searchKey: e } });
        }
        setSearchKey(e);
    };
    const handleSearchButtonClick = (e) => {
        SetFilterForm((previousValue) => { return { ...previousValue, searchText: e, searchKey } })
    };

    const setCourseName = (event) => {
        SetFilterForm((previousValue) => { return { ...previousValue, ...event } });
    }
    const setCollegeName = (event) => {
        SetFilterForm((previousValue) => { return { ...previousValue, ...event } });
    }

    const placeHolderForSearchBy = options.find((x) => x.id === searchKey).displayText;

    useEffect(() => {
        if (filterForm) {
            if (typeof onFilterChange === 'function') {
                onFilterChange(filterForm);
            }
        }
    }, [filterForm])

    return (
        <>
            <div className="pt-3 px-3">
                <div className="row">
                    <div className="col-md-4 col-lg-3 col-xl-2 col-sm-12 col-12">
                        <div className="position-relative">
                            <input className="custom-input ps-3" type="text" placeholder="Select College" onClick={handleCollegeOpen} readOnly value={collegeName} />
                            <div className="down-arrow">
                                {" "}
                                <img src={downArrow} width={"12px"} height={"13px"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-xl-2 col-sm-12 col-12">
                        <div className="position-relative">
                            <input className="custom-input ps-3" type="text" placeholder="Select Course" onClick={handleCourseOpen} readOnly value={courseName}  disabled={!collegeId}/>
                            <div className="down-arrow">
                                {" "}
                                <img src={downArrow} width={"12px"} height={"13px"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 col-xl-2 col-sm-12 col-12">
                        <select className="custom-input ps-3" name="stateProvince" value={searchKey} onChange={(e) => handleSearchByName(e.target.value)}>
                            {
                                options.map((option, index) => <>
                                    <option key={option?.id + index} value={option?.id}>{option?.displayText}</option>
                                </>)
                            }
                        </select>
                    </div>
                    <div className="col-md-6 col-lg-3 col-xl-4 col-sm-12 col-12">
                        <span className="position-relative">
                            <SearchField placeholderText={`${placeHolderForSearchBy}`} onSearchFun={handleSearchButtonClick} isCovered={true} />
                        </span>
                    </div>
                </div>
            </div>


            <Modal open={openCourseModal} onClose={handleCloseModalCourse}>
                <div>
                    <SelectCourseModal {...{ handleCourseClose: handleCloseModalCourse, setCourseName, collegeId }} />
                </div>
            </Modal>
            <Modal open={openCollegeModal} onClose={handleCloseModalCollege}>
                <div>
                    <SelectCollegeModal {...{ handleClose: handleCloseModalCollege, setCollegeName }} />
                </div>
            </Modal>
        </>
    );
};
export default StudentFilter;
