import React, { useEffect, useState } from "react";
import styles from "./UploadedContent.module.scss";
import AssignmentPieChart from "../../Components/AssignmentPieChart/AssignmentPieChart";
import AssessmentPieChart from "../AssessmentPieChart/AssessmentPieChart";
import * as DashboardService from "../../Services/DashboardService";
import { NextPreviousNavButton } from "../NextPreviousNavButton/NextPreviousNavButton";

const UploadedContent = ({ submissionGraphCount }) => {
    const [submissionGraphData, setSubmissionGraph] = useState(null);
    const [submissionGraphPayload, setSubmissionGraphPayload] = useState(new Date());
    useEffect(() => {
        getSubmissionGraph({
            year: submissionGraphPayload.getFullYear(),
            month: submissionGraphPayload.getMonth() + 1,
        });
    }, []);

    useEffect(() => {
        if (submissionGraphPayload) {
            getSubmissionGraph({
                year: submissionGraphPayload.getFullYear(),
                month: submissionGraphPayload.getMonth() + 1,
            });
        }
    }, [submissionGraphPayload]);

    const getSubmissionGraph = (payload) => {
        const { year = new Date().getFullYear(), month = new Date().getMonth() } = payload || {};
        DashboardService.getSubmissionGraph({ month, year })
            .then((resData) => {
                if (resData.data && resData.status === 200) {
                    setSubmissionGraph(resData.data);
                } else {
                    setSubmissionGraph(null);
                }
            })
            .catch((error) => {
                setSubmissionGraph(null);
            });
    };
    const onYearChange = (event) => {
        const { isNext, isPrevious } = event;
        let selectedYear = new Date(submissionGraphPayload);
        if (isNext) {
            const data = selectedYear.setFullYear(selectedYear.getFullYear() + 1);
            setSubmissionGraphPayload(() => {
                return new Date(data);
            });
        }
        if (isPrevious) {
            const data = selectedYear.setFullYear(selectedYear.getFullYear() - 1);
            setSubmissionGraphPayload(() => {
                return new Date(data);
            });
        }
    }

    const onMonthChange = (event) => {
        const { isNext, isPrevious } = event;
        let selectedYear = new Date(submissionGraphPayload);
        if (isNext) {
            const data = selectedYear.setMonth(selectedYear.getMonth() + 1);
            setSubmissionGraphPayload(() => {
                return new Date(data);
            });
        }
        if (isPrevious) {
            const data = selectedYear.setMonth(selectedYear.getMonth() - 1);
            setSubmissionGraphPayload(() => {
                return new Date(data);
            });
        }
    }

    const { assignment = {}, assessment = {} } = submissionGraphData || {};
    return (
        <div>
            <div className={styles.uploadedContentHeading}>Uploaded Content</div>
            <div className={styles.uploadedCard}>
                <div className={styles.assignmentAssessmentText}>
                    Assignment
                    <div className="d-flex align-items-center">
                        <NextPreviousNavButton label={submissionGraphPayload.getFullYear()} handleNavigationChange={onYearChange} />
                        <NextPreviousNavButton label={submissionGraphPayload.toLocaleString('default', { month: 'short' })} handleNavigationChange={onMonthChange} />
                    </div>
                </div>
                <div>
                    {" "}
                    <AssignmentPieChart assignmentCount={assignment} />
                </div>
            </div>
            <div className={styles.uploadedCard}>
                <div className={styles.assignmentAssessmentText}>
                    Assessment
                    <div className="d-flex align-items-center">
                        <NextPreviousNavButton label={submissionGraphPayload.getFullYear()} handleNavigationChange={onYearChange} />
                        <NextPreviousNavButton label={submissionGraphPayload.toLocaleString('default', { month: 'short' })} handleNavigationChange={onMonthChange} />
                    </div>
                </div>
                <div>
                    {" "}
                    <AssessmentPieChart assessmentCount={assessment} />
                </div>
            </div>
        </div>
    );
};

export default UploadedContent;
