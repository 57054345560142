import { readService } from './HttpService'
import { url } from '../Config/UrlRoute'

const getSubjectList = ({ collegeAdminId, courseId }) => {
    const params = new URLSearchParams();
    params.append('courseId', courseId);
    params.append('collegeAdminId', collegeAdminId);
    const endPoint = url.university.subject.subject;
    return readService(endPoint, params);
}
export { getSubjectList };