import React from 'react';
import style from './MembersContainer.module.scss'

const MembersContainer = ({ children }) => {
    return (
        <div className={style.MembersConstrainerStyle}>
            {children}
        </div>
    );
};

export default MembersContainer;
