import { IconButton } from "@mui/material";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import style from "./NextPreviousNavButton.module.scss";
export const NextPreviousNavButton = ({ label = "", handleNavigationChange }) => {
    return (
        <>
            <IconButton aria-label="NavigateBeforeIcon" onClick={() => {
                if (typeof handleNavigationChange === 'function') {
                    handleNavigationChange({ isNext: false, isPrevious: true })
                }
            }}>
                <NavigateBeforeIcon />
            </IconButton>
            <div className={style.year}>{label}</div>
            <IconButton aria-label="NavigateNextIcon" onClick={() => {
                if (typeof handleNavigationChange === 'function') {
                    handleNavigationChange({ isNext: true, isPrevious: false })
                }
            }}>
                <NavigateNextIcon />
            </IconButton>
        </>
    );
}