import React from "react";
import "./Header.scss";
import userProfile from "../../Assets/commonImg/user.png";
import settings from "../../Assets/Header/settings.svg";
import notifications from "../../Assets/Header/notification.png";
import logout from "../../Assets/Header/logout.svg";
import sideNavToggleImg from "./../../../src/Assets/Header/menu-burger.svg";
import zlippLogo from "../../Assets/img/zlipp-logo.png";
import zlippLogoText from "../../Assets/img/zlipp-logo-txt.png";
import crossNav from "./../../../src/Assets/Header/cross-cancel.svg";
import announcement from "./../../Assets/Header/announcementHeadIcon.svg";
import allMenu from "./../../Assets/Header/navbar-nav.svg";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import dashboard from "./../../Assets/sidenav/dashbaord.svg";
import student from "./../../Assets/sidenav/student.svg";
import college from "./../../Assets/sidenav/college.svg";
import members from "./../../Assets/sidenav/Members.svg";
import task from "./../../Assets/sidenav/task.svg";
import courses from "./../../Assets/sidenav/Courses.svg";
import liveClass from "./../../Assets/sidenav/liveclass.svg";
import studyMaterial from "./../../Assets/sidenav/Study-material.svg";
import assignment from "./../../Assets/sidenav/Assignment.svg";
import announcementMenu from "./../../Assets/sidenav/announcement.svg";
import attendance from "./../../Assets/sidenav/attendance.svg";
import trash from "./../../Assets/sidenav/trash.svg";
import report from "./../../Assets/sidenav/report.svg";
import academic from "./../../Assets/sidenav/Academic.svg";
import help from "./../../Assets/sidenav/Help.svg";
import department from "./../../Assets/sidenav/department.svg";
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import {signOut} from '../../Services/AuthService';

export default function Header({ sideNavState, setSideNavState }) {
  const navigate = useNavigate();
  const sideNavToggleClick = () => {
    setSideNavState(!sideNavState);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToSettings = () => {
    navigate('/home/settings', {replace: true});
  }

  const logoutAccount = async() =>{
    await signOut()
    navigate('/login');
  }

  return (
    <>
      <div className="header-container container-fluid d-flex justify-content-between align-items-center flex-wrap">
        <div className="mt-2">
          <div className="sideNavBtnCard d-flex align-items-center justify-content-around">
            <div>
              {sideNavState ? (
                <img
                  src={sideNavToggleImg}
                  alt=""
                  style={{
                    height: "30px",
                    width: "30px",
                    cursor: "pointer",
                    marginRight: "15px",
                    marginLeft: "15px",
                    marginTop: "10px",
                  }}
                  onClick={sideNavToggleClick}
                />
              ) : (
                <div onClick={sideNavToggleClick}>
                  <img
                    className="closeIcon"
                    src={crossNav}
                    alt=""
                    style={{
                      height: "30px",
                      width: "30px",
                      cursor: "pointer",
                      marginRight: "15px",
                      marginLeft: "15px",
                      marginTop: "10px",
                    }}
                  />
                </div>
              )}
            </div>
            <div>
              <img src={zlippLogo} alt="" style={{ width: "54px" }} />
            </div>
            {
              <div className="title">
                <img src={zlippLogoText} alt="" style={{ width: "74px" }} />
              </div>
            }
          </div>
        </div>
        <div>
          <div className="header-row d-flex align-items-center flex-wrap">
            <div>
              <img src={announcement} alt=""></img>
            </div>
            <div>
              <img src={notifications} alt=""></img>
            </div>
            <div onClick={goToSettings}>
              <img src={settings} alt=""></img>
            </div>
            {/* <div><img src={logout} alt=''></img></div> */}
            <div className="logoutIcon cursor-pointer d-flex justify-content-center mx-2">
              <img
                src={logout}
                alt=""
                style={{ width: "20px" }}
                onClick={logoutAccount}
              />
            </div>
              <div>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div>
                  <Tooltip title="All Features" 
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#DFEDF2",
                        color: "blue"
                      }
                    }
                  }}>
                    <img src={allMenu} alt=""></img>
                  </Tooltip>
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                    style: {
                      width: 400,
                    },
                    sx: {
                      "& .MuiMenuItem-root": {
                        fontSize: 9,
                        fontWeight: 700,
                      },
                    },
                  }}
                >
                  <div className="d-flex justify-content-center all-feature">
                    <div className="d-flex flex-column align-items-center first-column">
                      <div className="d-flex">
                        <MenuItem onClick={handleClose}>
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={dashboard} alt="" width={"26"} height={'26'}></img>
                            </div>
                            <div className="mt-2">Dashboard</div>
                          </div>
                        </MenuItem>
                      </div>
                      <div className="d-flex">
                        <MenuItem onClick={handleClose} >
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={student} alt="" width={"26"} height={'26'}></img>
                            </div>
                            <div className="mt-2">Student</div>
                          </div>
                        </MenuItem>
                      </div>
                      <div className="d-flex">
                        <MenuItem onClick={handleClose}>
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={college} alt="" width={"26"} height={'26'}></img>
                            </div>
                            <div className="mt-2">College</div>
                          </div>
                        </MenuItem>
                      </div>
                      <div className="d-flex">
                        <MenuItem onClick={handleClose} component="a" href="/home/members">
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={members} alt="" width={"26"} height={'26'}></img>
                            </div>
                            <div className="mt-2">Members</div>
                          </div>
                        </MenuItem>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <div className="d-flex">
                        <MenuItem onClick={handleClose}>
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={task} alt="" width={"26"} height={'26'}></img>
                            </div>
                            <div className="mt-2">Task</div>
                          </div>
                        </MenuItem>
                      </div>
                      <div className="d-flex">
                        <MenuItem onClick={handleClose}>
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={courses} alt="" width={"26"} height={'26'}></img>
                            </div>
                            <div className="mt-2">Courses</div>
                          </div>
                        </MenuItem>
                      </div>
                      <div className="d-flex">
                        <MenuItem onClick={handleClose}>
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={liveClass} alt="" width={"26"} height={'26'}></img>
                            </div>
                            <div className="mt-2">Live Class</div>
                          </div>
                        </MenuItem>
                      </div>
                      <div className="d-flex">
                        <MenuItem onClick={handleClose}>
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={studyMaterial} alt="" width={"26"} height={'26'}></img>
                            </div>
                            <div className="mt-2">Study Material</div>
                          </div>
                        </MenuItem>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <div className="d-flex">
                        <MenuItem onClick={handleClose}>
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={assignment} alt="" width={"26"} height={'26'}></img>
                            </div>
                            <div className="mt-2">Assignment</div>
                          </div>
                        </MenuItem>
                      </div>
                      <div className="d-flex">
                        <MenuItem onClick={handleClose}>
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={announcementMenu} alt="" width={"26"} height={'26'}></img>
                            </div>
                            <div className="mt-2">Announcement</div>
                          </div>
                        </MenuItem>
                      </div>
                      <div className="d-flex">
                        <MenuItem onClick={handleClose}>
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={attendance} alt="" width={"26"} height={'26'}></img>
                            </div>
                            <div className="mt-2">Attendance</div>
                          </div>
                        </MenuItem>
                      </div>
                      <div className="d-flex">
                        <MenuItem onClick={handleClose}>
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={trash} alt="" width={"26"} height={'26'}></img>
                            </div>
                            <div className="mt-2">Trash</div>
                          </div>
                        </MenuItem>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <div className="d-flex">
                        <MenuItem onClick={handleClose}>
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={report} alt="" width={"26"} height={'26'}></img>
                            </div>
                            <div className="mt-2">Report</div>
                          </div>
                        </MenuItem>
                      </div>
                      <div className="d-flex">
                        <MenuItem onClick={handleClose}>
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={academic} alt="" width={"26"} height={'26'}></img>
                            </div>
                            <div className="mt-2">Academics</div>
                          </div>
                        </MenuItem>
                      </div>
                      <div className="d-flex">
                        <MenuItem onClick={handleClose}>
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={help} alt="" width={"26"} height={'26'}></img>
                            </div>
                            <div className="mt-2">Help</div>
                          </div>
                        </MenuItem>
                      </div>
                      <div className="d-flex">
                        <MenuItem onClick={handleClose}>
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <img src={department} alt="" width={"26"} height={'26'}></img>
                            </div>
                            <div className="mt-2">Department</div>
                          </div>
                        </MenuItem>
                      </div>
                    </div>
                  </div>
                </Menu>
              </div>
            <div className="user-profile-card">
              <span className="user-name">Ventak</span>
              <p className="user-role">University</p>
            </div>
            <div className="mx-2">
              <img src={userProfile} alt=""></img>
            </div>
          </div>
        </div>
      </div>
      {sideNavState && (
        <div>
          <img
            className="hamburger"
            src={sideNavToggleImg}
            alt=""
            onClick={sideNavToggleClick}
          />
        </div>
      )}
    </>
  );
}
