import React, { useState } from 'react'
import Dashboard from '../Screens/University/DashboardScreen/Dashboard';
import Login from '../Screens/CommonScreens/Login/Login';
import ForgotPassword from '../Screens/CommonScreens/ForgotPassword/ForgotPassword';
import HomePage from '../Screens/CommonScreens/HomePage/HomePage';
import { Navigate, useRoutes } from 'react-router-dom';
import AddCollege from '../Screens/University/College/AddCollege';
import AddStudent from '../Screens/University/Student/AddStudent';
import ViewStudent from '../Screens/University/ViewStudent/ViewStudent';
import BulkStudentPromotion from '../Screens/University/BulkStudentPromotion/BulkStudentPromotion'
import CreatingStudentUsingCSV from '../Screens/University/CreatingStudentUsingCSV/CreatingStudentUsingCSV';
import ViewCollege from '../Screens/University/College/ViewCollege/ViewCollege';
import AssignCollege from '../Screens/University/College/AssignCollege/AssignCollege';
import MarksCardUpload from '../Screens/University/College/MarksCardUpload/MarksCardUpload';
import RegisteredStudent from '../Screens/University/RegisteredStudent/RegisteredStudent';
import Member from '../Screens/University/MemberScreen/Member';
import StudentPreview from '../Screens/University/CreatingStudentUsingCSV/StudentPreview';
import MarksInputScreen from '../Screens/University/College/MarksCardUpload/MarksInputScreen/MarksInputScreen';
import Reports from '../Screens/University/Reports/Reports';
import ViewDashboardReports from '../Components/ViewDashboardReports/ViewDashboardReports';
import CollegePerformance from '../Screens/University/Performance/CollegePerformance/CollegePerformance';
import EducatorPerformance from '../Screens/University/Performance/EducatorPerformance/EducatorPerformance';
import MostViewedVideo from '../Screens/University/Performance/MostViewedVideo/MostViewedVideo';
import MostViewedDigitalBook from '../Screens/University/Performance/MostViewedDigitalBook/MostViewedDigitalBook'
import StudyMaterialPerformance from '../Screens/University/Performance/StudyMaterialPerformance/StudyMaterialPerformance'
import EngagingStudentPerformance from '../Screens/University/Performance/EngagingStudentPerformance/EngagingStudentPerformance';
import OverallPerformanceView from '../Screens/University/Performance/OverallPerformanceView/OverallPerformanceView';
import TaskProgressView from '../Screens/University/Performance/TaskProgressView/TaskProgressView';
import StudentPerformance from '../Screens/University/Performance/StudentPerformance/StudentPerformance';
import ViewStudentPerformance from '../Screens/University/Performance/ViewStudentPerformance/ViewStudentPerformance';
import Task from '../Screens/University/Miscellaneous/Task/Task';
import AssignTask from '../Screens/University/Miscellaneous/AssignTask/AssignTask';
import Grievance from '../Screens/University/Miscellaneous/Grievance/Grievance';
import ComplaintStatus from '../Screens/University/Miscellaneous/ComplaintStatus/ComplaintStatus';
import CalendarView from '../Screens/University/Miscellaneous/CalendarView/CalendarView';
import AddNewEvent from '../Screens/University/Miscellaneous/AddNewEvent/AddNewEvent';
import CollegeList from '../Screens/University/Miscellaneous/CollegeList/CollegeList';
import ComplaintHistory from '../Screens/University/Miscellaneous/ComplaintHistory/ComplaintHistory';
import ViewEditStudentProfile from '../Screens/University/ViewEditStudentProfile/ViewEditStudentProfile';
import VerificationCode from '../Screens/CommonScreens/VerificationCode/VerificationCode';
import AddCollegeAdmin from '../Screens/University/CollegeAdmin/AddCollegeAdmin/AddCollegeAdmin';
import ViewCollegeAdmin from '../Screens/University/CollegeAdmin/ViewCollegeAdmin/ViewCollegeAdmin'
import AddEducator from '../Screens/University/Educator/AddEducator/AddEducator';
import ViewEducator from '../Screens/University/Educator/ViewEducator/ViewEducator';
// import Report from '../Screens/Report/Report';
import ReportTable from '../Components/Report/ReportTable';
import Report from '../Screens/Report/Report';
import RegisteredEducator from '../Screens/University/Educator/RegisteredEducator/RegisteredEducator';
import ViewSubject from '../Screens/University/Educator/ViewSubject/ViewSubject';
import Setting from '../Screens/Settings/Setting/Setting';
import SubjectList from '../Screens/University/Educator/SubjectList/SubjectList';

export default function Router() {
  const [sideNavState, setSideNavState] = useState(true);

  const routes = useRoutes([
    {
      path: "/",
      // element: <AuthLayout />,
      children: [
        { element: <Navigate to="login" />, index: true },
        { path: "login", element: <Login /> },
        { path: "forgotpassword", element: <ForgotPassword /> },
        { path: "verification-code", element: <VerificationCode /> },
        // { path: "new-password", element: <NewPassword /> },
      ],
    },
    {
      path: "home",
      element: (
        <HomePage sideNavState={sideNavState} setSideNavState={setSideNavState} />
      ),
      children: [
        {
          path: "dashboard",
          element: (
            <Dashboard
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "members",
          element: (
            <Member
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          ),
        },
        {
          path: "college",
          element: (
            <AddCollege
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "college/:id",
          element: (
            <AddCollege
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "student",
          element: (
            <AddStudent
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "student/:id",
          element: (
            <AddStudent
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "viewcollege",
          element: (
            <ViewCollege
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "assigncollege",
          element: (
            <AssignCollege
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "viewStudent",
          element: (
            <ViewStudent
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "viewEditStudentProfile",
          element: (
            <ViewEditStudentProfile
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "bulkStudentPromotion",
          element: (
            <BulkStudentPromotion
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "creatingStudentUsingCsv",
          element: (
            <CreatingStudentUsingCSV
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "studentPreview",
          element: (
            <StudentPreview
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "markscardupload",
          element: (
            <MarksCardUpload
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "registeredStudent",
          element: (
            <RegisteredStudent
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "inputmarks",
          element: (
            <MarksInputScreen
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        // {
        //   path: "reports",
        //   element: (
        //     <Reports
        //       sideNavState={sideNavState}
        //       setSideNavStsate={setSideNavState}
        //     />
        //   )
        // },
        {
          path: "report",
          element: (
            <ReportTable
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "requestReport",
          element: (
            <Report
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "viewDashboardReports",
          element: (
            <ViewDashboardReports
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "collegePerformance",
          element: (
            <CollegePerformance
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "collegePerformance/mostViewedVideo",
          element: (
            <MostViewedVideo
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "collegePerformance/mostViewedDigitalBook",
          element: (
            <MostViewedDigitalBook
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "educatorPerformance",
          element: (
            <EducatorPerformance
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "educatorPerformance/studyMaterialPerformance",
          element: (
            <StudyMaterialPerformance
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "educatorPerformance/engagingStudentPerformance",
          element: (
            <EngagingStudentPerformance
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "educatorPerformance/overallPerformanceView",
          element: (
            <OverallPerformanceView
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "educatorPerformance/TaskProgressView",
          element: (
            <TaskProgressView
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "studentPerformance",
          element: (
            <StudentPerformance
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "studentPerformance/viewStudentPerformance",
          element: (
            <ViewStudentPerformance
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "task",
          element: (
            <Task
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "task/assignTask",
          element: (
            <AssignTask
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "grievance",
          element: (
            <Grievance
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "grievance/complaintStatus",
          element: (
            <ComplaintStatus
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "grievance/complaintStatus/history",
          element: (
            <ComplaintHistory
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "calendarView",
          element: (
            <CalendarView
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "calendarView/addNewEvent",
          element: (
            <AddNewEvent
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "calendarView/addNewEvent/collegeList",
          element: (
            <CollegeList
              sideNavState={sideNavState}
              setSideNavStsate={setSideNavState}
            />
          )
        },
        {
          path: "collegeAdmin",
          element: (
            <AddCollegeAdmin
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        
        {
          path: "collegeAdmin/:id",
          element: (
            <AddCollegeAdmin
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "viewCollegeAdmin",
          element: (
            <ViewCollegeAdmin
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "addEducator",
          element: (
            <AddEducator
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "addEducator/:id",
          element: (
            <AddEducator
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "viewEducator",
          element: (
            <ViewEducator
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "RegisteredEducator",
          element: (
            <RegisteredEducator
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "ViewSubject",
          element: (
            <ViewSubject
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "settings",
          element: (
            <Setting
            sideNavState={sideNavState}
            setSideNavState={setSideNavState}
            />
          )
        },
        {
          path: "subjectList",
          element: (
            <SubjectList
              sideNavState={sideNavState}
              setSideNavState={setSideNavState}
            />
          )
        },
      ]
    },

  ]);
  return routes;
}

