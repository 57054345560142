import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Grievance.scss';
import BackIcon from '../../../../Assets/commonImg/back.svg';
import studentIcon from '../../../../Assets/commonImg/student-icon.png';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const complaintData = [
    { sino: 1, studentName: 'Anamika Khandelwal', createdDate: '23/05/2022', compain: 'lorem Ipsum is simply dummy text ?', status: 'open'},
    { sino: 1, studentName: 'Anamika Khandelwal', createdDate: '23/05/2022', compain: 'lorem Ipsum is simply dummy text ?', status: 'closed'},
    { sino: 1, studentName: 'Anamika Khandelwal', createdDate: '23/05/2022', compain: 'lorem Ipsum is simply dummy text ?', status: 'open'},
    { sino: 1, studentName: 'Anamika Khandelwal', createdDate: '23/05/2022', compain: 'lorem Ipsum is simply dummy text ?', status: 'closed'},
    { sino: 1, studentName: 'Anamika Khandelwal', createdDate: '23/05/2022', compain: 'lorem Ipsum is simply dummy text ?', status: 'open'},
    { sino: 1, studentName: 'Anamika Khandelwal', createdDate: '23/05/2022', compain: 'lorem Ipsum is simply dummy text ?', status: 'closed'},
    { sino: 1, studentName: 'Anamika Khandelwal', createdDate: '23/05/2022', compain: 'lorem Ipsum is simply dummy text ?', status: 'open'},
    { sino: 1, studentName: 'Anamika Khandelwal', createdDate: '23/05/2022', compain: 'lorem Ipsum is simply dummy text ?', status: 'closed'},
];


export default function Grievance({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/home/collegePerformance', { replace: true });
    }
    const onStatus = () => {
        navigate('complaintStatus', { replace: true });
    }
  
    return (
        <div className='grievance-container'
            style={{
                width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
                marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
                transition: "0.5s",
                position: "absolute",
                top: "4rem",
                padding: "1%",

            }}>
            <div className='backBtn' onClick={goBack}><img src={BackIcon} width={'12px'} height={'13px'} marginTop={'5px'} /><div className='backText'>Back</div></div>
            <div className='grievance-card mt-2 p-3'>
                <div className='row d-flex justify-content-start align-items-center flex-wrap filter-section px-2'>
                    <div className='col-xl-2 col-12 px-0 mx-0 all-complaints'>All Complaints</div>
                    <div className='col-lg-5 col-12 px-0 mx-0 search-filter position-relative'>
                        <input className="custom-search-input px-3 mx-0" type='text' />
                        <button className="search-btn">Search</button>
                    </div>
                    <div className='col-lg-2 col-12 px-0 mx-0'>
                    <select className="custom-select-input">
                            <option>Sort by</option>
                            <option>Closed</option>
                            <option>Open</option>
                        </select>
                    </div>
                    <div className="col-lg-3 d-none"></div>
                </div>
                <div className='mt-2'>
                    <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '40px' }}>
                        <TableContainer>
                            <Table sx={{
                                background: '#FFFFFF',
                                boxShadow: '0px 3px 6px #00000029',
                                borderRadius: '0px 0px 5px 5px'
                            }}>
                                <TableHead>
                                    <TableRow className='table-row'>
                                        <TableCell align="center" className='head-title'>SI No.</TableCell>
                                        <TableCell align="center" className='head-title'>Student Name</TableCell>
                                        <TableCell align="center" className='head-title'>Created Date</TableCell>
                                        <TableCell align="center" className='head-title'>Compain</TableCell>
                                        <TableCell align="center" className='head-title'>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {complaintData
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    <TableCell align="center" className='table-data'>{row.sino}</TableCell>
                                                    <TableCell align="center" className='table-data'><img src={studentIcon} width={'38px'} height={'38px'} style={{marginRight:'15px'}}/>{row.studentName}</TableCell>
                                                    <TableCell align="center" className='table-data'>{row.createdDate}</TableCell>
                                                    <TableCell align="center" className='table-data'>{row.compain}</TableCell>
                                                    <TableCell align="center" className='table-data' onClick={onStatus} style={{color:row.status=='open'?'#F02A2A':'#00FF00', cursor:'pointer'}}>{row.status}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            </div>
        </div>
    )
}
