import React, { useState } from "react";
import "./SideNav.scss";
import zlippLogo from "../../Assets/img/zlipp-logo.png"
import zlippLogoText from "../../Assets/img/zlipp-logo-txt.png";
import sideNavToggleImg from "../../Assets/sidenav/sideNavToggle.png"
import dashboardImg from "../../Assets/sidenav/dashbaord.svg"
import taskImg from "../../Assets/sidenav/task.svg"
import coursesImg from "../../Assets/sidenav/Courses.svg"
import liveclassImg from "../../Assets/sidenav/liveclass.svg"
import membersImg from "../../Assets/sidenav/Members.svg"
import studyMaterialsImg from "../../Assets/sidenav/Study-material.svg"
import assignmentImg from "../../Assets/sidenav/Assignment.svg"
import announcementImg from "../../Assets/sidenav/announcement.svg"
import attendenceImg from "../../Assets/sidenav/attendance.svg"
import trashImg from "../../Assets/sidenav/trash.svg"
import reportImg from "../../Assets/sidenav/report.svg"
import academicsImg from "../../Assets/sidenav/Academic.svg"
import helpImg from "../../Assets/sidenav/Help.svg"
import departmentImg from "../../Assets/sidenav/department.svg"
import crossNav from "../../Assets/sidenav/crossNav.png"
import collegeImg from "../../Assets/sidenav/college.svg"
import collegePerformanceImg from "../../Assets/sidenav/college-performance.svg"
import educatorPerformanceImg from "../../Assets/sidenav/educator-performance.svg"
import studentPerformanceImg from "../../Assets/sidenav/student-performance.svg"
import task from "../../Assets/sidenav/miscellaneous-task.svg"
import calendarImg from "../../Assets/sidenav/miscellaneous-calendar.svg"
import grievanceImg from "../../Assets/sidenav/miscellaneous-grievance.svg"


import arrowIcon from "../../Assets/sidenav/arrow_icon.svg"
import { useNavigate } from "react-router";
import studentIcon from "../../Assets/sidenav/student.svg";
import viewIcon from "../../Assets/sidenav/childNav/view.svg";
import addIcon from "../../Assets/sidenav/childNav/add.svg";
import assignIcon from "../../Assets/sidenav/childNav/assign.svg";
import fileIcon from "../../Assets/sidenav/childNav/file.svg";
import addStudentByCsvIcon from "../../Assets/sidenav/childNav/csv.svg";
import bulkStudentPromotion from "../../Assets/sidenav/childNav/promotion.svg";

export default function SideNav({ sideNavState, setSideNavState = true }) {
    // const [sideNavState, setSideNavState] = useState(true);
    const navigate = useNavigate();
    const [navItemSelected, setNavItemSelected] = useState(0);
    const [showChildNavItems, setShowChildNavItems] = useState(false);
    const [subNavItemSelected, setSubNavItemSelected] = useState(0);
    const [parentNavIndexFromChild, setParentNavIndexFromChild] = useState(0);

    const navLinks = [
        { url: "/home/dashboard", name: "DashBoard", icon: dashboardImg },
        { url: "/home/members", name: "Members", icon: membersImg },
        { url: "/home/studyMaterials", name: "Study Material", icon: studyMaterialsImg },
        { url: "/home/assignment", name: "Assignment", icon: assignmentImg },
        { url: "/home/announcement", name: "Announcement", icon: announcementImg },
        { url: "/home/attendance", name: "Attendance", icon: attendenceImg },
        { url: "/home/trash", name: "Trash", icon: trashImg },
        // { url: "/home/reports", name: "Report", icon: reportImg },
        { url: "/home/report", name: "Report", icon: reportImg },
        { url: "/home/requestReport", name: "requestReport", icon: reportImg },
        { url: "/home/academics", name: "Academics", icon: academicsImg },
        { url: "/home/help", name: "Help", icon: helpImg },
        { url: "/home/department", name: "Department", icon: departmentImg },
        { url: "/home/collegePerformance", name: "College Performance", icon: collegePerformanceImg },
        { url: "/home/educatorPerformance", name: "Educator Performance", icon: educatorPerformanceImg },
        { url: "/home/studentPerformance", name: "Student Performance", icon: studentPerformanceImg },
        { url: "/home/task", name: "Task", icon: task },
        { url: "/home/grievance", name: "Grievance", icon: grievanceImg },
        { url: "/home/calendarView", name: "Calendar View", icon: calendarImg },

        // { url: "/home/college", name: "College", icon: collegeImg},
        // { url: "/home/student", name: "Add Student", icon: collegeImg},
        // { url: "/home/viewStudent", name: "View Student", icon: collegeImg},
        // { url: "/home/bulkStudentPromotion", name: "Bulk Student Promotion", icon: collegeImg},
        // { url: "/home/creatingStudentUsingCsv", name: "Creating Student using CSV", icon: collegeImg},
        // { url: "/home/registeredStudent", name: "Registered Student", icon: collegeImg},

        { name: "Task", icon: taskImg, url: "/home/task" },
        { name: "Courses", icon: coursesImg, url: "/home/courses" },
        { name: "Live Class", icon: liveclassImg, url: "/home/liveclass" },
        { name: "Study Material", icon: studyMaterialsImg, url: "/home/studyMaterials" },
        { name: "Assignment", icon: assignmentImg, url: "/home/assignment" },
        { name: "Announcement", icon: announcementImg, url: "/home/announcement", },
        { name: "Attendance", icon: attendenceImg, url: "/home/attendance" },
        { name: "Trash", icon: trashImg, url: "/home/trash" },
        // { name: "Report", icon: reportImg, url: "/home/reports" },
        { name: "Report", icon: reportImg, url: "/home/report" },
        { name: "Academics", icon: academicsImg, url: "/home/academics", },
        { name: "Help", icon: helpImg, url: "/home/help" },
        { name: "Department", icon: departmentImg, url: "/home/department" },
    ];

    const sideBarData = [
        {
            name: 'Dashboard', icon: dashboardImg, url: '/home/dashboard'
        },
        // {
        //     name: 'Student', icon: studentIcon,
        //     subNavItems: [
        //         { subName: 'Add Student', url: '/home/student', icon: addIcon },
        //         { subName: 'View Student', url: "/home/viewStudent", icon: viewIcon },
        //         { subName: 'Bulk Student Promotion', url: "/home/bulkStudentPromotion", icon: bulkStudentPromotion },
        //         { subName: 'Creating Student Using CSV', url: "/home/creatingStudentUsingCsv", icon: addStudentByCsvIcon },
        //     ]
        // },
        // {
        //     name: 'College', icon: collegeImg,
        //     subNavItems: [
        //         { subName: 'Add College', url: '/home/college', icon: addIcon },
        //         { subName: 'View College', url: '/home/viewcollege', icon: viewIcon },
        //         { subName: 'Assign College', url: '/home/assigncollege', icon: assignIcon },
        //         { subName: 'Marks Card Upload', url: '/home/markscardupload', icon: fileIcon }
        //     ]
        // },
        { name: "Members", icon: membersImg, url: "/home/members" },
        // { name: "Task", icon: taskImg, url: "/home/task" },
        // { name: "Courses", icon: coursesImg, url: "/home/courses" },
        // { name: "Live Class", icon: liveclassImg, url: "/home/liveclass" },
        // { name: "Study Material", icon: studyMaterialsImg, url: "/home/studyMaterials" },
        // { name: "Assignment", icon: assignmentImg, url: "/home/assignment" },
        // { name: "Announcement", icon: announcementImg, url: "/home/announcement", },
        // { name: "Attendance", icon: attendenceImg, url: "/home/attendance" },
        // { name: "Trash", icon: trashImg, url: "/home/trash" },
        // { name: "Report", icon: reportImg, url: "/home/reports" },
        { name: "Report", icon: reportImg, url: "/home/report" },
        // { name: "Academics", icon: academicsImg, url: "/home/academics", },
        // { name: "Help", icon: helpImg, url: "/home/help" },
        // { name: "Department", icon: departmentImg, url: "/home/department" }
    ]

    const sideNavToggleClick = () => {
        setSideNavState(!sideNavState);
    };

    const onSectionSelect = (event, index, url) => {
        setNavItemSelected(index);
        if (index === navItemSelected) {
            setShowChildNavItems(showChildNavItems => !showChildNavItems)
        } else {
            setShowChildNavItems(true)
        }
        if (url) {
            navigate(url);
            setParentNavIndexFromChild(0);
        }
    };

    const onSubNavItemSelect = (event, index, url, parentIndex) => {
        setParentNavIndexFromChild(parentIndex);
        setSubNavItemSelected(index);
        if (url)navigate(url);
    }

    return (
        // <div id="sideNavContainer" style={{ width: sideNavState ? "20%" : "10%" }}>
        <div id="sideNavContainer" className={sideNavState ? 'sideNavOpenWidth' : 'sideNavCloseWidth'}>
            {/* <div className="sideNavBtnCard d-flex align-items-center justify-content-around">
                <div>
                    <img src={zlippLogo} alt="" style={{ width: '54px' }} />
                    
                </div>
                {sideNavState && <div className="title">
                    <img src={zlippLogoText} alt="" style={{ width: '74px' }} />
                </div>}
                <div>
                    {sideNavState ? <img src={sideNavToggleImg} alt=""
                        style={{ height: '38px', width: '38px', cursor: 'pointer' }} onClick={sideNavToggleClick} />
                        : <div onClick={sideNavToggleClick}>
                            <img className="closeIcon" src={crossNav} alt='' />
                        </div>}
                </div>
            </div> */}
            <div className={!sideNavState ? 'sideNavClosed sideNav-scroll' : 'sideNav-scroll'}>
                <ul>
                    {sideBarData.map(({ name, icon, url, subNavItems }, index) => (
                        <li key={index}>
                            <a
                                onClick={(e) => onSectionSelect(e, index, url)}
                                className={'parentNavItems ' + (navItemSelected === index ? "active" : "")}
                            >
                                <div>
                                    <img src={icon} alt="" style={{ width: '24px', height: '24px' }}
                                        className={navItemSelected === index ? 'imgActive' : ""} />
                                    {/* <icon /> */}
                                </div>
                                {sideNavState && (
                                    <p>
                                        {name}
                                    </p>
                                )}
                                <div className="arrow-icon" style={{ position: 'absolute', right: '2rem' }}>
                                    {!url && <img src={arrowIcon} alt='' className={(navItemSelected === index ? 'imgActive ' : "") + (showChildNavItems && navItemSelected === index ? 'imgRotate' : '')} />}
                                </div>
                            </a>
                            {subNavItems && (navItemSelected === index) && showChildNavItems
                                && subNavItems.map(({ subName, url, icon }, eleIndex) => (
                                    <li>
                                        <a onClick={(e) => onSubNavItemSelect(e, eleIndex, url, index)}
                                            className={'childNavItems ' + (showChildNavItems && (parentNavIndexFromChild === index) && (subNavItemSelected === eleIndex) ? "child-active" : "")}>
                                            <div>
                                                <img src={icon} alt="" style={{ width: '24px', height: '24px' }}
                                                    className={showChildNavItems && (parentNavIndexFromChild === index) && (subNavItemSelected === eleIndex) ? 'imgActive' : ""}
                                                />
                                            </div>
                                            {sideNavState && (
                                                <p>
                                                    {subName}
                                                </p>
                                            )}
                                        </a>
                                    </li>
                                ))}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
