import React, { useEffect, useState } from 'react'
import './ViewSubject.scss';
import searchIcon from '../../../../Assets/common/search_icon.svg';
import searchCloseIcon from '../../../../Assets/common/search_close_icon.png';
import { TablePagination } from '@mui/material';
import viewCollegeIcon from './../../../../Assets/memberImg/view.svg';
import editCollegeIcon from './../../../../Assets/memberImg/edit.svg';
import deleteCollegeIcon from './../../../../Assets/memberImg/delete.png';
import Swal from 'sweetalert2';
import actionBtnIcon from './../../../../Assets/common/actionBtn.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import resetPasswordICon from './../../../../Assets/common/resetPassword.svg';
import biometricIcon from './../../../../Assets/memberImg/fingerprint.svg';
import NavigateBack from '../../../CommonScreens/NavigateBack/NavigateBack';
import viewIcon from '../../../../Assets/common/view-icon-new.svg';
import { useNavigate } from 'react-router-dom';

export default function ViewSubject({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
    useEffect(() => {
        // getEducatorListData(0, 10)
    }, [])
    // const [educatorData, setEducatorData] = useState([]);
    const [searchText, setSearchText] = useState('')
    const [selectedRows, setSelectedRows] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [count, setTotalCollege] = React.useState(0);
    const [isDeleteAllDisabled, setIsDeleteAllDisabled] = useState(true);

    // const getEducatorListData = async (offset, limit) => {
    //     try {
    //        
    //     } catch (error) {
    //     }
    // }

    const handleSearchInput = (event) => {
        setSearchText(event.target.value);
    }

    const handleSearchClick = () => {
        // setPage(0);
        // let offset = 0;
        // getEducatorListData(offset, searchText, filterDataObj)
    }

    const handleSearchClear = () => {
        //  setSearchText('');
        //  setPage(0);
        //  let offset = 0;
        //  getEducatorListData(offset, '', filterDataObj);
    }

    const educatorData = [
        { usn: 1, educatorName: 'Snow', email: 'Jon@gmail.com', username: 'Jon', collegeName: 'Jon', contact: '124522' },
        { usn: 2, educatorName: 'Lannister', email: 'Cersei@gmail.com', username: 'Jon', collegeName: 'Jon', contact: '124522' },
        { usn: 3, educatorName: 'Lannister', email: 'Jaime@gmail.com', username: 'Jon', collegeName: 'Jon', contact: '124522' },
        { usn: 4, educatorName: 'Stark', email: 'Arya@gmail.com', username: 'Jon', collegeName: 'Jon', contact: '124522' },
        { usn: 5, educatorName: 'Targaryen', email: 'Daenerys@gmail.com', username: 'Jon', collegeName: 'Jon', contact: '124522' },
        { usn: 6, educatorName: 'Melisandre', email: 'nul@gmail.com', username: 'Jon', collegeName: 'Jon', contact: '124522' },
        { usn: 7, educatorName: 'Clifford', email: 'Ferrara@gmail.com', username: 'Jon', collegeName: 'Jon', contact: '124522' },
        { usn: 8, educatorName: 'Frances', email: 'Rossini@gmail.com', username: 'Jon', collegeName: 'Jon', contact: '124522' },
        { usn: 9, educatorName: 'Roxie', email: 'Harvey@gmail.com', username: 'Jon', collegeName: 'Jon', contact: '124522' },
    ];

    const handleView = (id) => {
        console.log(`View clicked for ID ${id}`);
    };

    const handleEdit = (id) => {
        console.log(`Edit clicked for ID ${id}`);
    };

    const handleDelete = (id) => {
        console.log("🚀 ~ handleDelete ~ id:", id)
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                console.log(`Delete clicked for ID ${id}`);
                Swal.fire({
                    title: "Deleted!",
                    text: "Your file has been deleted.",
                    icon: "success"
                });
            }
        });
    };

    const handleHeaderCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setSelectedRows(isChecked ? educatorData.map(row => row.id) : []);
        // Update the disabled state of "Delete All" button
        setIsDeleteAllDisabled(!isChecked);
    };

    const handleCheckboxChange = (e, id) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedRows([...selectedRows, id]);
        } else {
            setSelectedRows(selectedRows.filter(rowId => rowId !== id));
        }

        // Update the disabled state of "Delete All" button
        setIsDeleteAllDisabled(!isChecked && selectedRows.length === 0);
    };

    const deleteAllSelectedValue = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                console.log(`Delete clicked for ID`);
                Swal.fire({
                    title: "Deleted!",
                    text: "Your file has been deleted.",
                    icon: "success"
                });
            }
        });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        // getEducatorListData(newPage * rowsPerPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        // getEducatorListData(0, parseInt(event.target.value, 10));
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const viewSubjecrList = () => {
        navigate('/home/subjectList', { replace: true });
      }

    return (
        <div className={'view-college-container ' + (sideNavState ? 'fullWidthOpenSideNav' : 'halfWidthOpenSideNav')}>
            <NavigateBack />
            <div className='main-container'>
                <div className='d-flex flex-wrap justify-content-between p-4 filter-container mx-3'>
                    <div className='d-flex flex-wrap align-items-center'>
                        <div className='d-flex p-2'>
                            <div>
                                <select className="form-control px-5">
                                    <option>Select College</option>
                                </select>
                            </div>
                        </div>
                        <div className='d-flex p-2'>
                            <div>
                                <select className="form-control px-5">
                                    <option>Search By Name</option>
                                </select>
                            </div>
                        </div>
                        <div className='d-flex p-2'>
                            <label className='label-input'>
                                <input type='text' className='form-control search-input' placeholder='Search by name'
                                    onChange={handleSearchInput} value={searchText} />
                                {/* {searchText && <button className='search-btn-close' onClick={handleSearchClear}>
                                    <img src={searchCloseIcon} alt='' width={'24px'} />
                                </button>} */}
                                <button className='search-btn' onClick={handleSearchClick}>
                                    <img src={searchIcon} alt='' />
                                    <span>Search</span>
                                </button>
                            </label>
                        </div>
                    </div>
                    
                </div>
                <div className='table-container p-3'>
                    <div>
                        {educatorData.length > 0 ? (
                            <table>
                                <thead>
                                    <tr>
                                        <th>USN</th>
                                        <th>Educator name</th>
                                        <th>Username</th>
                                        <th>College Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {educatorData.map((row) => (
                                        <tr key={row.id}>
                                            <td>{row.usn}</td>
                                            <td>{row.educatorName}</td>
                                            <td>{row.username}</td>
                                            <td>{row.collegeName}</td>
                                            <td>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <div className='cursor-pointer' onClick={() => viewSubjecrList(row.id)}><img src={viewIcon} width={'25px'} height={'12px'} marginTop={'5px'} />
                                                </div>
                                            </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>

                        ) : (
                            <p>No data available</p>
                        )}
                    </div>

                    <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Items per page" // Change labelRowsPerPage to customize the text
                    />
                </div>
            </div>
        </div>
    )
}
