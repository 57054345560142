import React from 'react';
import { styled } from '@mui/system';
import { TabsList as TabsListUnstyled } from '@mui/base/TabsList';
import { Tab as TabsUnstyled } from '@mui/base/Tab';
import { TabPanel as TabPanelUnstyled } from '@mui/base/TabPanel';
import Box from '@mui/material/Box';
import { Tab as TabUnstyled, tabClasses } from '@mui/base/Tab';
import './Reports.scss';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import ViewDashboardReports from '../../../Components/ViewDashboardReports/ViewDashboardReports';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";



const LineCharts = styled(LineChart)`
padding:20px;
`;

const BarCharts = styled(BarChart)`
padding:20px;
 
`;

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: #EAF2FC;
  width: 100%;
  padding: 10px 12px;
  margin: 6px 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

 

  &.${tabClasses.selected} {
    background-color: #fff;
    color: blue;
  }

  &#clg_data{
    display:none;
  }

`;

const TabPanel = styled(TabPanelUnstyled)(
  ({ theme }) => `
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  padding: 20px 12px;
  
  border-radius: 12px;
  
  `,
);

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  min-width: 200px;
  max-width: 500px;
  background-color: #EAF2FC;
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  
  `,
);


const SearchBar = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'black', // set font color
    padding: '10px', // add some padding to input
  },
  '& .MuiInputBase-root': {
    borderRadius: '20px', // set border radius
    backgroundColor: 'white', // set background color
  },
});

const lineChartData = [
  { name: 'Jan', uv: 4000, pv: 2400, amt: 2400 },
  { name: 'Feb', uv: 3000, pv: 1398, amt: 2210 },
  { name: 'Mar', uv: 2000, pv: 9800, amt: 2290 },
  { name: 'Apr', uv: 2780, pv: 3908, amt: 2000 },
  { name: 'May', uv: 1890, pv: 4800, amt: 2181 },
  { name: 'Jun', uv: 2390, pv: 3800, amt: 2500 },
  { name: 'Jul', uv: 3490, pv: 4300, amt: 2100 },
];

const barChartData = [
  { name: 'Jan', uv: 4000, pv: 2400, amt: 2400 },
  { name: 'Feb', uv: 3000, pv: 1398, amt: 2210 },
  { name: 'Mar', uv: 2000, pv: 9800, amt: 2290 },
  { name: 'Apr', uv: 2780, pv: 3908, amt: 2000 },
  { name: 'May', uv: 1890, pv: 4800, amt: 2181 },
  { name: 'Jun', uv: 2390, pv: 3800, amt: 2500 },
  { name: 'Jul', uv: 3490, pv: 4300, amt: 2100 },
];

const data = [
  { enrlmnt_no: 5657, enrlmnt_name: 'Dr. Sarang P.', enrlmnt_usrname: 'Sarang_Med', enrlmnt_asnd_task: 89, enrlmnt_cmpltd_task: 111, enrlmnt_task_progress: 23, enrlmnt_action: <RemoveRedEyeIcon color="primary" /> },
  { enrlmnt_no: 5657, enrlmnt_name: 'Dr. Sarang P.', enrlmnt_usrname: 'Sarang_Med', enrlmnt_asnd_task: 89, enrlmnt_cmpltd_task: 111, enrlmnt_task_progress: 23, enrlmnt_action: <RemoveRedEyeIcon color="primary" /> },
  { enrlmnt_no: 5657, enrlmnt_name: 'Dr. Sarang P.', enrlmnt_usrname: 'Sarang_Med', enrlmnt_asnd_task: 89, enrlmnt_cmpltd_task: 111, enrlmnt_task_progress: 23, enrlmnt_action: <RemoveRedEyeIcon color="primary" /> },
  { enrlmnt_no: 5657, enrlmnt_name: 'Dr. Sarang P.', enrlmnt_usrname: 'Sarang_Med', enrlmnt_asnd_task: 89, enrlmnt_cmpltd_task: 111, enrlmnt_task_progress: 23, enrlmnt_action: <RemoveRedEyeIcon color="primary" /> },
  { enrlmnt_no: 5657, enrlmnt_name: 'Dr. Sarang P.', enrlmnt_usrname: 'Sarang_Med', enrlmnt_asnd_task: 89, enrlmnt_cmpltd_task: 111, enrlmnt_task_progress: 23, enrlmnt_action: <RemoveRedEyeIcon color="primary" /> },
  { enrlmnt_no: 5657, enrlmnt_name: 'Dr. Sarang P.', enrlmnt_usrname: 'Sarang_Med', enrlmnt_asnd_task: 89, enrlmnt_cmpltd_task: 111, enrlmnt_task_progress: 23, enrlmnt_action: <RemoveRedEyeIcon color="primary" /> },
  { enrlmnt_no: 5657, enrlmnt_name: 'Dr. Sarang P.', enrlmnt_usrname: 'Sarang_Med', enrlmnt_asnd_task: 89, enrlmnt_cmpltd_task: 111, enrlmnt_task_progress: 23, enrlmnt_action: <RemoveRedEyeIcon color="primary" /> },

];

const attendance = [
  { attendance_no: 5657, attendance_name: 'Dr. Sarang P.', attendance_usrname: 'Sarang_Med', attandance_course: 'MBBS', attendance_subject: 'Anatomy', attendance_scheduled: '12th June 2022', attendance_cls_start: '12:00 PM', attendance_cls_end: '30 Min', attendance_action: <RemoveRedEyeIcon color="primary" /> },
  { attendance_no: 5657, attendance_name: 'Dr. Sarang P.', attendance_usrname: 'Sarang_Med', attandance_course: 'MBBS', attendance_subject: 'Anatomy', attendance_scheduled: '12th June 2022', attendance_cls_start: '12:00 PM', attendance_cls_end: '30 Min', attendance_action: <RemoveRedEyeIcon color="primary" /> },
  { attendance_no: 5657, attendance_name: 'Dr. Sarang P.', attendance_usrname: 'Sarang_Med', attandance_course: 'MBBS', attendance_subject: 'Anatomy', attendance_scheduled: '12th June 2022', attendance_cls_start: '12:00 PM', attendance_cls_end: '30 Min', attendance_action: <RemoveRedEyeIcon color="primary" /> },
  { attendance_no: 5657, attendance_name: 'Dr. Sarang P.', attendance_usrname: 'Sarang_Med', attandance_course: 'MBBS', attendance_subject: 'Anatomy', attendance_scheduled: '12th June 2022', attendance_cls_start: '12:00 PM', attendance_cls_end: '30 Min', attendance_action: <RemoveRedEyeIcon color="primary" /> },
  { attendance_no: 5657, attendance_name: 'Dr. Sarang P.', attendance_usrname: 'Sarang_Med', attandance_course: 'MBBS', attendance_subject: 'Anatomy', attendance_scheduled: '12th June 2022', attendance_cls_start: '12:00 PM', attendance_cls_end: '30 Min', attendance_action: <RemoveRedEyeIcon color="primary" /> },
  { attendance_no: 5657, attendance_name: 'Dr. Sarang P.', attendance_usrname: 'Sarang_Med', attandance_course: 'MBBS', attendance_subject: 'Anatomy', attendance_scheduled: '12th June 2022', attendance_cls_start: '12:00 PM', attendance_cls_end: '30 Min', attendance_action: <RemoveRedEyeIcon color="primary" /> },
  { attendance_no: 5657, attendance_name: 'Dr. Sarang P.', attendance_usrname: 'Sarang_Med', attandance_course: 'MBBS', attendance_subject: 'Anatomy', attendance_scheduled: '12th June 2022', attendance_cls_start: '12:00 PM', attendance_cls_end: '30 Min', attendance_action: <RemoveRedEyeIcon color="primary" /> },

];


export default function Reports({ sideNavState, setSideNavState }) {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div id='reports_container' className={sideNavState ? 'fullWidthOpenSideNav' : 'halfWidthOpenSideNav'}>
      <div className='main-container row m-0'>
        <h3>Report</h3>
        <TabsUnstyled defaultValue={0}>
          <TabsList>
            <Tab> Task Report</Tab>
            <Tab>Attendance Report</Tab>
          </TabsList>
          <TabPanel value={0}>
            <div className='row'>
              <div className='col-md-4'>
                <SearchBar
                  id="outlined-basic"
                  label="Search"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                /> </div>

              <div className='col-md-7'>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel htmlFor="grouped-native-select">College</InputLabel>
                  <Select native defaultValue="" id="grouped-native-select" label="Grouping">
                    <optgroup>
                      <option value={3}>College of Agriculture, Mandor</option>
                      <option value={4}>College of Agriculture, Nagpur</option>
                      <option value={4}>College of Agriculture, Bhanda</option>
                    </optgroup>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                  <InputLabel htmlFor="grouped-select">Department</InputLabel>
                  <Select defaultValue="" id="grouped-select" label="Grouping">
                    <MenuItem value={1}>Agronomy</MenuItem>
                    <MenuItem value={2}>Soil Science</MenuItem>
                    <MenuItem value={2}>Fruit Science</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel htmlFor="grouped-select">Course</InputLabel>
                  <Select defaultValue="" id="grouped-select" label="Grouping">
                    <MenuItem value={3}>MBBS 1st Year</MenuItem>
                    <MenuItem value={3}>MBBS 2nd Year</MenuItem>
                    <MenuItem value={3}>MBBS 1st Year</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <br /> <br /> <br />
            <div className='row'>
              <div className='col-md-6'>
                <LineCharts width={500} height={300} data={lineChartData} >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="uv" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineCharts>
              </div>

              <div className='col-md-6'>
                <BarCharts width={500} height={300} data={barChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="uv" fill="#8884d8" />
                  <Bar dataKey="pv" fill="#82ca9d" />

                </BarCharts>
              </div>
            </div>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Enrolment No.</TableCell>
                  <TableCell>Educator Name</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>Assigned Task</TableCell>
                  <TableCell>Completed Task</TableCell>
                  <TableCell>No. Of Task In Progress</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.enrlmnt_no}</TableCell>
                    <TableCell>{row.enrlmnt_name}</TableCell>
                    <TableCell>{row.enrlmnt_usrname}</TableCell>
                    <TableCell>{row.enrlmnt_asnd_task}</TableCell>
                    <TableCell>{row.enrlmnt_cmpltd_task}</TableCell>
                    <TableCell>{row.enrlmnt_task_progress}</TableCell>
                    <TableCell>{row.enrlmnt_action}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={1}>
            <div className='row'>
              <div className='col-md-4'>
                <SearchBar
                  id="outlined-basic"
                  label="Search"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ERN No.</TableCell>
                  <TableCell>Educator Name</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>Course</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Scheduled on</TableCell>
                  <TableCell>Class Start Time</TableCell>
                  <TableCell>Class End Time</TableCell>
                  <TableCell>Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attendance.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.attendance_no}</TableCell>
                    <TableCell>{row.attendance_name}</TableCell>
                    <TableCell>{row.attendance_usrname}</TableCell>
                    <TableCell>{row.attandance_course}</TableCell>
                    <TableCell>{row.attendance_subject}</TableCell>
                    <TableCell>{row.attendance_scheduled}</TableCell>
                    <TableCell>{row.attendance_cls_start}</TableCell>
                    <TableCell>{row.attendance_cls_end}</TableCell>
                    <TableCell>{row.attendance_action}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TabPanel>
        </TabsUnstyled>
      </div>
    </div>
  )
}
