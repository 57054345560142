import { Auth } from "aws-amplify";
import { url } from '../Config/UrlRoute'
import { storeEncryptedData, getDecryptData,clearSession } from './SessionStoreService';
import { readService } from './HttpService'; 

// sign in and return user
async function signIn(username, password) {
    try {
        const endPoint = url.university.admin.get
        const auth = await Auth.signIn(username, password);
        let userdata = await readService(endPoint, { adminId: auth?.attributes?.['custom:_id'] });
        storeEncryptedData("uadminProfile",JSON.stringify(userdata?.data))
        getCurrentSession();
        return auth;
    } catch (error) {
        console.log("Sign in error "+JSON.stringify(error))
        throw error;
    }
}

// set new password and return user
async function completeNewPassword(user, password) {
    try {
        const auth = await Auth.completeNewPassword(user, password);
        return auth;
    } catch (error) {
        throw error;
    }
}

// Send confirmation code to user's email
async function forgotPassword(username) {
    try {
        const data = await Auth.forgotPassword(username);
        return data;
    } catch (err) {
        throw err;
    }
}

// Collect confirmation code and new password
async function forgotPasswordSubmit(username, code, newPassword) {
    try {
        const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
        return data;
    } catch (err) {
        throw err;
    }
}
//get current user session and store in local session using storeEncryptedData
async function getCurrentSession() {
    try {
        const data = await Auth.currentSession();
        if (data) {
            const cognitoUserSession = JSON.stringify(data);
            storeEncryptedData("cognitoUserSession", cognitoUserSession);
        }
    } catch (err) {
        throw err;
    }
}

//get current user session and store in local session using storeEncryptedData
async function signOut() {
    try {
        await  Auth.signOut();
        await clearSession()
        return
    } catch (err) {
        throw err;
    }
}

async function verifyCurrentUserAttribute(attribute) {
    try {
        const data = await Auth.verifyCurrentUserAttribute(attribute);
        return data;
    } catch (err) {
        throw err;
    }
}

async function verifyCurrentUserAttributeSubmit(attribute, code) {
    try {
        const data = await Auth.verifyCurrentUserAttributeSubmit(attribute, code);
        return data;
    } catch (err) {
        throw err;
    }
}
async function getCurrentUserInfo() {
    try {
        return await Auth.currentUserInfo();
    } catch (error) {
        throw error;
    }
}


// get token form local session store
async function getToken() {
    const cognitoUserSession = getDecryptData("cognitoUserSession");
    if (cognitoUserSession) {
        return JSON.parse(cognitoUserSession);
    }
    return null;
}

const currentAuthenticatedUser = async () => {
    return await Auth.currentAuthenticatedUser();
};

const changePassword = async (user, oldPassword, newPassword) => {
    return await Auth.changePassword(user, oldPassword, newPassword);
};

const updateUserAttributes = async (user, attributes) => {
    return await Auth.updateUserAttributes(user, {
        ...attributes
    });
};

export {
    signIn,
    completeNewPassword,
    forgotPassword,
    forgotPasswordSubmit,
    getCurrentSession,
    getToken,
    signOut,
    verifyCurrentUserAttribute,
    verifyCurrentUserAttributeSubmit,
    currentAuthenticatedUser,
    changePassword,
    updateUserAttributes,
    getCurrentUserInfo
};
