import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from 'react-router-dom';
import './EngagingStudentPerformance.scss';
import BackIcon from '../../../../Assets/commonImg/back.svg';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const TabPanel = ({ children, value, index }) => {
    return (
        <div hidden={value !== index}>
            {value === index && <div>{children}</div>}
        </div>
    );
};

const assignments = [
    { slno: 1, subjectName: 'Anatomy', assignmentTitle: 'Assignment Tittle', startDate: '15 Aug 2022', submissionDate: '30 Aug 2022' },
    { slno: 1, subjectName: 'Biotechnology', assignmentTitle: 'Assignment Tittle', startDate: '15 Aug 2022', submissionDate: '30 Aug 2022' },
    { slno: 1, subjectName: 'Anatomy', assignmentTitle: 'Assignment Tittle', startDate: '15 Aug 2022', submissionDate: '30 Aug 2022' },
    { slno: 1, subjectName: 'Biotechnology', assignmentTitle: 'Assignment Tittle', startDate: '15 Aug 2022', submissionDate: '30 Aug 2022' },
    { slno: 1, subjectName: 'Anatomy', assignmentTitle: 'Assignment Tittle', startDate: '15 Aug 2022', submissionDate: '30 Aug 2022' },
    { slno: 1, subjectName: 'Biotechnology', assignmentTitle: 'Assignment Tittle', startDate: '15 Aug 2022', submissionDate: '30 Aug 2022' },
    { slno: 1, subjectName: 'Anatomy', assignmentTitle: 'Assignment Tittle', startDate: '15 Aug 2022', submissionDate: '30 Aug 2022' },
    { slno: 1, subjectName: 'Biotechnology', assignmentTitle: 'Assignment Tittle', startDate: '15 Aug 2022', submissionDate: '30 Aug 2022' },
];
const assessments = [
    { slno: 1, subjectName: 'Anatomy', assessmentTitle: 'Assessment Tittle', startTime: '12:00 PM', endTime: '3:00 PM' },
    { slno: 1, subjectName: 'Biotechnology', assessmentTitle: 'Assessment Tittle', startTime: '12:00 PM', endTime: '3:00 PM' },
    { slno: 1, subjectName: 'Anatomy', assessmentTitle: 'Assessment Tittle', startTime: '12:00 PM', endTime: '3:00 PM' },
    { slno: 1, subjectName: 'Biotechnology', assessmentTitle: 'Assessment Tittle', startTime: '12:00 PM', endTime: '3:00 PM' },
    { slno: 1, subjectName: 'Anatomy', assessmentTitle: 'Assessment Tittle', startTime: '12:00 PM', endTime: '3:00 PM' },
    { slno: 1, subjectName: 'Biotechnology', assessmentTitle: 'Assessment Tittle', startTime: '12:00 PM', endTime: '3:00 PM' },
    { slno: 1, subjectName: 'Anatomy', assessmentTitle: 'Assessment Tittle', startTime: '12:00 PM', endTime: '3:00 PM' },
    { slno: 1, subjectName: 'Biotechnology', assessmentTitle: 'Assessment Tittle', startTime: '12:00 PM', endTime: '3:00 PM' },
];

export default function EngagingStudentPerformance({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/home/educatorPerformance', { replace: true });
    }

    const [activeTab, setActiveTab] = useState(0);
    const handleTabChange = (event, newActiveTab) => {
        setActiveTab(newActiveTab);
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className='engaging-student-performance-container'
            style={{
                width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
                marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
                transition: "0.5s",
                position: "absolute",
                top: "4rem",
                padding: "1%",

            }}>
            <div className='backBtn' onClick={goBack}><img src={BackIcon} width={'12px'} height={'13px'} marginTop={'5px'} /><div className='backText'>Back</div></div>
            <div className='engaging-student-performance-card p-3'>
                <div className='engaging-student-performance-tracker-title'>
                    Performance Tracker
                </div>
                <div>
                    <Tabs value={activeTab} variant='fullWidth' onChange={handleTabChange} className='my-4 tab-section' TabIndicatorProps={{
                        style: {
                            background: '#32496B',
                            borderRadius: '29px',
                            border: '3px solid blue !important',
                            borderBottom: 'none',
                            
                        }
                    }}>
                        <Tab className='col-6 tab' label="Assignment" />
                        <Tab className='col-6 tab' label="Assessment" />
                    </Tabs>
                    <TabPanel value={activeTab} index={0}>
                        <div>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer>
                                    <Table sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '0px 3px 6px #00000029',
                                        borderRadius: '0px 0px 5px 5px'
                                    }}>
                                        <TableHead>
                                            <TableRow className='table-row'>
                                                <TableCell align="center">SL .No</TableCell>
                                                <TableCell align="center">Subject Name</TableCell>
                                                <TableCell align="center">Assignment Title</TableCell>
                                                <TableCell align="center">Start Date</TableCell>
                                                <TableCell align="center">Submission Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {assignments
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                            <TableCell align="center">{row.slno}</TableCell>
                                                            <TableCell align="center">{row.subjectName}</TableCell>
                                                            <TableCell align="center">{row.assignmentTitle}</TableCell>
                                                            <TableCell align="center">{row.startDate}</TableCell>
                                                            <TableCell align="center">{row.submissionDate}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={assignments.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <div>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer>
                                    <Table sx={{
                                        background: '#FFFFFF',
                                        boxShadow: '0px 3px 6px #00000029',
                                        borderRadius: '0px 0px 5px 5px'
                                    }}>
                                        <TableHead>
                                            <TableRow className='table-row'>
                                                <TableCell align="center">SL .No</TableCell>
                                                <TableCell align="center">Subject Name</TableCell>
                                                <TableCell align="center">Assessment Title</TableCell>
                                                <TableCell align="center">Start Date</TableCell>
                                                <TableCell align="center">End Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {assessments
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                            <TableCell align="center">{row.slno}</TableCell>
                                                            <TableCell align="center">{row.subjectName}</TableCell>
                                                            <TableCell align="center">{row.assessmentTitle}</TableCell>
                                                            <TableCell align="center">{row.startTime}</TableCell>
                                                            <TableCell align="center">{row.endTime}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={assessments.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </TabPanel>
                </div>
            </div>
        </div>
    )
}
