import { readService } from "./HttpService";
import { url } from "../Config/UrlRoute";
import { getDecryptData } from "./SessionStoreService";

const getAssignedTaskProgress = (collegeId) => {
	const endPoint = url.university.dashboard.dashboard;
	const params = {
		action: "assigned-task-progress",
		collegeId,
	};
	return readService(endPoint, params);
};
const getRecentEntityCount = (collegeId) => {
	const endPoint = url.university.dashboard.dashboard;
	const params = {
		action: "recent:entity-counts",
		collegeId,
	};
	return readService(endPoint, params);
};
const getContentGraph = ({ year }) => {
	const endPoint = url.university.dashboard.dashboard;
	
	const params = {
		action: "recent:graph",
		graphType: "content-graph",
		year,
	};
	return readService(endPoint, params);
};
const getAttendanceGraph = async ({ year, educatorId }) => {
	try {
		if (year && educatorId) {
			const endPoint = url.university.dashboard.dashboard;
			const params = {
				action: "recent:graph",
				graphType: "attendance-graph",
				year,
				educatorId
			};
			return await readService(endPoint, params);
		} else {
			throw "Please check your payload "
		}


	} catch (error) {
		throw error;
	}
};
const getSubmissionGraph = ({ year, month }) => {
	const endPoint = url.university.dashboard.dashboard;
	const params = {
		action: "recent:graph",
		graphType: "submission-graph",
		year,
		month,
	};
	return readService(endPoint, params);
};

const getEducatorByPaginated = ({ offset, limit }) => {
	const endPoint = 'educator';
	const userData = JSON.parse(getDecryptData('uadminProfile'));
	const params = {
		universityId: userData?.universityId,
		offset,
		limit
	};
	return readService(endPoint, params);
}

export { getAssignedTaskProgress, getRecentEntityCount, getContentGraph, getAttendanceGraph, getSubmissionGraph, getEducatorByPaginated };
