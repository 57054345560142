import React, { useState } from 'react';
import './Setting.scss';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import MyProfile from '../MyProfile/MyProfile';
import ChangePassword from '../ChangePassword/ChangePassword';
import ChangeEmail from '../ChangeEmail/ChangeEmail';
import ChangePhone from '../ChangePhone/ChangePhone';
import { useEffect } from 'react';
import { currentAuthenticatedUser } from '../../../Services/AuthService';
import { getCurrentUniversityAdmin } from '../../../Services/UniversityAdminService';

export default function Setting({ sideNavState, setSideNavState }) {

    const [currentUserDetails, setCurrentUserDetails] = useState('');

    useEffect(() => {
        getCurrentUserDetails();
    }, []);

    const getCurrentUserDetails = async () => {
        try {
            const userDetails = await getCurrentUniversityAdmin();
            if (userDetails?.data) {
                setCurrentUserDetails(userDetails.data)
            }
        } catch (error) {
            console.log(`🚀 ~ file: Setting.js:24 ~ getCurrentUserDetails ~ error:`, error)
        }
    }

    return (
        <div className={'dashboard-container ' + (sideNavState ? 'fullWidthOpenSideNav' : 'halfWidthOpenSideNav')}>
            <div className='member-container'>
                <Tabs
                    defaultActiveKey="myProfile"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="myProfile" title="My Profile">
                        <MyProfile userProfile={currentUserDetails} />
                    </Tab>
                    <Tab eventKey="changePassword" title="Change Password">
                        <ChangePassword />
                    </Tab>
                    <Tab eventKey="changeEmail" title="Change Email">
                        <ChangeEmail userProfile={currentUserDetails} />
                    </Tab>
                    <Tab eventKey="changePhone" title="Change Phone">
                        <ChangePhone userProfile={currentUserDetails} />
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

