import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import style from "./MonthlyProgressChart.module.scss";
import HighchartsReact from "highcharts-react-official";
import { Col, Row } from "react-bootstrap";
import { NextPreviousNavButton } from "../NextPreviousNavButton/NextPreviousNavButton";
import { getEducatorByPaginated } from "../../Services/DashboardService";

const MonthlyProgressChart = ({ attendanceGraphCount, getAttendanceGraphByYear }) => {
    const [attendanceGraph, setAttendanceGraph] = useState();
    const [displayYear, setDisplayYear] = useState(new Date());
    const [educatorId, setEducatorId] = useState("");
    const [educatorList, setEducatorList] = useState([]);

    useEffect(() => {
        if (attendanceGraphCount && typeof attendanceGraphCount === "object") {
            setAttendanceGraph(attendanceGraphCount);
        }
    }, [attendanceGraphCount]);

    useEffect(() => {
        // getAttendanceGraphByYear({ year: displayYear.getFullYear() })
        getEducatorList(true);
    }, []);

    useEffect(() => {
        if (displayYear && educatorId) {
            getAttendanceGraphByYear({ year: displayYear.getFullYear(), educatorId });
        }
    }, [displayYear, educatorId]);

    const getEducatorList = (isSetValue) => {
        getEducatorByPaginated({})
            .then((resData) => {
                if (resData?.data?.educators && resData?.data?.educators?.length) {
                    setEducatorList(resData?.data?.educators);
                    if (isSetValue) {
                        setEducatorId(resData?.data?.educators[0]._id);
                    }
                } else {
                    setEducatorList([]);
                }
            })
            .catch((error) => {
                console.log(`🚀 ~ file: MonthlyProgressChart.js:27 ~ getEducatorByPaginated ~ error:`, error);
                setEducatorList([]);
            });
    };

    useEffect(() => {
        if (displayYear) {
            getAttendanceGraphByYear({ year: displayYear.getFullYear() });
        }
    }, [displayYear]);

    const onYearChange = (event) => {
        const { isNext, isPrevious } = event;
        let selectedYear = new Date(displayYear);
        console.log(`🚀 ~ file: MonthlyProgressChart.js:26 ~ onYearChange ~ selectedYear:`, isNext, isPrevious);
        if (isNext) {
            const data = selectedYear.setFullYear(selectedYear.getFullYear() + 1);
            setDisplayYear(new Date(data));
        }
        if (isPrevious) {
            const data = selectedYear.setFullYear(selectedYear.getFullYear() - 1);
            setDisplayYear(new Date(data));
        }
    };

    // getAttendanceGraphByYear
    const options = {
        chart: {
            type: "column",
        },
        title: {
            text: "",
        },
        xAxis: {
            categories: attendanceGraph?.map((x) => x.month),
        },
        yAxis: {
            min: 0,
            title: {
                text: "Classes conducted",
            },
        },
        tooltip: {
            shared: false,
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                pointWidth: 5,
                dashStyle: "DashDot",
            },
        },
        legend: {
            enabled: false,
        },
        series: [
            {
                data: attendanceGraph?.map((x) => x.count),
                color: "#582ACD",
            },
        ],
    };
    return (
        <Row>
            <Col className="col-12 p-0">
                <div className={[style.gap10Px]}>
                    <span className={[style.title]}> Monthly Progress</span>
                    <span className={[style.titleImg]} />
                </div>
            </Col>
            <Col className={[style.monthlyColumnChart, "col-12"]}>
                <div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                            <div className={style.classesConducted}>Classes conducted</div>
                            <div className={style.monthClasses}>
                                <div className="">X - axis : Months</div>
                                <div className="">Y - axis : No.of Classes</div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <select
                                className="custom-select-input no-arrow"
                                value={educatorId}
                                onChange={(e) => {
                                    setEducatorId(e.target.value);
                                }}
                            >
                                <option value="" selected disabled>
                                    Select Educator
                                </option>
                                {educatorList?.map((x) => {
                                    return (
                                        <option key={x._id} value={x._id}>
                                            {x?.fullName}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                    <div className="d-flex mb-3 mx-3 align-items-center">
                        <NextPreviousNavButton label={displayYear.getFullYear()} handleNavigationChange={onYearChange} />
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default MonthlyProgressChart;
