import { decryptText, encryptText } from './AesHelperService'


function getData(sessionName) {
    sessionStorage.getItem(sessionName);
}

function getDecryptData(sessionName) {
    return decryptText(sessionStorage.getItem(sessionName)) || 'null';
}

function storeData(sessionName, sessionData) {
    sessionStorage.setItem(sessionName, sessionData);
}

function storeEncryptedData(sessionName, sessionData) {
    const sessionDataEncrypt = encryptText(sessionData);
    storeData(sessionName, sessionDataEncrypt);
}

function removeItem(sessionItemName) {
    sessionStorage.removeItem(sessionItemName);
}

function clearSession() {
    sessionStorage.clear();
}

function decodeJWTToken(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return '%' + ('00' +
            c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}


export {
    getData,
    getDecryptData,
    storeData,
    storeEncryptedData,
    removeItem,
    clearSession,
    decryptText,
    encryptText,
    decodeJWTToken
}