import React, { useEffect } from "react";
import * as ReportService from "../../Services/ReportService";

import "./ReportTable.scss";
import backBtnIcon from "../../Assets/common/back_btn_icon.svg";
import download from "../../Assets/common/download.svg";
import { useNavigate } from "react-router-dom";
import { SelectCollegeModal } from "../SelectCollegeModal/SelectCollegeModal";
import downArrow from '../../Assets/commonImg/select-dropdown-arrow.svg';
import { Modal, TablePagination } from "@mui/material";
import { getcollegeList } from "../../Services/memberService";
import { format } from 'date-fns';


const ReportTable = ({ sideNavState, setSideNavState }) => {
  const navigate = useNavigate();
  const [openCollege, setCollegeOpen] = React.useState(false);
  const handleOpen = () => setCollegeOpen(true);
  const handleClose = () => setCollegeOpen(false);
  const [collegeName, setCollege] = React.useState('');
  const [collegeId, setCollegeId] = React.useState('');
  const [count, setTotalReport] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isLoading, setLoading] = React.useState(false);
  const [recordData, setRecordData] = React.useState([])

  const handleRequestReportClick = () => {
    navigate("/home/requestReport");
  };

  useEffect(() => {
    getCollegeList();
  }, []);

  const getCollegeList = async () => {

    getcollegeList().then((resData) => {
      if (resData?.data?.collegeList?.length) {
        const firstCollege = resData?.data?.collegeList[0];
        setCollege(firstCollege?.displayName)
        getReport(firstCollege._id, page, rowsPerPage);
        setCollegeId(firstCollege._id);
      }
    }).catch((error) => {
      console.log(`🚀 ~ file: ReportTable.js:31 ~ getcollegeList ~ error:`, error)
    }
    )
  }

  const getReport = (collegeId, offset, limit) => {
    if (collegeId) {
      ReportService.getReport(offset, limit, collegeId).then((resData) => {
        if (resData?.data?.reports?.length) {
          setRecordData(resData?.data?.reports);
        } else {
          setRecordData([]);
        }
        if (resData?.data?.totalRecords) {
          setTotalReport(resData?.data?.totalRecords || 0);
        }
        // setTotalReport
      }).catch((error) => {
        console.log(`🚀 ~ file: ReportTable.js:46 ~ ReportService.getReport ~ error:`, error)
      });
    }
  }

  const setCollegeName = (event) => {
    console.log(`🚀 ~ file: ReportTable.js:70 ~ setCollegeName ~ event:`, event)
    if (event.collegeId) {
      setRowsPerPage(10);
      setPage(0);
      setCollegeId(event.collegeId);
      getReport(event.collegeId, 0, 10);
    }
    setCollege(event.collegeName)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getReport(collegeId, newPage * rowsPerPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
    getReport(collegeId, 0, parseInt(event?.target?.value, 10));
  };

  return (
    <div
      id="reports_container"
      className={sideNavState ? "fullWidthOpenSideNav" : "halfWidthOpenSideNav"}
    >
      <div id="report-table">
        <div className="d-flex" style={{ margin: 32, gap: 33 }}>
          <div className="report-text">Requested Report List</div>
          <div className="request-report">
            <button onClick={handleRequestReportClick}>Request Report</button>
          </div>
          <div className="col-12 col-md-2">
            <div className='position-relative'>
              <input className="form-control w-100" type='text' onClick={handleOpen} readOnly value={collegeName} />
              <div className='down-arrow'> <img src={downArrow} width={'12px'} height={'13px'} /></div>

            </div>
          </div>
        </div>
        <div className="main-container row m-4">
          <table>
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>
                  <span className="row-numbers">Sl No.</span>
                </th>
                <th>
                  <span className="row-numbers">College</span>
                </th>
                <th>
                  <span className="row-numbers">Report Type</span>
                </th>
                <th>
                  <span className="row-numbers">Requested By</span>
                </th>
                <th>
                  <span className="row-numbers">Request Date</span>
                </th>
                <th>
                  <span className="row-numbers">Request Parameter</span>
                </th>
                <th>
                  <span className="row-numbers">Status</span>
                </th>
                <th>
                  <span className="row-numbers">Download</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {recordData?.map((report, index) => (
                (console.log(report)),
                <tr key={report?._id}>
                  <td>
                    <span className="row-numbers-td">{(page * (rowsPerPage)) + 1 + index}</span>
                  </td>
                  <td>
                    <span className="row-numbers-td">{'N/A'}</span>
                  </td>
                  <td>
                    <span className="row-numbers-td">{report?.reportType?.label}</span>
                  </td>
                  <td>
                    <span className="row-numbers-td">{report?.requestedBy?.userName}</span>
                  </td>
                  <td>
                    <span className="row-numbers-td">{
                      report?.requestedOn && !isNaN(new Date(report?.requestedOn)) ?
                        format(report?.requestedOn, 'dd-MMM-yyyy') : ''
                    }</span>
                  </td>
                  <td>
                    <span className="row-numbers-td">
                      {typeof report?.requestedParameter==='string'?report?.requestedParameter:''}
                    </span>
                  </td>
                  <td>
                    <span
                      className={`row-numbers-td ${report?.reportStatus ? report?.reportStatus?.toLowerCase() : ''}-text`}
                    >
                      {report?.reportStatus}
                    </span>
                  </td>
                  <td>
                    <span className="row-numbers-td">
                    <img
                        src={download}
                        alt="Download"
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Items per page"
            disabled={isLoading}
          />
        </div>
      </div>
      <Modal
        open={openCollege}
        onClose={handleClose}
        backdrop="static"
      >
        <div>
          <SelectCollegeModal {...{ handleClose, setCollegeName }} />
        </div>
      </Modal>
    </div>
  );
};

export default ReportTable;
