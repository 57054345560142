import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import { forgotPassword, forgotPasswordSubmit } from '../../../Services/AuthService';
import eyeSolid from '../../../Assets/login/eye-solid.svg'
import eyeSlash from '../../../Assets/login/eye-slash-solid.svg'
import './ForgotPassword.scss';
import { getMentorId, getUserRole, readService } from '../../../Services/HttpService';
import blueCurve from '../../../Assets/login/blueCurve.svg';
import appleStoreImg from '../../../Assets/login/app.svg'
import playStoreImg from '../../../Assets/login/play.png'
import mobileImg from '../../../Assets/login/mobile.png'
import resetPasswordImg from '../../../Assets/login/resetPasswordKey.png'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import loginDesktop from '../../../Assets/login/login-desktop.png'

export default function ForgotPassword() {

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [otp, setOtp] = useState("")
  const [passwordType, setPasswordType] = useState("password");
  const [showModal, setShowModal] = useState(false);
  const [errorCatch, setError] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isVerificationCodeIsSend, setVerificationCodeIsSend] = useState(false);

  const [snackbarState, setSnackbarState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
    message: '',
    alertSeverity: 'success',
    open: false
  });
  const { vertical, horizontal, open, message, alertSeverity } = snackbarState;

  const navigate = useNavigate();
  //  const dispatch = useDispatch();

  const usernameHandler = (e) => {
    setUsername(e.target.value)
  }

  const passwordHandler = (e) => {
    setPassword(e.target.value)
  }

  const otpHandler = (e) => {
    setOtp(e.target.value);
  }

  const closeModal = () => {
    setShowModal(false);
    setError(!errorCatch)
  }

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const toggleShowPassword = () => {
    setShowPassword(showPassword => !showPassword)
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarState({ ...snackbarState, open: false, message: '' });
  };

  //call forgotPassword function in AuthService
  const sendConfirmationCode = async () => {
    try {
      const isSendConfirmationCode = await forgotPassword(username);
      if (isSendConfirmationCode?.CodeDeliveryDetails) {
        const {
          AttributeName,
          DeliveryMedium,
          Destination
        } = isSendConfirmationCode?.CodeDeliveryDetails
        setSnackbarState({ ...snackbarState, open: true, message: `Verification code send successfully please check ${AttributeName}` });
        setVerificationCodeIsSend(true)
      }
    } catch (error) {
      const { code, name } = JSON.parse(JSON.stringify(error));
      if (code === 'UserNotFoundException') {
        // const [snackbarState, setSnackbarState] = React.useState({
        //   vertical: 'top',
        //   horizontal: 'right',
        //   message: '',
        //   alertSeverity: 'success',
        //   open: false
        // });

        setSnackbarState({ ...snackbarState, open: true, })
      }
      // else  if(){

      // }
    }

  };

  const forgotPasswordSubmitHandler = async () => {
    try {
      const passwordChange = await forgotPasswordSubmit(username, otp, password)
      navigate('/login');
    } catch (error) {
      console.log(`🚀 ~ forgotPasswordSubmitHandler ~ error:`, error)
    }
  };
  const forgetPasswordContain = <>
    <div className="col-md-4 offset-md-1">
      <div className='login-form-container'>
        {/* <img src={resetPasswordImg} alt='' className='reset-password-circle'></img> */}
        <p className="main-text d-flex justify-content-center mt-4"><span className="forgotText">Forgot</span>&nbsp;&nbsp;<span className="passwordText">Password?</span></p>
        <p className="enterUserNameText">Please enter your username to receive the OTP</p>
        <input className='input-block' type='text' placeholder='User name' value={username || ''} onChange={usernameHandler}></input>
        <div className='text-center login-btn-data'>
          <input type="submit" value="Reset Password" className="btn btn-reset" id="login_btn" onClick={sendConfirmationCode} />
        </div>
      </div>
    </div>
  </>
  const verifyForgetPasswordContain =
    <>
      <div className="col-md-4 offset-md-1">
        <div className='login-form-container'>
          {/* <img src={resetPasswordImg} alt='' className='reset-password-circle'></img> */}
          <p className="main-text d-flex mt-4"><span className="forgotText">Set</span>&nbsp;&nbsp;<span className="passwordText">New Password</span></p>
          <p className="enterUserNameText text-left">Please enter OTP</p>
          <input className='input-block' type='text' placeholder='OTP' value={otp || ''} onChange={otpHandler}></input>
          <p className="enterUserNameText ">Please enter Password</p>
          <div className='position-relative'>
            <input className='input-block' type={showPassword ? 'text' : 'password'} placeholder='password' onChange={passwordHandler}></input>
            <div className='show-password' onClick={toggleShowPassword}>
              <img src={showPassword ? eyeSlash : eyeSolid} alt='' /></div>
          </div>
          <div className='text-center login-btn-data'>
            <input type="submit" value="Set New Password" className="btn btn-reset" id="login_btn" onClick={forgotPasswordSubmitHandler} />
          </div>
        </div>
      </div>
    </>

  return (
    <>

      <div className='container-fluid forgot-password-container p-0'>
        <div id="login_bg">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-12 d-flex justify-content-center forgot-backg-img">
                <div className="form-content d-flex flex-column mt-5 pb-3 ">
                  <div className="loginFormContent1 my-auto text-center px-4">
                    <h5 className="text-center">Welcome to ZLIPP LMS</h5>
                    <p className="text-center"><b> India's leading online learning platform for all medical aspirants. Download the application by
                      clicking on the link below and learn anytime, anywhere.</b></p>
                    <img className="img-fluid backg-img2 mt-3" src={loginDesktop} alt="" />
                  </div>
                </div>
              </div>
              {
                isVerificationCodeIsSend
                  ? verifyForgetPasswordContain
                  : forgetPasswordContain
              }

            </div>
          </div>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>

  )
}
