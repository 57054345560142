import React from 'react'
import './MarksInputScreen.scss'
import backBtnIcon from '../../../../../Assets/common/back_btn_icon.svg';
import { useNavigate } from 'react-router-dom';
import userImg from '../../../../../Assets/common/userImg.png'

export default function MarksInputScreen({ sideNavState, setSideNavState }) {

    const navigate = useNavigate();

    const navigateBack = () => {
        navigate(-1);
    }

    const studentData = [
        {studentName: 'Anatomy', internalMarks: 255, externalMarks: 455, total: 100},
        {studentName: 'Anatomy', internalMarks: 255, externalMarks: 455, total: 100},
        {studentName: 'Anatomy', internalMarks: 255, externalMarks: 455, total: 100},
        {studentName: 'Anatomy', internalMarks: 255, externalMarks: 455, total: 100}
    ]

    return (
        <div id='marks-input-screen' className={(sideNavState ? 'fullWidthOpenSideNav' : 'halfWidthOpenSideNav')}>
            <div className='m-2'>
                <span className='back-btn-card mb-2' onClick={navigateBack}>
                    <img src={backBtnIcon} alt='' />
                    <p>Back</p>
                </span>
                <div className='main-container'>
                    <div className='row mx-0'>
                        <div className='col-9'>
                            <p className='title-text'>Student MarksSheet</p>
                            <div className='blue-card'>
                                Final Exam Markssheet
                            </div>
                            <div className='remarks-card'>
                                <div className='check-box-card'>
                                    <p>Remark</p>
                                    <div>
                                        <input type='radio' name='remark'/>
                                        <label className='label-pass'>Pass</label>
                                    </div>
                                    <div>
                                        <input type='radio' name='remark'/>
                                        <label className='label-fail'>Fail</label>
                                    </div>
                                </div>
                                <div>
                                    <button className='custom-btn btn-primary'>Edit</button>
                                </div>
                            </div>
                            <div className='table-container'>
                                <table className='custom-table'>
                                    <thead>
                                        <tr>
                                            <th>Subject Name</th>
                                            <th>Internal Marks</th>
                                            <th>External Marks</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {studentData && studentData.map((item) => {
                                        return (
                                        <tr>
                                            <td>{item.studentName}</td>
                                            <td> 
                                                <input className='marks-input' type='number'/>
                                                <span>/</span>{item.internalMarks}
                                            </td>
                                            <td>
                                                <input className='marks-input' type='number'/>
                                                <span>/</span>{item.externalMarks}
                                            </td>
                                            <td>
                                                <input className='marks-input' type='number'/>
                                                <span>/</span>{item.total}
                                            </td>
                                        </tr>)
                                    })}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={2}>Total Marks</td>
                                            <td colSpan={2}>
                                            <input className='marks-input' type='number'/>
                                                <span>/</span><span>1000</span>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className='col-3 user-image-card'>
                             <div className='user-image'>
                                <img src={userImg} alt=''/>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
