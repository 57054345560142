import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import './AddNewEvent.scss';
import BackIcon from '../../../../Assets/commonImg/back.svg';
import addColleges from '../../../../Assets/commonImg/add-colleges.svg';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Swal from 'sweetalert2';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    background: ' #FFFFFF',
    borderRadius: '9px',
    boxShadow: 24,
    p: 4,
    width: '70%',
     border:'none',
};
   
export default function AddNewEvent({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/home/grievance', { replace: true });
    }
    const addCollege = () => {
        navigate('collegeList', { replace: true });
    }
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [value, setValue] = useState('');
    const showAlert = () => {
        Swal.fire(
            '',
            'Successful!',
            ''
          )
      };
    return (
        <div className='add-new-event-container'
            style={{
                width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
                marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
                transition: "0.5s",
                position: "absolute",
                top: "4rem",
                padding: "1%",

            }}>
            <div className='backBtn'><img src={BackIcon} width={'12px'} height={'13px'} marginTop={'5px'} /><div className='backText' onClick={goBack}>Back</div></div>
            <div className='add-new-event-card mt-2 px-3'>
                <div className='new-event-title'>
                    <div className='mb-1'>New Event</div>
                </div>
                <div className='new-event-content mt-4 p-4'>
                    <div className='row'>
                        <div className='col-lg-6 col-12'>
                            <p className='event-label'>Title</p>
                            <input type='text' className='custom-input' />
                        </div>
                        <div className='col-lg-6 col-12'>
                            <p className='event-label'>Venue</p>
                            <input type='text' className='custom-input' />
                        </div>
                    </div>
                    <div className='my-4 text-editor-section'>
                        <ReactQuill theme="snow" value={value} onChange={setValue} className='text-editor' />
                    </div>
                    <div className='time-date-content mt-5 pt-2'>
                        <div className='date-title'>Set Date & Time</div>
                        <div className='date-section mt-2 p-3' style={{ columnGap: '40px' }}>
                            <div className='row' style={{ rowGap: '10px' }}>
                                <div className='col-lg-6 col-12'>
                                    <div className='row' style={{ rowGap: '10px' }}>
                                        <div className='col-lg-6 col-12' style={{ borderRight: '1px solid #707070' }}>
                                            <p className='date-label'>Start Date</p>
                                            <input type='date' className='select-date-input' />
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <p className='date-label'>Start Time</p>
                                            <input type='date' className='select-date-input' />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className='row' style={{ rowGap: '10px' }}>
                                        <div className='col-lg-6 col-12' style={{ borderRight: '1px solid #707070' }}>
                                            <p className='date-label'>End Date</p>
                                            <input type='date' className='select-date-input' />
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <p className='date-label'>End Time</p>
                                            <input type='date' className='select-date-input' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='note mt-3'>Note: You can select either individual college or all colleges.</div>
                    </div>
                    <div className='d-flex flex-wrap justify-content-start my-4' style={{ columnGap: '50px', rowGap: '10px' }}>
                        <div className='send-invite-to'>Send Invite to :</div>
                        <div className='d-flex align-items-center'><div className='send-invite-to' onClick={addCollege} style={{ cursor: 'pointer' }}><img src={addColleges} width={'17px'} height={'17px'} style={{ marginRight: '10px' }} />Add College(s)</div></div>
                    </div>
                </div>
                <div className='d-flex flex-wrap justify-content-start gap-3 my-4'>
                    <div className='cancel'>Cancel</div>
                    <div className='preview' onClick={handleOpen}>Preview</div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className='preview-modal'
                >
                    <Box sx={style}>
                        <div className='presentation-content p-3'>
                            <div className='d-flex justify-content-center paper-presentation'>Paper Presentation</div>
                            <div className='d-flex flex-wrap justify-content-between'>
                                <div className='d-flex flex-column mt-3'>
                                    <div className='d-flex flex-wrap gap-2'>
                                        <div className='presentation-details'>Start date : 22-11-21</div>
                                        <div className='presentation-details'>Start Time : 12 : 00 PM</div>
                                    </div>
                                    <div className='presentation-details mt-2'>Venue : PSGVP Medical College Bangalore</div>
                                </div>
                                <div className='d-flex flex-wrap gap-2 mt-3'>
                                    <div className='presentation-details'>Start Time : 12 : 00 PM</div>
                                    <div className='presentation-details'>End Time : 12 : 00 PM</div>
                                </div>
                            </div>
                        </div>
                        <div className='presentation-description my-4 p-3'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lore
                        </div>
                       <div className='d-flex justify-content-center' style={{columnGap:'30px', rowGap:'10px'}}>
                        <div className='cancel-btn'>Cancel</div>
                        <div className='create-btn' onClick={showAlert}>Create</div>
                       </div>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}
