import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ComplaintHistory.scss';
import BackIcon from '../../../../Assets/commonImg/back.svg';
import userFirst from '../../../../Assets/commonImg/user-first.png';
import userSecond from '../../../../Assets/commonImg/user-second.png';
import sendMessage from '../../../../Assets/commonImg/add-circle-icon.svg';
import files from '../../../../Assets/commonImg/files.svg';
import addMore from '../../../../Assets/commonImg/plus-circle.svg';
import notepad from '../../../../Assets/commonImg/notepad.svg';
import imageIcon from '../../../../Assets/commonImg/image-square.svg';
import camera from '../../../../Assets/commonImg/camera.svg';

export default function ComplaintHistory({ sideNavState, setSideNavState }) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate('/home/grievance/complaintStatus', { replace: true });
    }

    return (
        <div className='grievance-container'
            style={{
                width: sideNavState ? "calc(100% - 20%)" : "calc(100% - 10%)",
                marginLeft: sideNavState ? "calc(100% - 80%)" : "10%",
                transition: "0.5s",
                position: "absolute",
                top: "4rem",
                padding: "1%",

            }}>
            <div className='backBtn' onClick={goBack}><img src={BackIcon} width={'12px'} height={'13px'} marginTop={'5px'} /><div className='backText'>Back</div></div>
            <div className='grievance-card mt-2 p-5'>
                <div className='chat-forum'>
                    <div className='d-flex justify-content-between chat-forum-header p-3'>
                        <div className='title-of-complaint'>Title of the Complaint</div>
                        <div className='complaint-date'>27 July 2022</div>
                    </div>
                    <div className='message-section p-3'>
                        <div className='d-flex flex-column justify-content-end align-items-end'>
                            <div className='studentName'>Student Name<img src={userFirst} width={'30px'} height={'30px'} style={{ marginLeft: '5px' }} /></div>
                            <div className='message-content p-2 mx-3'>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text</p>
                                <div className='d-flex justify-content-end gap-2'>
                                    <div className='fileName'><img src={files} width={'11px'} height={'11px'} style={{ marginRight: '2px' }} />File Name</div>
                                    <div className='fileName'>View</div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center mx-3'>
                                <div className='d-flex message-time'>10:30 pm</div>
                                <div className='d-flex add-more'><img src={addMore} width={'11px'} height={'11px'} style={{ marginRight: '2px' }} />Add more files</div>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-start align-items-start'>
                            <div className='studentName'><img src={userSecond} width={'30px'} height={'30px'} style={{ marginRight: '5px' }} />University Support</div>
                            <div className='reply-message-content p-2 mx-3'>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text</p>
                                <div className='d-flex justify-content-end gap-2'>
                                    <div className='fileName'><img src={files} width={'11px'} height={'11px'} style={{ marginRight: '2px' }} />File Name</div>
                                    <div className='fileName'>View</div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center mx-3'>
                                <div className='d-flex message-time'>10:30 pm</div>
                                <div className='d-flex add-more'><img src={addMore} width={'11px'} height={'11px'} style={{ marginRight: '2px' }} />Add more files</div>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-end align-items-end'>
                            <div className='studentName'>Student Name<img src={userFirst} width={'30px'} height={'30px'} style={{ marginLeft: '5px' }} /></div>
                            <div className='message-content p-2 mx-3'>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text</p>
                                <div className='d-flex justify-content-end gap-2'>
                                    <div className='fileName'><img src={files} width={'11px'} height={'11px'} style={{ marginRight: '2px' }} />File Name</div>
                                    <div className='fileName'>View</div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center mx-3'>
                                <div className='d-flex message-time'>10:30 pm</div>
                                <div className='d-flex add-more'><img src={addMore} width={'11px'} height={'11px'} style={{ marginRight: '2px' }} />Add more files</div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center chat-forum-footer'>
                        <div className='d-flex flex-column'>
                            <div className='d-flex'>
                                <div className='px-2' style={{ borderRight: '1px solid #3B86FF', margin: '5px 0' }}><img src={userFirst} width={'30px'} height={'30px'} /></div>
                                <div className='p-2'><input type='text' className='massage-input-field' placeholder='Type your message here' /> </div>
                            </div>
                            <div className='px-5'>
                                <img src={notepad} width={'20px'} height={'20px'} />
                                <img src={imageIcon} width={'20px'} height={'20px'} style={{ margin: '0 15px' }} />
                                <img src={camera} width={'21px'} height={'21px'} />
                            </div>
                        </div>
                        <div className='send-message-btn mx-3'><img src={sendMessage} width={'15px'} height={'15px'} marginTop={'5px'} /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
