import React, { useState } from 'react'
import './MarksCardUpload.scss'
import searchIcon from '../../../../Assets/common/search_icon.svg';
import searchCloseIcon from '../../../../Assets/common/search_close_icon.png';
import uploadIcon from '../../../../Assets/common/upload.svg'
import { TablePagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function MarksCardUpload({ sideNavState, setSideNavState }) {

    const collegeData = [
        {enrolmentId: '2334456', studentName: 'Mr. Rahul p', userName: 'abc123@gmail.com', collegeName: 'PSGV College of medical Bangalore', courseName: 'MBBS 1st Year', department: 'Anatomy', date: '5 May 20233'},
        {enrolmentId: '2334456', studentName: 'Mr. Rahul p', userName: 'abc123@gmail.com', collegeName: 'PSGV College of medical Bangalore', courseName: 'MBBS 1st Year', department: 'Anatomy', date: '5 May 20233'},
        {enrolmentId: '2334456', studentName: 'Mr. Rahul p', userName: 'abc123@gmail.com', collegeName: 'PSGV College of medical Bangalore', courseName: 'MBBS 1st Year', department: 'Anatomy', date: '5 May 20233'},
        {enrolmentId: '2334456', studentName: 'Mr. Rahul p', userName: 'abc123@gmail.com', collegeName: 'PSGV College of medical Bangalore', courseName: 'MBBS 1st Year', department: 'Anatomy', date: '5 May 20233'},
        {enrolmentId: '2334456', studentName: 'Mr. Rahul p', userName: 'abc123@gmail.com', collegeName: 'PSGV College of medical Bangalore', courseName: 'MBBS 1st Year', department: 'Anatomy', date: '5 May 20233'},
        {enrolmentId: '2334456', studentName: 'Mr. Rahul p', userName: 'abc123@gmail.com', collegeName: 'PSGV College of medical Bangalore', courseName: 'MBBS 1st Year', department: 'Anatomy', date: '5 May 20233'},
        {enrolmentId: '2334456', studentName: 'Mr. Rahul p', userName: 'abc123@gmail.com', collegeName: 'PSGV College of medical Bangalore', courseName: 'MBBS 1st Year', department: 'Anatomy', date: '5 May 20233'},
    ]
    const [searchText, setSearchText] = useState('')
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [offset, setOffset] = useState(0);
    const navigate = useNavigate();

    const handleSearchInput = (event) => {
        // setSearchText(event.target.value);
    }

    const handleSearchClick = () => {
        // setPage(0);
        // let offset = 0;
        // getHealthCampList(offset, searchText, filterDataObj)
    }

    const handleSearchClear = () => {
        //  setSearchText('');
        //  setPage(0);
        //  let offset = 0;
        //  getHealthCampList(offset, '', filterDataObj);
    }

    const handleChangePage = (event, newPage) => {
        // let pageIndex = newPage;
        // setPage(newPage);
        // let offset = pageSize * pageIndex;
        // getHealthCampList(offset, searchText.filterDataObj)
        // setOffset(offset)
    };

    const handleChangeRowsPerPage = (event) => {
        // setPageSize(parseInt(event.target.value, 10));
        // setPage(0);
    };

    const onMarksUploadClick = (selectedStudent) => {
        navigate('/home/inputmarks');
    }

    return (
        <div className={'markscard-upload ' + (sideNavState ? 'fullWidthOpenSideNav' : 'halfWidthOpenSideNav')}>
            <div className='main-container'>
                <div className='row mx-2 mb-3'>
                    <div className='col-md-3 col-6'>
                        <p className='assign-text'>Student List</p>
                    </div>
                    <div className='col-md-5 col-6'>
                        <label className='label-input'>
                            <input type='text' className='form-control search-input' placeholder='Search by name'
                                onChange={handleSearchInput} value={searchText} />
                            {searchText && <button className='search-btn-close' onClick={handleSearchClear}>
                                <img src={searchCloseIcon} alt='' width={'24px'} />
                            </button>}
                            <button className='search-btn' onClick={handleSearchClick}>
                                <img src={searchIcon} alt='' />
                                <span>Search</span>
                            </button>
                        </label>
                    </div>
                </div>
                <div className='table-container'>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>Enrolment No.</th>
                                <th>Student Name</th>
                                <th>User Name</th>
                                <th>College Name</th>
                                <th>Course Name</th>
                                <th>Department</th>
                                <th>Date Added</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {collegeData && collegeData.map((items, index) => {
                                return (
                                    <tr>
                                        <td>
                                            <span className="round">
                                                <input type="checkbox" id="checkbox" />
                                                <label for="checkbox"></label>
                                            </span>
                                            {items.enrolmentId}
                                        </td>
                                        <td>{items.studentName}</td>
                                        <td>{items.userName}</td>
                                        <td>{items.collegeName}</td>
                                        <td>{items.courseName}</td>
                                        <td>{items.department}</td>
                                        <td>{items.date}</td>
                                        <td>
                                            <span className='d-flex cursor-pointer' onClick={() => onMarksUploadClick(items)}>
                                                <img src={uploadIcon} alt='' style={{marginRight: '3px'}}/>
                                                <span>upload</span>
                                            </span>
                                        </td>
                                        {/* <td>
                                            <img src={assign} alt='' onClick={() => onViewPatientClick(items)} />
                                        </td> */}
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
                <TablePagination
                    component="div"
                    count={totalRecords}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[5, 10, 20, 40]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    )
}
